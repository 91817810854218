import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import SeasonContext from "../../context/SeasonContext";
import {
  TextField,
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";

const CreateSeasons = () => {
  const { id: courseId } = useParams();
  const { t } = useTranslation();
  const {
    season_registered,
    season_name_required,
    en_season_name_required,
    add_season,
    season_name_input,
    en_season_name_input,
    save
  } = t("lngCreateSessions");
  const { postMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getSeasonsByCourseId } = useContext(SeasonContext);
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [seasonName, setSeasonName] = useState("");
  const [seasonNameError, setSeasonNameError] = useState("");
  const [enSeasonName, setEnSeasonName] = useState("");
  const [enSeasonNameError, setEnSeasonNameError] = useState("");

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        course_id: courseId,
        season_name: seasonName,
        en_season_name: enSeasonName
      };

      await postMethod("/seasons", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "فصل با موفقیت ثبت شد"
        season_registered
      );

      getSeasonsByCourseId(courseId);

    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.season &&
          ex.response.data.errors.season.length > 1) {
          setSeasonNameError(ex.response.data.errors.season_name[0]);
        } else {
          setSeasonNameError(ex.response.data.errors.season_name);
        }
        if (ex.response.data.errors.en_season &&
          ex.response.data.errors.en_season.length > 1) {
          setEnSeasonNameError(ex.response.data.errors.en_season_name[0]);
        } else {
          setEnSeasonNameError(ex.response.data.errors.en_season_name);
        }
      }
    }

    setLoadingStoreButton(true);
  };

  const handleSeasonNameChange = (e) => {
    const currentValue = e.target.value;
    setSeasonName(currentValue);
    if (currentValue?.length === 0) {
      // setSeasonNameError("تکمیل گزینه نام فصل الزامی است");
      setSeasonNameError(season_name_required);
    } else {
      setSeasonNameError("");
    }
  };

  const handleEnSeasonNameChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnSeasonName(currentValue);
    if (currentValue?.length === 0) {
      // setEnSeasonNameError("تکمیل گزینه نام فصل الزامی است");
      setEnSeasonNameError(en_season_name_required);
    } else {
      setEnSeasonNameError("");
    }
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Box mb="50px">
        <Fade triggerOnce={true}>
          <Card>
            <CardHeader title={add_season} />
            <CardContent>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={seasonName ?? ""}
                    onChange={handleSeasonNameChange}
                    autoComplete="off"
                    // label="نام فصل"
                    label={season_name_input}
                    required
                    inputProps={{
                      style: { direction: "rtl", textAlign: "right" },
                      maxLength: 40
                    }}
                    error={Boolean(seasonNameError)}
                    helperText={seasonNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={enSeasonName ?? ""}
                    onChange={handleEnSeasonNameChange}
                    autoComplete="off"
                    // label="نام فصل"
                    label={en_season_name_input}
                    required
                    inputProps={{
                      style: { direction: "ltr", textAlign: "left" },
                      maxLength: 40
                    }}
                    error={Boolean(enSeasonNameError)}
                    helperText={enSeasonNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <LoadingButton
                    fullWidth
                    variant="outlined"
                    loadingPosition="start"
                    size="large"
                    loading={!loadingStoreButton}
                    disabled={
                      !seasonName ||
                      !enSeasonName
                    }
                    startIcon={<SaveOutlined />}
                    onClick={handleStore}
                    sx={{ height: "56px" }}
                  >
                    {/* ذخیره */}
                    {save}
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fade>
      </Box>
    </Box >
  );
};

export default CreateSeasons;
