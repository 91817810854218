import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
    Button,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    useTheme,
    CircularProgress,
    Grid,
    CardActions,
    DialogContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import { separateNumbers } from "../../components/PriceFormat";
import { tokens } from "../../theme";

const CreateReceipt = (props) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        instructor_bank_receipt_registered,
        total_required,
        en_total_required,
        destination_card_number_required,
        issue_tracking_required,
        instructor_input_label,
        total_with_unit,
        en_total_with_unit,
        destination_cards_input_label,
        issue_tracking_input_label,
        cancel,
        save
    } = t("lngCreateReceipt");
    const { postMethod, postMethodByPageId } = useServices();
    const { handleOpenSnackbar } = useSnackbarContext();
    const { handleCloseDialog } = useDialogContext();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loadingStoreButton, setLoadingStoreButton] = useState(true);
    const [total, setTotal] = useState(0);
    const [totalError, setTotalError] = useState("");
    const [enTotal, setEnTotal] = useState(0);
    const [enTotalError, setEnTotalError] = useState("");
    const [destinationCardNumber, setDestinationCardNumber] = useState("");
    const [destinationCardNumberError, setDestinationCardNumberError] = useState("");
    const [issueTracking, setIssueTracking] = useState("");
    const [issueTrackingError, setIssueTrackingError] = useState("");
    const [coursesInstructorsLoading, setCoursesInstructorsLoading] = useState(true);
    const [coursesInstructors, setCoursesInstructors] = useState([]);
    const [instructorId, setInstructorId] = useState("");
    const [destinationCardsLoading, setDestinationCardsLoading] = useState(true);
    const [destinationCards, setDestinationCards] = useState([]);

    useEffect(() => {
        setInstructorId("");
        getCoursesInstructors();
        // eslint-disable-next-line
    }, [props?.params]);

    useEffect(() => {
        getInstructorBankAccounts();
        getTotal();
        // eslint-disable-next-line
    }, [instructorId]);

    const getCoursesInstructors = () => {
        setCoursesInstructorsLoading(false);

        var list = [];
        props?.params?.courses.forEach((course) => {
            list.push(course?.user);
        });

        list = list?.filter((value, index, self) =>
            index === self?.findIndex((t) => (
                t.id === value?.id
            ))
        );
        setCoursesInstructors(list);

        setCoursesInstructorsLoading(true);
    }

    const handleStore = async () => {
        setLoadingStoreButton(false);

        try {
            const obj = {
                transaction_id: props?.params?.id,
                user_bank_account_id: destinationCardNumber,
                total: total,
                en_total: enTotal,
                issue_tracking: issueTracking,
            };

            await postMethod("/receipts", obj);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "رسید بانکی استاد با موفقیت ثبت شد"
                instructor_bank_receipt_registered
            );

            handleResetDialogItems();
        } catch (ex) {
            if (ex.response && ex.response.status === 422) {
                if (ex.response.data.errors.total &&
                    ex.response.data.errors.total.length > 1) {
                    setTotalError(ex.response.data.errors.total[0]);
                } else {
                    setTotalError(ex.response.data.errors.total);
                }
                if (ex.response.data.errors.en_total &&
                    ex.response.data.errors.en_total.length > 1) {
                    setEnTotalError(ex.response.data.errors.en_total[0]);
                } else {
                    setEnTotalError(ex.response.data.errors.en_total);
                }
                if (ex.response.data.errors.destination_card_number &&
                    ex.response.data.errors.destination_card_number.length > 1) {
                    setDestinationCardNumberError(ex.response.data.errors.destination_card_number[0]);
                } else {
                    setDestinationCardNumberError(ex.response.data.errors.destination_card_number);
                }
                if (ex.response.data.errors.issue_tracking &&
                    ex.response.data.errors.issue_tracking.length > 1) {
                    setIssueTrackingError(ex.response.data.errors.issue_tracking[0]);
                } else {
                    setIssueTrackingError(ex.response.data.errors.issue_tracking);
                }
            }
        }

        setLoadingStoreButton(true);
    };

    const getInstructorBankAccounts = async () => {
        setDestinationCardsLoading(false);

        try {
            const obj = {
                user_id: instructorId,
                page: 1,
                rows: 0,
            };

            const response = await postMethodByPageId("/user_bank_accounts/list", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                setDestinationCards(data.data);
            }

        } catch (ex) {

        }

        setDestinationCardsLoading(true);
    };

    const getTotal = () => {
        var sum = 0;
        var enSum = 0;
        props?.params?.courses.forEach((course) => {
            if (course?.user?.id === instructorId) {
                sum = sum + (course?.pivot?.price - (course?.pivot?.price * (course?.pivot?.discount + course?.pivot?.front_page_percentage + course?.pivot?.membership_percentage)) / 100);
                enSum = enSum + (course?.pivot?.en_price - (course?.pivot?.en_price * (course?.pivot?.discount + course?.pivot?.front_page_percentage + course?.pivot?.membership_percentage)) / 100);
            }
        });

        setTotal(separateNumbers(sum, "settlementNumToLetter"));
        setEnTotal(enSum);
    };

    const handleInstructorChange = (e) => {
        setDestinationCardNumber("");
        const currentValue = e.target.value;
        setInstructorId(currentValue);
    };

    const handleTotalChange = (e) => {
        const currentValue = e.target.value;
        setTotal(currentValue);
        if (currentValue?.length === 0) {
            // setTotalError("تکمیل گزینه مبلغ نهایی الزامی است");
            setTotalError(total_required);
        } else {
            setTotalError("");
        }
    };

    const handleEnTotalChange = (e) => {
        const currentValue = e.target.value;
        setEnTotal(currentValue);
        if (currentValue?.length === 0) {
            // setEnTotalError("تکمیل گزینه مبلغ نهایی الزامی است");
            setEnTotalError(en_total_required);
        } else {
            setEnTotalError("");
        }
    };

    const handleDestinationCardNumberChange = (e) => {
        const currentValue = e.target.value;
        setDestinationCardNumber(currentValue);
        if (currentValue?.length === 0) {
            // setDestinationCardNumberError("تکمیل گزینه شماره کارت مقصد الزامی است");
            setDestinationCardNumberError(destination_card_number_required);
        } else {
            setDestinationCardNumberError("");
        }
    };

    const handleIssueTrackingChange = (e) => {
        const currentValue = e.target.value;
        setIssueTracking(currentValue);
        if (currentValue?.length === 0) {
            // setIssueTrackingError("تکمیل گزینه شماره پیگیری الزامی است");
            setIssueTrackingError(issue_tracking_required);
        } else {
            setIssueTrackingError("");
        }
    };

    const handleResetDialogItems = () => {
        setInstructorId("");
        setTotal(0);
        setTotalError("");
        setEnTotal(0);
        setEnTotalError("");
        document.getElementById("settlementNumToLetter").innerHTML = "";
        setDestinationCardNumber("");
        setDestinationCardNumberError("");
        setIssueTracking("");
        setIssueTrackingError("");
        handleCloseDialog();
    };

    return (
        <Fade triggerOnce={true}>
            <DialogContent>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {!coursesInstructorsLoading ? (
                            <FormControl required fullWidth>
                                <InputLabel id="courses-instructors-loading">
                                    {/* استاد مربوطه */}
                                    {instructor_input_label}
                                </InputLabel>
                                <Select value="" labelId="courses-instructors-loading" label="استاد مربوطه">
                                    <MenuItem>
                                        <CircularProgress />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <FormControl required fullWidth>
                                <InputLabel id="courses-instructors-label">
                                    {/* استاد مربوطه */}
                                    {instructor_input_label}
                                </InputLabel>
                                <Select
                                    labelId="courses-instructors-label"
                                    // label="استاد مربوطه"
                                    label={instructor_input_label}
                                    value={instructorId ?? ""}
                                    onChange={handleInstructorChange}
                                >
                                    {coursesInstructors?.map((item, index1) => (
                                        <MenuItem key={index1} value={item?.id}>
                                            {direction === "rtl" ? item?.full_name : item?.en_full_name ?? item?.full_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={total ?? ""}
                            onChange={handleTotalChange}
                            onKeyUp={() => setTotal(separateNumbers(total, "settlementNumToLetter"))}
                            autoComplete="off"
                            // label="مبلغ نهایی (تومان)"
                            label={total_with_unit}
                            required
                            inputProps={{ maxLength: 20 }}
                            error={Boolean(totalError)}
                            helperText={totalError}
                        />
                        <Box
                            color={theme.palette.mode === "light" ?
                                colors.blueAccent[500]
                                :
                                colors.orangeAccent[500]
                            }
                            id="settlementNumToLetter"
                            sx={{ textAlign: "justify", fontSize: "12px" }}
                        ></Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={enTotal ?? ""}
                            onChange={handleEnTotalChange}
                            autoComplete="off"
                            // label="مبلغ نهایی ($)"
                            label={en_total_with_unit}
                            required
                            inputProps={{ maxLength: 20 }}
                            error={Boolean(enTotalError)}
                            helperText={enTotalError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {!destinationCardsLoading ? (
                            <FormControl required fullWidth>
                                <InputLabel id="receipt-instructor-loading">
                                    {/* شماره کارت مقصد */}
                                    {destination_cards_input_label}
                                </InputLabel>
                                <Select value="" labelId="receipt-instructor-loading"
                                    // label="شماره کارت مقصد"
                                    label={destination_cards_input_label}
                                >
                                    <MenuItem>
                                        <CircularProgress />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <FormControl required fullWidth>
                                <InputLabel id="receipt-instructor-label">
                                    {/* شماره کارت مقصد */}
                                    {destination_cards_input_label}
                                </InputLabel>
                                <Select
                                    labelId="receipt-instructor-label"
                                    // label="شماره کارت مقصد"
                                    label={destination_cards_input_label}
                                    value={destinationCardNumber ?? ""}
                                    onChange={handleDestinationCardNumberChange}
                                >
                                    {destinationCards?.map((item, index2) => (
                                        <MenuItem key={index2} value={item?.id}>
                                            {item?.bank?.bank_name} | {item?.account_number} | {item?.card_number}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{destinationCardNumberError}</FormHelperText>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={issueTracking ?? ""}
                            onChange={handleIssueTrackingChange}
                            autoComplete="off"
                            // label="شماره پیگیری"
                            label={issue_tracking_input_label}
                            required
                            error={Boolean(issueTrackingError)}
                            helperText={issueTrackingError}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <CardActions sx={{
                justifyContent: "flex-end",
            }}>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleResetDialogItems}
                >
                    {/* انصراف */}
                    {cancel}
                </Button>
                <LoadingButton
                    variant="outlined"
                    loadingPosition="start"
                    size="large"
                    loading={!loadingStoreButton}
                    disabled={
                        !instructorId ||
                        !total ||
                        !enTotal ||
                        !destinationCardNumber ||
                        !issueTracking
                    }
                    startIcon={<SaveOutlined />}
                    onClick={handleStore}
                >
                    {/* ذخیره */}
                    {save}
                </LoadingButton>
            </CardActions>
        </Fade>
    );
};

export default CreateReceipt;
