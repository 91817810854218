import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "../../context/DialogContext";
import UserBankAccountsContext from "../../context/UserBankAccountsContext";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Button, Stack } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import DeleteBankAccounts from "./DeleteBankAccounts";
import EditBankAccounts from "./EditBankAccounts";

const BankAccountsTable = () => {
    const { id: instructorId } = useParams();
    const { t } = useTranslation();
    const {
        bank,
        account_number,
        shaba_number,
        card_number,
        edit_bank_account,
        edit_button,
        delete_button
    } = t("lngBankAccountsTable");
    const { handleOpenDialog } = useDialogContext();
    const {
        getBankAccountsByUserId,
        bankAccountsByUserId,
        bankAccountsByUserIdLoading,
        selectedRow, setSelectedRow,
        page,
        setPage,
        rows,
        setRows,
        from,
        to,
        total
    } = useContext(UserBankAccountsContext);

    useEffect(() => {
        getBankAccountsByUserId(instructorId);
        // eslint-disable-next-line
    }, [instructorId, rows, total, page]);

    const columns = [
        {
            field: "bank_name",
            valueGetter: (params) => params.row?.bank?.bank_name,
            // headerName: "بانک",
            headerName: bank,
            width: 200,
            editable: "true",
            cellClassName: "name-column--cell",
        },
        {
            field: "account_number",
            // headerName: "شماره حساب",
            headerName: account_number,
            width: 200,
            cellClassName: "phone-column--cell",
        },
        {
            field: "shaba_number",
            // headerName: "شماره شبا",
            headerName: shaba_number,
            width: 250,
            cellClassName: "phone-column--cell",
        },
        {
            field: "card_number",
            // headerName: "شماره کارت",
            headerName: card_number,
            width: 200,
            cellClassName: "phone-column--cell",
        }
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            // "ویرایش حساب بانکی",
                            edit_bank_account,
                            <EditBankAccounts
                                params={selectedRow}
                                instructorId={instructorId}
                                getBankAccountsByUserId={getBankAccountsByUserId}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Edit />}
                    disabled={!selectedRow}
                >
                    {/* ویرایش */}
                    {edit_button}
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeleteBankAccounts
                                params={selectedRow}
                                getBankAccountsByUserId={getBankAccountsByUserId}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!selectedRow}
                >
                    {/* حذف */}
                    {delete_button}
                </Button>
            </Stack>
            <CustomDataGrid
                loading={bankAccountsByUserIdLoading}
                myData={bankAccountsByUserId}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default BankAccountsTable;
