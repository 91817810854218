import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import SettingsContext from "../../context/SettingsContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CountryContext from "../../context/CountryContext";
import { tokens } from "../../theme";
import {
  Container,
  Stack,
  Avatar,
  Box,
  Grid,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LockReset } from "@mui/icons-material";
import { persian2english } from "../../components/ConvertDigits";

const boxStyle = (theme) => ({
  width: {
    xs: '90%',   // در اندازه موبایل (xs)، عرض 90 درصد
    sm: '70%',   // در اندازه‌های کوچک (sm)، عرض 70 درصد
    md: '50%',   // در اندازه‌های متوسط (md)، عرض 50 درصد
    lg: '35%',   // در اندازه‌های بزرگ (lg)، عرض 35 درصد
    xl: '35%',   // در اندازه‌های بسیار بزرگ (xl)، عرض 35 درصد
  },
  maxWidth: 'sm', // می‌توانید این مقدار را بر اساس نیاز تنظیم کنید
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  background: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.8)",
  backdropFilter: theme.palette.mode === "light" ? "blur(5px)" : "blur(3px)",
  border: theme.palette.mode === "light" ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid rgba(255, 255, 255, 0.8)",
});

const RecoveryPassword = () => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    not_found_phone_number,
    country_id_required,
    username_required,
    typography_password_recovery,
    country_input_label,
    username_input_label,
    temporary_code_button,
    system_login1,
    system_login2,
    system_login3
  } = t("lngRecoveryPassword");
  const { baseImageUrl, settings } = useContext(SettingsContext);
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getAllCountries, allCountries, allCountriesLoading, allCountryStatus } = useContext(CountryContext);
  const { postMethod } = useServices();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const [loadingRecoveryButton, setLoadingRecoveryButton] = useState(true);
  const [countryId, setCountryId] = useState("");
  const [countryIdError, setCountryIdError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneMask, setPhoneMask] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);

  useEffect(() => {
    if (!isMounted.current) {
      getAllCountries();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Check if the data is loaded and if so, set default country to "Iran"
    if (allCountryStatus === 200 && allCountries.length > 0) {
      const defaultCountry = allCountries.find(country => country.country_name === "Iran");
      if (defaultCountry) {
        setCountryCode(defaultCountry.country_code);
        setPhoneMask(defaultCountry.phone_mask);
        setCountryId(defaultCountry.id);
      }
    }
  }, [allCountryStatus, allCountries]); // Dependency array to re-run when data loads

  const handleRecovery = async () => {
    setLoadingRecoveryButton(false);

    try {
      const cleanUsername = username.replace(/\D/g, ''); // 9191785593
      const fullUsername = countryCode + cleanUsername;
      const obj = {
        username: fullUsername,
      };
      const { data: response } = await postMethod("/password/recovery", obj);
      if (response?.status === 200) {
        if (response.user === null) {
          handleOpenSnackbar(
            "bottom",
            "left",
            "error",
            // "کاربری با این شماره تماس یافت نشد"
            not_found_phone_number
          );
        } else {
          handleOpenSnackbar(
            "bottom",
            "left",
            "success",
            response.message
          );
          navigate("/recovery-password/" + fullUsername);
        }
      }
    } catch (ex) {
      // console.log(ex.response);
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.country_id &&
          ex.response.data.errors.country_id.length > 1) {
          setCountryIdError(ex.response.data.errors.country_id[0]);
        } else {
          setCountryIdError(ex.response.data.errors.country_id);
        }
        if (ex.response.data.errors.username &&
          ex.response.data.errors.username.length > 1) {
          setUsernameError(ex.response.data.errors.username[0]);
        } else {
          setUsernameError(ex.response.data.errors.username);
        }
      }
      if (ex.response?.data?.errors?.popup) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          ex.response.data.errors.popup[0]
        );
      }
    }

    setLoadingRecoveryButton(true);
  };

  const handleCountryChange = (e) => {
    const currentValue = e.target.value;
    if (currentValue?.length === 0) {
      // setCountryIdError("تکمیل گزینه کشور الزامی است");
      setCountryIdError(country_id_required);
    } else {
      setCountryIdError("");
      const selectedCountry = allCountries.find(item => item.id === currentValue);
      if (selectedCountry) {
        setCountryCode(selectedCountry.country_code);
        setPhoneMask(selectedCountry.phone_mask);
        setCountryId(currentValue);
      }
    }
  };

  const validatePhoneNumber = (number, mask) => {
    // حذف کاراکترهای غیر عددی از شماره
    const cleanNumber = number.replace(/\D/g, '');
    // تعیین تعداد ارقام از الگوی mask
    const maskDigits = mask.replace(/\D/g, '').length;
    // بررسی تعداد ارقام
    return cleanNumber.length === maskDigits;
  };

  const handleUsernameChange = (e) => {
    const currentValue = e.target.value;
    setUsername(persian2english(currentValue));
    if (currentValue?.length === 0) {
      // setUsernameError("تکمیل گزینه موبایل الزامی است");
      setUsernameError(username_required);
    } else {
      setUsernameError("");
    }
    const isValidPhone = validatePhoneNumber(currentValue, phoneMask);
    if (!isValidPhone) {
      // setUsernameError("شماره موبایل نادرست است");
      setIsPhoneDisabled(true);
    } else {
      // setUsernameError("");
      setIsPhoneDisabled(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${baseImageUrl + settings.main_background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={boxStyle} borderRadius="20px">
        <Grid container className="top_to_bottom_animation">
          <Grid item xs={12} sm={12} lg={12}>
            <Box
              component="div"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
              }}
            >
              <Container maxWidth="xs">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar>
                    <LockReset sx={{
                      color: mode === "light"
                        ? colors.black
                        : colors.white,
                    }} />
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    marginTop: "4px",
                    marginBottom: "35px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography component="h1" variant="h4" sx={{
                    color: mode === "light"
                      ? colors.black
                      : colors.white,
                  }}>
                    {/* بازیابی رمز عبور */}
                    {typography_password_recovery}
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl required fullWidth>
                      {allCountriesLoading ? (
                        <Select value="">
                          <MenuItem>
                            <CircularProgress />
                          </MenuItem>
                        </Select>
                      ) : (
                        <FormControl required fullWidth error={Boolean(countryIdError)}>
                          <InputLabel id="country-name-label">
                            {/* کشور */}
                            {country_input_label}
                          </InputLabel>
                          <Select
                            value={countryId}
                            onChange={handleCountryChange}
                            label={country_input_label}
                            style={{ direction: direction === "rtl" ? "rtl" : "ltr" }}
                            renderValue={(selected) => {
                              const country = allCountries.find((item) => item.id === selected);
                              return country ? country.country_name : "";
                            }}
                          >
                            {allCountries?.map((item, index) => (
                              <MenuItem key={index} value={item?.id} style={{ direction: "ltr", textAlign: "left" }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                  <Typography variant="body1">{item?.country_name}</Typography>
                                  <Typography variant="body2" sx={{ color: colors.grey[500] }}>{item?.country_code}</Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{countryIdError}</FormHelperText>
                        </FormControl>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputMask
                      mask={phoneMask}
                      maskChar="_"
                      value={username}
                      onChange={handleUsernameChange}
                    >
                      {() => (
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={Boolean(usernameError)}
                          required
                        >
                          <InputLabel htmlFor="username">
                            {/* موبایل */}
                            {username_input_label}
                          </InputLabel>
                          <OutlinedInput
                            id="username"
                            type="text"
                            label="موبایل"
                            autoComplete="off"
                            {...(direction === "rtl" ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body1" style={{
                                    direction: "ltr",
                                    color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                                  }}>
                                    {countryCode}
                                  </Typography>
                                </InputAdornment>
                              )
                            } : {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Typography variant="body1" style={{
                                    direction: "ltr",
                                    color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                                  }}>
                                    {countryCode}
                                  </Typography>
                                </InputAdornment>
                              )
                            })}
                            inputProps={{
                              style: {
                                letterSpacing: '2px', // تنظیم فاصله بین کاراکترها
                                textAlign: 'center', // وسط‌چین کردن متن
                                direction: 'ltr', // تنظیم جهت متن به چپ به راست
                                color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                              },
                            }}
                          />
                          <FormHelperText>{usernameError}</FormHelperText>
                        </FormControl>
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      size="large"
                      sx={{
                        borderRadius: 28,
                        color: colors.white,
                      }}
                      loading={!loadingRecoveryButton}
                      disabled={
                        !countryId ||
                        !username ||
                        isPhoneDisabled
                      }
                      onClick={handleRecovery}
                    >
                      {/* ارسال رمز موقت */}
                      {temporary_code_button}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{
                          color: mode === "light" ? colors.black : colors.white,
                        }}
                      >
                        {/* جهت ورود به سیستم&nbsp; */}
                        {system_login1}&nbsp;
                        <span
                          style={{
                            color: mode === "light" ? colors.blueAccent[500] : colors.blueAccent[300],
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          {/* اینجا */}
                          {system_login2}
                        </span>
                        {/* &nbsp;کلیک کنید */}
                        &nbsp;{system_login3}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RecoveryPassword;
