import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import GalleryContext from "../../context/GalleryContext";
import { Box, Grid, Pagination } from "@mui/material";
import GalleryPost from "./GalleryPost";

function Gallery() {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngGalleryIndex");
  const {
    getGalleries,
    galleries,
    setPage,
    lastPage
  } = useContext(GalleryContext);

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getGalleries();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Grid container spacing={4}>
          {galleries?.map((gallery, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
              <GalleryPost gallery={gallery} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          {lastPage !== 1 && (
            <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
          )}
        </Box>
      </Box>
    </>
  );
}

export default Gallery;
