import React from "react";
import { Fade } from "react-awesome-reveal";
import { Grid, Skeleton, Typography } from "@mui/material";

const InstructorInfo = (props) => {
    return (
        <Grid container>
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                {props?.title ? (
                    <Fade triggerOnce={true}>
                        <Typography sx={{ fontWeight: 700 }}>{props?.title}</Typography>
                    </Fade>
                ) : (
                    <Fade triggerOnce={true}>
                        <Skeleton variant="text" height={20} width="50%" />
                    </Fade>
                )}
            </Grid>
            <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
                {props?.value ? (
                    <Fade direction="up" triggerOnce={true}>
                        <Typography>{props?.value}</Typography>
                    </Fade>
                ) : (
                    <Fade direction="up" triggerOnce={true}>
                        <Skeleton variant="text" height={20} width="50%" />
                    </Fade>
                )}
            </Grid>
        </Grid>
    )
}

export default InstructorInfo;
