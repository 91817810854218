import React, { useContext, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import DirectionContext from "../../context/DirectionContext";
import CoursesContext from "../../context/CoursesContext";
import {
  Box,
  Button,
  Grid,
  Pagination,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CoursesPost from "./CoursesPost";
import Header from "../../components/Header";
import SectionTitle from "../../components/SectionTitle";
import CoursesToolBox from "./CoursesToolBox";
import { KeyboardDoubleArrowLeftOutlined, KeyboardDoubleArrowRightOutlined } from "@mui/icons-material";

function Courses() {
  const { t } = useTranslation();
  const {
    title,
    subtitle,
    view_more
  } = t("lngCoursesIndex");
  const navigate = useNavigate();
  const location = useLocation();
  const { direction } = useContext(DirectionContext);
  const {
    getCourses,
    page,
    setPage,
    lastPage,
    courses
  } = useContext(CoursesContext);
  const [formatPage, setFormatPage] = useState();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getCourses();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname === "/courses") {
      setFormatPage(true);
    } else {
      setFormatPage(false);
    }
    // eslint-disable-next-line
  }, [location]);

  function renderFormatPage() {
    return (
      formatPage ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={title} subtitle={subtitle} backToPage="/" />
        </Box>
      ) : (
        <SectionTitle title={title} subtitle={subtitle} />
      )
    );
  }

  return (
    <>
      {renderFormatPage()}
      <Fade triggerOnce={true}>
        <Box mb="50px" mt="50px" className="container">
          {formatPage && (
            <CoursesToolBox />
          )}
          <Box className="row">
            <Grid container>
              {courses?.map((course, index) => (
                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <CoursesPost course={course} />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              {formatPage ? (
                lastPage !== 1 && (
                  <Box sx={{ margin: "0 0 30px 0" }}>
                    <Pagination page={page} count={lastPage} onChange={(e, value) => setPage(value)} />
                  </Box>
                )
              ) : (
                <Button
                  variant="outlined"
                  endIcon={direction === "rtl" ? <KeyboardDoubleArrowLeftOutlined /> : <KeyboardDoubleArrowRightOutlined />}
                  onClick={() => navigate("/courses")}
                >
                  {/* مشاهده بیشتر */}
                  {view_more}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
}

export default Courses;
