import { createContext, useContext, useEffect, useState } from "react";
import AuthContext from './AuthContext';
import { pages } from './../config/MenuItems';
import SettingsContext from "./SettingsContext";

const MenuContext = createContext();
MenuContext.displayName = "MenuContext";

export default MenuContext;

export const MenuContextProvider = (props) => {
    const { user } = useContext(AuthContext);
    const {
        getCounts,
        inboxCount,
        usersCountForBadge,
        contactCount,
        transactionsCount,
        instructorsInvoicesCount
    } = useContext(SettingsContext);
    const [displayMenu, setDisplayMenu] = useState(pages);

    useEffect(() => {
        if (user === null) {
            defaultMenuItems();
            setDisplayMenu(pages);
        } else {
            authMenuItems();
            setDisplayMenu(pages);
        }
        // eslint-disable-next-line
    }, [user]);

    function defaultMenuItems() {
        pages.forEach((parent) => {
            switch (parent.path) {
                case 'account':
                    parent.isAccess = 1;
                    parent.children.forEach((child) => {
                        child.isAccess = 1;
                    });
                    break;
                case 'shop':
                    parent.children.forEach((child) => {
                        if (child.path === 'invoices') {
                            child.isAccess = 0;
                        }
                        if (child.path === 'order-status') {
                            child.isAccess = 0;
                        }
                    });
                    break;
                case 'student':
                    parent.isAccess = 0;
                    parent.children.forEach((child) => {
                        child.isAccess = 0;
                    });
                    break;
                case 'settings':
                    parent.isAccess = 0;
                    parent.children.forEach((child) => {
                        child.isAccess = 0;
                    });
                    break;
                case 'admin':
                    parent.isAccess = 0;
                    parent.children.forEach((child) => {
                        child.isAccess = 0;
                    });
                    break;
                default:
                    return;
            }
        });
    }

    function authMenuItems() {
        pages.forEach((parent) => {
            switch (parent.path) {
                case 'account':
                    parent.isAccess = 0;
                    parent.children.forEach((child) => {
                        child.isAccess = 0;
                    });
                    break;
                case 'shop':
                    parent.children.forEach((child) => {
                        if (child.path === 'invoices') {
                            child.isAccess = 1;
                        }
                        if (child.path === 'order-status') {
                            child.isAccess = 1;
                        }
                    });
                    break;
                case 'student':
                    parent.isAccess = 1;
                    parent.children.forEach((child) => {
                        child.isAccess = 1;
                        if (child.path === 'inbox') {
                            child.badge = inboxCount;
                        }
                    });
                    break;
                case 'settings':
                    parent.isAccess = 1;
                    parent.children.forEach((child) => {
                        child.isAccess = 1;
                    });
                    break;
                case 'admin':
                    parent.isAccess = user?.role?.admin;
                    parent.children.forEach((child) => {
                        switch (child.path) {
                            case 'admin-users':
                                child.isAccess = user?.role?.admin_users;
                                if (child.isAccess === 1) {
                                    child.badge = usersCountForBadge;
                                }
                                break;
                            case 'admin-events':
                                child.isAccess = user?.role?.admin_events;
                                break;
                            case 'admin-gallery':
                                child.isAccess = user?.role?.admin_gallery;
                                break;
                            case 'admin-faq':
                                child.isAccess = user?.role?.admin_faq;
                                break;
                            case 'admin-blog':
                                child.isAccess = user?.role?.admin_blog;
                                break;
                            case 'admin-tags':
                                child.isAccess = user?.role?.admin_tags;
                                break;
                            case 'admin-contact':
                                child.isAccess = user?.role?.admin_contact;
                                if (child.isAccess === 1) {
                                    child.badge = contactCount;
                                }
                                break;
                            case 'admin-privacy':
                                child.isAccess = user?.role?.admin_privacy;
                                break;
                            case 'admin-categories':
                                child.isAccess = user?.role?.admin_categories;
                                break;
                            case 'admin-courses':
                                child.isAccess = user?.role?.admin_courses;
                                break;
                            case 'admin-transactions':
                                child.isAccess = user?.role?.admin_transactions;
                                if (child.isAccess === 1) {
                                    child.badge = transactionsCount;
                                }
                                break;
                            case 'admin-receipts':
                                child.isAccess = user?.role?.admin_transactions;
                                break;
                            case 'admin-instructors-invoices':
                                child.isAccess = user?.role?.admin_instructors_invoices;
                                if (child.isAccess === 1) {
                                    child.badge = instructorsInvoicesCount;
                                }
                                break;
                            case 'admin-settings':
                                child.isAccess = user?.role?.admin_settings;
                                break;
                            case 'admin-roles':
                                child.isAccess = user?.role?.admin_roles;
                                break;
                            default:
                                return;
                        }
                    });
                    break;
                default:
                    return;
            }
        });
    }

    useEffect(() => {
        if (user !== null) {
            getCounts();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user !== null) {
            updateInboxBadge();
        }
        // eslint-disable-next-line
    }, [inboxCount]);

    function updateInboxBadge() {
        pages.forEach((parent) => {
            if (parent.path === "student") {
                parent.children.forEach((child) => {
                    if (child.path === "inbox") {
                        child.badge = inboxCount;
                    }
                }
                )
            }
        });
    }

    useEffect(() => {
        if (user !== null) {
            updateUserForBadge();
        }
        // eslint-disable-next-line
    }, [usersCountForBadge]);

    function updateUserForBadge() {
        pages.forEach((parent) => {
            if (parent.path === "admin") {
                parent.children.forEach((child) => {
                    if (child.path === "admin-users") {
                        child.badge = usersCountForBadge;
                    }
                }
                )
            }
        });
    }

    useEffect(() => {
        if (user !== null) {
            updateContactBadge();
        }
        // eslint-disable-next-line
    }, [contactCount]);

    function updateContactBadge() {
        pages.forEach((parent) => {
            if (parent.path === "admin") {
                parent.children.forEach((child) => {
                    if (child.path === "admin-contact") {
                        child.badge = contactCount;
                    }
                }
                )
            }
        });
    }

    useEffect(() => {
        if (user !== null) {
            updateTransactionBadge();
        }
        // eslint-disable-next-line
    }, [transactionsCount]);

    function updateTransactionBadge() {
        pages.forEach((parent) => {
            if (parent.path === "admin") {
                parent.children.forEach((child) => {
                    if (child.path === "admin-transactions") {
                        child.badge = transactionsCount;
                    }
                }
                )
            }
        });
    }

    useEffect(() => {
        if (user !== null) {
            updateInstructorsInvoicesBadge();
        }
        // eslint-disable-next-line
    }, [instructorsInvoicesCount]);

    function updateInstructorsInvoicesBadge() {
        pages.forEach((parent) => {
            if (parent.path === "admin") {
                parent.children.forEach((child) => {
                    if (child.path === "admin-instructors-invoices") {
                        child.badge = instructorsInvoicesCount;
                    }
                }
                )
            }
        });
    }

    return (
        <>
            <MenuContext.Provider
                value={{
                    pages: displayMenu
                }}
            >
                {props.children}
            </MenuContext.Provider>
        </>
    );
}
