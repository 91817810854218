import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { Box } from "@mui/material";
import PrivacyTable from "./PrivacyTable";

const AdminPrivacy = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngAdminPrivacy");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/privacy" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <PrivacyTable />
      </Box>
    </>
  );
};

export default AdminPrivacy;
