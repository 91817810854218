import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./i18n.js";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { DirectionContextProvider } from './context/DirectionContext';
import { ProgressBarContextProvider } from './context/ProgressBarContext';
import { SnackbarContextProvider } from './context/SnackbarContext';
import { AuthContextProvider } from './context/AuthContext';
import { CanContextProvider } from './context/CanContext';
import { SettingsContextProvider } from './context/SettingsContext';
import { Box, Typography } from "@mui/material";

const loadingMarkup = (
  <Box>
    <Typography variant="h5">
      Loading ...
    </Typography>
  </Box>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <React.Suspense fallback={loadingMarkup}>
        <DirectionContextProvider>
          <ProgressBarContextProvider>
            <SnackbarContextProvider>
              <AuthContextProvider>
                <CanContextProvider>
                  <SettingsContextProvider>
                    <App />
                  </SettingsContextProvider>
                </CanContextProvider>
              </AuthContextProvider>
            </SnackbarContextProvider>
          </ProgressBarContextProvider>
        </DirectionContextProvider>
      </React.Suspense>
    </BrowserRouter>
  </React.StrictMode >
);
