import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { separateNumbers } from "../../components/PriceFormat";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Button, Stack } from "@mui/material";
import { ReceiptLong } from "@mui/icons-material";

const ReceiptsTable = () => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        columns_row,
        columns_order_id,
        columns_user_id,
        columns_bank_id,
        columns_card_number,
        columns_total,
        columns_en_total,
        columns_issue_tracking,
        instructor_sales_invoice_button
    } = t("lngReceiptsTable");
    const navigate = useNavigate();
    const { postMethodByPageId } = useServices();
    const [receipts, setReceipts] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getReceipts();
        // eslint-disable-next-line
    }, [rows, total, page]);

    async function getReceipts() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/getReceipts", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                data.data.forEach(function (item) {
                    item.transaction.created_at = new Date(item.transaction.created_at).toLocaleDateString('fa-IR');
                    item.created_at = new Date(item.created_at).toLocaleDateString('fa-IR');
                    item.total = separateNumbers(item.total);
                });
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setReceipts(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const columns = [
        {
            field: "id",
            // headerName: "ردیف",
            headerName: columns_row,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "order_id",
            valueGetter: (params) => params?.row?.transaction?.order_id,
            // headerName: "شماره سفارش",
            headerName: columns_order_id,
            width: 140,
        },
        {
            field: "user_id",
            valueGetter: (params) => direction === "rtl"
                ? params?.row?.user_bank_account?.user?.full_name
                : (params?.row?.user_bank_account?.user?.en_full_name ?? params?.row?.user_bank_account?.user?.full_name),
            // headerName: "استاد",
            headerName: columns_user_id,
            width: 200,
        },
        {
            field: "bank_id",
            valueGetter: (params) => params?.row?.user_bank_account?.bank?.bank_name,
            // headerName: "بانک",
            headerName: columns_bank_id,
            width: 200,
        },
        {
            field: "card_number",
            valueGetter: (params) => params?.row?.user_bank_account?.card_number,
            // headerName: "شماره کارت",
            headerName: columns_card_number,
            width: 150,
        },
        {
            field: "total",
            // headerName: "جمع واریزی (تومان)",
            headerName: columns_total,
            width: 160,
        },
        {
            field: "en_total",
            // headerName: "جمع واریزی (دلار)",
            headerName: columns_en_total,
            width: 160,
        },
        {
            field: "issue_tracking",
            // headerName: "شماره پیگیری",
            headerName: columns_issue_tracking,
            width: 180,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() => navigate(`/transactions/instructors-invoices/${selectedRow?.transaction?.id}`)}
                    variant="outlined"
                    startIcon={<ReceiptLong />}
                    disabled={!selectedRow}
                >
                    {/* فاکتور فروش استاد */}
                    {instructor_sales_invoice_button}
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={receipts}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default ReceiptsTable;
