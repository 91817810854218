import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import CategoryContext from "../../context/CategoryContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const EditCategories = (props) => {
  const { t } = useTranslation();
  const {
    category_name_required,
    en_category_name_required,
    courses_category_updated,
    record_deleted,
    category_name_input,
    en_category_name_input,
    cancel,
    save
  } = t("lngEditCategories");
  const { putMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { getCategories } = useContext(CategoryContext);
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");
  const [enCategoryName, setEnCategoryName] = useState("");
  const [enCategoryNameError, setEnCategoryNameError] = useState("");

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [props?.params]);

  function fillRow() {
    setCategoryName(props?.params?.category_name);
    setCategoryNameError("");
    setEnCategoryName(props?.params?.en_category_name);
    setEnCategoryNameError("");
  }

  const handleCategoryNameChange = (e) => {
    const currentValue = e.target.value;
    setCategoryName(currentValue);
    if (currentValue?.length === 0) {
      // setCategoryNameError("تکمیل گزینه نام دسته‌بندی الزامی است");
      setCategoryNameError(category_name_required);
    } else {
      setCategoryNameError("");
    }
  };

  const handleEnCategoryNameChange = (e) => {
    let currentValue = e.target.value;
    // جلوگیری از ورود حروف فارسی
    const englishLettersOnly = /^[A-Za-z\s&()/.|"']*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }

    // تبدیل حرف اول هر کلمه به حروف بزرگ، بدون حذف فاصله بین کلمات
    currentValue = currentValue
      .split(' ') // رشته را به کلمات جدا می‌کند
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // حرف اول هر کلمه را به بزرگ تبدیل می‌کند
      .join(' '); // کلمات را با یک فاصله به هم متصل می‌کند

    setEnCategoryName(currentValue);
    if (currentValue?.length === 0) {
      // setEnCategoryNameError("تکمیل گزینه نام دسته‌بندی الزامی است");
      setEnCategoryNameError(en_category_name_required);
    } else {
      setEnCategoryNameError("");
    }
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        category_name: categoryName,
        en_category_name: enCategoryName,
      };

      await putMethod(
        "/categories",
        props?.params?.id,
        obj
      );

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "دسته‌بندی دوره‌ها با موفقیت ویرایش شد"
        courses_category_updated
      );

      getCategories();
      handleCloseDialog();
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          // "رکورد مورد نظر قبلاً حذف شده است"
          record_deleted
        );
      }
      if (ex.response && ex.response.status === 422) {
        setCategoryNameError(ex.response.data.errors.category_name);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleResetDialogItems = () => {
    handleCloseDialog();
    fillRow();
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={categoryName ?? ""}
            onChange={handleCategoryNameChange}
            autoComplete="off"
            // label="نام دسته‌بندی"
            label={category_name_input}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "right" },
              maxLength: 40
            }}
            error={Boolean(categoryNameError)}
            helperText={
              categoryNameError
                ? categoryNameError
                : `${categoryName?.length ?? 0}/40`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enCategoryName ?? ""}
            onChange={handleEnCategoryNameChange}
            autoComplete="off"
            // label="نام دسته‌بندی"
            label={en_category_name_input}
            required
            inputProps={{
              style: { direction: "ltr", textAlign: "left" },
              maxLength: 40
            }}
            error={Boolean(enCategoryNameError)}
            helperText={
              enCategoryNameError
                ? enCategoryNameError
                : `${enCategoryName?.length ?? 0}/40`
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          {/* انصراف */}
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={!categoryName || !enCategoryName}
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          {/* ذخیره */}
          {save}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditCategories;
