import { useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { Bounce, Fade, Slide, Zoom } from "react-awesome-reveal";
import { useDialogContext } from "../../context/DialogContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  Button,
  Typography,
  Stack,
  DialogContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  CancelOutlined,
  ArrowBackOutlined,
  DoneAll,
  Send,
  ArrowForwardOutlined
} from "@mui/icons-material";

const Sending = (props) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    package_sent,
    send_package_completed,
    close_dialog,
    typography_buyer,
    typography_payment_receipt,
    typography_order_id,
    typography_active,
    successful,
    unsuccessful,
    sure_send_package,
    no_way_to_go_back,
    cancel,
    I_agree,
    next
  } = t("lngSending");
  const { handleCloseDialog } = useDialogContext();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { putMethod, wait } = useServices();
  const [loadingSendingButton, setLoadingSendingButton] = useState(false);

  const handleSending = async () => {
    setLoadingSendingButton(true);

    try {
      await putMethod("/sending", props?.params?.id);
      props?.getTransactions();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          // "بسته مورد نظر قبلاً ارسال شده است"
          package_sent
        );
      }
      if (ex.response.data.errors) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          ex.response.data.errors.message
        );
      }
    }

    setLoadingSendingButton(false);
  };

  const closeDialog = () => {
    handleCloseDialog();
    wait(500).then(() => setActiveStep(0));
  };

  const steps = ["صحت اطلاعات", "تأیید نهایی"];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <Box>
              <Fade>
                <Typography
                  sx={{ mt: 2, mb: 1, fontWeight: "700", textAlign: "center" }}
                >
                  {/* فرآیند ارسال بسته تکمیل شد */}
                  {send_package_completed}
                </Typography>
              </Fade>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  variant="outlined"
                  startIcon={<CancelOutlined />}
                  onClick={closeDialog}
                >
                  {/* بستن */}
                  {close_dialog}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ mt: 2, mb: 1 }}>
                {activeStep === 0 ? (
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack direction="row" spacing={1}>
                        <DoneAll color="primary" />
                        <Typography
                          component="span"
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: "700" }}
                        >
                          {/* خریدار */}
                          {typography_buyer}
                        </Typography>
                      </Stack>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={direction === "rtl" ? props?.params?.user?.full_name ?? "" : props?.params?.user?.en_full_name ?? props?.params?.user?.full_name ?? ""}
                        inputProps={{
                          style: { textAlign: "justify" },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack direction="row" spacing={1}>
                        <DoneAll color="primary" />
                        <Typography
                          component="span"
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: "700" }}
                        >
                          {/* رسید پرداخت */}
                          {typography_payment_receipt}
                        </Typography>
                      </Stack>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={props?.params?.payment_receipt ?? ""}
                        inputProps={{
                          style: { textAlign: "justify" },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack direction="row" spacing={1}>
                        <DoneAll color="primary" />
                        <Typography
                          component="span"
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: "700" }}
                        >
                          {/* شماره سفارش */}
                          {typography_order_id}
                        </Typography>
                      </Stack>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={props?.params?.order_id ?? ""}
                        inputProps={{
                          style: { textAlign: "justify" },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Stack direction="row" spacing={1}>
                        <DoneAll color="primary" />
                        <Typography
                          component="span"
                          variant="body1"
                          color="primary"
                          sx={{ fontWeight: "700" }}
                        >
                          {/* وضعیت تراکنش */}
                          {typography_active}
                        </Typography>
                      </Stack>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={props?.params?.active === 1 ? successful : unsuccessful}
                        inputProps={{
                          style: { textAlign: "justify" },
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Stack direction="column" alignItems="center" gap={1}>
                    <Slide triggerOnce={true}>
                      <svg fill="green" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 612 612">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                        <g id="SVGRepo_iconCarrier"><g><path d="M580.592,135.703L497.33,98.681L234.478,222.254v79.353l-45.854-20.389v-79.353L451.477,78.293l-88.287-39.256 c-11.822-5.256-31.166-5.256-42.987,0l-217.401,96.666c-17.319,7.701-31.453,29.51-31.409,48.464l0.339,143.238 c13.628-4.385,28.128-6.806,43.195-6.806c77.949,0,141.373,63.424,141.373,141.382c0,20.36-4.413,39.682-12.196,57.188 l76.764,32.815c11.436,4.888,30.146,4.889,41.583,0.002l216.905-92.694c17.614-7.527,32.062-29.357,32.107-48.513L612,184.166 C612.044,165.212,597.911,143.403,580.592,135.703z M496.655,300.16l29.458-62.149c2.974-6.273,10.429-9.892,13.413-6.49 l30.229,34.461c3.019,3.441-0.812,11.277-6.869,14.087l-12.724,5.903l-0.257,89.528c-0.012,3.912-3.467,8.569-7.708,10.399 l-19.049,8.223c-4.176,1.803-7.554,0.132-7.556-3.729l-0.064-88.354l-12.325,5.718C497.407,310.446,493.778,306.23,496.655,300.16z M388.039,350.943l26.773-59.078c2.7-5.959,9.604-9.312,12.422-6.012l28.532,33.423c2.85,3.336-0.628,10.779-6.237,13.381 l-11.782,5.466l0.76,85.727c0.033,3.749-3.135,8.163-7.066,9.86l-17.664,7.625c-3.873,1.672-7.042,0.025-7.087-3.675l-1.035-84.647 l-11.429,5.302C388.852,360.808,385.422,356.718,388.039,350.943z M583.911,399.271c-0.014,1.967-1.781,4.298-3.946,5.208 l-201.751,84.757c-1.883,0.791-3.432-0.037-3.459-1.851l-0.155-9.861c-0.028-1.817,1.476-3.942,3.361-4.745l202.111-86.097 c2.17-0.924,3.92-0.075,3.908,1.896L583.911,399.271z M114.925,347.054C51.454,347.054,0,398.508,0,461.979 c0,63.472,51.454,114.926,114.925,114.926s114.925-51.454,114.925-114.926C229.851,398.508,178.396,347.054,114.925,347.054z M190.021,438.367l-70.724,79.563c-3.484,3.919-8.335,5.932-13.221,5.932c-3.881,0-7.783-1.27-11.038-3.877l-44.202-35.361 c-7.624-6.095-8.862-17.223-2.759-24.846c6.095-7.632,17.228-8.867,24.851-2.763l31.093,24.872l59.574-67.02 c6.479-7.295,17.659-7.959,24.958-1.468C195.853,419.893,196.509,431.063,190.021,438.367z" /></g></g>
                      </svg>
                    </Slide>
                    <Zoom>
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          component="span"
                          sx={{ fontWeight: 700 }}
                        >
                          {/* آیا از تکمیل فرآیند ارسال بسته اطمینان دارید؟ */}
                          {sure_send_package}
                        </Typography>
                      </Box>
                    </Zoom>
                    <Bounce>
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{ fontWeight: 700 }}
                      >
                        <Box sx={{ textAlign: "center" }}>
                          {/* با کلیک روی دکمه "موافقم" امکان بازگشت وجود ندارد. */}
                          {no_way_to_go_back}
                        </Box>
                      </Typography>
                    </Bounce>
                  </Stack>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<CancelOutlined />}
                  onClick={closeDialog}
                >
                  {/* انصراف */}
                  {cancel}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 ? (
                  <LoadingButton
                    variant="outlined"
                    loadingPosition="start"
                    startIcon={<Send />}
                    loading={loadingSendingButton}
                    onClick={handleSending}
                  >
                    {/* موافقم */}
                    {I_agree}
                  </LoadingButton>
                ) : (
                  <Button
                    variant="outlined"
                    endIcon={direction === "rtl" ? <ArrowBackOutlined /> : <ArrowForwardOutlined />}
                    onClick={handleNext}
                  >
                    {/* بعدی */}
                    {next}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </>
  );
};

export default Sending;
