import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import TagsContext from "../../context/TagsContext";
import TagPost from "./TagPost";
import { Box, Grid, Pagination } from "@mui/material";
import CreateTags from "./CreateTags";

const AdminTags = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngAdminTags");
  const {
    getTags,
    tags,
    page, setPage,
    lastPage
  } = useContext(TagsContext);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/blog" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <CreateTags />
        <Grid container spacing={2}>
          {tags?.map((tag, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3}>
              <TagPost tag={tag} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          {lastPage !== 1 && (
            <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminTags;
