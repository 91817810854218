import { useContext, useState } from "react";
import DirectionContext from "../context/DirectionContext";
import {
    IconButton,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';
import { LanguageOutlined } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";
import { tokens } from "../theme";

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.mode === "light"
            ? `${tokens(theme.palette.mode).black} !important`
            : `${tokens(theme.palette.mode).white} !important`,
        "&:hover": {
            backgroundColor: "transparent !important",
        },
    },
}));

const LanguageSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { languages, i18n, currentLanguageCode } = useContext(DirectionContext);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (code) => {
        i18n.changeLanguage(code);
        setAnchorEl(null);
    }

    return (
        <>
            <Tooltip
                title="Lang"
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -14],
                                },
                            },
                        ],
                    },
                }}
            >
                <IconButton
                    aria-label="more"
                    id="long-button"
                    className={classes.root}
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <LanguageOutlined />
                </IconButton>
            </Tooltip>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    },
                }}
            >
                {languages.map(({ code, name, country_code }) => (
                    <MenuItem
                        key={country_code}
                        onClick={() => changeLanguage(code)}
                        disabled={code === currentLanguageCode}
                        sx={{
                            fontFamily: code === "fa" ? "shabnam, sans-serif" : "poppins, sans-serif",
                        }}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default LanguageSelector;
