import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import SettingsContext from "../../context/SettingsContext";
import InstructorsInvoicesContext from "../../context/InstructorsInvoicesContext";
import { useDialogContext } from "../../context/DialogContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { CreditScore, ReceiptLong } from "@mui/icons-material";
import ShowInstructorsInvoices from "./ShowInstructorsInvoices";

const InstructorsInvoicesTable = () => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        columns_row,
        columns_buyer,
        columns_payment_receipt,
        columns_order_id,
        columns_created_at,
        successful,
        unsuccessful,
        columns_active,
        complete,
        unfinished,
        columns_packing,
        columns_sending,
        instructor_sales_invoice_title,
        instructor_sales_invoice_button,
        instructor_status_button
    } = t("lngInstructorsInvoicesTable");
    const { putMethod } = useServices();
    const navigate = useNavigate();
    const {
        getInstructorsInvoices,
        instructorsInvoices,
        selectedRow, setSelectedRow,
        loading,
        page,
        setPage,
        rows,
        setRows,
        from,
        to,
        total
    } = useContext(InstructorsInvoicesContext);
    const { handleOpenDialog } = useDialogContext();
    const { getCounts } = useContext(SettingsContext);

    useEffect(() => {
        getInstructorsInvoices();
        // eslint-disable-next-line
    }, [rows, total, page]);

    useEffect(() => {
        async function updateIsReadInstructorInvoice() {
            if (selectedRow?.id !== undefined && selectedRow?.is_read === 0) {
                try {
                    await putMethod(
                        "/update_instructor_invoice_count",
                        selectedRow?.id,
                    );

                    getCounts();
                } catch (ex) {

                }
            }
        }

        updateIsReadInstructorInvoice();
        // eslint-disable-next-line
    }, [selectedRow?.id]);

    const columns = [
        {
            field: "id",
            // headerName: "ردیف",
            headerName: columns_row,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "full_name",
            valueGetter: (params) => direction === "rtl"
                ? params.row?.user?.full_name
                : (params.row?.user?.en_full_name ?? params.row?.user?.full_name),
            // headerName: "خریدار",
            headerName: columns_buyer,
            width: 200,
        },
        {
            field: "payment_receipt",
            // headerName: "رسید پرداخت",
            headerName: columns_payment_receipt,
            width: 200,
        },
        {
            field: "order_id",
            // headerName: "شماره سفارش",
            headerName: columns_order_id,
            width: 200,
        },
        {
            field: "created_at",
            // headerName: "تاریخ ثبت سفارش",
            headerName: columns_created_at,
            width: 150,
        },
        {
            field: "active",
            valueGetter: (params) => params.row.active === 1 ? successful : unsuccessful,
            // headerName: "تراکنش",
            headerName: columns_active,
            width: 140,
        },
        {
            field: "packing",
            valueGetter: (params) => params.row.packing === 1 ? complete : unfinished,
            // headerName: "بسته‌بندی",
            headerName: columns_packing,
            width: 140,
        },
        {
            field: "sending",
            valueGetter: (params) => params.row.sending === 1 ? complete : unfinished,
            // headerName: "ارسال بسته",
            headerName: columns_sending,
            width: 140,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "lg", // xs sm md lg xl
                            // "فاکتور فروش استاد",
                            instructor_sales_invoice_title,
                            <ShowInstructorsInvoices params={selectedRow} />
                        )
                    }
                    variant="outlined"
                    startIcon={<ReceiptLong />}
                    disabled={!selectedRow}
                >
                    {/* فاکتور فروش استاد */}
                    {instructor_sales_invoice_button}
                </Button>
                <Button
                    onClick={() => navigate(`/receipt/show/${selectedRow?.id}`)}
                    variant="outlined"
                    startIcon={<CreditScore />}
                    disabled={!selectedRow || selectedRow?.active === 0}
                >
                    {/* وضعیت تسویه با استاد */}
                    {instructor_status_button}
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={instructorsInvoices}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default InstructorsInvoicesTable;