import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";
import FaqContext from "../../context/FaqContext";
import {
    useTheme,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Skeleton
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const FAQContent = () => {
    const { i18n } = useTranslation();
    const direction = i18n.dir();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [expanded, setExpanded] = useState(false);
    const { getAllFaqs, loading, faqs } = useContext(FaqContext);
    const isMounted = useRef(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (!isMounted.current) {
            getAllFaqs();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {faqs?.map((item, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === "panel" + index}
                    onChange={handleChange("panel" + index)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        id={`panel${index}-header`}
                    >
                        {loading ? (
                            <Skeleton
                                width="100%"
                                height="30px"
                                padding="0px 16px"
                                variant="rectangular"
                                sx={{
                                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                                }}
                            />
                        ) : (
                            <Typography color={colors.grey[100]}>
                                {index + 1}.&nbsp;
                                {direction === "rtl" ? (
                                    item?.question
                                ) : (
                                    item?.en_question
                                )}
                            </Typography>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading ? (
                            <Skeleton
                                width="100%"
                                height="30px"
                                padding="8px 16px 16px"
                                variant="rectangular"
                            />
                        ) : (
                            <Typography sx={{ textAlign: "justify" }}>
                                {direction === "rtl" ? (
                                    item?.answer
                                ) : (
                                    item?.en_answer
                                )}
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default FAQContent;
