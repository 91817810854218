import { useMemo, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  InputAdornment,
  Box,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloudUpload, SaveOutlined } from "@mui/icons-material";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import { persian2english } from "../../components/ConvertDigits";

const CreateEvents = () => {
  const { t } = useTranslation();
  const {
    date_is_not_valid,
    date_format_incorrect,
    evennt_registered,
    title_required,
    address_required,
    add_event,
    event_cover_input,
    en_event_cover_input,
    cancel_button_label,
    ok_button_label,
    today_button_label,
    clear_button_label,
    date_picker_input,
    time_picker_input,
    day_input,
    day_select,
    Saturday,
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    title_input,
    en_title_input,
    description_input,
    en_description_input,
    address_input,
    en_address_input,
    save
  } = t("lngCreateEvents");
  const { handleOpenSnackbar } = useSnackbarContext();
  const { uploadPostMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [enCover, setEnCover] = useState([]);
  const [enCoverError, setEnCoverError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date() || null);
  const [selectedDateError, setSelectedDateError] = useState(null);
  const [selectedTime, setSelectedTime] = useState(dayjs() || null);
  const [day, setDay] = useState("");
  const [dayError, setDayError] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enTitleError, setEnTitleError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [enDescriptionError, setEnDescriptionError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [enAddress, setEnAddress] = useState("");
  const [enAddressError, setEnAddressError] = useState("");

  const selectedDateMessageError = useMemo(() => {
    switch (selectedDateError) {
      case 'maxDate':
      case 'minDate': {
        // return 'تاریخ وارد شده معتبر نمی باشد';
        return date_is_not_valid;
      }

      case 'invalidDate': {
        // return 'فرمت تاریخ صحیح نمی باشد';
        return date_format_incorrect;
      }

      default: {
        return '';
      }
    }
  }, [selectedDateError, date_format_incorrect, date_is_not_valid]);

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      let updatedDay = day;

      switch (day) {
        case "Saturday":
          updatedDay = "شنبه";
          break;
        case "Sunday":
          updatedDay = "یکشنبه";
          break;
        case "Monday":
          updatedDay = "دوشنبه";
          break;
        case "Tuesday":
          updatedDay = "سه‌شنبه";
          break;
        case "Wednesday":
          updatedDay = "چهارشنبه";
          break;
        case "Thursday":
          updatedDay = "پنج‌شنبه";
          break;
        case "Friday":
          updatedDay = "جمعه";
          break;
        default:
          break;
      }

      setDay(updatedDay);

      const obj = {
        cover: cover,
        en_cover: enCover,
        date: persian2english(selectedDate.toLocaleDateString('fa-IR')),
        day: updatedDay,
        time: selectedTime.$H + ":" + selectedTime.$m,
        title: title,
        en_title: enTitle,
        description: description,
        en_description: enDescription,
        address: address,
        en_address: enAddress,
      };

      await uploadPostMethod("/events", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "رویداد با موفقیت ثبت شد"
        evennt_registered
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.cover &&
          ex.response.data.errors.cover.length > 1) {
          setCoverError(ex.response.data.errors.cover[0]);
        } else {
          setCoverError(ex.response.data.errors.cover);
        }
        if (ex.response.data.errors.en_cover &&
          ex.response.data.errors.en_cover.length > 1) {
          setEnCoverError(ex.response.data.errors.en_cover[0]);
        } else {
          setEnCoverError(ex.response.data.errors.en_cover);
        }
        setSelectedDateError(ex.response.data.errors.date);
        setDayError(ex.response.data.errors.day);
        setTitleError(ex.response.data.errors.title);
        setEnTitleError(ex.response.data.errors.en_title);
        setDescriptionError(ex.response.data.errors.description);
        setEnDescriptionError(ex.response.data.errors.en_description);
        setAddressError(ex.response.data.errors.address);
        setEnAddressError(ex.response.data.errors.en_address);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    // console.log(cover);
  };

  const handleEnCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setEnCover(currentValue);
    // console.log(enCover);
  };

  const handleDayChange = (e) => {
    let currentValue = e.target.value;
    setDay(currentValue);
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setTitleError(title_required);
    } else {
      setTitleError("");
    }
  };

  const handleEnTitleChange = (e) => {
    const currentValue = e.target.value;
    // جلوگیری از ورود حروف فارسی
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnTitle(currentValue);
  };

  const handleDescriptionChange = (e) => {
    const currentValue = e.target.value;
    setDescription(currentValue);
  };

  const handleEnDescriptionChange = (e) => {
    const currentValue = e.target.value;
    // جلوگیری از ورود حروف فارسی
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnDescription(currentValue);
  };

  const handleAddressChange = (e) => {
    const currentValue = e.target.value;
    setAddress(currentValue);
    if (currentValue?.length === 0) {
      // setAddressError("تکمیل گزینه آدرس الزامی است");
      setAddressError(address_required);
    } else {
      setAddressError("");
    }
  };

  const handleEnAddressChange = (e) => {
    const currentValue = e.target.value;
    // جلوگیری از ورود حروف فارسی
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnAddress(currentValue);
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Card>
          <CardHeader title={add_event} />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  type="file"
                  // label="تصویر رویداد"
                  label={event_cover_input}
                  onChange={handleCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(coverError)}
                  helperText={coverError ? coverError : "810px × 500px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  type="file"
                  // label="تصویر رویداد"
                  label={en_event_cover_input}
                  onChange={handleEnCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(enCoverError)}
                  helperText={enCoverError ? enCoverError : "810px × 500px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: cancel_button_label,
                    okButtonLabel: ok_button_label,
                    todayButtonLabel: today_button_label,
                    clearButtonLabel: clear_button_label
                  }}
                >
                  <DatePicker
                    sx={{ width: "100%" }}
                    // label="تاریخ"
                    label={date_picker_input}
                    minDate={new Date()}
                    defaultValue={new Date() || null}
                    value={selectedDate}
                    inputFormat="YYYY/MM/DD"
                    views={["day", "month", "year"]}
                    onChange={(currentValue) => { setSelectedDate(currentValue) }}
                    onError={(newError) => setSelectedDateError(newError)}
                    slotProps={{
                      actionBar: { actions: ['clear', 'today'] },
                      textField: {
                        helperText: selectedDateMessageError,
                      },
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}
                  localeText={{
                    cancelButtonLabel: cancel_button_label,
                    okButtonLabel: ok_button_label,
                    todayButtonLabel: today_button_label,
                    clearButtonLabel: clear_button_label,
                  }}
                >
                  <TimePicker
                    sx={{ width: "100%" }}
                    // label="زمان"
                    label={time_picker_input}
                    defaultValue={dayjs() || null}
                    value={selectedTime}
                    onChange={(currentValue) => { setSelectedTime(currentValue) }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <FormControl required fullWidth error={Boolean(dayError)}>
                  <InputLabel id="create-day-label">
                    {/* روز */}
                    {day_input}
                  </InputLabel>
                  <Select
                    labelId="create-day-label"
                    value={day}
                    // label="روز"
                    label={day_select}
                    onChange={handleDayChange}
                  >
                    <MenuItem value={Saturday}>
                      {/* شنبه */}
                      {Saturday}
                    </MenuItem>
                    <MenuItem value={Sunday}>
                      {/* یکشنبه */}
                      {Sunday}
                    </MenuItem>
                    <MenuItem value={Monday}>
                      {/* دوشنبه */}
                      {Monday}
                    </MenuItem>
                    <MenuItem value={Tuesday}>
                      {/* سه شنبه */}
                      {Tuesday}
                    </MenuItem>
                    <MenuItem value={Wednesday}>
                      {/* چهارشنبه */}
                      {Wednesday}
                    </MenuItem>
                    <MenuItem value={Thursday}>
                      {/* پنجشنبه */}
                      {Thursday}
                    </MenuItem>
                    <MenuItem value={Friday}>
                      {/* جمعه */}
                      {Friday}
                    </MenuItem>
                  </Select>
                  <FormHelperText>{dayError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  autoComplete="off"
                  // label="عنوان"
                  label={title_input}
                  required
                  inputProps={{
                    maxLength: 100,
                    style: { direction: "rtl", textAlign: "right" },
                  }}
                  error={Boolean(titleError)}
                  helperText={
                    titleError ? titleError : `${title?.length ?? 0}/100`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enTitle}
                  onChange={handleEnTitleChange}
                  autoComplete="off"
                  // label="عنوان"
                  label={en_title_input}
                  inputProps={{
                    maxLength: 100,
                    style: { direction: "ltr", textAlign: "left" },
                  }}
                  error={Boolean(enTitleError)}
                  helperText={
                    enTitleError ? enTitleError : `${enTitle?.length ?? 0}/100`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={description ?? ""}
                  onChange={handleDescriptionChange}
                  autoComplete="off"
                  // label="شرح"
                  label={description_input}
                  multiline
                  rows={4}
                  inputProps={{
                    style: { direction: "rtl", textAlign: "justify" },
                    maxLength: 300
                  }}
                  error={Boolean(descriptionError)}
                  helperText={
                    descriptionError ? descriptionError : `${description?.length ?? 0}/300`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enDescription ?? ""}
                  onChange={handleEnDescriptionChange}
                  autoComplete="off"
                  // label="شرح"
                  label={en_description_input}
                  multiline
                  rows={4}
                  inputProps={{
                    style: { direction: "ltr", textAlign: "justify" },
                    maxLength: 300
                  }}
                  error={Boolean(enDescriptionError)}
                  helperText={
                    enDescriptionError ? enDescriptionError : `${enDescription?.length ?? 0}/300`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={address}
                  onChange={handleAddressChange}
                  autoComplete="off"
                  // label="آدرس"
                  label={address_input}
                  required
                  inputProps={{
                    maxLength: 100,
                    style: { direction: "rtl", textAlign: "right" },
                  }}
                  error={Boolean(addressError)}
                  helperText={
                    addressError ? addressError : `${address?.length ?? 0}/100`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enAddress}
                  onChange={handleEnAddressChange}
                  autoComplete="off"
                  // label="آدرس"
                  label={en_address_input}
                  inputProps={{
                    maxLength: 100,
                    style: { direction: "ltr", textAlign: "left" },
                  }}
                  error={Boolean(enAddressError)}
                  helperText={
                    enAddressError ? enAddressError : `${enAddress?.length ?? 0}/100`
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{
            justifyContent: "flex-end",
          }}>
            <UploadProgressBar />
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              size="large"
              loading={!loadingStoreButton}
              disabled={selectedDateMessageError.length > 0 || !day || !title || !address}
              startIcon={<SaveOutlined />}
              onClick={handleStore}
            >
              {/* ذخیره */}
              {save}
            </LoadingButton>
          </CardActions>
        </Card>
      </Fade>
    </Box>
  );
};

export default CreateEvents;
