import { forwardRef } from "react";
import { useSnackbarContext } from "../context/SnackbarContext";
import { Stack, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackbarComponent() {
  const {
    verticalSnackbar,
    horizontalSnackbar,
    severitySnackbar,
    messageSnackbar,
    openSnackbar,
    handleCloseSnackbar
  } = useSnackbarContext();

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: verticalSnackbar,
          horizontal: horizontalSnackbar
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severitySnackbar}
          sx={{ width: "100%" }}
        >
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default SnackbarComponent;
