import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const FaqContext = createContext(null);
FaqContext.displayName = "FaqContext";

export default FaqContext;

export const FaqContextProvider = ({ children }) => {
    const { postMethodByPageId, getMethod } = useServices();
    const [faqs, setFaqs] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getAllFaqs() {
        setLoading(true);

        try {
            const response = await getMethod("/faqs");
            const data = await response?.data;

            if (response?.status === 200) {
                setFaqs(data);
            }

        } catch (ex) {

        }

        setLoading(false);
    }

    async function getFaqs() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/getFaqs", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setFaqs(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getAllFaqs,
        getFaqs,
        faqs, setFaqs,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <FaqContext.Provider value={contextData}>
            {children}
        </FaqContext.Provider>
    );
}
