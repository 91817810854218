import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import InboxTable from "./InboxTable";

const Inbox = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngInboxIndex");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <InboxTable />
      </Box>
    </>
  );
};

export default Inbox;
