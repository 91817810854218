import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import ShowInstructorInfo from './ShowInstructorInfo';
import { Box } from "@mui/material";

const ShowInstructor = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngShowInstructor");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/instructors" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ShowInstructorInfo />
      </Box>
    </>
  );
};

export default ShowInstructor;
