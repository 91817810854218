import React, { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "../../context/DialogContext";
import SettingsContext from "../../context/SettingsContext";
import {
    DialogContent,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    useTheme,
    Button,
    Box,
    DialogActions,
} from "@mui/material";
import logo from "../../images/Master-It.png";
import { separateNumbers } from "../../components/PriceFormat";
import { CancelOutlined, PrintOutlined } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    header: {
        borderRadius: "10px",
        backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
        padding: "20px 30px 20px 30px",
        marginBottom: "25px"
    },
    content: {
        borderRadius: "10px",
        backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
        padding: "20px 30px 30px 20px",
        marginBottom: "25px"
    },
    headleRightSidebar: (props) => ({
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            justifyContent: props.direction === "rtl" ? "flex-end" : "flex-start",
        },
        fontWeight: "700",
    }),
}));

const ShowInstructorsInvoices = (props) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        typography_course,
        typography_price_with_unit,
        typography_discount_percentage,
        typography_discount,
        typography_membership_percentage,
        typography_membership,
        typography_front_page_percentage,
        typography_front_page,
        typography_total_with_unit,
        buyer_value,
        invoice_number,
        date_value,
        price_unit,
        close_dialog,
        download_print_button
    } = t("lngShowInstructorsInvoices2");
    const { handleCloseDialog } = useDialogContext();
    const { getSettings, settings } = useContext(SettingsContext);
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [coursesTotal, setCoursesTotal] = useState(0);
    const [coursesEnTotal, setCoursesEnTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [enTotal, setEnTotal] = useState(0);
    const componentRef = useRef();
    const classes = useStyles();
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            getSettings();
            isMounted.current = true;
        }
        calcTotalCourses();
        // eslint-disable-next-line
    }, [props?.params]);

    function calcTotalCourses() {
        var coursesTotal = 0;
        var sumTotal = 0;
        var coursesEnTotal = 0;
        var enSumTotal = 0;
        props?.params?.courses?.forEach((item) => {
            var percentages = 0;
            coursesTotal = coursesTotal + parseInt(item?.pivot?.price);
            percentages = parseInt(item?.pivot?.discount) + parseInt(item?.pivot?.membership_percentage) + parseInt(item?.pivot?.front_page_percentage);
            sumTotal = sumTotal + (parseInt(item?.pivot?.price) - (parseInt(item?.pivot?.price * percentages) / 100));

            coursesEnTotal = coursesEnTotal + parseInt(item?.pivot?.en_price);
            enSumTotal = enSumTotal + (parseInt(item?.pivot?.en_price) - (parseInt(item?.pivot?.en_price * percentages) / 100));
        });
        setCoursesTotal(coursesTotal);
        setTotal(sumTotal);
        setCoursesEnTotal(coursesEnTotal);
        setEnTotal(enSumTotal);
    }
    const handleExportPDF = useReactToPrint({
        content: () => componentRef.current,
    });

    function invoiceItems(courses) {
        return courses?.map(function (course, index) {
            return (
                <React.Fragment key={index}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* دوره */}
                                {typography_course}
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* دوره */}
                                    {typography_course}
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={direction === "rtl"
                                ? course?.course_name ?? ""
                                : (course?.en_course_name ?? course?.course_name ?? "")}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* قیمت (تومان) */}
                                {typography_price_with_unit}
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* قیمت (تومان) */}
                                    {typography_price_with_unit}
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={direction === "rtl"
                                ? separateNumbers(course?.pivot?.price) ?? ""
                                : (course?.pivot?.en_price ?? separateNumbers(course?.pivot?.price) ?? "")}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1.3} lg={1.3} xl={1.3}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* درصد تخفیف */}
                                {typography_discount_percentage}
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* تخفیف */}
                                    {typography_discount}
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.pivot.discount + " %" ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1.3} lg={1.3} xl={1.3}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* درصد عضویت */}
                                {typography_membership_percentage}
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* عضویت */}
                                    {typography_membership}
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.pivot.membership_percentage + " %" ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1.4} lg={1.4} xl={1.4}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* درصد صفحه اول */}
                                {typography_front_page_percentage}
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* صفحه اول */}
                                    {typography_front_page}
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={course.pivot.front_page_percentage + " %" ?? ""}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                        {isMobile ? (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* جمع دوره (تومان) */}
                                {typography_total_with_unit}
                            </Typography>
                        ) : (
                            index === 0 && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* جمع دوره (تومان) */}
                                    {typography_total_with_unit}
                                </Typography>
                            )
                        )}
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={direction === "rtl"
                                ? separateNumbers(parseInt(course?.pivot?.price) - (parseInt(course?.pivot?.price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage))) / 100)) ?? ""
                                : (parseInt(course?.pivot?.en_price) - (parseInt(course?.pivot?.en_price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage)) / 100)) ?? separateNumbers(parseInt(course?.pivot?.price) - (parseInt(course?.pivot?.price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage))) / 100)) ?? "")}
                            inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                        />
                    </Grid>
                </React.Fragment>
            )
        })
    }

    return (
        <>
            <DialogContent>
                <Box ref={componentRef}>
                    {isMobile ? (
                        <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "150px",
                                        display: "block",
                                        margin: "0 auto 10px auto",
                                    }}
                                    alt="Master it"
                                    src={logo}
                                />
                                <Box
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        margin: "0 0 30px 0",
                                        color: colors.orangeAccent[500]
                                    }}>
                                    {direction === "rtl" ? (
                                        settings?.slogan
                                    ) : (
                                        settings?.en_slogan
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="div"
                                    className={classes.headleRightSidebar}>
                                    {buyer_value} {props?.params?.user?.full_name ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headleRightSidebar}>
                                    {invoice_number} {props?.params?.order_id ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headleRightSidebar}>
                                    {date_value}تاریخ: {props?.params?.created_at ?? ""}
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="div"
                                    className={classes.headleRightSidebar}>
                                    {buyer_value} {props?.params?.user?.full_name ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headleRightSidebar}>
                                    {invoice_number} {props?.params?.order_id ?? ""}
                                </Box>
                                <Box
                                    component="div"
                                    className={classes.headleRightSidebar}>
                                    {date_value} {props?.params?.created_at ?? ""}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Box
                                    component="img"
                                    sx={{
                                        maxWidth: "150px",
                                        display: "block",
                                        margin: "0 auto 10px auto",
                                    }}
                                    alt="Master it"
                                    src={logo}
                                />
                                <Box
                                    component="div"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        margin: "0 0 30px 0",
                                        color: colors.orangeAccent[500]
                                    }}>
                                    {direction === "rtl" ? (
                                        settings?.slogan
                                    ) : (
                                        settings?.en_slogan
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
                        {invoiceItems(props?.params?.courses)}
                    </Grid>
                    <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {isMobile && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* جمع کل دوره‌ها (تومان) */}
                                    {typography_total_with_unit}
                                </Typography>
                            )}
                            <TextField
                                label={!isMobile ? typography_total_with_unit : ""}
                                fullWidth
                                variant="outlined"
                                type="text"
                                value={direction === "rtl"
                                    ? separateNumbers(coursesTotal) + " " + price_unit ?? ""
                                    : (price_unit + " " + coursesEnTotal ?? separateNumbers(coursesTotal) + " " + price_unit ?? "")}
                                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            {isMobile && (
                                <Typography
                                    component="span"
                                    variant="body1"
                                    color="primary"
                                    sx={{ fontWeight: "700" }}
                                >
                                    {/* جمع نهایی (تومان) */}
                                    {typography_total_with_unit}
                                </Typography>
                            )}
                            <TextField
                                label={!isMobile ? typography_total_with_unit : ""}
                                fullWidth
                                variant="outlined"
                                type="text"
                                value={direction === "rtl"
                                    ? separateNumbers(total) + " " + price_unit ?? ""
                                    : (price_unit + " " + enTotal ?? separateNumbers(total) + " " + price_unit ?? "")}
                                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleCloseDialog}
                >
                    {/* بستن */}
                    {close_dialog}
                </Button>
                <Button
                    onClick={handleExportPDF}
                    variant="outlined"
                    startIcon={<PrintOutlined />}
                >
                    {/* دانلود / پرینت */}
                    {download_print_button}
                </Button>
            </DialogActions>
        </>
    );
};

export default ShowInstructorsInvoices;