import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const UserBankAccountsContext = createContext(null);
UserBankAccountsContext.displayName = "UserBankAccountsContext";

export default UserBankAccountsContext;

export const UserBankAccountsContextProvider = ({ children }) => {
    const { postMethodByPageId } = useServices();
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccountsByUserId, setBankAccountsByUserId] = useState([]);
    const [bankAccountsByUserIdLoading, setBankAccountsByUserIdLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getBankAccountsByUserId(instructorId) {
        setBankAccountsByUserIdLoading(true);

        try {
            const obj = {
                user_id: instructorId,
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/user_bank_accounts/list", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setBankAccountsByUserId(data.data);
            }
        } catch (ex) {

        }

        setBankAccountsByUserIdLoading(false);
    }

    const contextData = {
        getBankAccountsByUserId,
        bankAccounts, setBankAccounts,
        bankAccountsByUserId, setBankAccountsByUserId,
        bankAccountsByUserIdLoading, setBankAccountsByUserIdLoading,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <UserBankAccountsContext.Provider value={contextData}>
            {children}
        </UserBankAccountsContext.Provider>
    );
}
