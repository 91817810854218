import React from "react";
import { Grid, Skeleton } from "@mui/material";

const ContactInfoLoading = (props) => {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Skeleton variant="text" height={props?.titleHeight} width={props?.titleWidth} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <Skeleton variant="text" height={props?.valueHeight} width={props?.valueWidth} />
            </Grid>
        </Grid>
    )
}

export default ContactInfoLoading;
