import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import CreateCourses from "./CreateCourses";
import { Box } from "@mui/material";

const AdminCourses = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngAdminCourses");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/courses" />
      </Box>
      <CreateCourses />
    </>
  );
};

export default AdminCourses;
