import { useState, useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CategoryContext from "../../context/CategoryContext";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";

const CreateCategories = () => {
    const { t } = useTranslation();
    const {
        courses_category_registered,
        category_name_required,
        en_category_name_required,
        add_category,
        category_name_input,
        en_category_name_input,
        save
    } = t("lngCreateCategories");
    const { handleOpenSnackbar } = useSnackbarContext();
    const { getCategories } = useContext(CategoryContext);
    const { postMethod } = useServices();
    const [loadingStoreButton, setLoadingStoreButton] = useState(true);
    const [categoryName, setCategoryName] = useState("");
    const [categoryNameError, setCategoryNameError] = useState("");
    const [enCategoryName, setEnCategoryName] = useState("");
    const [enCategoryNameError, setEnCategoryNameError] = useState("");

    const handleStore = async () => {
        setLoadingStoreButton(false);

        try {
            const obj = {
                category_name: categoryName,
                en_category_name: enCategoryName
            };
            await postMethod("/categories", obj);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "دسته‌بندی دوره‌ها با موفقیت ثبت شد"
                courses_category_registered
            );

            getCategories();

        } catch (ex) {
            if (ex.response && ex.response.status === 422) {
                setCategoryNameError(ex.response.data.errors.category_name);
                setEnCategoryNameError(ex.response.data.errors.en_category_name);
            }
        }

        setLoadingStoreButton(true);
    };

    const handleCategoryNameChange = (e) => {
        const currentValue = e.target.value;
        setCategoryName(currentValue);
        if (currentValue?.length === 0) {
            // setCategoryNameError("تکمیل گزینه نام دسته‌بندی الزامی است");
            setCategoryNameError(category_name_required);
        } else {
            setCategoryNameError("");
        }
    };

    const handleEnCategoryNameChange = (e) => {
        let currentValue = e.target.value;
        // جلوگیری از ورود حروف فارسی
        const englishLettersOnly = /^[A-Za-z\s&()/.|"']*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }

        // تبدیل حرف اول هر کلمه به حروف بزرگ، بدون حذف فاصله بین کلمات
        currentValue = currentValue
            .split(' ') // رشته را به کلمات جدا می‌کند
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // حرف اول هر کلمه را به بزرگ تبدیل می‌کند
            .join(' '); // کلمات را با یک فاصله به هم متصل می‌کند

        setEnCategoryName(currentValue);
        if (currentValue?.length === 0) {
            // setEnCategoryNameError("تکمیل گزینه نام دسته‌بندی الزامی است");
            setEnCategoryNameError(en_category_name_required);
        } else {
            setEnCategoryNameError("");
        }
    };

    return (
        <Box mb="50px" mt="50px" className="container">
            <Fade triggerOnce={true}>
                <Card>
                    <CardHeader title={add_category} />
                    <CardContent>
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    value={categoryName ?? ""}
                                    onChange={handleCategoryNameChange}
                                    autoComplete="off"
                                    // label="نام دسته‌بندی"
                                    label={category_name_input}
                                    required
                                    inputProps={{
                                        style: { direction: "rtl", textAlign: "right" },
                                        maxLength: 40
                                    }}
                                    error={Boolean(categoryNameError)}
                                    helperText={
                                        categoryNameError
                                            ? categoryNameError
                                            : `${categoryName?.length ?? 0}/40`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    value={enCategoryName ?? ""}
                                    onChange={handleEnCategoryNameChange}
                                    autoComplete="off"
                                    // label="نام دسته‌بندی"
                                    label={en_category_name_input}
                                    required
                                    inputProps={{
                                        style: { direction: "ltr", textAlign: "left" },
                                        maxLength: 40
                                    }}
                                    error={Boolean(enCategoryNameError)}
                                    helperText={
                                        enCategoryNameError
                                            ? enCategoryNameError
                                            : `${enCategoryName?.length ?? 0}/40`
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <LoadingButton
                                    fullWidth
                                    variant="outlined"
                                    loadingPosition="start"
                                    loading={!loadingStoreButton}
                                    disabled={!categoryName || !enCategoryName}
                                    startIcon={<SaveOutlined />}
                                    onClick={handleStore}
                                    sx={{ height: "56px" }}
                                >
                                    {/* ذخیره */}
                                    {save}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Fade>
        </Box>
    )
}

export default CreateCategories
