import { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { persian2english } from "../../components/ConvertDigits";
import Header from "../../components/Header";
import { useSnackbarContext } from "../../context/SnackbarContext";
import GalleryContext from "../../context/GalleryContext";
import {
  TextField,
  CardActions,
  Grid,
  CardHeader,
  Card,
  CardContent,
  InputAdornment,
  Box,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloudUpload, SaveOutlined } from "@mui/icons-material";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const EditGallery = () => {
  const { id: galleryId } = useParams();
  const { t } = useTranslation();
  const {
    date_is_not_valid,
    date_format_incorrect,
    gallery_registered,
    gallery_updated,
    record_deleted,
    title_required,
    en_title_required,
    header_title,
    header_subtitle,
    gallery_cover_change,
    gallery_cover_input,
    gallery_cover_save,
    gallery_edit,
    title_input,
    en_title_input,
    cancel_button_label,
    ok_button_label,
    today_button_label,
    clear_button_label,
    date_picker_input,
    save
  } = t("lngEditGallery");
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getGalleryById, gallery } = useContext(GalleryContext);
  const { putMethod, uploadPostMethod } = useServices();
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [loadingCoverStore, setLoadingCoverStore] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enTitleError, setEnTitleError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date() || null);
  const [selectedDateError, setSelectedDateError] = useState(null);

  const selectedDateMessageError = useMemo(() => {
    switch (selectedDateError) {
      case 'maxDate':
      case 'minDate': {
        // return 'تاریخ وارد شده معتبر نمی باشد';
        return date_is_not_valid;
      }

      case 'invalidDate': {
        // return 'فرمت تاریخ صحیح نمی باشد';
        return date_format_incorrect;
      }

      default: {
        return '';
      }
    }
  }, [selectedDateError, date_format_incorrect, date_is_not_valid]);

  useEffect(() => {
    getGalleryById(galleryId);
    // eslint-disable-next-line
  }, [galleryId]);

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [gallery]);

  function fillRow() {
    setTitle(gallery?.title);
    setTitleError("");
    setEnTitle(gallery?.en_title);
    setEnTitleError("");
    // setSelectedDate(gallery?.date);
    setSelectedDateError(null);
  }

  const handleCoverStore = async () => {
    setLoadingCoverStore(false);

    try {
      const obj = {
        cover: cover,
        gallery_id: galleryId,
      };

      await uploadPostMethod("/gallery/cover", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "تصویر گالری با موفقیت ثبت شد"
        gallery_registered
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.cover &&
          ex.response.data.errors.cover.length > 1) {
          setCoverError(ex.response.data.errors.cover[0]);
        } else {
          setCoverError(ex.response.data.errors.cover);
        }
      }
    }

    setLoadingCoverStore(true);
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        title: title,
        en_title: enTitle,
        date: persian2english(selectedDate.toLocaleDateString('fa-IR')),
      };

      await putMethod(
        "/gallery",
        gallery?.id,
        obj
      );

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "گالری با موفقیت ویرایش شد"
        gallery_updated
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          // "رکورد مورد نظر قبلاً حذف شده است"
          record_deleted
        );
      }
      if (ex.response && ex.response.status === 422) {
        setTitleError(ex.response.data.errors.title);
        setEnTitleError(ex.response.data.errors.en_title);
        setSelectedDateError(ex.response.data.errors.date);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    // console.log(cover);
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setTitleError(title_required);
    } else {
      setTitleError("");
    }
  };

  const handleEnTitleChange = (e) => {
    const currentValue = e.target.value;
    // جلوگیری از ورود حروف فارسی
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setEnTitleError(en_title_required);
    } else {
      setEnTitleError("");
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/gallery" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Box mb="50px">
          <Fade triggerOnce={true}>
            <Card>
              <CardHeader title={gallery_cover_change} />
              <CardContent>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                    <TextField
                      fullWidth
                      type="file"
                      // label="تصویر گالری"
                      label={gallery_cover_input}
                      onChange={handleCoverChange}
                      accept="image/*"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CloudUpload />
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(coverError)}
                      helperText={coverError ? coverError : "390px × 250px"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <LoadingButton
                      fullWidth
                      variant="outlined"
                      loadingPosition="start"
                      loading={!loadingCoverStore}
                      disabled={cover?.length === 0}
                      startIcon={<SaveOutlined />}
                      onClick={handleCoverStore}
                      sx={{ height: "56px" }}
                    >
                      {/* ذخیره تصویر */}
                      {gallery_cover_save}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <UploadProgressBar />
              </CardActions>
            </Card>
          </Fade>
        </Box>
        <Fade triggerOnce={true}>
          <Card>
            <CardHeader title={gallery_edit} />
            <CardContent>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={title ?? ""}
                    onChange={handleTitleChange}
                    autoComplete="off"
                    // label="عنوان"
                    label={title_input}
                    required
                    inputProps={{
                      maxLength: 50,
                      style: { direction: "rtl", textAlign: "right" },
                    }}
                    error={Boolean(titleError)}
                    helperText={
                      titleError ? titleError : `${title?.length ?? 0}/50`
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={enTitle ?? ""}
                    onChange={handleEnTitleChange}
                    autoComplete="off"
                    // label="عنوان"
                    label={en_title_input}
                    required
                    inputProps={{
                      maxLength: 50,
                      style: { direction: "ltr", textAlign: "left" },
                    }}
                    error={Boolean(enTitleError)}
                    helperText={
                      enTitleError ? enTitleError : `${enTitle?.length ?? 0}/50`
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFnsJalali}
                    localeText={{
                      cancelButtonLabel: cancel_button_label,
                      okButtonLabel: ok_button_label,
                      todayButtonLabel: today_button_label,
                      clearButtonLabel: clear_button_label
                    }}
                  >
                    <DatePicker
                      sx={{ width: "100%" }}
                      // label="تاریخ"
                      label={date_picker_input}
                      // minDate={new Date()}
                      // maxDate={new Date("2042-02-08")}
                      defaultValue={new Date() || null}
                      value={selectedDate}
                      inputFormat="YYYY/MM/DD"
                      views={["day", "month", "year"]}
                      onChange={(currentValue) => { setSelectedDate(currentValue) }}
                      onError={(newError) => setSelectedDateError(newError)}
                      slotProps={{
                        actionBar: { actions: ['clear', 'today'] },
                        textField: {
                          helperText: selectedDateMessageError,
                        },
                        field: { clearable: true },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <LoadingButton
                variant="outlined"
                loadingPosition="start"
                size="large"
                loading={!loadingUpdateButton}
                disabled={!title || !enTitle || !selectedDate}
                startIcon={<SaveOutlined />}
                onClick={handleUpdate}
              >
                {/* ذخیره */}
                {save}
              </LoadingButton>
            </CardActions>
          </Card>
        </Fade>
      </Box>
    </>
  );
};

export default EditGallery;
