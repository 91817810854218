import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ErrorPage = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        textAlign: "center",
        margin: "auto auto 30px auto",
      }}
    >
      <Typography
        variant="h3"
        color={colors.blueAccent[500]}
        fontWeight="bold"
        sx={{ mb: "10px" }}
        className="scale_animation"
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        color={colors.orangeAccent[500]}
        className="scale_animation"
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default ErrorPage;
