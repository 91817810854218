import { useContext } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const PrivateRoute = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const { id } = useParams();

    var state403 = false;
    if (user) { // This is checking if the action is 401 or not.
        switch (location.pathname) {
            case '/invoices':
                state403 = true;
                break;
            case '/order-status':
                state403 = true;
                break;
            case '/profile':
                state403 = true;
                break;
            case '/change-password':
                state403 = true;
                break;
            case '/inbox':
                state403 = true;
                break;
            case '/sent-emails':
                state403 = true;
                break;
            case '/dashboard':
                state403 = true;
                break;
            case '/transactions/show/' + id:
                state403 = true;
                break;
            case '/admin-contact':
                if (user?.role?.admin_contact === 1) state403 = true;
                break;
            case '/admin-courses':
                if (user?.role?.admin_courses === 1) state403 = true;
                break;
            case '/admin-course/edit/' + id:
                if (user?.role?.admin_courses === 1) state403 = true;
                break;
            case '/admin-categories':
                if (user?.role?.admin_categories === 1) state403 = true;
                break;
            case '/admin-seasons/' + id:
                if (user?.role?.admin_courses === 1) state403 = true;
                break;
            case '/admin-episodes/' + id:
                if (user?.role?.admin_courses === 1) state403 = true;
                break;
            case '/admin-events':
                if (user?.role?.admin_events === 1) state403 = true;
                break;
            case '/admin-event/edit/' + id:
                if (user?.role?.admin_events === 1) state403 = true;
                break;
            case '/admin-faq':
                if (user?.role?.admin_faq === 1) state403 = true;
                break;
            case '/admin-blog':
                if (user?.role?.admin_blog === 1) state403 = true;
                break;
            case '/admin-tags':
                if (user?.role?.admin_tags === 1) state403 = true;
                break;
            case '/admin-gallery':
                if (user?.role?.admin_gallery === 1) state403 = true;
                break;
            case '/admin-gallery/edit/' + id:
                if (user?.role?.admin_gallery === 1) state403 = true;
                break;
            case '/admin-instructor/edit/' + id:
                if (user?.role?.admin_instructors === 1) state403 = true;
                break;
            case '/admin-instructors-invoices':
                if (user?.role?.admin_instructors_invoices === 1) state403 = true;
                break;
            case '/receipt/show/' + id:
                if (user?.role?.admin_instructors_invoices === 1) state403 = true;
                break;
            case '/admin-instructor/bank-accounts/' + id:
                if (user?.role?.admin_instructors === 1) state403 = true;
                break;
            case '/admin-privacy':
                if (user?.role?.admin_privacy === 1) state403 = true;
                break;
            case '/admin-roles':
                if (user?.role?.admin_roles === 1) state403 = true;
                break;
            case '/admin-settings':
                if (user?.role?.admin_settings === 1) state403 = true;
                break;
            case '/admin-transactions':
                if (user?.role?.admin_transactions === 1) state403 = true;
                break;
            case '/admin-receipts':
                if (user?.role?.admin_transactions === 1) state403 = true;
                break;
            case '/transactions/instructors-invoices/' + id:
                if (user?.role?.admin_transactions === 1) state403 = true;
                break;
            case '/admin-user-bank-accounts':
                if (user?.role?.admin_users === 1) state403 = true;
                break;
            case '/admin-users':
                if (user?.role?.admin_users === 1) state403 = true;
                break;
            case '/admin-user/show/' + id:
                if (user?.role?.admin_users === 1) state403 = true;
                break;
            default:
                return <Navigate to="/error-404" />;
        }
        if (state403 === true) { // This is checking if the action is 403 or not.
            return <Outlet />;
        } else {
            return <Navigate to="/error-403" />;
        }
    } else {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
}

export default PrivateRoute;
