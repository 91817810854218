import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const EditSeason = (props) => {
  const { t } = useTranslation();
  const {
    season_name_required,
    en_season_name_required,
    season_updated,
    record_deleted,
    season_name_input,
    en_season_name_input,
    cancel,
    save
  } = t("lngEditSession");
  const { putMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [seasonName, setSeasonName] = useState("");
  const [seasonNameError, setSeasonNameError] = useState("");
  const [enSeasonName, setEnSeasonName] = useState("");
  const [enSeasonNameError, setEnSeasonNameError] = useState("");

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [props]);

  function fillRow() {
    setSeasonName(props?.season?.season_name);
    setSeasonNameError("");
    setEnSeasonName(props?.season?.en_season_name);
    setEnSeasonNameError("");
  }

  const handleSeasonNameChange = (e) => {
    const currentValue = e.target.value;
    setSeasonName(currentValue);
    if (currentValue?.length === 0) {
      // setSeasonNameError("تکمیل گزینه نام فصل الزامی است");
      setSeasonNameError(season_name_required);
    } else {
      setSeasonNameError("");
    }
  };

  const handleEnSeasonNameChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnSeasonName(currentValue);
    if (currentValue?.length === 0) {
      // setEnSeasonNameError("تکمیل گزینه نام فصل الزامی است");
      setEnSeasonNameError(en_season_name_required);
    } else {
      setEnSeasonNameError("");
    }
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        season_name: seasonName,
        en_season_name: enSeasonName,
      };

      await putMethod(
        "/seasons",
        props?.season?.id,
        obj
      );

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "فصل با موفقیت ویرایش شد"
        season_updated
      );

      props?.getSeasonsByCourseId(props?.season?.course_id)
      handleResetDialogItems();

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          // "رکورد مورد نظر قبلاً حذف شده است"
          record_deleted
        );
      }
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.season &&
          ex.response.data.errors.season.length > 1) {
          setSeasonNameError(ex.response.data.errors.season_name[0]);
        } else {
          setSeasonNameError(ex.response.data.errors.season_name);
        }
        if (ex.response.data.errors.en_season &&
          ex.response.data.errors.en_season.length > 1) {
          setEnSeasonNameError(ex.response.data.errors.en_season_name[0]);
        } else {
          setEnSeasonNameError(ex.response.data.errors.en_season_name);
        }
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleResetDialogItems = () => {
    handleCloseDialog();
    fillRow();
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={seasonName ?? ""}
            onChange={handleSeasonNameChange}
            autoComplete="off"
            // label="نام فصل"
            label={season_name_input}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "right" },
              maxLength: 40
            }}
            error={Boolean(seasonNameError)}
            helperText={seasonNameError}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enSeasonName ?? ""}
            onChange={handleEnSeasonNameChange}
            autoComplete="off"
            // label="نام فصل"
            label={en_season_name_input}
            required
            inputProps={{
              style: { direction: "ltr", textAlign: "left" },
              maxLength: 40
            }}
            error={Boolean(enSeasonNameError)}
            helperText={enSeasonNameError}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          {/* انصراف */}
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={
            !seasonName ||
            !enSeasonName
          }
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          {/* ذخیره */}
          {save}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditSeason;
