import { createContext, useContext, useEffect, useState } from "react";
import useServices from "../utils/useServices";
import SettingsContext from "./SettingsContext";

const GalleryContext = createContext(null);
GalleryContext.displayName = "GalleryContext";

export default GalleryContext;

export const GalleryContextProvider = ({ children }) => {
    const { postMethodByPageId, getMethod } = useServices();
    const { settings } = useContext(SettingsContext);
    const baseImageUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/galleries/`;
    // const baseImageUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/galleries/`;
    const [galleries, setGalleries] = useState([]);
    const [galleryByIdLoading, setGalleryByIdLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [gallery, setGallery] = useState([]);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(9);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        getGalleries();
        // eslint-disable-next-line
    }, [page]);

    async function getGalleries() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: settings?.gallery_rows ?? rows,
            };

            const response = await postMethodByPageId("/getGalleries", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setGalleries(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    async function getGalleryById(galleryId) {
        setGalleryByIdLoading(true);

        try {
            const response = await getMethod("/gallery/" + galleryId);
            const data = await response?.data;

            if (response?.status === 200) {
                setGallery(data);
            }
        } catch (ex) {

        }

        setGalleryByIdLoading(false);
    }

    const contextData = {
        getGalleries,
        getGalleryById,
        baseImageUrl,
        galleries, setGalleries,
        galleryByIdLoading, setGalleryByIdLoading,
        gallery, setGallery,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <GalleryContext.Provider value={contextData}>
            {children}
        </GalleryContext.Provider>
    );
}
