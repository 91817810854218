import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { useDialogContext } from "../../context/DialogContext";
import EpisodeContext from "../../context/EpisodeContext";
import CustomDataGrid from "../../components/CustomDataGrid";
import {
  Box,
  Button,
  Stack
} from "@mui/material";
import DeleteEpisode from "./DeleteEpisode";
import EditEpisode from "./EditEpisode";
import { Delete, Edit } from "@mui/icons-material";

const AdminEpisodes = () => {
  const { id: seasonId } = useParams();
  const { t } = useTranslation();
  const {
    columns_row,
    columns_episode_name,
    columns_en_episode_name,
    title,
    subtitle,
    episode_edit_title,
    edit_button,
    delete_button
  } = t("lngAdminEpisodes");
  const { handleOpenDialog } = useDialogContext();
  const {
    getEpisodesBySeasonId,
    episodes,
    selectedRow, setSelectedRow,
    loading,
    page, setPage,
    rows, setRows,
    from,
    to,
    total
  } = useContext(EpisodeContext);

  const columns = [
    {
      field: "id",
      // headerName: "ردیف",
      headerName: columns_row,
      filterable: false,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
    },
    {
      field: "episode_name",
      // headerName: "نام بخش",
      headerName: columns_episode_name,
      flex: 1,
    },
    {
      field: "en_episode_name",
      // headerName: "نام بخش",
      headerName: columns_en_episode_name,
      flex: 1,
    },
  ];

  useEffect(() => {
    getEpisodesBySeasonId(seasonId);
    // eslint-disable-next-line
  }, [rows, total, page]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/courses" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <Stack direction="row" spacing={1}>
          <Button
            onClick={() =>
              handleOpenDialog(
                "sm", // xs sm md lg xl
                // "ویرایش بخش",
                episode_edit_title,
                <EditEpisode
                  params={selectedRow}
                  getEpisodesBySeasonId={getEpisodesBySeasonId}
                  seasonId={seasonId}
                />
              )
            }
            variant="outlined"
            startIcon={<Edit />}
            disabled={!selectedRow}
          >
            {/* ویرایش */}
            {edit_button}
          </Button>
          <Button
            onClick={() =>
              handleOpenDialog(
                "sm", // xs sm md lg xl
                "",
                <DeleteEpisode
                  params={selectedRow}
                  getEpisodesBySeasonId={getEpisodesBySeasonId}
                  seasonId={seasonId}
                />
              )
            }
            variant="outlined"
            startIcon={<Delete />}
            disabled={!selectedRow}
          >
            {/* حذف */}
            {delete_button}
          </Button>
        </Stack>
        <CustomDataGrid
          loading={loading}
          myData={episodes}
          myColumns={columns}
          setSelectedRow={setSelectedRow}
          setRows={setRows}
          setPage={setPage}
          from={from}
          to={to}
          total={total}
        />
      </Box>
    </>
  );
};

export default AdminEpisodes;
