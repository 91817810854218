import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import CategoryContext from "../../context/CategoryContext";
import CategoryPost from "./CategoryPost";
import { Box, Grid, Pagination } from "@mui/material";
import CreateCategories from "./CreateCategories";

const AdminCategories = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngAdminCategories");

  const {
    getCategories,
    categories,
    page, setPage,
    lastPage
  } = useContext(CategoryContext);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/admin-courses" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <CreateCategories />
        <Grid container spacing={2}>
          {categories?.map((category, index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3}>
              <CategoryPost category={category} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          {lastPage !== 1 && (
            <Pagination count={lastPage} onChange={(e, value) => setPage(value)} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default AdminCategories;
