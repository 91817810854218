import { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { separateNumbers } from "../../components/PriceFormat";
import { tokens } from "../../theme";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CategoryContext from "../../context/CategoryContext";
import InstructorsContext from "../../context/InstructorsContext";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Box,
  useTheme,
  InputAdornment,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Typography
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined, CloudUpload } from "@mui/icons-material";
import UploadProgressBar from "../../components/UploadProgressBar";

const CreateCourses = () => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    course_registered,
    front_page_percentage_required,
    category_name_required,
    course_name_required,
    en_course_name_required,
    price_required,
    en_price_required,
    discount_required,
    add_course,
    add_category_quickly1,
    add_category_quickly2,
    add_category_quickly3,
    add_instructor_quickly1,
    add_instructor_quickly2,
    add_instructor_quickly3,
    course_cover_input,
    en_course_cover_input,
    category_name_input_label,
    course_name_input,
    en_course_name_input,
    description_input,
    en_description_input,
    instructor_input_label,
    price_with_unit_input,
    en_price_with_unit_input,
    course_discount_percent_input,
    front_page_percentage_input,
    save
  } = t("lngCreateCourses");
  const { uploadPostMethod } = useServices();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const { handleOpenSnackbar } = useSnackbarContext();
  const {
    getAllCategories,
    allCategories,
    allCategoriesLoading
  } = useContext(CategoryContext);
  const {
    getAllInstructors,
    allInstructors,
    allInstructorsLoading
  } = useContext(InstructorsContext);
  const isMounted = useRef(false);
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [frontPagePercentage, setFrontPagePercentage] = useState("");
  const [frontPagePercentageError, setFrontPagePercentageError] = useState("");
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [enCover, setEnCover] = useState([]);
  const [enCoverError, setEnCoverError] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameError, setCategoryNameError] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseNameError, setCourseNameError] = useState("");
  const [enCourseName, setEnCourseName] = useState("");
  const [enCourseNameError, setEnCourseNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [enDescription, setEnDescription] = useState("");
  const [enDescriptionError, setEnDescriptionError] = useState("");
  const [instructor, setInstructor] = useState("");
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [enPrice, setEnPrice] = useState("");
  const [enPriceError, setEnPriceError] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountError, setDiscountError] = useState("");

  useEffect(() => {
    if (!isMounted.current) {
      getAllCategories();
      getAllInstructors();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        front_page_percentage: frontPagePercentage,
        category_id: categoryName,
        cover: cover,
        en_cover: enCover,
        course_name: courseName,
        en_course_name: enCourseName,
        description: description,
        en_description: enDescription,
        instructor: instructor,
        price: price,
        en_price: enPrice,
        discount: discount
      };
      const { data: insertedProcess } = await uploadPostMethod("/courses", obj);

      const categoryNameById = allCategories?.find((item) => {
        return item.id === obj.category_id;
      });

      insertedProcess.category = categoryNameById;
      insertedProcess.price = separateNumbers(insertedProcess.price);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "دوره با موفقیت ثبت شد"
        course_registered
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setCategoryNameError(ex.response.data.errors.category_id);
        if (ex.response.data.errors.cover &&
          ex.response.data.errors.cover.length > 1) {
          setCoverError(ex.response.data.errors.cover[0]);
        } else {
          setCoverError(ex.response.data.errors.cover);
        }
        if (ex.response.data.errors.en_cover &&
          ex.response.data.errors.en_cover.length > 1) {
          setEnCoverError(ex.response.data.errors.en_cover[0]);
        } else {
          setEnCoverError(ex.response.data.errors.en_cover);
        }
        setCourseNameError(ex.response.data.errors.course_name);
        setEnCourseNameError(ex.response.data.errors.en_course_name);
        setDescriptionError(ex.response.data.errors.description);
        setEnDescriptionError(ex.response.data.errors.en_description);
        if (ex.response.data.errors.price &&
          ex.response.data.errors.price.length > 1) {
          setPriceError(ex.response.data.errors.price[0]);
        } else {
          setPriceError(ex.response.data.errors.price);
        }
        if (ex.response.data.errors.en_price &&
          ex.response.data.errors.en_price.length > 1) {
          setEnPriceError(ex.response.data.errors.en_price[0]);
        } else {
          setEnPriceError(ex.response.data.errors.en_price);
        }
        if (ex.response.data.errors.discount &&
          ex.response.data.errors.discount.length > 1) {
          setDiscountError(ex.response.data.errors.discount[0]);
        } else {
          setDiscountError(ex.response.data.errors.discount);
        }
        if (ex.response.data.errors.front_page_percentage &&
          ex.response.data.errors.front_page_percentage.length > 1) {
          setFrontPagePercentageError(ex.response.data.errors.front_page_percentage[0]);
        } else {
          setFrontPagePercentageError(ex.response.data.errors.front_page_percentage);
        }
      }
    }

    setLoadingStoreButton(true);
  };

  const handleFrontPagePercentageChange = (e) => {
    const currentValue = e.target.value;
    setFrontPagePercentage(currentValue);
    if (currentValue?.length === 0) {
      // setFrontPagePercentageError("تکمیل گزینه درصد صفحه اول الزامی است");
      setFrontPagePercentageError(front_page_percentage_required);
    } else {
      setFrontPagePercentageError("");
    }
  };

  const handleCategoryNameChange = (e) => {
    const currentValue = e.target.value;
    setCategoryName(currentValue);
    if (currentValue?.length === 0) {
      // setCategoryNameError("تکمیل گزینه نام دسته‌بندی الزامی است");
      setCategoryNameError(category_name_required);
    } else {
      setCategoryNameError("");
    }
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    // console.log(cover);
  };

  const handleEnCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setEnCover(currentValue);
    // console.log(enCover);
  };

  const handleCourseNameChange = (e) => {
    const currentValue = e.target.value;
    setCourseName(currentValue);
    if (currentValue?.length === 0) {
      // setCourseNameError("تکمیل گزینه نام دوره الزامی است");
      setCourseNameError(course_name_required);
    } else {
      setCourseNameError("");
    }
  };

  const handleEnCourseNameChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnCourseName(currentValue);
    if (currentValue?.length === 0) {
      // setEnCourseNameError("تکمیل گزینه نام دوره الزامی است");
      setEnCourseNameError(en_course_name_required);
    } else {
      setEnCourseNameError("");
    }
  };

  const handleDescriptionChange = (e) => {
    const currentValue = e.target.value;
    setDescription(currentValue);
  };

  const handleEnDescriptionChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnDescription(currentValue);
  };

  const handleInstructorChange = (e) => {
    const currentValue = e.target.value;
    setInstructor(currentValue);
  };

  const handlePriceChange = (e) => {
    const currentValue = e.target.value;
    setPrice(currentValue);
    if (currentValue?.length === 0) {
      // setPriceError("تکمیل گزینه قیمت الزامی است");
      setPriceError(price_required);
    } else {
      setPriceError("");
    }
  };

  const handleEnPriceChange = (e) => {
    const currentValue = e.target.value;
    setEnPrice(currentValue);
    if (currentValue?.length === 0) {
      // setEnPriceError("تکمیل گزینه قیمت الزامی است");
      setEnPriceError(en_price_required);
    } else {
      setEnPriceError("");
    }
  };

  const handleDiscountChange = (e) => {
    const currentValue = e.target.value;
    setDiscount(currentValue);
    if (currentValue?.length === 0) {
      // setDiscountError("تکمیل گزینه درصد تخفیف دوره الزامی است");
      setDiscountError(discount_required);
    } else {
      setDiscountError("");
    }
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Card>
          <CardHeader title={add_course} />
          <CardContent>
            <Grid container rowSpacing={4} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Fade triggerOnce={true}>
                    <Typography
                      component="span"
                      variant="h6"
                    >
                      {/* در صورتی که دسته‌بندی مورد نظر وجود ندارد */}
                      {add_category_quickly1}
                      <NavLink to="/admin-categories">
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            color: mode === "light"
                              ? colors.blueAccent[500]
                              : colors.orangeAccent[500],
                          }}
                        >
                          {/* &nbsp;اینجا&nbsp; */}
                          &nbsp;{add_category_quickly2}&nbsp;
                        </Typography>
                      </NavLink>
                      {/* کلیک کنید. */}
                      {add_category_quickly3}
                    </Typography>
                  </Fade>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Fade triggerOnce={true}>
                    <Typography
                      component="span"
                      variant="h6"
                    >
                      {/* در صورتی که استاد مورد نظر وجود ندارد */}
                      {add_instructor_quickly1}
                      <NavLink to="/admin-users">
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{
                            fontWeight: 700,
                            color: mode === "light"
                              ? colors.blueAccent[500]
                              : colors.orangeAccent[500],
                          }}
                        >
                          {/* &nbsp;اینجا&nbsp; */}
                          &nbsp;{add_instructor_quickly2}&nbsp;
                        </Typography>
                      </NavLink>
                      {/* کلیک کنید. */}
                      {add_instructor_quickly3}
                    </Typography>
                  </Fade>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  type="file"
                  // label="تصویر دوره"
                  label={course_cover_input}
                  onChange={handleCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(coverError)}
                  helperText={coverError ? coverError : "810px × 500px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  type="file"
                  // label="تصویر دوره"
                  label={en_course_cover_input}
                  onChange={handleEnCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(enCoverError)}
                  helperText={enCoverError ? enCoverError : "810px × 500px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                {allInstructorsLoading ? (
                  <FormControl required fullWidth>
                    <InputLabel id="create-instructor-loading">
                      {/* استاد */}
                      {instructor_input_label}
                    </InputLabel>
                    <Select value="" labelId="create-instructor-loading" label={instructor_input_label}>
                      <MenuItem>
                        <CircularProgress />
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl required fullWidth>
                    <InputLabel id="create-instructor-label">
                      {/* استاد */}
                      {instructor_input_label}
                    </InputLabel>
                    <Select
                      labelId="create-instructor-label"
                      label={instructor_input_label}
                      value={instructor ?? ""}
                      onChange={handleInstructorChange}
                    >
                      {allInstructors?.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {direction === "rtl" ? item?.full_name : item?.en_full_name ?? item?.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                {allCategoriesLoading ? (
                  <FormControl required fullWidth>
                    <InputLabel id="create-category-loading">
                      {/* نام دسته‌بندی */}
                      {category_name_input_label}
                    </InputLabel>
                    <Select value="" labelId="create-category-loading" label={category_name_input_label}>
                      <MenuItem>
                        <CircularProgress />
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl required fullWidth error={Boolean(categoryNameError)}>
                    <InputLabel id="create-category-name-label">
                      {/* نام دسته‌بندی */}
                      {category_name_input_label}
                    </InputLabel>
                    <Select
                      labelId="create-category-name-label"
                      value={categoryName}
                      // label="نام دسته‌بندی"
                      label={category_name_input_label}
                      onChange={handleCategoryNameChange}
                    >
                      {allCategories?.map((item) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          {direction === "rtl" ? item?.category_name : item?.en_category_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{categoryNameError}</FormHelperText>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={courseName ?? ""}
                  onChange={handleCourseNameChange}
                  autoComplete="off"
                  // label="نام دوره"
                  label={course_name_input}
                  required
                  inputProps={{
                    style: { direction: "rtl", textAlign: "right" },
                    maxLength: 100
                  }}
                  error={Boolean(courseNameError)}
                  helperText={
                    courseNameError ? courseNameError : `${courseName?.length ?? 0}/100`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enCourseName ?? ""}
                  onChange={handleEnCourseNameChange}
                  autoComplete="off"
                  // label="نام دوره"
                  label={en_course_name_input}
                  required
                  inputProps={{
                    style: { direction: "ltr", textAlign: "left" },
                    maxLength: 100
                  }}
                  error={Boolean(enCourseNameError)}
                  helperText={
                    enCourseNameError ? enCourseNameError : `${enCourseName?.length ?? 0}/100`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={description ?? ""}
                  onChange={handleDescriptionChange}
                  autoComplete="off"
                  // label="شرح"
                  label={description_input}
                  multiline
                  rows={4}
                  inputProps={{
                    style: { direction: "rtl", textAlign: "justify" },
                    maxLength: 300
                  }}
                  error={Boolean(descriptionError)}
                  helperText={
                    descriptionError ? descriptionError : `${description?.length ?? 0}/300`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enDescription ?? ""}
                  onChange={handleEnDescriptionChange}
                  autoComplete="off"
                  // label="شرح"
                  label={en_description_input}
                  multiline
                  rows={4}
                  inputProps={{
                    style: { direction: "ltr", textAlign: "justify" },
                    maxLength: 300
                  }}
                  error={Boolean(enDescriptionError)}
                  helperText={
                    enDescriptionError ? enDescriptionError : `${enDescription?.length ?? 0}/300`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={price ?? ""}
                  onChange={handlePriceChange}
                  onKeyUp={() => setPrice(separateNumbers(price, "createCoursesNumToLetter"))}
                  autoComplete="off"
                  label={price_with_unit_input}
                  required
                  inputProps={{
                    style: { direction: "rtl", textAlign: "right" },
                    maxLength: 50
                  }}
                  error={Boolean(priceError)}
                  helperText={priceError}
                />
                <Box
                  color={theme.palette.mode === "light" ?
                    colors.blueAccent[500]
                    :
                    colors.orangeAccent[500]
                  }
                  id="createCoursesNumToLetter"
                  sx={{ textAlign: "justify", fontSize: "12px" }}
                ></Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enPrice ?? ""}
                  onChange={handleEnPriceChange}
                  autoComplete="off"
                  label={en_price_with_unit_input}
                  required
                  inputProps={{
                    style: { direction: "ltr", textAlign: "left" },
                    maxLength: 50
                  }}
                  error={Boolean(enPriceError)}
                  helperText={enPriceError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={discount ?? ""}
                  onChange={handleDiscountChange}
                  autoComplete="off"
                  // label="درصد تخفیف دوره"
                  label={course_discount_percent_input}
                  required
                  inputProps={{
                    style: { direction: "rtl", textAlign: "right" },
                    maxLength: 3
                  }}
                  error={Boolean(discountError)}
                  helperText={discountError}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={frontPagePercentage ?? ""}
                  onChange={handleFrontPagePercentageChange}
                  autoComplete="off"
                  // label="درصد صفحه اول"
                  label={front_page_percentage_input}
                  required
                  inputProps={{
                    style: { direction: "rtl", textAlign: "right" },
                    maxLength: 3
                  }}
                  error={Boolean(frontPagePercentageError)}
                  helperText={frontPagePercentageError}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{
            justifyContent: "flex-end",
          }}>
            <UploadProgressBar />
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              size="large"
              loading={!loadingStoreButton}
              disabled={
                !categoryName ||
                !courseName ||
                !enCourseName ||
                !instructor ||
                !price ||
                !enPrice ||
                !discount ||
                !frontPagePercentage
              }
              startIcon={<SaveOutlined />}
              onClick={handleStore}
            >
              {/* ذخیره */}
              {save}
            </LoadingButton>
          </CardActions>
        </Card>
      </Fade>
    </Box >
  );
};

export default CreateCourses;
