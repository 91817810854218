export const accessibility = [
    {
        "id": 1,
        "fa_title": "فعال‌سازی",
        "en_title": "Activation",
    },
    {
        "id": 2,
        "fa_title": "کاربران",
        "en_title": "Users",
    },
    {
        "id": 3,
        "fa_title": "رویدادها",
        "en_title": "Events",
    },
    {
        "id": 4,
        "fa_title": "اساتید",
        "en_title": "Instructors",
    },
    {
        "id": 5,
        "fa_title": "گالری",
        "en_title": "Gallery",
    },
    {
        "id": 6,
        "fa_title": "سؤالات متداول",
        "en_title": "FAQ",
    },
    {
        "id": 7,
        "fa_title": "بلاگ",
        "en_title": "Blog",
    },
    {
        "id": 8,
        "fa_title": "پاسخ به بلاگ",
        "en_title": "Answer to the Blog",
    },
    {
        "id": 9,
        "fa_title": "تگ‌ها",
        "en_title": "Tags",
    },
    {
        "id": 10,
        "fa_title": "تماس با ما",
        "en_title": "Contact Us",
    },
    {
        "id": 11,
        "fa_title": "قوانین و مقررات",
        "en_title": "Terms & Conditions",
    },
    {
        "id": 12,
        "fa_title": "دسته‌بندی دوره‌ها",
        "en_title": "Category of Courses",
    },
    {
        "id": 13,
        "fa_title": "دوره‌ها",
        "en_title": "Courses",
    },
    {
        "id": 14,
        "fa_title": "تراکنش‌ها",
        "en_title": "Transactions",
    },
    {
        "id": 15,
        "fa_title": "حساب‌های بانکی اساتید",
        "en_title": "Instructors' Bank Accounts",
    },
    {
        "id": 16,
        "fa_title": "رسید بانکی اساتید",
        "en_title": "Instructors' Bank Receipt",
    },
    {
        "id": 17,
        "fa_title": "فاکتورهای استاد",
        "en_title": "Instructors' Invoices",
    },
    {
        "id": 18,
        "fa_title": "تنظیمات",
        "en_title": "Settings",
    },
    {
        "id": 19,
        "fa_title": "نقش‌ها و سطح دسترسی",
        "en_title": "Roles",
    },
];
