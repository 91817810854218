import { createContext, useContext, useState } from "react";

const SnackbarContext = createContext();
SnackbarContext.displayName = "SnackbarContext";

const SnackbarContextProvider = ({ children }) => {
    const [verticalSnackbar, setVerticalSnackbar] = useState("bottom");
    const [horizontalSnackbar, setHorizontalSnackbar] = useState("left");
    const [severitySnackbar, setSeveritySnackbar] = useState("");
    const [messageSnackbar, setMessageSnackbar] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleOpenSnackbar = (vertical, horizontal, severity, message) => {
        setVerticalSnackbar(vertical);
        setHorizontalSnackbar(horizontal);
        setSeveritySnackbar(severity);
        setMessageSnackbar(message);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <SnackbarContext.Provider value={{
            verticalSnackbar,
            horizontalSnackbar,
            severitySnackbar,
            messageSnackbar,
            openSnackbar,
            handleOpenSnackbar,
            handleCloseSnackbar
        }}>
            {children}
        </SnackbarContext.Provider>
    );
};

// hook
const useSnackbarContext = () => {
    const context = useContext(SnackbarContext);
    return context;
};

export { useSnackbarContext, SnackbarContextProvider };