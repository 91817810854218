import { useContext, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import UsersContext from "./../../context/UsersContext";
import CanContext from "../../context/CanContext";
import AdminAnswerBlogMenu from "./AdminAnswerBlogMenu";
import {
  Avatar,
  Box,
  Typography,
  Stack,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  Paper,
  Badge,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  postText: {
    textAlign: "justify"
  },
  profileTitle: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
  },
  profileSubTitle: {
    fontSize: "13px !important",
    fontWeight: "700 !important",
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function AnswerBlog({ answerBlogs }) {
  const { t } = useTranslation();
  const {
    answer_given,
    offline,
    online
  } = t("lngAnswerBlog");
  const { baseProfileUrl } = useContext(UsersContext);
  const { adminAnswerBlog } = useContext(CanContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
              <Stack direction="row" spacing={2}>
                <IconButton
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
                <Box sx={{ marginTop: "6px !important" }}>
                  {answerBlogs?.length} {answer_given}
                </Box>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {answerBlogs?.map((answerBlog, index) => (
                  <Box
                    key={index}
                    component="form"
                    sx={{ p: '20px 10px 10px 30px' }}
                  >
                    <Box display="flex" justifyContent="space-between">
                      <Stack direction="row" spacing={2}>
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant={answerBlog?.user?.role_id === 1 ? "standard" : "dot"}
                        >
                          <Avatar src={baseProfileUrl + answerBlog?.user?.profile} />
                        </StyledBadge>
                        <Box sx={{ display: "block" }}>
                          <Typography className={classes.profileTitle} gutterBottom sx={{ marginBottom: "0px !important" }}>
                            {answerBlog?.user?.full_name}
                          </Typography>
                          <Typography className={classes.profileSubTitle} gutterBottom>
                            {answerBlog?.user?.role_id === 1 ? offline : online}
                          </Typography>
                        </Box>
                      </Stack>
                      {adminAnswerBlog === 1 && (
                        <Fade triggerOnce={true}>
                          <AdminAnswerBlogMenu answerBlog={answerBlog} />
                        </Fade>
                      )}
                    </Box>
                    <Stack sx={{ p: '20px 30px 10px 55px' }}>
                      <Typography variant="body2" className={classes.postText}>
                        {answerBlog?.answer}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AnswerBlog;
