import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import AuthContext from "./context/AuthContext";
import { RoleContextProvider } from "./context/RoleContext";
import { CountryContextProvider } from "./context/CountryContext";
import { UsersContextProvider } from "./context/UsersContext";
import DirectionContext from "./context/DirectionContext";
import CanContext from "./context/CanContext";
import { InboxContextProvider } from "./context/InboxContext";
import { TransactionsContextProvider } from "./context/TransactionsContext";
import { InstructorsInvoicesContextProvider } from "./context/InstructorsInvoicesContext";
import { EventsContextProvider } from "./context/EventsContext";
import { InstructorsContextProvider } from "./context/InstructorsContext";
import { CartContextProvider } from "./context/CartContext";
import { MenuContextProvider } from "./context/MenuContext";
import { DialogContextProvider } from "./context/DialogContext";
import { ProvinceContextProvider } from "./context/ProvinceContext";
import { GalleryContextProvider } from "./context/GalleryContext";
import { FaqContextProvider } from "./context/FaqContext";
import { PrivacyContextProvider } from "./context/PrivacyContext";
import { ContactContextProvider } from "./context/ContactContext";
import { TagsContextProvider } from "./context/TagsContext";
import { CategoryContextProvider } from "./context/CategoryContext";
import { CoursesContextProvider } from "./context/CoursesContext";
import { BlogsContextProvider } from "./context/BlogsContext";
import { SeasonContextProvider } from "./context/SeasonContext";
import { EpisodeContextProvider } from "./context/EpisodeContext";
import { BankContextProvider } from "./context/BankContext";
import { UserBankAccountsContextProvider } from "./context/UserBankAccountsContext";
import PrivateRoute from "./utils/PrivateRoute";
import { CacheProvider } from "@emotion/react";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import createCache from "@emotion/cache";
import AdminSettings from "./pages/settings/AdminSettings";
import AdminRoles from "./pages/roles/AdminRoles";
import AdminUsers from "./pages/users/AdminUsers";
import AdminFaq from "./pages/faq/AdminFaq";
import AdminInstructorsInvoices from "./pages/receipt/AdminInstructorsInvoices";
import AdminReceipts from './pages/receipt/AdminReceipts';
import AdminTags from "./pages/tags/AdminTags";
import AdminCategories from "./pages/categories/AdminCategories";
import AdminCourses from "./pages/courses/AdminCourses";
import AdminSeasons from "./pages/seasons/AdminSeasons";
import AdminEpisodes from "./pages/episodes/AdminEpisodes";
import AdminTransactions from "./pages/transactions/AdminTransactions";
import ShowTransactions from "./pages/transactions/ShowTransactions";
import OrderStatus from "./pages/transactions/OrderStatus";
import TransactionFailed from "./pages/transactions/TransactionFailed";
import TransactionSuccess from "./pages/transactions/TransactionSuccess";
import EditInstructors from "./pages/instructors/EditInstructors";
import AdminGallery from "./pages/gallery/AdminGallery";
import EditGallery from "./pages/gallery/EditGallery";
import AdminPrivacy from "./pages/privacy/AdminPrivacy";
import AdminContact from "./pages/contact/AdminContact";
import AdminEvents from "./pages/events/AdminEvents";
import EditEvents from "./pages/events/EditEvents";
import HomeComponent from "./pages/home";
import Register from "./pages/register";
import ActiveAccount from './pages/register/ActiveAccount';
import RecoveryPassword from "./pages/recovery_password";
import TemporaryCode from "./pages/recovery_password/TemporaryCode";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Contact from "./pages/contact";
import Instructors from "./pages/instructors";
import FAQ from "./pages/faq";
import Gallery from "./pages/gallery";
import Events from "./pages/events";
import ShowEvent from "./pages/events/ShowEvents";
import Courses from "./pages/courses/index";
import EditCourses from "./pages/courses/EditCourses";
import ShowCourse from "./pages/courses/ShowCourses";
import Cart from "./pages/cart/index";
import Privacy from "./pages/privacy";
import Blog from "./pages/blog/index";
import Profile from "./pages/profile";
import ChangePassword from "./pages/change_password";
import ShowInstructor from "./pages/instructors/ShowInstructor";
import BankAccounts from "./pages/bank_accounts";
import Inbox from "./pages/inbox";
import SentEmails from "./pages/sent_emails";
import Invoices from "./pages/invoices";
import InstructorsInvoices from "./pages/invoices/InstructorsInvoices";
import ShowReceipt from "./pages/receipt/ShowReceipt";
import ShowUser from "./pages/users/ShowUser";
import BackToTop from "./components/BackToTop";
import LinearDeterminate from "./components/LinearDeterminate";
import DialogComponent from "./components/DialogComponent";
import SnackbarComponent from "./components/SnackbarComponent";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Error400 from "./errors/Error400";
import Error401 from "./errors/Error401";
import Error402 from "./errors/Error402";
import Error403 from "./errors/Error403";
import Error404 from "./errors/Error404";
import Error405 from "./errors/Error405";
import Error429 from "./errors/Error429";
import Error500 from "./errors/Error500";
import Error501 from "./errors/Error501";
import Error502 from "./errors/Error502";
import Error503 from "./errors/Error503";
import Error504 from "./errors/Error504";
import Error505 from "./errors/Error505";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: "muiltr",
});

const useStyles = makeStyles((theme) => ({
  toolbar: {
    alignItems: "end !important",
    minHeight: "23px !important",
    padding: "0 0 0 0 !important",
  },

}));

function App() {
  const { t } = useTranslation();
  const { copyright } = t("lngApp");
  const { currentLanguage, direction } = useContext(DirectionContext);
  const fontClass = `font-${direction}`;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnWindowFocus: false }
    }
  });
  const { user } = useContext(AuthContext);
  const { resetCan } = useContext(CanContext);
  const location = useLocation();
  const [theme, colorMode] = useMode();
  const classes = useStyles();

  useEffect(() => {
    document.body.dir = direction || "fa";
    // eslint-disable-next-line
  }, [currentLanguage]);

  useEffect(() => {
    resetCan();
    // eslint-disable-next-line
  }, [user]);

  return (
    <Box className={`app ${fontClass}`}>

      <ColorModeContext.Provider value={colorMode}>
        <CacheProvider value={direction === "rtl" ? cacheRtl : cacheLtr}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <main className="content">
              <QueryClientProvider client={queryClient}>
                <BankContextProvider>
                  <RoleContextProvider>
                    <CountryContextProvider>
                      <UsersContextProvider>
                        <UserBankAccountsContextProvider>
                          <TagsContextProvider>
                            <CategoryContextProvider>
                              <DialogContextProvider>
                                <CoursesContextProvider>
                                  <BlogsContextProvider>
                                    <SeasonContextProvider>
                                      <EpisodeContextProvider>
                                        <CartContextProvider>
                                          <InboxContextProvider>
                                            <TransactionsContextProvider>
                                              <InstructorsContextProvider>
                                                <InstructorsInvoicesContextProvider>
                                                  <EventsContextProvider>
                                                    <ProvinceContextProvider>
                                                      <GalleryContextProvider>
                                                        <FaqContextProvider>
                                                          <PrivacyContextProvider>
                                                            <ContactContextProvider>
                                                              <MenuContextProvider>
                                                                <Toolbar>
                                                                  <Navbar />
                                                                </Toolbar>
                                                                <Toolbar className={classes.toolbar}>
                                                                  <LinearDeterminate />
                                                                </Toolbar>
                                                                <Box sx={{ minHeight: "100%" }}>
                                                                  <Routes>
                                                                    {/* Private Routes */}
                                                                    <Route element={<PrivateRoute />} >
                                                                      <Route path="/profile" element={<Profile />} />
                                                                      <Route path="/change-password" element={<ChangePassword />} />
                                                                      <Route path="/inbox" element={<Inbox />} />
                                                                      <Route path="/sent-emails" element={<SentEmails />} />
                                                                      <Route path="/dashboard" element={<Dashboard />} />
                                                                      <Route path="/admin-users" element={<AdminUsers />} />
                                                                      <Route path="/admin-user/show/:id" element={<ShowUser />} />
                                                                      <Route path="/admin-events" element={<AdminEvents />} />
                                                                      <Route path="/admin-event/edit/:id" element={<EditEvents />} />
                                                                      <Route path="/admin-instructor/edit/:id" element={<EditInstructors />} />
                                                                      <Route path="/admin-instructor/bank-accounts/:id" element={<BankAccounts />} />
                                                                      <Route path="/admin-gallery" element={<AdminGallery />} />
                                                                      <Route path="/admin-gallery/edit/:id" element={<EditGallery />} />
                                                                      <Route path="/admin-faq" element={<AdminFaq />} />
                                                                      <Route path="/admin-tags" element={<AdminTags />} />
                                                                      <Route path="/admin-contact" element={<AdminContact />} />
                                                                      <Route path="/admin-privacy" element={<AdminPrivacy />} />
                                                                      <Route path="/admin-categories" element={<AdminCategories />} />
                                                                      <Route path="/admin-courses" element={<AdminCourses />} />
                                                                      <Route path="/admin-course/edit/:id" element={<EditCourses />} />
                                                                      <Route path="/admin-seasons/:id" element={<AdminSeasons />} />
                                                                      <Route path="/admin-episodes/:id" element={<AdminEpisodes />} />
                                                                      <Route path="/invoices" element={<Invoices />} />
                                                                      <Route path="/order-status" element={<OrderStatus />} />
                                                                      <Route path="/admin-transactions" element={<AdminTransactions />} />
                                                                      <Route path="/transactions/show/:id" element={<ShowTransactions />} />
                                                                      <Route path="/admin-instructors-invoices" element={<AdminInstructorsInvoices />} />
                                                                      <Route path="/transactions/instructors-invoices/:id" element={<InstructorsInvoices />} />
                                                                      <Route path="/admin-receipts" element={<AdminReceipts />} />
                                                                      <Route path="/receipt/show/:id" element={<ShowReceipt />} />
                                                                      <Route path="/admin-settings" element={<AdminSettings />} />
                                                                      <Route path="/admin-roles" element={<AdminRoles />} />
                                                                    </Route>
                                                                    {/* Private Routes */}
                                                                    <Route
                                                                      path="/"
                                                                      exact
                                                                      element={
                                                                        user !== null ? (
                                                                          <Dashboard />
                                                                        ) : (
                                                                          <HomeComponent />
                                                                        )
                                                                      }
                                                                    />
                                                                    <Route path="/events" element={<Events />} />
                                                                    <Route path="/event/show/:id" element={<ShowEvent />} />
                                                                    <Route path="/instructors" element={<Instructors />} />
                                                                    <Route path="/instructor/show/:id" element={<ShowInstructor />} />
                                                                    <Route
                                                                      path="/login"
                                                                      element={
                                                                        user !== null ? (
                                                                          !user?.first_name ||
                                                                            !user?.en_first_name ||
                                                                            !user?.last_name ||
                                                                            !user?.en_last_name ||
                                                                            !user?.address ||
                                                                            !user?.zip_code ? (
                                                                            <Navigate to="/profile" />
                                                                          ) : (
                                                                            location.state ? (
                                                                              <Navigate to={location.state.from.pathname} />
                                                                            ) : (
                                                                              <Navigate to="/dashboard" />
                                                                            )
                                                                          )
                                                                        ) : (
                                                                          <Login />
                                                                        )
                                                                      }
                                                                    />
                                                                    <Route
                                                                      path="/register"
                                                                      element={
                                                                        user !== null ? (
                                                                          <Navigate to="/dashboard" />
                                                                        ) : (
                                                                          <Register />
                                                                        )
                                                                      }
                                                                    />
                                                                    <Route
                                                                      path="/active-account/:id"
                                                                      element={
                                                                        user !== null ? (
                                                                          <Navigate to="/dashboard" />
                                                                        ) : (
                                                                          <ActiveAccount />
                                                                        )
                                                                      }
                                                                    />
                                                                    <Route
                                                                      path="/recovery-password"
                                                                      element={
                                                                        user !== null ? (
                                                                          <Navigate to="/dashboard" />
                                                                        ) : (
                                                                          <RecoveryPassword />
                                                                        )
                                                                      }
                                                                    />
                                                                    <Route
                                                                      path="/recovery-password/:id"
                                                                      element={
                                                                        user !== null ? (
                                                                          <Navigate to="/dashboard" />
                                                                        ) : (
                                                                          <TemporaryCode />
                                                                        )
                                                                      }
                                                                    />
                                                                    <Route path="/privacy" element={<Privacy />} />
                                                                    <Route path="/gallery" element={<Gallery />} />
                                                                    <Route path="/faq" element={<FAQ />} />
                                                                    <Route path="/courses" element={<Courses />} />
                                                                    <Route path="/course/show/:id" element={<ShowCourse />} />
                                                                    <Route path="/cart" element={<Cart />} />
                                                                    <Route path="/transaction/failed" element={<TransactionFailed />} />
                                                                    <Route path="/transaction/success" element={<TransactionSuccess />} />
                                                                    <Route path="/blog" element={<Blog user={user} />} />
                                                                    <Route path="/contact" element={<Contact />} />
                                                                    <Route path="/error-400" element={<Error400 />} />
                                                                    <Route path="/error-401" element={<Error401 />} />
                                                                    <Route path="/error-402" element={<Error402 />} />
                                                                    <Route path="/error-403" element={<Error403 />} />
                                                                    <Route path="/error-404" element={<Error404 />} />
                                                                    <Route path="/error-405" element={<Error405 />} />
                                                                    <Route path="/error-429" element={<Error429 />} />
                                                                    <Route path="/error-500" element={<Error500 />} />
                                                                    <Route path="/error-501" element={<Error501 />} />
                                                                    <Route path="/error-502" element={<Error502 />} />
                                                                    <Route path="/error-503" element={<Error503 />} />
                                                                    <Route path="/error-504" element={<Error504 />} />
                                                                    <Route path="/error-505" element={<Error505 />} />
                                                                    <Route path="*" element={<Error404 />} />
                                                                  </Routes>
                                                                </Box>
                                                                {user !== null ? (
                                                                  <Box sx={{ background: "#02182d", padding: "1px 0 1px 0" }}>
                                                                    <Box sx={{ margin: "10px auto 10px auto" }} className="container">
                                                                      <Box display="flex" justifyContent="center" sx={{ textAlign: "center", color: "#fff" }}>
                                                                        {/* 2024 <Copyright />&nbsp;
                                                                        کلیه حقوق مادی و معنوی این سایت محفوظ و متعلق به شرکت master it است */}
                                                                        {copyright}
                                                                      </Box>
                                                                    </Box>
                                                                  </Box>
                                                                ) : (
                                                                  <Footer />
                                                                )}
                                                                <BackToTop showBelow={1} /> {/* showBelow={200} */}
                                                              </MenuContextProvider>
                                                            </ContactContextProvider>
                                                          </PrivacyContextProvider>
                                                        </FaqContextProvider>
                                                      </GalleryContextProvider>
                                                    </ProvinceContextProvider>
                                                  </EventsContextProvider>
                                                </InstructorsInvoicesContextProvider>
                                              </InstructorsContextProvider>
                                            </TransactionsContextProvider>
                                          </InboxContextProvider>
                                        </CartContextProvider>
                                      </EpisodeContextProvider>
                                    </SeasonContextProvider>
                                  </BlogsContextProvider>
                                </CoursesContextProvider>
                                <SnackbarComponent />
                                <DialogComponent />
                              </DialogContextProvider>
                            </CategoryContextProvider>
                          </TagsContextProvider>
                        </UserBankAccountsContextProvider>
                      </UsersContextProvider>
                    </CountryContextProvider>
                  </RoleContextProvider>
                </BankContextProvider>
              </QueryClientProvider>
            </main>
          </ThemeProvider>
        </CacheProvider>
      </ColorModeContext.Provider>

    </Box>
  );
}

export default App;
