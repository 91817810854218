import { createContext, useContext, useState } from "react";
import useServices from './../utils/useServices';
import SettingsContext from "./SettingsContext";

const CategoryContext = createContext(null);
CategoryContext.displayName = "CategoryContext";

export default CategoryContext;

export const CategoryContextProvider = ({ children }) => {
    const { getMethod, postMethodByPageId } = useServices();
    const { settings } = useContext(SettingsContext);
    const [allCategoriesLoading, setAllCategoriesLoading] = useState(true);
    const [allCategories, setAllCategories] = useState([]);
    const [allCategoriesStatus, setAllCategoriesStatus] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(16);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getAllCategories() {
        setAllCategoriesLoading(true);

        try {
            const response = await getMethod("/categories");
            const data = await response?.data;

            if (response?.status === 200) {
                setAllCategories(data);
                setAllCategoriesStatus(response.status);
            }
        } catch (ex) {

        }

        setAllCategoriesLoading(false);
    }

    async function getCategories() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: settings?.category_rows ?? rows,
            };

            const response = await postMethodByPageId("/getCategories", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setCategories(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getAllCategories,
        getCategories,
        allCategoriesLoading, setAllCategoriesLoading,
        allCategories, setAllCategories,
        allCategoriesStatus, setAllCategoriesStatus,
        categories, setCategories,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <CategoryContext.Provider value={contextData}>
            {children}
        </CategoryContext.Provider>
    );
}
