import { useContext, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { makeStyles } from "@mui/styles";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useSnackbarContext } from "../../context/SnackbarContext";
import SettingsContext from "../../context/SettingsContext";
import {
  useTheme,
  Box,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Phone, LocationOn, Email } from '@mui/icons-material';
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  singleContactInfo: {
    textAlign: "center !important",
    padding: "0 30px",
    position: "relative",
    transition: "all ease 0.5s",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    [theme.breakpoints.up("md")]: {
      ...(theme.direction === "ltr"
        ? {
          borderRight:
            theme.palette.mode === "light"
              ? `1px solid ${tokens(theme.palette.mode).grey[800]} !important`
              : `1px solid ${tokens(theme.palette.mode).grey[700]} !important`,
        }
        : {
          borderLeft:
            theme.palette.mode === "light"
              ? `1px solid ${tokens(theme.palette.mode).grey[800]} !important`
              : `1px solid ${tokens(theme.palette.mode).grey[700]} !important`,
        }),
    },
  },
  lastSingleContactInfo: {
    textAlign: "center !important",
    padding: "0 30px",
    position: "relative",
    transition: "all ease 0.5s",
    "&:hover": {
      transform: "translateY(-5px)",
    },
  },
  icon: {
    fontSize: "54px !important",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      fontSize: "42px !important",
      marginBottom: theme.spacing(2),
    }
  },
  text: {
    fontWeight: "700 !important",
    marginBottom: `${theme.spacing(4)} !important`,
  },
  link: {
    display: "block",
    color: `${tokens(theme.palette.mode).grey[500]} !important`,
  },
  typography: {
    color: `${tokens(theme.palette.mode).grey[500]} !important`,
  },
  contactForm: {
    textAlign: "center !important",
    padding: "0 30px",
    position: "relative",
  }
}));

const Contact = () => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    message_sent,
    full_name_required,
    email_required,
    invalid_email,
    subject_required,
    contact_message_required,
    title,
    subtitle,
    typography_contact_us,
    lnk_phone,
    lnk_tel,
    typography_address,
    typography_email,
    learn_more,
    full_name_input,
    full_name_placeholder,
    email_input,
    email_placeholder,
    subject_input,
    subject_placeholder,
    contact_message_input,
    contact_message_placeholder,
    send_message_button
  } = t("lngContactIndex");
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getSettings, settings } = useContext(SettingsContext);
  const { postMethod } = useServices();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const classes = useStyles();
  const isMounted = useRef(false);
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(true);
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [contactMessageError, setContactMessageError] = useState("");

  useEffect(() => {
    if (!isMounted.current) {
      getSettings();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    setLoadingSubmitButton(false);

    try {
      const obj = {
        full_name: fullName,
        email: email,
        subject: subject,
        message: contactMessage,
      };
      await postMethod("/contact", obj);
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "پیام شما با موفقیت ارسال شد"
        message_sent
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex?.response?.data?.errors?.full_name?.length > 1) {
          setFullNameError(ex.response.data.errors.full_name[0]);
        } else {
          setFullNameError(ex.response.data.errors.full_name);
        }
        setEmailError(ex.response.data.errors.email);
        setSubjectError(ex.response.data.errors.subject);
        setContactMessageError(ex.response.data.errors.message);
      }
    }

    setLoadingSubmitButton(true);
  };

  const handleFullNameChange = (e) => {
    const currentValue = e.target.value;
    setFullName(currentValue);
    if (currentValue?.length === 0) {
      // setFullNameError("تکمیل گزینه نام و نام خانوادگی الزامی است");
      setFullNameError(full_name_required);
    } else {
      setFullNameError("");
    }
  };

  const handleEmailChange = (e) => {
    const currentValue = e.target.value;
    setEmail(currentValue);
    if (currentValue?.length === 0) {
      // return setEmailError("تکمیل گزینه ایمیل الزامی است");
      return setEmailError(email_required);
    } else {
      setEmailError("");
    }
    if (!validateEmail(currentValue)) {
      // setEmailError("گزینه ایمیل باید یک آدرس ایمیل صحیح باشد");
      setEmailError(invalid_email);
    } else {
      setEmailError("");
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSubjectChange = (e) => {
    const currentValue = e.target.value;
    setSubject(currentValue);
    if (currentValue?.length === 0) {
      // setSubjectError("تکمیل گزینه موضوع الزامی است");
      setSubjectError(subject_required);
    } else {
      setSubjectError("");
    }
  };

  const handleContactMessageChange = (e) => {
    const currentValue = e.target.value;
    setContactMessage(currentValue);
    if (currentValue?.length === 0) {
      // setContactMessageError("تکمیل گزینه پیام الزامی است");
      setContactMessageError(contact_message_required);
    } else {
      setContactMessageError("");
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Grid container rowSpacing={10}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className={classes.singleContactInfo}>
              <Phone className={classes.icon} />
              <Typography className={classes.text} variant="h3">
                {/* با ما تماس بگیرید */}
                {typography_contact_us}
              </Typography>
              <Link
                variant="body1"
                href={"tel:" + settings?.phone2 || ""}
                underline="none"
                className={classes.link}
              >
                {lnk_phone} : <Typography variant="span" style={{ direction: "ltr", unicodeBidi: "embed" }}>{settings?.phone2 || ""}</Typography>
              </Link>
              <Link
                variant="body1"
                href={"tel:" + settings?.tel || ""}
                underline="none"
                className={classes.link}
              >
                {lnk_tel} : <Typography variant="span" style={{ direction: "ltr", unicodeBidi: "embed" }}>{settings?.tel || ""}</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className={classes.singleContactInfo}>
              <LocationOn className={classes.icon} />
              <Typography className={classes.text} variant="h3">
                {/* آدرس */}
                {typography_address}
              </Typography>
              <Typography className={classes.typography}>
                {direction === "rtl" ? (
                  settings?.address || ""
                ) : (
                  settings?.en_address || ""
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className={classes.lastSingleContactInfo} sx={{ borderRight: "none !important" }}>
              <Email className={classes.icon} />
              <Typography className={classes.text} variant="h3">
                {/* ایمیل */}
                {typography_email}
              </Typography>
              <Typography className={classes.typography}>
                {settings?.email1 || ""}
              </Typography>
              <Typography className={classes.typography}>
                {settings?.email2 || ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className={classes.contactForm} sx={{ borderRight: "none !important" }}>
              <Typography variant="h3" className={classes.text}>
                {/* جهت کسب اطلاعات بیشتر در مورد دوره‌ها با ما در تماس باشید */}
                {learn_more}
              </Typography>
              <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={fullName ?? ""}
                    type="text"
                    onChange={handleFullNameChange}
                    required
                    autoComplete="off"
                    fullWidth
                    // label="نام و نام خانوادگی"
                    label={full_name_input}
                    // placeholder="نام و نام خانوادگی را وارد کنید"
                    placeholder={full_name_placeholder}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      style: { textAlign: "center" },
                    }}
                    error={Boolean(fullNameError)}
                    helperText={fullNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    value={email ?? ""}
                    type="text"
                    onChange={handleEmailChange}
                    required
                    autoComplete="off"
                    fullWidth
                    // label="ایمیل"
                    label={email_input}
                    // placeholder="ایمیل را وارد کنید"
                    placeholder={email_placeholder}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      style: { textAlign: "center" },
                    }}
                    error={Boolean(emailError)}
                    helperText={emailError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    value={subject ?? ""}
                    type="text"
                    onChange={handleSubjectChange}
                    required
                    autoComplete="off"
                    fullWidth
                    // label="موضوع"
                    label={subject_input}
                    // placeholder="موضوع را وارد کنید"
                    placeholder={subject_placeholder}
                    variant="outlined"
                    error={Boolean(subjectError)}
                    helperText={subjectError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    value={contactMessage ?? ""}
                    type="text"
                    onChange={handleContactMessageChange}
                    required
                    autoComplete="off"
                    fullWidth
                    multiline
                    rows={5}
                    // label="متن پیام"
                    label={contact_message_input}
                    // placeholder="پیام خود را وارد کنید"
                    placeholder={contact_message_placeholder}
                    variant="outlined"
                    inputProps={{
                      min: 0,
                      style: { textAlign: "justify" },
                    }}
                    error={Boolean(contactMessageError)}
                    helperText={contactMessageError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    sx={{
                      borderradius: 28,
                      color: colors.white,
                      padding: "15px 40px"
                    }}
                    loading={!loadingSubmitButton}
                    disabled={fullName?.length === 0 || emailError?.length > 0 || !subject || !contactMessage}
                    onClick={handleSubmit}
                  >
                    {/* ارسال پیام */}
                    {send_message_button}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: "80px" }}>
                  <Fade triggerOnce={true}>
                    <Box width="100%" height="100%">
                      <iframe
                        title="googleMapFrame"
                        src={settings?.google_map_area}
                        width="100%"
                        height="400"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </Box>
                  </Fade>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
