import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";
import CanContext from './../../context/CanContext';
import EventsContext from "../../context/EventsContext";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Typography,
    useTheme,
    Skeleton,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AdminEventsMenu from "./AdminEventsMenu";

const EventsPost = ({ event }) => {
    const { i18n } = useTranslation();
    const direction = i18n.dir();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const { baseImageUrl, loading } = useContext(EventsContext);
    const { adminEvents } = useContext(CanContext);

    return (
        <Box className="my-animation">
            {adminEvents === 1 &&
                <AdminEventsMenu event={event} />
            }

            <Card sx={{ margin: "10px" }}>
                {loading ? (
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                    >
                        <CardMedia
                            component="img"
                            sx={{ width: "810px", height: "380px" }}
                        />
                    </Skeleton>
                ) : (
                    <NavLink to={`/event/show/${event?.id}`}>
                        <Fade triggerOnce={true}>
                            <CardMedia
                                component="img"
                                image={direction === "rtl"
                                    ? baseImageUrl + event?.cover
                                    : baseImageUrl + (event?.en_cover ?? event?.cover)}
                                alt={direction === "rtl"
                                    ? event?.title
                                    : (event?.en_title ?? event?.title)}
                            />
                        </Fade>
                    </NavLink>
                )}
                <Box
                    sx={{ display: "flex", flexDirection: "column" }}
                    width="100%"
                >
                    <CardContent>
                        <Box display="flex" justifyContent="space-between">
                            <Box display="flex" p={1}>
                                <CalendarMonthIcon />
                                &nbsp;
                                {loading ? (
                                    <Skeleton variant="text" width="130px" />
                                ) : (
                                    <Fade triggerOnce={true}>
                                        <Typography component="span">
                                            {direction === "rtl" ? (
                                                event.date
                                            ) : (
                                                event.date
                                            )}
                                        </Typography>
                                    </Fade>
                                )}
                            </Box>
                            <Box display="flex" p={1}>
                                <ScheduleIcon />
                                &nbsp;
                                {loading ? (
                                    <Skeleton variant="text" width="45px" />
                                ) : (
                                    <Fade triggerOnce={true}>
                                        <Typography component="span">
                                            {direction === "rtl" ? (
                                                event.time
                                            ) : (
                                                event.time
                                            )}
                                        </Typography>
                                    </Fade>
                                )}
                            </Box>
                        </Box>
                        {loading ? (
                            <Skeleton variant="text" width="100%" />
                        ) : (
                            <NavLink to={`/event/show/${event?.id}`}>
                                <Fade triggerOnce={true}>
                                    <Typography
                                        component="div"
                                        variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                            color: mode === "light"
                                                ? colors.blueAccent[500]
                                                : colors.orangeAccent[500],
                                        }}
                                        p={1}
                                    >
                                        {direction === "rtl" ? (
                                            event.title
                                        ) : (
                                            event.en_title
                                        )}
                                    </Typography>
                                </Fade>
                            </NavLink>
                        )}
                        <Box display="flex" p={1}>
                            <LocationOnIcon />
                            &nbsp;
                            {loading ? (
                                <Skeleton variant="text" width="100%" />
                            ) : (
                                <Fade triggerOnce={true}>
                                    <Typography component="span">
                                        {direction === "rtl" ? (
                                            event.address
                                        ) : (
                                            event.en_address
                                        )}
                                    </Typography>
                                </Fade>
                            )}
                        </Box>
                    </CardContent>
                </Box>
            </Card>
        </Box>
    );
};

export default EventsPost;
