import React, { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import TagsContext from "../../context/TagsContext";
import AdminTagsMenu from "./AdminTagsMenu";
import {
    Box,
    Card,
    Skeleton,
    CardHeader,
    CardContent,
    Typography,
    CardActionArea,
} from "@mui/material";

const TagPost = ({ tag }) => {
    const { i18n } = useTranslation();
    const direction = i18n.dir();
    const { loading } = useContext(TagsContext);

    return (
        <Box className="my-animation">
            <Card>
                {loading ? (
                    <>
                        <CardHeader />
                        <CardContent>
                            <Skeleton variant="text" width="200px" height="20px" />
                        </CardContent>
                    </>
                ) : (
                    <>
                        <CardHeader
                            action={
                                loading ? null : (
                                    <AdminTagsMenu tag={tag} />
                                )
                            }
                        />
                        <CardActionArea>
                            <CardContent>
                                <Fade direction="up" triggerOnce={true}>
                                    <Box
                                        sx={{
                                            direction: direction === "rtl" && "rtl",
                                        }}>
                                        {tag ? (
                                            <Typography>{tag.tag_name}</Typography>
                                        ) : (
                                            <Skeleton variant="text" />
                                        )}
                                    </Box>
                                </Fade>
                            </CardContent>
                        </CardActionArea>
                    </>
                )}
            </Card>
        </Box>
    );
};

export default TagPost;
