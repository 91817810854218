import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const CountryContext = createContext(null);
CountryContext.displayName = "CountryContext";

export default CountryContext;

export const CountryContextProvider = ({ children }) => {
    const { getMethod } = useServices();
    const [allCountries, setAllCountries] = useState([]);
    const [allCountriesLoading, setAllCountriesLoading] = useState(true);
    const [allCountryStatus, setAllCountryStatus] = useState(0);

    async function getAllCountries() {
        setAllCountriesLoading(true);

        try {
            const response = await getMethod("/countries");
            const data = await response?.data;

            if (response?.status === 200) {
                setAllCountries(data);
                setAllCountryStatus(response.status);
            }
        } catch (ex) {

        }

        setAllCountriesLoading(false);
    }

    const contextData = {
        getAllCountries,
        allCountries, setAllCountries,
        allCountriesLoading, setAllCountriesLoading,
        allCountryStatus, setAllCountryStatus,
    };

    return (
        <CountryContext.Provider value={contextData}>
            {children}
        </CountryContext.Provider>
    );
}
