import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, CloudUpload, SaveOutlined } from "@mui/icons-material";

const EditEpisode = (props) => {
  const { t } = useTranslation();
  const {
    episode_name_required,
    episode_updated,
    record_deleted,
    episode_name_input,
    en_episode_name_input,
    form_control_label_play,
    course_video_change,
    course_video_input,
    course_video_save,
    course_video_registered,
    cancel,
    save
  } = t("lngEditEpisode");

  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog, openDialog } = useDialogContext(); // اضافه کردن openDialog
  const { putMethod, uploadPostMethod } = useServices();

  const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
  const [episodeName, setEpisodeName] = useState("");
  const [episodeNameError, setEpisodeNameError] = useState("");
  const [enEpisodeName, setEnEpisodeName] = useState("");
  const [enEpisodeNameError, setEnEpisodeNameError] = useState("");
  const [play, setPlay] = useState(false);
  const [loadingCourseVideoStore, setLoadingCourseVideoStore] = useState(true);
  const [courseVideo, setCourseVideo] = useState(null);
  const [courseVideoError, setCourseVideoError] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    fillRow();
    // eslint-disable-next-line
  }, [props?.params]);

  function fillRow() {
    setEpisodeName(props?.params.episode_name);
    setEpisodeNameError("");
    setEnEpisodeName(props?.params.en_episode_name);
    setEnEpisodeNameError("");
    setPlay(props?.params?.play === 0 ? false : true);
    setCourseVideo(null);
    setCourseVideoError("");

    // ریست کردن input فایل
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setTimeout(() => {
        fileInputRef.current.focus(); // بازگرداندن فوکوس به ورودی فایل
      }, 0);
    }
  }

  const handleEpisodeNameChange = (e) => {
    const currentValue = e.target.value;
    setEpisodeName(currentValue);
    if (currentValue?.length === 0) {
      setEpisodeNameError(episode_name_required);
    } else {
      setEpisodeNameError("");
    }
  };

  const handleEnEpisodeNameChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnEpisodeName(currentValue);
  };

  const handleCourseVideoChange = (e) => {
    const currentValue = e.target.files[0];
    setCourseVideo(currentValue);
  };

  const handleCourseVideoStore = async () => {
    setLoadingCourseVideoStore(false);

    try {
      const obj = {
        course_video: courseVideo,
        episode_id: props?.params?.id,
      };

      await uploadPostMethod("/episode/video", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        course_video_registered
      );
    } catch (ex) {
      if (ex && ex.status === 413) {
        setCourseVideoError(ex.message);
      }
    }

    setLoadingCourseVideoStore(true);
  };

  const handleUpdate = async () => {
    setLoadingUpdateButton(false);

    try {
      const obj = {
        episode_name: episodeName,
        en_episode_name: enEpisodeName,
        play: play === true ? 1 : 0,
      };

      await putMethod(
        "/episodes",
        props?.params.id,
        obj
      );

      props?.getEpisodesBySeasonId(props?.seasonId);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        episode_updated
      );

      handleCloseDialog();

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          record_deleted
        );
      }
      if (ex.response && ex.response.status === 422) {
        setEpisodeNameError(ex.response.data.errors.episode_name);
        setEnEpisodeNameError(ex.response.data.errors.en_episode_name);
      }
    }

    setLoadingUpdateButton(true);
  };

  const handleResetDialogItems = () => {
    handleCloseDialog();
    fillRow();
  };

  return (
    <>
      <DialogContent>
        <Card>
          <CardHeader title={course_video_change} />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  fullWidth
                  type="file"
                  label={course_video_input}
                  onChange={handleCourseVideoChange}
                  accept="video/mp4,video/x-m4v,video/*"
                  InputProps={{
                    inputRef: fileInputRef,  // افزودن ref برای فیلد فایل
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                    'aria-hidden': openDialog ? 'false' : 'true',  // مدیریت aria-hidden
                  }}
                  error={Boolean(courseVideoError)}
                  helperText={courseVideoError ? courseVideoError : "1920px × 1080px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <LoadingButton
                  fullWidth
                  variant="outlined"
                  loadingPosition="start"
                  loading={!loadingCourseVideoStore}
                  disabled={!courseVideo}
                  startIcon={<SaveOutlined />}
                  onClick={handleCourseVideoStore}
                  sx={{ height: "56px" }}
                >
                  {course_video_save}
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <UploadProgressBar />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Stack direction="column" spacing={2} marginTop={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={episodeName ?? ""}
            onChange={handleEpisodeNameChange}
            autoComplete="off"
            label={episode_name_input}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "justify" },
              maxLength: 100
            }}
            error={Boolean(episodeNameError)}
            helperText={episodeNameError}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enEpisodeName ?? ""}
            onChange={handleEnEpisodeNameChange}
            autoComplete="off"
            label={en_episode_name_input}
            inputProps={{
              style: { direction: "ltr", textAlign: "justify" },
              maxLength: 100
            }}
            error={Boolean(enEpisodeNameError)}
            helperText={enEpisodeNameError}
          />
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                checked={play}
                onChange={() => setPlay(!play)}
              />
            }
            label={form_control_label_play}
            labelPlacement="end"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingUpdateButton}
          disabled={!episodeName}
          startIcon={<SaveOutlined />}
          onClick={handleUpdate}
        >
          {save}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditEpisode;
