import { createContext, useContext, useState } from "react";

const DialogContext = createContext();
DialogContext.displayName = "DialogContext";

const DialogContextProvider = ({ children }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [maxWidthDialog, setMaxWidthDialog] = useState("sm");
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogActions, setDialogActions] = useState("");

    const handleOpenDialog = (maxWidth, title, actions) => {
        setMaxWidthDialog(maxWidth);
        setDialogTitle(title);
        setDialogActions(actions);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <DialogContext.Provider value={{
            maxWidthDialog,
            dialogTitle,
            dialogActions,
            openDialog,
            handleOpenDialog,
            handleCloseDialog
        }}>
            {children}
        </DialogContext.Provider>
    );
};

// hook
const useDialogContext = () => {
    const context = useContext(DialogContext);
    return context;
};

export { useDialogContext, DialogContextProvider };