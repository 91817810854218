import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const RoleContext = createContext(null);
RoleContext.displayName = "RoleContext";

export default RoleContext;

export const RoleContextProvider = ({ children }) => {
    const { getMethod, postMethodByPageId } = useServices();
    const [allRoles, setAllRoles] = useState([]);
    const [allRolesLoading, setAllRolesLoading] = useState(true);
    const [allRoleStatus, setAllRoleStatus] = useState(0);
    const [roles, setRoles] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getAllRoles() {
        setAllRolesLoading(true);

        try {
            const response = await getMethod("/roles");
            const data = await response?.data;

            if (response?.status === 200) {
                setAllRoles(data);
                setAllRoleStatus(response.status);
            }
        } catch (ex) {

        }

        setAllRolesLoading(false);
    }

    async function getRoles() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/getRoles", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setRoles(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getAllRoles,
        getRoles,
        allRoles, setAllRoles,
        allRolesLoading, setAllRolesLoading,
        allRoleStatus, setAllRoleStatus,
        roles, setRoles,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <RoleContext.Provider value={contextData}>
            {children}
        </RoleContext.Provider>
    );
}
