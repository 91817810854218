import { useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const CreatePrivacy = (props) => {
  const { t } = useTranslation();
  const {
    privacy_registered,
    title_required,
    en_title_required,
    law_required,
    en_law_required,
    title_input,
    en_title_input,
    law_input,
    en_law_input,
    cancel,
    save
  } = t("lngCreatePrivacy");
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { postMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enTitleError, setEnTitleError] = useState("");
  const [law, setLaw] = useState("");
  const [lawError, setLawError] = useState("");
  const [enLaw, setEnLaw] = useState("");
  const [enLawError, setEnLawError] = useState("");

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        title: title,
        en_title: enTitle,
        law: law,
        en_law: enLaw,
      };
      await postMethod("/privacy", obj);
      props?.getPrivacy();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "قانون با موفقیت ثبت شد"
        privacy_registered
      );
      resetDialogItems();
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setTitleError(ex.response.data.errors.title);
        setEnTitleError(ex.response.data.errors.en_title);
        setLawError(ex.response.data.errors.law);
        setEnLawError(ex.response.data.errors.en_law);
      }
    }

    setLoadingStoreButton(true);
  };

  const resetDialogItems = () => {
    handleCloseDialog();
    setTitle("");
    setTitleError("");
    setEnTitle("");
    setEnTitleError("");
    setLaw("");
    setLawError("");
    setEnLaw("");
    setEnLawError("");
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setTitleError(title_required);
    } else {
      setTitleError("");
    }
  };

  const handleEnTitleChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnTitle(currentValue);
    if (currentValue?.length === 0) {
      // setEnTitleError("تکمیل گزینه عنوان الزامی است");
      setEnTitleError(en_title_required);
    } else {
      setEnTitleError("");
    }
  };

  const handleLawChange = (e) => {
    const currentValue = e.target.value;
    setLaw(currentValue);
    if (currentValue?.length === 0) {
      // setLawError("تکمیل گزینه قانون الزامی است");
      setLawError(law_required);
    } else {
      setLawError("");
    }
  };

  const handleEnLawChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnLaw(currentValue);
    if (currentValue?.length === 0) {
      // setEnLawError("تکمیل گزینه قانون الزامی است");
      setEnLawError(en_law_required);
    } else {
      setEnLawError("");
    }
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={title ?? ""}
            onChange={handleTitleChange}
            autoComplete="off"
            // label="عنوان"
            label={title_input}
            multiline
            rows={2}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "justify" },
              maxLength: 200
            }}
            error={Boolean(titleError)}
            helperText={
              titleError ? titleError : `${title?.length ?? 0}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enTitle ?? ""}
            onChange={handleEnTitleChange}
            autoComplete="off"
            // label="عنوان"
            label={en_title_input}
            multiline
            rows={2}
            required
            inputProps={{
              style: { direction: "ltr", textAlign: "justify" },
              maxLength: 200
            }}
            error={Boolean(enTitleError)}
            helperText={
              enTitleError ? enTitleError : `${enTitle?.length ?? 0}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={law ?? ""}
            onChange={handleLawChange}
            autoComplete="off"
            // label="قانون"
            label={law_input}
            multiline
            rows={3}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "justify" },
              maxLength: 500
            }}
            error={Boolean(lawError)}
            helperText={lawError ? lawError : `${law?.length ?? 0}/500`}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enLaw ?? ""}
            onChange={handleEnLawChange}
            autoComplete="off"
            // label="قانون"
            label={en_law_input}
            multiline
            rows={3}
            required
            inputProps={{
              style: { direction: "ltr", textAlign: "justify" },
              maxLength: 500
            }}
            error={Boolean(enLawError)}
            helperText={enLawError ? enLawError : `${enLaw?.length ?? 0}/500`}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={resetDialogItems}
        >
          {/* انصراف */}
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={!title || !enTitle || !law || !enLaw}
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          {/* ذخیره */}
          {save}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreatePrivacy;
