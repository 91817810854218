import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import InstructorsContext from "../../context/InstructorsContext";
import UsersContext from "../../context/UsersContext";
import CoursesPost from "../courses/CoursesPost";
import InstructorInfo from "./InstructorInfo";
import {
    Box,
    CardMedia,
    Grid,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, minHeight: "385px" }}>
                    <Box sx={{ textAlign: "justify" }}>{children}</Box>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ShowInstructorInfo = () => {
    const { id: instructorId } = useParams();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        instructor_info_tab,
        biography_tab,
        instructor_courses_tab,
        ins_info_full_name
    } = t("lngShowInstructorInfo");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [value, setValue] = useState(0);
    const { baseImageUrl } = useContext(InstructorsContext);
    const { getUserById, userLoading, user, userCourses } = useContext(UsersContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getUserById(instructorId);
        // eslint-disable-next-line
    }, [instructorId]);

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Fade direction="up" triggerOnce={true}>
                    {userLoading ? (
                        <Skeleton
                            sx={{ height: 408 }}
                            animation="wave"
                            variant="rectangular"
                        />
                    ) : (
                        <CardMedia
                            component="img"
                            image={baseImageUrl + user?.cover}
                            alt={direction === "rtl" ? user?.full_name : user?.en_full_name ?? user?.full_name}
                        />
                    )}
                </Fade>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Box sx={{ margin: isMobile ? "20px 0 0 30px" : "0 0 0 30px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={instructor_info_tab} {...a11yProps(0)} />
                            <Tab label={biography_tab} {...a11yProps(1)} />
                            <Tab label={instructor_courses_tab} {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Stack direction="column" spacing={2}>
                            <InstructorInfo
                                title={ins_info_full_name}
                                value={
                                    direction === "rtl" ? (
                                        user?.full_name
                                    ) : (
                                        user?.en_full_name
                                    )
                                }
                            />
                        </Stack>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Fade triggerOnce={true}>
                            {user?.biography ? (
                                <Typography>
                                    {direction === "rtl" ? (
                                        user?.biography
                                    ) : (
                                        user?.en_biography
                                    )}
                                </Typography>
                            ) : (
                                <Skeleton variant="text" height={20} width="100%" />
                            )}
                        </Fade>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Grid container>
                            {userCourses?.map((course, index) => (
                                <Grid key={index} item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <CoursesPost course={course} />
                                </Grid>
                            ))}
                        </Grid>
                    </CustomTabPanel>
                </Box>
            </Grid>
        </Grid>
    )
}

export default ShowInstructorInfo
