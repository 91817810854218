import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, useTheme } from "@mui/material";
import ErrorPage from "../components/ErrorPage";
import { tokens } from "../theme";

const boxStyle = (theme) => ({
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%"
});

const Error404 = () => {
  const { t } = useTranslation();
  const {
    title,
    sub_title,
    return_back
  } = t("lngError404");
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="center">
          <Typography sx={{ fontSize: "100px", fontWeight: "700", color: colors.blueAccent[500] }}>
            404
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ErrorPage title={title} subtitle={sub_title} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => navigate(-1)}>
            {return_back}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Error404;
