import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import ShowUserInfo from "./ShowUserInfo";
import { Box } from "@mui/material";

const ShowUser = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngShowUser");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/admin-users" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ShowUserInfo />
      </Box>
    </>
  );
};

export default ShowUser;
