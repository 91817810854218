import { useState, useEffect, useRef, useContext } from "react";
import useServices from "../../utils/useServices";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "../../context/SnackbarContext";
import BankContext from "../../context/BankContext";
import { useDialogContext } from "../../context/DialogContext";
import {
    TextField,
    DialogContent,
    DialogActions,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const EditBankAccounts = (props) => {
    const { t } = useTranslation();
    const {
        bank_required,
        account_number_required,
        shaba_number_required,
        card_number_required,
        bank_account_updated,
        record_deleted,
        bank_input,
        account_number_input,
        shaba_number_input,
        card_number_input,
        cancel,
        save
    } = t("lngEditBankAccounts");
    const { putMethod } = useServices();
    const isMounted = useRef(false);
    const { handleOpenSnackbar } = useSnackbarContext();
    const { getBanks, banks } = useContext(BankContext);
    const { handleCloseDialog } = useDialogContext();
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [bankId, setBankId] = useState("");
    const [bankIdError, setBankIdError] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [shabaNumber, setShabaNumber] = useState("");
    const [shabaNumberError, setShabaNumberError] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [cardNumberError, setCardNumberError] = useState("");

    useEffect(() => {
        if (!isMounted.current) {
            getBanks();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fillRow();
        // eslint-disable-next-line
    }, [props?.params]);

    function fillRow() {
        setBankId(props?.params?.bank_id);
        setBankIdError("");
        setAccountNumber(props?.params?.account_number);
        setAccountNumberError("");
        setShabaNumber(props?.params?.shaba_number);
        setShabaNumberError("");
        setCardNumber(props?.params?.card_number);
        setCardNumberError("");
    }

    const handleResetDialogItems = () => {
        handleCloseDialog();
        fillRow();
    };

    const handleBankIdChange = (e) => {
        const currentValue = e.target.value;
        setBankId(currentValue);
        if (currentValue?.length === 0) {
            // setBankIdError("تکمیل گزینه بانک الزامی است");
            setBankIdError(bank_required);
        } else {
            setBankIdError("");
        }
    };

    const handleAccountNumberChange = (e) => {
        const currentValue = e.target.value;
        setAccountNumber(currentValue);
        if (currentValue?.length === 0) {
            // setAccountNumberError("تکمیل گزینه شماره حساب الزامی است");
            setAccountNumberError(account_number_required);
        } else {
            setAccountNumberError("");
        }
    };

    const handleShabaNumberChange = (e) => {
        const currentValue = e.target.value;
        setShabaNumber(currentValue);
        if (currentValue?.length === 0) {
            // setShabaNumberError("تکمیل گزینه شماره شبا الزامی است");
            setShabaNumberError(shaba_number_required);
        } else {
            setShabaNumberError("");
        }
    };

    const handleCardNumberChange = (e) => {
        const currentValue = e.target.value;
        setCardNumber(currentValue);
        if (currentValue?.length === 0) {
            // setCardNumberError("تکمیل گزینه شماره کارت الزامی است");
            setCardNumberError(card_number_required);
        } else {
            setCardNumberError("");
        }
    };

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                user_id: props?.instructorId,
                bank_id: bankId,
                account_number: accountNumber,
                shaba_number: shabaNumber,
                card_number: cardNumber,
            };

            await putMethod(
                "/user_bank_accounts",
                props?.params.id,
                obj
            );

            props?.getBankAccountsByUserId(props?.instructorId);
            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "حساب بانکی با موفقیت ویرایش شد"
                bank_account_updated
            );
            handleCloseDialog();
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "رکورد مورد نظر قبلاً حذف شده است"
                    record_deleted
                );
            }
            if (ex.response && ex.response.status === 422) {
                if (ex.response.data.errors.account_number &&
                    ex.response.data.errors.account_number.length > 1) {
                    setAccountNumberError(ex.response.data.errors.account_number[0]);
                } else {
                    setAccountNumberError(ex.response.data.errors.account_number);
                }
                if (ex.response.data.errors.shaba_number &&
                    ex.response.data.errors.shaba_number.length > 1) {
                    setShabaNumberError(ex.response.data.errors.shaba_number[0]);
                } else {
                    setShabaNumberError(ex.response.data.errors.shaba_number);
                }
                if (ex.response.data.errors.card_number &&
                    ex.response.data.errors.card_number.length > 1) {
                    setCardNumberError(ex.response.data.errors.card_number[0]);
                } else {
                    setCardNumberError(ex.response.data.errors.card_number);
                }
            }
        }

        setLoadingUpdateButton(true);
    };

    return (
        <>
            <DialogContent>
                <Stack direction="column" spacing={2} marginTop={2}>
                    <FormControl required fullWidth error={Boolean(bankIdError)}>
                        <InputLabel id="edit-bank-name-label">
                            {/* بانک */}
                            {bank_input}
                        </InputLabel>
                        <Select
                            labelId="edit-bank-name-label"
                            value={bankId ?? ""}
                            label={bank_input}
                            onChange={handleBankIdChange}
                        >
                            {banks?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.bank_name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{bankIdError}</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={accountNumber ?? ""}
                        onChange={handleAccountNumberChange}
                        autoComplete="off"
                        label={account_number_input}
                        required
                        inputProps={{ style: { textAlign: "center" }, maxLength: 12 }}
                        error={Boolean(accountNumberError)}
                        helperText={accountNumberError}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={shabaNumber ?? ""}
                        onChange={handleShabaNumberChange}
                        autoComplete="off"
                        label={shaba_number_input}
                        required
                        inputProps={{
                            style: { textAlign: "center" },
                            maxLength: 26,
                        }}
                        error={Boolean(shabaNumberError)}
                        helperText={shabaNumberError}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={cardNumber ?? ""}
                        onChange={handleCardNumberChange}
                        autoComplete="off"
                        label={card_number_input}
                        required
                        inputProps={{ style: { textAlign: "center" }, maxLength: 16 }}
                        error={Boolean(cardNumberError)}
                        helperText={cardNumberError}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleResetDialogItems}
                >
                    {/* انصراف */}
                    {cancel}
                </Button>
                <LoadingButton
                    variant="outlined"
                    loadingPosition="start"
                    size="large"
                    loading={!loadingUpdateButton}
                    disabled={
                        !bankId ||
                        !accountNumber ||
                        !shabaNumber ||
                        !cardNumber
                    }
                    startIcon={<SaveOutlined />}
                    onClick={handleUpdate}
                >
                    {/* ذخیره */}
                    {save}
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default EditBankAccounts;
