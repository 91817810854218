import { forwardRef } from "react";
import { useDialogContext } from "../context/DialogContext";
import { Dialog, DialogTitle, Slide } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = () => {
  const {
    maxWidthDialog,
    dialogTitle,
    dialogActions,
    openDialog,
  } = useDialogContext();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidthDialog}
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {dialogTitle}
      </DialogTitle>
      {dialogActions}
    </Dialog>
  );
};

export default DialogComponent;
