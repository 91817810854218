import { useContext, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "../../context/SnackbarContext";
import SettingsContext from "../../context/SettingsContext";
import { Box, Skeleton, useTheme } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { tokens } from "../../theme";
import Courses from './../courses/index';
import Events from "../events";
import Instructors from "../instructors";

const Home = () => {
    const { t } = useTranslation();
    const {
        video_is_not_available
    } = t("lngHomeIndex");
    const isMounted = useRef(false);
    const { handleOpenSnackbar } = useSnackbarContext();
    const {
        baseImageUrl,
        baseVideoUrl,
        getSettings,
        settings,
        settingsLoading
    } = useContext(SettingsContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (!isMounted.current) {
            getSettings();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    const handleVideoReady = () => {
        // console.log("onReady callback");
    }

    const handleVideoStart = () => {
        // console.log("onStart callback");
    }

    const handleVideoPause = () => {
        // console.log("onPause callback");
    }

    const handleVideoEnded = () => {
        // console.log("onEnded callback");
    }

    const handleVideoError = () => {
        // console.log("onError callback");
        handleOpenSnackbar(
            "bottom",
            "left",
            "info",
            "در حال حاضر ویدیو در دسترس نمی باشد",
            { video_is_not_available }
        );
    }

    return (
        <>
            <Box mb="50px" mt="50px" className="container top_to_bottom_animation">
                <Box mb="50px" mt="50px" className="container">
                    {settingsLoading ? (
                        <Box className="video-wrap">
                            <Box className="video-content">
                                <Skeleton
                                    animation="wave"
                                    variant="rectangular"
                                >
                                    <ReactPlayer />
                                </Skeleton>
                            </Box>
                        </Box>
                    ) : (
                        <Box className="video-wrap">
                            <Box className="video-content">
                                <ReactPlayer
                                    controls
                                    url={baseVideoUrl + settings.video_url || null}
                                    playing
                                    playIcon={
                                        <button
                                            className="video-btn popup-youtube"
                                        >
                                            <PlayArrowIcon
                                                sx={{ fontSize: "40px", color: colors.white }}
                                            />
                                        </button>
                                    }
                                    light={baseImageUrl + settings.video_cover || null}
                                    onReady={() => handleVideoReady()}
                                    onStart={() => handleVideoStart()}
                                    onPause={() => handleVideoPause()}
                                    onEnded={() => handleVideoEnded()}
                                    onError={() => handleVideoError()}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
            <Courses />
            <Events />
            <Instructors />
        </>
    );
};

export default Home;
