import { createContext, useContext, useState } from "react";
import useServices from "../utils/useServices";
import AuthContext from "./AuthContext";

const SettingsContext = createContext();
SettingsContext.displayName = "SettingsContext";

export default SettingsContext;

export const SettingsContextProvider = (props) => {
    const { getMethod } = useServices();
    const { user } = useContext(AuthContext);
    const baseImageUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/settings/`;
    const baseVideoUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/settings/`;
    // const baseImageUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/settings/`;
    // const baseVideoUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/settings/`;
    const [settings, setSettings] = useState([]);
    const [settingsLoading, setSettingsLoading] = useState(true);
    const [inboxCount, setInboxCount] = useState(0);
    const [usersCountForStatistics, setUsersCountForStatistics] = useState(0);
    const [usersCountForBadge, setUsersCountForBadge] = useState(0);
    const [contactCount, setContactCount] = useState(0);
    const [transactionsCount, setTransactionsCount] = useState(0);
    const [instructorsInvoicesCount, setInstructorsInvoicesCount] = useState(0);
    const [coursesCount, setCoursesCount] = useState(0);
    const [instructorsCount, setInstructorsCount] = useState(0);
    const [eventsCount, setEventsCount] = useState(0);

    async function getSettings() {
        setSettingsLoading(true);

        try {
            const response = await getMethod("/settings");
            const data = await response?.data;

            if (response?.status === 200) {
                setSettings(data);
            }
        } catch (ex) {

        }

        setSettingsLoading(false);
    }

    async function getCounts() {
        if (user !== null) {
            try {
                const response = await getMethod("/statistics_counts");
                const data = await response?.data;

                if (response?.status === 200) {
                    setInboxCount(data.inbox_count);
                    setUsersCountForStatistics(data.users_count_for_statistics);
                    setUsersCountForBadge(data.users_count_for_badge);
                    setContactCount(data.contact_count);
                    setTransactionsCount(data.transactions_count);
                    setInstructorsInvoicesCount(data.instructors_invoices_count);
                    setCoursesCount(data.courses_count);
                    setInstructorsCount(data.instructors_count);
                    setEventsCount(data.events_count);
                }
            } catch (ex) {

            }
        }
    }

    return (
        <>
            <SettingsContext.Provider
                value={{
                    getCounts,
                    getSettings,
                    baseImageUrl,
                    baseVideoUrl,
                    settings,
                    settingsLoading, setSettingsLoading,
                    inboxCount, setInboxCount,
                    usersCountForStatistics, setUsersCountForStatistics,
                    usersCountForBadge, setUsersCountForBadge,
                    contactCount, setContactCount,
                    transactionsCount, setTransactionsCount,
                    instructorsInvoicesCount, setInstructorsInvoicesCount,
                    coursesCount, setCoursesCount,
                    instructorsCount, setInstructorsCount,
                    eventsCount, setEventsCount
                }}
            >
                {props.children}
            </SettingsContext.Provider>
        </>
    );
}
