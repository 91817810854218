import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import Header from "../../components/Header";
import { useSnackbarContext } from "../../context/SnackbarContext";
import CoursesContext from "../../context/CoursesContext";
import CartContext from "../../context/CartContext";
import EpisodeContext from "../../context/EpisodeContext";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Lock, LockOpen, PlayArrowOutlined } from '@mui/icons-material';
import VideoModal from "./VideoModal";

const useStyles = makeStyles((theme) => ({
  tabMenu: {
    backgroundColor: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).grey[900]} !important`
      : `${tokens(theme.palette.mode).grey[300]} !important`,
    borderRadius: "4px"
  },
  tabLabel: {
    fontSize: "16px !important",
    color: "black !important",
  },
  tabTitles: {
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).black} !important`
      : `${tokens(theme.palette.mode).white} !important`,
    fontWeight: "700 !important",
    paddingBottom: "10px",
    borderBottom: "1px solid #eee",
    marginBottom: "20px !important"
  },
  title: {
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).black} !important`
      : `${tokens(theme.palette.mode).white} !important`,
    fontWeight: "700 !important",
  },
  text: {
    textAlign: "justify"
  },
  card: {
    padding: "30px"
  },
  season: {
    backgroundColor: "#f5f6fa",
  },
  seasonSpace: {
    display: "flex",
    justifyContent: "space-between"
  },
  subSeason: {
    color: `${tokens(theme.palette.mode).black} !important`,
  },
  playIcon: {
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).blueAccent[500]}`
      : `${tokens(theme.palette.mode).orangeAccent[500]}`,
    marginRight: "6px"
  },
  downloadButton: {
    backgroundColor: theme.palette.mode === 'light'
      ? `${tokens(theme.palette.mode).blueAccent[500]} !important`
      : `${tokens(theme.palette.mode).orangeAccent[500]} !important`,
    color: `${tokens(theme.palette.mode).white} !important`,
  }
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight: "385px" }}>
          <Box sx={{ textAlign: "justify" }}>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ShowCourses = () => {
  const { id: courseId } = useParams();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    video_is_not_available
  } = t("lngHomeIndex");
  const { handleOpenSnackbar } = useSnackbarContext();
  const {
    title,
    subtitle,
    course_description,
    course_seassons,
    play_button,
    typography_course,
    typography_category,
    typography_instructor,
    typography_price,
    price_unit,
    typography_discount,
    typography_percent,
    typography_total,
    add_to_cart_button
  } = t("lngShowCourses");
  const {
    baseImageUrl,
    getCourseById,
    courseByIdloading,
    course
  } = useContext(CoursesContext);
  const { baseVideoUrl } = useContext(EpisodeContext);
  const { addToCart } = useContext(CartContext);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const isMounted = useRef(false);
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleOpenModal = (season, episode) => {
    if (episode?.play === 1 && episode?.course_video !== null) {
      const videoPath = `${baseVideoUrl}instructor_${course?.user?.id}/course_${courseId}/season_${season?.id}/${episode?.course_video}`;
      setVideoUrl(videoPath);
      setOpen(true);
    } else {
      setVideoUrl("");
      handleOpenSnackbar(
        "bottom",
        "left",
        "info",
        "در حال حاضر ویدیو در دسترس نمی باشد",
        { video_is_not_available }
      );
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setVideoUrl("");
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!isMounted.current) {
      getCourseById(courseId);
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [courseId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/courses" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              {courseByIdloading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="100%"
                    sx={{ height: 50 }}
                  />
                  <Skeleton
                    sx={{ height: 500 }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    variant="text"
                    width="100%"
                    sx={{ height: 50 }}
                  />
                  <Skeleton
                    sx={{ height: 200 }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ) : (
                <Fade direction="up" triggerOnce={true}>
                  <Grid container spacing={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h2" className={classes.course_name}>
                        {direction === "rtl" ? (
                          course?.course_name
                        ) : (
                          course?.en_course_name
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={direction === "rtl"
                            ? baseImageUrl + course?.cover
                            : baseImageUrl + (course?.en_cover ?? course?.cover)}
                          title={direction === "rtl"
                            ? course?.course_name
                            : (course?.en_course_name ?? course?.course_name)}
                          loading="lazy"
                          style={{ cursor: "pointer" }}
                          alt={direction === "rtl"
                            ? course?.course_name
                            : (course?.en_course_name ?? course?.course_name)}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box className={classes.tabMenu}>
                        <Tabs value={value} onChange={handleTabChange} centered>
                          <Tab
                            className={classes.tabLabel}
                            // label="شرح دوره"
                            label={course_description}
                            {...a11yProps(0)}
                          />
                          <Tab
                            className={classes.tabLabel}
                            // label="فصل‌های دوره"
                            label={course_seassons}
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={value} index={0}>
                        <Fade direction="up" triggerOnce={true}>
                          <Typography variant="h3" className={classes.tabTitles}>
                            {/* شرح دوره */}
                            {course_description}
                          </Typography>
                          <Box className={classes.text}>
                            {direction === "rtl" ? (
                              course?.description
                            ) : (
                              course?.en_description
                            )}
                          </Box>
                        </Fade>
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1}>
                        <Fade triggerOnce={true}>
                          <Typography variant="h3" className={classes.tabTitles}>
                            {/* فصل‌های دوره */}
                            {course_seassons}
                          </Typography>
                          <Box className={classes.text}>
                            <table className="table">
                              <tbody>
                                {course?.seasons?.map((season, index1) => (
                                  <React.Fragment key={index1}>
                                    <tr className={classes.season}>
                                      <th colSpan={2}>
                                        {index1 + 1}.&nbsp;
                                        {direction === "rtl" ? (
                                          season?.season_name
                                        ) : (
                                          season?.en_season_name
                                        )}
                                      </th>
                                    </tr>
                                    {season?.episodes?.map((episode, index2) => (
                                      <tr key={index2}>
                                        <td className={classes.subSeason}>
                                          <Box>
                                            <PlayArrowOutlined className={classes.playIcon} />
                                            {direction === "rtl" ? (
                                              episode?.episode_name
                                            ) : (
                                              episode?.en_episode_name
                                            )}
                                          </Box>
                                        </td>
                                        <td>
                                          <Box display="flex" sx={{ float: "right" }}>
                                            <Button
                                              startIcon={episode?.play ? <LockOpen fontSize="small" /> : <Lock fontSize="small" />}
                                              variant="contained"
                                              size="small"
                                              className={classes.downloadButton}
                                              disabled={!episode?.play}
                                              onClick={() => handleOpenModal(season, episode)}
                                            >
                                              {/* پخش ویدیو */}
                                              {play_button}
                                            </Button>
                                          </Box>
                                        </td>
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </Box>
                        </Fade>
                      </CustomTabPanel>
                      <VideoModal
                        open={open}
                        handleCloseModal={handleCloseModal}
                        videoUrl={videoUrl}
                      />
                    </Grid>
                  </Grid>
                </Fade>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Card className={classes.card}>
                <CardActions>
                  {courseByIdloading ? (
                    <Stack direction="column" spacing={2}>
                      <Skeleton variant="text" width="300px" />
                      <Skeleton variant="text" width="180px" />
                      <Skeleton variant="text" width="230px" />
                      <Skeleton variant="text" width="150px" />
                      <Skeleton variant="text" width="100px" />
                      <Skeleton variant="text" width="150px" />
                    </Stack>
                  ) : (
                    <Fade triggerOnce={true}>
                      <Grid container spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Fade direction="down" triggerOnce={true}>
                            <Typography component="span">
                              {/* دوره */}
                              {typography_course}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {direction === "rtl" ? (
                                course?.course_name
                              ) : (
                                course?.en_course_name
                              )}
                            </Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Fade direction="down" triggerOnce={true}>
                            <Typography component="span">
                              {/* دسته‌بندی */}
                              {typography_category}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {direction === "rtl" ? (
                                course?.category?.category_name
                              ) : (
                                course?.category?.en_category_name
                              )}
                            </Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Fade direction="down" triggerOnce={true}>
                            <Typography component="span">
                              {/* استاد */}
                              {typography_instructor}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {direction === "rtl" ? (
                                course?.user?.full_name
                              ) : (
                                course?.user?.en_full_name ?? course?.user?.full_name
                              )}
                            </Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Fade direction="down" triggerOnce={true}>
                            <Typography component="span">
                              {/* قیمت */}
                              {typography_price}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Fade direction="up" triggerOnce={true}>
                            {direction === "rtl" ? (
                              <Typography component="span">{course?.price} {price_unit}</Typography>
                            ) : (
                              <Typography component="span">{price_unit} {course?.en_price}</Typography>
                            )}
                          </Fade>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Fade direction="down" triggerOnce={true}>
                            <Typography component="span">
                              {/* تخفیف */}
                              {typography_discount}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">{course?.discount} {typography_percent}</Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Fade direction="down" triggerOnce={true}>
                            <Typography component="span">
                              {/* قیمت نهایی */}
                              {typography_total}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Fade direction="up" triggerOnce={true}>
                            {direction === "rtl" ? (
                              <Typography component="span">{course?.total} {price_unit}</Typography>
                            ) : (
                              <Typography component="span">{price_unit} {course?.en_total}</Typography>
                            )}
                          </Fade>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Fade direction="up" triggerOnce={true}>
                            <Button variant="contained" fullWidth
                              onClick={() => addToCart(course)}>
                              {/* افزودن به سبد خرید */}
                              {add_to_cart_button}
                            </Button>
                          </Fade>
                        </Grid>
                      </Grid>
                    </Fade>
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Fade>
      </Box >
    </>
  );
};

export default ShowCourses;
