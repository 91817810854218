import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbarContext } from "./SnackbarContext";

const CartContext = createContext(null);
CartContext.displayName = "CartContext";

export default CartContext;

export const CartContextProvider = (props) => {
    const { t } = useTranslation();
    const { cart_is_full, exist_item_in_cart } = t("lngCartContext");
    const { handleOpenSnackbar } = useSnackbarContext();
    const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem("cart") || "[]")); // "[]" !important

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cartItems));
        // eslint-disable-next-line
    }, [cartItems]);

    const addToCart = (product) => {
        if (cartItems?.length > 4) {
            handleOpenSnackbar(
                "bottom",
                "left",
                "info",
                // "سبد خرید پر است، بقیه خریدهای خود را در تراکنش بعدی انجام دهید"
                cart_is_full
            );
        } else {
            if (!cartItems?.find((item) => item.id === product.id))
                setCartItems([...cartItems, product]);
            else {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "info",
                    // "آیتم در سبد خرید موجود است"
                    exist_item_in_cart
                );
            }
        }
    };

    const removeFromCart = (itemId) => {
        const data = Object.values(cartItems).filter((p) => p.id !== itemId);
        setCartItems(data);
    };

    return (
        <>
            <CartContext.Provider
                value={{
                    cartItems,
                    addToCart,
                    removeFromCart
                }}
            >
                {props.children}
            </CartContext.Provider>
        </>
    );
}
