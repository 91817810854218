import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import TransactionsContext from "../../context/TransactionsContext";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Check, BookmarkAdded, CreditScore, Backpack, Send } from '@mui/icons-material';
import {
    Card,
    CardContent,
    CardHeader,
    Skeleton,
    Stack,
    Step,
    StepLabel,
    Stepper,
    StepConnector,
    stepConnectorClasses
} from "@mui/material";

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#0a66c2',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#0a66c2',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(95deg,rgb(10,102,194) 0%,rgb(229,86,4) 50%,rgb(10,102,194) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(95deg,rgb(10,102,194) 0%,rgb(229,86,4) 50%,rgb(10,102,194) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient(136deg, rgb(10,102,194) 0%, rgb(10,102,194) 50%, rgb(10,102,194) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient(136deg, rgb(10,102,194) 0%, rgb(10,102,194) 50%, rgb(10,102,194) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <BookmarkAdded />,
        2: <CreditScore />,
        3: <Backpack />,
        4: <Send />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const steps = ['ثبت سفارش', 'پرداخت هزینه', 'بسته‌بندی', 'ارسال'];
const en_steps = ['Order Registration', 'Payment', 'Packaging', 'Sending'];

const OrderStatusPost = ({ userTransaction }) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        invoice_number
    } = t("lngOrderStatusPost");
    const { loading } = useContext(TransactionsContext);
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        if (userTransaction?.sending === 1) {
            setActiveStep(3);
        } else if (userTransaction?.packing === 1) {
            setActiveStep(2);
        } else {
            setActiveStep(1);
        }
        // eslint-disable-next-line
    }, [userTransaction]);

    return (
        <Fade triggerOnce={true}>
            <Card sx={{ marginBottom: "30px" }}>
                <CardHeader
                    title={
                        loading ? (
                            <Skeleton variant="text" height={15} width="30%" />
                        ) : (
                            <Fade triggerOnce={true}>
                                <span>{userTransaction?.created_at}</span>
                            </Fade>
                        )
                    }
                    subheader={
                        loading ? (
                            <Skeleton variant="text" height={10} width="20%" />
                        ) : (
                            <Fade triggerOnce={true}>
                                {/* <span>شماره فاکتور: {userTransaction?.order_id}</span> */}
                                <span>{invoice_number} {userTransaction?.order_id}</span>
                            </Fade>
                        )
                    }
                />
                <CardContent>
                    {loading ? (
                        <Skeleton
                            sx={{ height: 150 }}
                            animation="wave"
                            variant="rectangular"
                        />
                    ) : (
                        <Fade triggerOnce={true}>
                            <Stack sx={{ width: '100%' }} spacing={4}>
                                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                    {direction === "rtl" ? (
                                        steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </Step>
                                        ))
                                    ) : (
                                        en_steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                            </Step>
                                        ))
                                    )}
                                </Stepper>
                            </Stack>
                        </Fade>
                    )}
                </CardContent>
            </Card>
        </Fade>
    )
}

export default OrderStatusPost
