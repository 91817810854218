import React, { useContext, useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import SettingsContext from "../../context/SettingsContext";
import {
  Typography,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import logo from "../../images/Master-It.png";
import { separateNumbers } from "../../components/PriceFormat";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 20px 30px",
    marginBottom: "25px"
  },
  content: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 30px 20px",
    marginBottom: "25px"
  },
  headleRightSidebar: (props) => ({
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: props.direction === "rtl" ? "flex-end" : "flex-start",
      fontWeight: "700 !important",
    },
    fontWeight: "700 !important",
  }),
}));

const ShowInstructorsInvoices = ({ transaction }) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    typography_course,
    typography_instructor,
    typography_price_with_unit,
    typography_discount_percentage,
    typography_discount,
    typography_membership_percentage,
    typography_membership,
    typography_front_page_percentage,
    typography_front_page,
    typography_total_with_unit,
    buyer_value,
    invoice_number_value,
    date_value,
    typography_total_courses,
    price_unit
  } = t("lngShowInstructorsInvoices1");
  const { getSettings, settings } = useContext(SettingsContext);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [coursesTotal, setCoursesTotal] = useState(0);
  const [coursesEnTotal, setCoursesEnTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [enTotal, setEnTotal] = useState(0);
  const classes = useStyles();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getSettings();
      isMounted.current = true;
    }
    calcTotalCourses();
    // eslint-disable-next-line
  }, [transaction]);

  function calcTotalCourses() {
    var coursesTotal = 0;
    var sumTotal = 0;
    var coursesEnTotal = 0;
    var enSumTotal = 0;
    transaction?.courses?.forEach((item) => {
      var percentages = 0;
      coursesTotal = coursesTotal + parseInt(item?.pivot?.price);
      percentages = parseInt(item?.pivot?.discount) + parseInt(item?.pivot?.membership_percentage) + parseInt(item?.pivot?.front_page_percentage);
      sumTotal = sumTotal + (parseInt(item?.pivot?.price) - (parseInt(item?.pivot?.price * percentages) / 100));

      coursesEnTotal = coursesEnTotal + parseInt(item?.pivot?.en_price);
      enSumTotal = enSumTotal + (parseInt(item?.pivot?.en_price) - (parseInt(item?.pivot?.en_price * percentages) / 100));
    });
    setCoursesTotal(coursesTotal);
    setTotal(sumTotal);
    setCoursesEnTotal(coursesEnTotal);
    setEnTotal(enSumTotal);
  }

  function invoiceItems(courses) {
    return courses?.map(function (course, index) {
      return (
        <React.Fragment key={index}>
          <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* دوره */}
                {typography_course}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* دوره */}
                  {typography_course}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={direction === "rtl"
                ? course?.course_name ?? ""
                : (course?.en_course_name ?? course?.course_name ?? "")}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2.5} lg={2.5} xl={2.5}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* استاد */}
                {typography_instructor}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* استاد */}
                  {typography_instructor}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={direction === "rtl"
                ? course?.user?.full_name ?? ""
                : (course?.user?.en_full_name ?? course?.user?.full_name ?? "")}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* قیمت (تومان) */}
                {typography_price_with_unit}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* قیمت (تومان) */}
                  {typography_price_with_unit}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={direction === "rtl"
                ? separateNumbers(course?.pivot?.price) ?? ""
                : (course?.pivot?.en_price ?? separateNumbers(course?.pivot?.price) ?? "")}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* درصد تخفیف */}
                {typography_discount_percentage}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* تخفیف */}
                  {typography_discount}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.pivot?.discount + " %" ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* درصد عضویت */}
                {typography_membership_percentage}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* عضویت */}
                  {typography_membership}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.pivot?.membership_percentage + " %" ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* درصد صفحه اول */}
                {typography_front_page_percentage}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* صفحه اول */}
                  {typography_front_page}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={course?.pivot?.front_page_percentage + " %" ?? ""}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            {isMobile ? (
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* جمع دوره (تومان) */}
                {typography_total_with_unit}
              </Typography>
            ) : (
              index === 0 && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* جمع دوره (تومان) */}
                  {typography_total_with_unit}
                </Typography>
              )
            )}
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={direction === "rtl"
                ? separateNumbers(parseInt(course?.pivot?.price) - (parseInt(course?.pivot?.price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage))) / 100)) ?? ""
                : (parseInt(course?.pivot?.en_price) - (parseInt(course?.pivot?.en_price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage)) / 100)) ?? separateNumbers(parseInt(course?.pivot?.price) - (parseInt(course?.pivot?.price * (parseInt(course?.pivot?.discount) + parseInt(course?.pivot?.membership_percentage) + parseInt(course?.pivot?.front_page_percentage))) / 100)) ?? "")}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
        </React.Fragment>
      )
    })
  }

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Box>
          {isMobile ? (
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "150px",
                    display: "block",
                    margin: "0 auto 10px auto",
                  }}
                  alt="Master it"
                  src={logo}
                />
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0 0 30px 0",
                    color: colors.orangeAccent[500]
                  }}>
                  {direction === "rtl" ? (
                    settings?.slogan
                  ) : (
                    settings?.en_slogan
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="div"
                  className={classes.headleRightSidebar}>
                  {direction === "rtl" ? (
                    <>
                      {/* خریدار: {transaction?.user?.full_name ?? ""} */}
                      {buyer_value} {transaction?.user?.full_name ?? ""}
                    </>
                  ) : (
                    <>
                      {/* خریدار: {transaction?.user?.en_full_name ?? ""} */}
                      {buyer_value} {transaction?.user?.en_full_name ?? ""}
                    </>
                  )}
                </Box>
                <Box
                  component="div"
                  className={classes.headleRightSidebar}>
                  {/* شماره فاکتور: {transaction?.order_id ?? ""} */}
                  {invoice_number_value} {transaction?.order_id ?? ""}
                </Box>
                <Box
                  component="div"
                  className={classes.headleRightSidebar}>
                  {/* تاریخ: {transaction?.created_at ?? ""} */}
                  {date_value} {transaction?.created_at ?? ""}
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="div"
                  className={classes.headleRightSidebar}>
                  {direction === "rtl" ? (
                    <>
                      {/* خریدار: {transaction?.user?.full_name ?? ""} */}
                      {buyer_value} {transaction?.user?.full_name ?? ""}
                    </>
                  ) : (
                    <>
                      {/* خریدار: {transaction?.user?.en_full_name ?? ""} */}
                      {buyer_value} {transaction?.user?.en_full_name ?? ""}
                    </>
                  )}
                </Box>
                <Box
                  component="div"
                  className={classes.headleRightSidebar}>
                  {/* شماره فاکتور: {transaction?.order_id ?? ""} */}
                  {invoice_number_value} {transaction?.order_id ?? ""}
                </Box>
                <Box
                  component="div"
                  className={classes.headleRightSidebar}>
                  {/* تاریخ: {transaction?.created_at ?? ""} */}
                  {date_value} {transaction?.created_at ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: "150px",
                    display: "block",
                    margin: "0 auto 10px auto",
                  }}
                  alt="Master it"
                  src={logo}
                />
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                    fontSize: "16px",
                    margin: "0 0 30px 0",
                    color: colors.orangeAccent[500]
                  }}>
                  {direction === "rtl" ? (
                    settings?.slogan
                  ) : (
                    settings?.en_slogan
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              </Grid>
            </Grid>
          )}
          <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
            {invoiceItems(transaction?.courses)}
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isMobile && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* جمع کل دوره‌ها (تومان) */}
                  {typography_total_courses}
                </Typography>
              )}
              <TextField
                label={!isMobile ? typography_total_courses : ""}
                fullWidth
                variant="outlined"
                type="text"
                value={direction === "rtl"
                  ? separateNumbers(coursesTotal) + " " + price_unit ?? ""
                  : (price_unit + " " + coursesEnTotal ?? separateNumbers(coursesTotal) + " " + price_unit ?? "")}
                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isMobile && (
                <Typography
                  component="span"
                  variant="body1"
                  color="primary"
                  sx={{ fontWeight: "700" }}
                >
                  {/* جمع نهایی (تومان) */}
                  {typography_total_with_unit}
                </Typography>
              )}
              <TextField
                label={!isMobile ? typography_total_with_unit : ""}
                fullWidth
                variant="outlined"
                type="text"
                value={direction === "rtl"
                  ? separateNumbers(total) + " " + price_unit ?? ""
                  : (price_unit + " " + enTotal ?? separateNumbers(total) + " " + price_unit ?? "")}
                inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
              />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Box>
  );
};

export default ShowInstructorsInvoices;
