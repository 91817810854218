import * as React from 'react';
import ProgressBarContext from '../context/ProgressBarContext';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearDeterminate() {
  const { pageLoading } = React.useContext(ProgressBarContext);

  return (
    pageLoading !== 0 && (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="primary" variant="determinate" value={pageLoading} />
      </Box>
    )
  );
}