import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from '../../utils/useServices';
import UserBankAccountsContext from "../../context/UserBankAccountsContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import BankContext from "../../context/BankContext";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  CardActions,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined } from "@mui/icons-material";

const CreateBankAccounts = () => {
  const { id: instructorId } = useParams();
  const { t } = useTranslation();
  const {
    bank_account_registered,
    bank_required,
    account_number_required,
    shaba_number_required,
    card_number_required,
    add_bank_account,
    bank_input,
    account_number_input,
    shaba_number_input,
    card_number_input,
    save
  } = t("lngCreateBankAccounts");
  const isMounted = useRef(false);
  const { postMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { getBanks, banks } = useContext(BankContext);
  const { getBankAccountsByUserId } = useContext(UserBankAccountsContext);
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [bankId, setBankId] = useState("");
  const [bankIdError, setBankIdError] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberError, setAccountNumberError] = useState("");
  const [shabaNumber, setShabaNumber] = useState("");
  const [shabaNumberError, setShabaNumberError] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");

  useEffect(() => {
    if (!isMounted.current) {
      getBanks();
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, []);

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        user_id: instructorId,
        bank_id: bankId,
        account_number: accountNumber,
        shaba_number: shabaNumber,
        card_number: cardNumber,
      };

      await postMethod("/user_bank_accounts", obj);

      getBankAccountsByUserId(instructorId);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "حساب بانکی با موفقیت ثبت شد"
        bank_account_registered
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        if (ex.response.data.errors.account_number &&
          ex.response.data.errors.account_number.length > 1) {
          setAccountNumberError(ex.response.data.errors.account_number[0]);
        } else {
          setAccountNumberError(ex.response.data.errors.account_number);
        }
        if (ex.response.data.errors.shaba_number &&
          ex.response.data.errors.shaba_number.length > 1) {
          setShabaNumberError(ex.response.data.errors.shaba_number[0]);
        } else {
          setShabaNumberError(ex.response.data.errors.shaba_number);
        }
        if (ex.response.data.errors.card_number &&
          ex.response.data.errors.card_number.length > 1) {
          setCardNumberError(ex.response.data.errors.card_number[0]);
        } else {
          setCardNumberError(ex.response.data.errors.card_number);
        }
      }
    }

    setLoadingStoreButton(true);
  };

  const handleBankIdChange = (e) => {
    const currentValue = e.target.value;
    setBankId(currentValue);
    if (currentValue?.length === 0) {
      // setBankIdError("تکمیل گزینه بانک الزامی است");
      setBankIdError(bank_required);
    } else {
      setBankIdError("");
    }
  };

  const handleAccountNumberChange = (e) => {
    const currentValue = e.target.value;
    setAccountNumber(currentValue);
    if (currentValue?.length === 0) {
      // setAccountNumberError("تکمیل گزینه شماره حساب الزامی است");
      setAccountNumberError(account_number_required);
    } else {
      setAccountNumberError("");
    }
  };

  const handleShabaNumberChange = (e) => {
    const currentValue = e.target.value;
    setShabaNumber(currentValue);
    if (currentValue?.length === 0) {
      // setShabaNumberError("تکمیل گزینه شماره شبا الزامی است");
      setShabaNumberError(shaba_number_required);
    } else {
      setShabaNumberError("");
    }
  };

  const handleCardNumberChange = (e) => {
    const currentValue = e.target.value;
    setCardNumber(currentValue);
    if (currentValue?.length === 0) {
      // setCardNumberError("تکمیل گزینه شماره کارت الزامی است");
      setCardNumberError(card_number_required);
    } else {
      setCardNumberError("");
    }
  };

  return (
    <Fade triggerOnce={true}>
      <Card>
        <CardHeader title={add_bank_account} />
        <CardContent>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <FormControl required fullWidth error={Boolean(bankIdError)}>
                <InputLabel id="create-bank-name-label">
                  {/* بانک */}
                  {bank_input}
                </InputLabel>
                <Select
                  labelId="create-bank-name-label"
                  value={bankId ?? ""}
                  label={bank_input}
                  onChange={handleBankIdChange}
                >
                  {banks?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.bank_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{bankIdError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={accountNumber ?? ""}
                onChange={handleAccountNumberChange}
                autoComplete="off"
                label={account_number_input}
                required
                inputProps={{ style: { textAlign: "center" }, maxLength: 12 }}
                error={Boolean(accountNumberError)}
                helperText={accountNumberError}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={shabaNumber ?? ""}
                onChange={handleShabaNumberChange}
                autoComplete="off"
                label={shaba_number_input}
                required
                inputProps={{
                  style: { textAlign: "center" },
                  maxLength: 26,
                }}
                error={Boolean(shabaNumberError)}
                helperText={shabaNumberError}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                value={cardNumber ?? ""}
                onChange={handleCardNumberChange}
                autoComplete="off"
                label={card_number_input}
                required
                inputProps={{ style: { textAlign: "center" }, maxLength: 16 }}
                error={Boolean(cardNumberError)}
                helperText={cardNumberError}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <LoadingButton
            variant="outlined"
            loadingPosition="start"
            size="large"
            loading={!loadingStoreButton}
            disabled={
              !bankId ||
              !accountNumber ||
              !shabaNumber ||
              !cardNumber
            }
            startIcon={<SaveOutlined />}
            onClick={handleStore}
          >
            {/* ذخیره */}
            {save}
          </LoadingButton>
        </CardActions>
      </Card>
    </Fade>
  );
};

export default CreateBankAccounts;
