import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const BankContext = createContext(null);
BankContext.displayName = "BankContext";

export default BankContext;

export const BankContextProvider = ({ children }) => {
    const { getMethod } = useServices();
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [banksStatus, setBanksStatus] = useState(false);

    async function getBanks() {
        setLoading(true);

        try {
            const response = await getMethod("/banks");
            const data = await response?.data;

            if (response?.status === 200) {
                setBanks(data);
                setBanksStatus(response.status);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getBanks,
        banks, setBanks,
        loading, setLoading,
        banksStatus, setBanksStatus
    };

    return (
        <BankContext.Provider value={contextData}>
            {children}
        </BankContext.Provider>
    );
}
