import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import CreateGallery from './CreateGallery';
import { Box } from "@mui/material";

const AdminGallery = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngAdminGallery");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/gallery" />
      </Box>
      <CreateGallery />
    </>
  );
};

export default AdminGallery;
