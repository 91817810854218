import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from 'react-input-mask';
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import RoleContext from "../../context/RoleContext";
import SettingsContext from "../../context/SettingsContext";
import CountryContext from "../../context/CountryContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
    Container,
    IconButton,
    Stack,
    InputAdornment,
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    useTheme,
    FormControl,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Select,
    CircularProgress,
    FormHelperText,
} from "@mui/material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { tokens } from "../../theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { persian2english } from "../../components/ConvertDigits";

const boxStyle = (theme) => ({
    width: {
        xs: '90%',
        sm: '70%',
        md: '50%',
        lg: '35%',
        xl: '35%',
    },
    maxWidth: 'sm',
    padding: '20px',
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    background: theme.palette.mode === "light" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.8)",
    backdropFilter: theme.palette.mode === "light" ? "blur(5px)" : "blur(3px)",
    border: theme.palette.mode === "light" ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid rgba(255, 255, 255, 0.8)",
});

const Login = () => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        typography_login_to_user_account,
        input_label_country,
        input_label_phone_number,
        input_label_password,
        input_label_placeholder_password,
        form_control_label_remember_me,
        typography_forgot_the_password,
        loading_button_log_in,
        typography_not_registered,
        link_button_create_an_account,
        country_is_required,
        username_is_required,
        password_is_required,
        role_is_required
    } = t("lngLoginIndex");
    const { baseImageUrl, settings } = useContext(SettingsContext);
    const { login } = useContext(AuthContext);
    const { getAllCountries, allCountries, allCountriesLoading, allCountryStatus } = useContext(CountryContext);
    const { handleOpenSnackbar } = useSnackbarContext();
    const {
        getAllRoles,
        allRoles,
        allRoleStatus,
        allRolesLoading
    } = useContext(RoleContext);
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const navigate = useNavigate();
    const isMounted = useRef(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [remember, setRemember] = useState(JSON.parse(localStorage.getItem("remember")) ?? false); // JSON.parse important
    const [countryId, setCountryId] = useState("");
    const [countryIdError, setCountryIdError] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [phoneMask, setPhoneMask] = useState("");
    const [username, setUsername] = useState(localStorage.getItem("username") ?? "");
    const [usernameError, setUsernameError] = useState("");
    const [isPhoneDisabled, setIsPhoneDisabled] = useState(false);
    const [password, setPassword] = useState(localStorage.getItem("password") ?? "");
    const [passwordError, setPasswordError] = useState("");
    const [loadingLoginButton, setLoadingLoginButton] = useState(true);
    const [role, setRole] = useState(localStorage.getItem("role") ?? 2);
    const [roleError, setRoleError] = useState("");

    useEffect(() => {
        if (!isMounted.current) {
            getAllCountries();
            getAllRoles();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Check if the data is loaded and if so, set default country to "Iran"
        if (allCountryStatus === 200 && allCountries.length > 0) {
            const defaultCountry = allCountries.find(country => country.country_name === "Iran");
            if (defaultCountry) {
                setCountryCode(defaultCountry.country_code);
                setPhoneMask(defaultCountry.phone_mask);
                setCountryId(defaultCountry.id);
            }
        }
    }, [allCountryStatus, allCountries]); // Dependency array to re-run when data loads

    const handleLogin = async () => {
        setLoadingLoginButton(false);

        try {
            const cleanUsername = username.replace(/\D/g, ''); // 9191785593
            const fullUsername = countryCode + cleanUsername;
            await login(fullUsername, password, role);
            if (remember === true) {
                localStorage.setItem("remember", true);
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
                localStorage.setItem("role", role);
            } else {
                localStorage.removeItem("remember");
                localStorage.removeItem("username");
                localStorage.removeItem("password");
                localStorage.removeItem("role");
            }
        } catch (ex) {
            if (ex.response && ex.response?.status === 422) {
                if (ex.response.data.errors.country_id &&
                    ex.response.data.errors.country_id.length > 1) {
                    setCountryIdError(ex.response.data.errors.country_id[0]);
                } else {
                    setCountryIdError(ex.response.data.errors.country_id);
                }
                if (ex.response.data.errors.username &&
                    ex.response.data.errors.username?.length > 1) {
                    setUsernameError(ex.response.data.errors.username[0]);
                } else {
                    setUsernameError(ex.response.data.errors.username);
                }
                setPasswordError(ex.response.data.errors.password);
                setRoleError(ex.response.data.errors.role_id);
            }
            if (ex.response?.data?.errors?.popup) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    ex.response.data.errors.popup[0]
                );
            }
        }

        setLoadingLoginButton(true);
    };

    const handleCountryChange = (e) => {
        const currentValue = e.target.value;
        if (currentValue?.length === 0) {
            // setCountryIdError("تکمیل گزینه کشور الزامی است");
            setCountryIdError(country_is_required);
        } else {
            setCountryIdError("");
            const selectedCountry = allCountries.find(item => item.id === currentValue);
            if (selectedCountry) {
                setCountryCode(selectedCountry.country_code);
                setPhoneMask(selectedCountry.phone_mask);
                setCountryId(currentValue);
            }
        }
    };

    const validatePhoneNumber = (number, mask) => {
        // حذف کاراکترهای غیر عددی از شماره
        const cleanNumber = number.replace(/\D/g, '');
        // تعیین تعداد ارقام از الگوی mask
        const maskDigits = mask.replace(/\D/g, '').length;
        // بررسی تعداد ارقام
        return cleanNumber.length === maskDigits;
    };

    const handleUsernameChange = (e) => {
        const currentValue = e.target.value;
        setUsername(persian2english(currentValue));
        if (currentValue?.length === 0) {
            // setUsernameError("تکمیل گزینه موبایل الزامی است");
            setUsernameError(username_is_required);
        } else {
            setUsernameError("");
        }
        const isValidPhone = validatePhoneNumber(currentValue, phoneMask);
        if (!isValidPhone) {
            // setUsernameError("شماره موبایل نادرست است");
            setIsPhoneDisabled(true);
        } else {
            // setUsernameError("");
            setIsPhoneDisabled(false);
        }
    };

    const handlePasswordChange = (e) => {
        const currentValue = e.target.value;
        setPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            // setPasswordError("تکمیل گزینه رمز عبور الزامی است");
            setPasswordError(password_is_required);
        } else {
            setPasswordError("");
        }
    };

    const handleRoleChange = (e) => {
        const currentValue = e.target.value;
        setRole(currentValue);
        if (currentValue?.length === 0) {
            // setRoleError("تکمیل گزینه نقش الزامی است");
            setRoleError(role_is_required);
        } else {
            setRoleError("");
        }
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${baseImageUrl + settings.main_background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={boxStyle} borderRadius="20px">
                <Grid container className="top_to_bottom_animation">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box
                            component="div"
                            style={{
                                marginTop: "20px",
                                margin: "20px",
                            }}
                        >
                            <Container maxWidth="sm">
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Avatar>
                                        <LockOutlined sx={{
                                            color: mode === "light"
                                                ? colors.black
                                                : colors.white,
                                        }} />
                                    </Avatar>
                                </Box>
                                <Box
                                    sx={{
                                        marginTop: "4px",
                                        marginBottom: "35px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography component="h1" variant="h4" sx={{
                                        color: mode === "light"
                                            ? colors.black
                                            : colors.white,
                                    }}>
                                        {/* ورود به حساب کاربری */}
                                        {typography_login_to_user_account}
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl required fullWidth>
                                            {allCountriesLoading ? (
                                                <Select value="">
                                                    <MenuItem>
                                                        <CircularProgress />
                                                    </MenuItem>
                                                </Select>
                                            ) : (
                                                <FormControl required fullWidth error={Boolean(countryIdError)}>
                                                    <InputLabel id="country-name-label">
                                                        {/* کشور */}
                                                        {input_label_country}
                                                    </InputLabel>
                                                    <Select
                                                        value={countryId}
                                                        onChange={handleCountryChange}
                                                        label={input_label_country}
                                                        style={{ direction: direction === "rtl" ? "rtl" : "ltr" }}
                                                        renderValue={(selected) => {
                                                            const country = allCountries.find((item) => item.id === selected);
                                                            return country ? country.country_name : "";
                                                        }}
                                                    >
                                                        {allCountries?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.id} style={{ direction: "ltr", textAlign: "left" }}>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                                    <Typography variant="body1">{item?.country_name}</Typography>
                                                                    <Typography variant="body2" sx={{ color: colors.grey[500] }}>{item?.country_code}</Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{countryIdError}</FormHelperText>
                                                </FormControl>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputMask
                                            mask={phoneMask}
                                            maskChar="_"
                                            value={username}
                                            onChange={handleUsernameChange}
                                        >
                                            {() => (
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    error={Boolean(usernameError)}
                                                    required
                                                >
                                                    <InputLabel htmlFor="username">
                                                        {/* موبایل */}
                                                        {input_label_phone_number}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        id="username"
                                                        type="text"
                                                        label={input_label_phone_number}
                                                        autoComplete="off"
                                                        {...(direction === "rtl" ? {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Typography variant="body1" style={{
                                                                        direction: "ltr",
                                                                        color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                                                                    }}>
                                                                        {countryCode}
                                                                    </Typography>
                                                                </InputAdornment>
                                                            )
                                                        } : {
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Typography variant="body1" style={{
                                                                        direction: "ltr",
                                                                        color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                                                                    }}>
                                                                        {countryCode}
                                                                    </Typography>
                                                                </InputAdornment>
                                                            )
                                                        })}
                                                        inputProps={{
                                                            style: {
                                                                letterSpacing: '2px', // تنظیم فاصله بین ماسک‌ها
                                                                textAlign: 'center',
                                                                direction: 'ltr',
                                                                color: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500]
                                                            },
                                                        }}
                                                    />
                                                    <FormHelperText>{usernameError}</FormHelperText>
                                                </FormControl>
                                            )}
                                        </InputMask>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(passwordError)} required
                                        >
                                            <InputLabel htmlFor="password">
                                                {/* رمز عبور */}
                                                {input_label_password}
                                            </InputLabel>
                                            <OutlinedInput
                                                value={password ?? ""}
                                                onChange={handlePasswordChange}
                                                autoComplete="off"
                                                placeholder={input_label_placeholder_password}
                                                inputProps={{
                                                    style: { direction: "ltr", textAlign: "center" },
                                                }}
                                                type={showPassword ? "text" : "password"}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff />
                                                            ) : (
                                                                <Visibility />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label={input_label_password}
                                            />
                                            <FormHelperText>{passwordError}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl required fullWidth>
                                            {allRolesLoading ? (
                                                <Select value="">
                                                    <MenuItem>
                                                        <CircularProgress />
                                                    </MenuItem>
                                                </Select>
                                            ) : (
                                                <>
                                                    <Select
                                                        value={allRoleStatus === 200 ? role : ""}
                                                        onChange={handleRoleChange}
                                                    >
                                                        {allRoles?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.id}>
                                                                {direction === "rtl" ? (
                                                                    item?.role_name
                                                                ) : (
                                                                    item?.en_role_name
                                                                )}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>{roleError}</FormHelperText>
                                                </>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        checked={remember}
                                                        onChange={() => setRemember(!remember)}
                                                    />
                                                }
                                                label={form_control_label_remember_me}
                                                labelPlacement="end"
                                                sx={{ marginLeft: "5px" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <Box
                                                component="div"
                                                sx={{
                                                    float: {
                                                        xs: "none",
                                                        sm: "right",
                                                        md: "right",
                                                        lg: "right",
                                                        xl: "right",
                                                    },
                                                    marginTop: {
                                                        xs: "none",
                                                        sm: "8px",
                                                        md: "8px",
                                                        lg: "8px",
                                                        xl: "8px",
                                                    },
                                                    marginLeft: { xs: "16px" }
                                                }}
                                            >
                                                <Typography
                                                    variant="span"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        navigate("/recovery-password");
                                                    }}>
                                                    {/* رمز عبور را فراموش کردم */}
                                                    {typography_forgot_the_password}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <LoadingButton
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            sx={{
                                                borderRadius: 28,
                                                color: colors.white,
                                            }}
                                            loading={!loadingLoginButton}
                                            disabled={
                                                !countryId ||
                                                !username ||
                                                isPhoneDisabled ||
                                                !password ||
                                                !role
                                            }
                                            onClick={handleLogin}
                                        >
                                            {/* ورود */}
                                            {loading_button_log_in}
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Typography
                                                variant="body1"
                                                component="span"
                                            >
                                                {/* ثبت نام نکرده‌اید؟ */}
                                                {typography_not_registered}
                                                &nbsp;&nbsp;
                                                <span
                                                    style={{
                                                        color: mode === "light"
                                                            ? colors.blueAccent[500]
                                                            : colors.blueAccent[300],
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        navigate("/register");
                                                    }}
                                                >
                                                    {/* ایجاد حساب کاربری */}
                                                    {link_button_create_an_account}
                                                </span>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
};

export default Login;
