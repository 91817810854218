import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";
import {
    Box,
    IconButton,
    useTheme,
    Menu,
    MenuItem,
    Tooltip,
    ListItemIcon,
    Divider,
} from "@mui/material";
import { Edit, MoreVert, Settings } from "@mui/icons-material";

const AdminInstructorsMenu = (props) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        settings,
        lst_item_edit,
        lst_item_bank_accounts_management
    } = t("lngAdminInstructorsMenu");
    const navigate = useNavigate();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title={settings}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'instructors-settings-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVert sx={{ color: mode === "light" ? colors.black : colors.white }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="instructors-settings-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    horizontal: direction === "rtl" ? "left" : "right",
                    vertical: "top",
                }}
                anchorOrigin={{
                    horizontal: direction === "rtl" ? "left" : "right",
                    vertical: 'bottom',
                }}
            >
                <MenuItem onClick={() =>
                    navigate(`/admin-instructor/edit/${props?.instructor?.id}`)
                }>
                    <ListItemIcon>
                        <Edit fontSize="medium"
                            sx={{
                                color: theme.palette.mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    {/* ویرایش */}
                    {lst_item_edit}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() =>
                    navigate(`/admin-instructor/bank-accounts/${props?.instructor?.id}`)
                }>
                    <ListItemIcon>
                        <Settings fontSize="medium"
                            sx={{
                                color: theme.palette.mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    {/* مدیریت حساب‌های بانکی */}
                    {lst_item_bank_accounts_management}
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default AdminInstructorsMenu;
