import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PrivacyContext from "../../context/PrivacyContext";
import {
    useTheme,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Skeleton
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const PrivacyPost = () => {
    const { i18n } = useTranslation();
    const direction = i18n.dir();
    const isMounted = useRef(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [expanded, setExpanded] = useState(false);
    const { getAllPrivacies, privacies, loading } = useContext(PrivacyContext);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (!isMounted.current) {
            getAllPrivacies();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {privacies?.map((item, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === "panel" + index}
                    onChange={handleChange("panel" + index)}
                >

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id={`panel${index}-header`}
                    >
                        {loading ? (
                            <Skeleton
                                width="100%"
                                height="30px"
                                padding="0px 16px"
                                variant="rectangular"
                                sx={{
                                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                                }}
                            />
                        ) : (
                            <Typography color={colors.grey[100]}>
                                {index + 1}.&nbsp;
                                {direction === "rtl" ? (
                                    item?.title
                                ) : (
                                    item?.en_title
                                )}
                            </Typography>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading ? (
                            <Skeleton
                                width="100%"
                                height="30px"
                                padding="8px 16px 16px"
                                variant="rectangular"
                            />
                        ) : (
                            <Typography sx={{ textAlign: "justify" }}>
                                {direction === "rtl" ? (
                                    item?.law
                                ) : (
                                    item?.en_law
                                )}
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

export default PrivacyPost;

