import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import AuthContext from "./../../context/AuthContext";
import InstructorsInvoicesContext from "../../context/InstructorsInvoicesContext";
import { separateNumbers } from "../../components/PriceFormat";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Skeleton,
} from "@mui/material";

const ShowReceipt = () => {
    const { id: transactionId } = useParams();
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        header_title,
        header_subtitle,
        tbl_cell_bank,
        tbl_cell_account_number,
        tbl_cell_shaba_number,
        tbl_cell_cart_number,
        tbl_cell_total_with_unit,
        tbl_cell_tracking_number
    } = t("lngShowReceipt");
    const { user } = useContext(AuthContext);
    const {
        getReceiptByTransactionId,
        receipts, receiptsByTransactionIdLoading
    } = useContext(InstructorsInvoicesContext);
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            getReceiptByTransactionId(transactionId);
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, [transactionId]);

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={header_title} subtitle={header_subtitle} backToPage={user?.role?.role_key === "admin" ? "/admin-transactions" : "/admin-instructors-invoices"} />
            </Box>
            <Box mb="50px" mt="50px" className="container">
                <Fade triggerOnce={true}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        {/* بانک */}
                                        {tbl_cell_bank}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* شماره حساب */}
                                        {tbl_cell_account_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* شماره شبا */}
                                        {tbl_cell_shaba_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* شماره کارت */}
                                        {tbl_cell_cart_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* مبلغ (تومان) */}
                                        {tbl_cell_total_with_unit}
                                    </TableCell>
                                    <TableCell align="center">
                                        {/* شماره پیگیری */}
                                        {tbl_cell_tracking_number}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {receiptsByTransactionIdLoading ? (
                                        <>
                                            <TableCell align="center">
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="text" />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Skeleton variant="text" />
                                            </TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell align="center">{receipts?.user_bank_account?.bank?.bank_name}</TableCell>
                                            <TableCell align="center">{receipts?.user_bank_account?.account_number}</TableCell>
                                            <TableCell align="center">{receipts?.user_bank_account?.shaba_number}</TableCell>
                                            <TableCell align="center">{receipts?.user_bank_account?.card_number}</TableCell>
                                            <TableCell align="center">{direction === "rtl" ? separateNumbers(receipts?.total) : receipts?.en_total}</TableCell>
                                            <TableCell align="center">{receipts?.issue_tracking}</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fade>
            </Box >
        </>
    )
}

export default ShowReceipt
