import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, CloudUpload, SaveOutlined } from "@mui/icons-material";

const CreateEpisode = ({ season }) => {
  const { t } = useTranslation();
  const {
    episode_name_required,
    episode_registered,
    record_deleted,
    episode_name_input,
    en_episode_name_input,
    form_control_label_play,
    course_video_input,
    cancel,
    save
  } = t("lngCreateEpisode");
  const { uploadPostMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [episodeName, setEpisodeName] = useState("");
  const [episodeNameError, setEpisodeNameError] = useState("");
  const [enEpisodeName, setEnEpisodeName] = useState("");
  const [enEpisodeNameError, setEnEpisodeNameError] = useState("");
  const [play, setPlay] = useState(false);
  const [courseVideo, setCourseVideo] = useState(null);
  const [courseVideoError, setCourseVideoError] = useState("");
  const fileInputRef = useRef(null);

  const handleEpisodeNameChange = (e) => {
    const currentValue = e.target.value;
    setEpisodeName(currentValue);
    if (currentValue?.length === 0) {
      // setEpisodeNameError("تکمیل گزینه نام بخش الزامی است");
      setEpisodeNameError(episode_name_required);
    } else {
      setEpisodeNameError("");
    }
  };

  const handleEnEpisodeNameChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnEpisodeName(currentValue);
  };

  const handleCourseVideoChange = (e) => {
    const currentValue = e.target.files[0];
    setCourseVideo(currentValue);
    // console.log(courseVideo);
  };

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        season_id: season?.id,
        episode_name: episodeName,
        en_episode_name: enEpisodeName,
        play: play === true ? 1 : 0,
        course_video: courseVideo,
      };

      await uploadPostMethod("/episodes", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "بخش با موفقیت ثبت شد"
        episode_registered
      );

      handleResetForm();

    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          // "رکورد مورد نظر قبلاً حذف شده است"
          record_deleted
        );
      }
      if (ex.response && ex.response.status === 422) {
        setEpisodeNameError(ex.response.data.errors.episode_name);
        setEnEpisodeNameError(ex.response.data.errors.en_episode_name);
      }
      if (ex && ex.status === 413) {
        setCourseVideoError(ex.message);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleResetForm = () => {
    setEpisodeName("");
    setEpisodeNameError("");
    setEnEpisodeName("");
    setEnEpisodeNameError("");
    setPlay(false);
    setCourseVideo(null);
    setCourseVideoError("");

    // ریست کردن input فایل
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    handleCloseDialog();
  }

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={episodeName ?? ""}
            onChange={handleEpisodeNameChange}
            autoComplete="off"
            // label="نام بخش"
            label={episode_name_input}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "right" },
              maxLength: 100
            }}
            error={Boolean(episodeNameError)}
            helperText={episodeNameError}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enEpisodeName ?? ""}
            onChange={handleEnEpisodeNameChange}
            autoComplete="off"
            // label="نام بخش"
            label={en_episode_name_input}
            inputProps={{
              style: { direction: "ltr", textAlign: "left" },
              maxLength: 100
            }}
            error={Boolean(enEpisodeNameError)}
            helperText={enEpisodeNameError}
          />
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                checked={play}
                onChange={() => setPlay(!play)}
              />
            }
            label={form_control_label_play}
            labelPlacement="end"
          />
          <TextField
            fullWidth
            type="file"
            // label="آدرس ویدیو آموزشی"
            label={course_video_input}
            onChange={handleCourseVideoChange}
            accept="video/mp4,video/x-m4v,video/*"
            InputProps={{
              inputRef: fileInputRef,  // افزودن ref برای فیلد فایل
              startAdornment: (
                <InputAdornment position="start">
                  <CloudUpload />
                </InputAdornment>
              ),
            }}
            error={Boolean(courseVideoError)}
            helperText={courseVideoError ? courseVideoError : "1920px × 1080px"}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <UploadProgressBar />
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetForm}
        >
          {/* انصراف */}
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={!episodeName}
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          {/* ذخیره */}
          {save}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateEpisode;
