import { useMemo, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  TextField,
  InputAdornment,
  Grid,
  CardActions,
  Card,
  CardHeader,
  CardContent,
  Box,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { SaveOutlined, CloudUpload } from "@mui/icons-material";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { persian2english } from "../../components/ConvertDigits";

const CreateGallery = (props) => {
  const { t } = useTranslation();
  const {
    date_is_not_valid,
    date_format_incorrect,
    gallery_registered,
    cover_required,
    title_required,
    en_title_required,
    add_galery,
    gallery_cover_input,
    title_input,
    en_title_input,
    cancel_button_label,
    ok_button_label,
    today_button_label,
    clear_button_label,
    date_input,
    save
  } = t("lngCreateGallery");
  const { handleOpenSnackbar } = useSnackbarContext();
  const { uploadPostMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [cover, setCover] = useState([]);
  const [coverError, setCoverError] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [enTitle, setEnTitle] = useState("");
  const [enTitleError, setEnTitleError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date() || null);
  const [selectedDateError, setSelectedDateError] = useState(null);

  const selectedDateMessageError = useMemo(() => {
    switch (selectedDateError) {
      case 'maxDate':
      case 'minDate': {
        // return 'تاریخ وارد شده معتبر نمی باشد';
        return date_is_not_valid;
      }

      case 'invalidDate': {
        // return 'فرمت تاریخ صحیح نمی باشد';
        return date_format_incorrect;
      }

      default: {
        return '';
      }
    }
  }, [selectedDateError, date_format_incorrect, date_is_not_valid]);

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        cover: cover,
        title: title,
        en_title: enTitle,
        date: persian2english(selectedDate.toLocaleDateString('fa-IR')),
      };

      await uploadPostMethod("/gallery", obj);

      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "گالری با موفقیت ثبت شد"
        gallery_registered
      );
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setCoverError(ex.response.data.errors.cover);
        setTitleError(ex.response.data.errors.title);
        setEnTitleError(ex.response.data.errors.en_title);
        setSelectedDateError(ex.response.data.errors.date);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleCoverChange = (e) => {
    const currentValue = e.target.files[0];
    setCover(currentValue);
    if (currentValue?.length === 0) {
      // setCoverError("تکمیل گزینه تصویر الزامی است");
      setCoverError(cover_required);
    } else {
      setCoverError("");
    }
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setTitleError(title_required);
    } else {
      setTitleError("");
    }
  };

  const handleEnTitleChange = (e) => {
    const currentValue = e.target.value;
    // جلوگیری از ورود حروف فارسی
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setEnTitleError(en_title_required);
    } else {
      setEnTitleError("");
    }
  };

  return (
    <Box mb="50px" mt="50px" className="container">
      <Fade triggerOnce={true}>
        <Card>
          <CardHeader title={add_galery} />
          <CardContent>
            <Grid container rowSpacing={2} columnSpacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <TextField
                  fullWidth
                  type="file"
                  // label="تصویر گالری"
                  label={gallery_cover_input}
                  onChange={handleCoverChange}
                  accept="image/*"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CloudUpload />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(coverError)}
                  helperText={coverError ? coverError : "390px × 250px"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  autoComplete="off"
                  // label="عنوان"
                  label={title_input}
                  required
                  inputProps={{
                    maxLength: 50,
                    style: { direction: "rtl", textAlign: "right" },
                  }}
                  error={Boolean(titleError)}
                  helperText={
                    titleError ? titleError : `${title?.length ?? 0}/50`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={enTitle}
                  onChange={handleEnTitleChange}
                  autoComplete="off"
                  // label="عنوان"
                  label={en_title_input}
                  required
                  inputProps={{
                    maxLength: 50,
                    style: { direction: "ltr", textAlign: "left" },
                  }}
                  error={Boolean(enTitleError)}
                  helperText={
                    enTitleError ? enTitleError : `${enTitle?.length ?? 0}/50`
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDateFnsJalali}
                  localeText={{
                    cancelButtonLabel: cancel_button_label,
                    okButtonLabel: ok_button_label,
                    todayButtonLabel: today_button_label,
                    clearButtonLabel: clear_button_label
                  }}
                >
                  <DatePicker
                    sx={{ width: "100%" }}
                    // label="تاریخ"
                    label={date_input}
                    // minDate={new Date()}
                    // maxDate={new Date("2042-02-08")}
                    defaultValue={new Date() || null}
                    value={selectedDate}
                    inputFormat="YYYY/MM/DD"
                    views={["day", "month", "year"]}
                    onChange={(currentValue) => { setSelectedDate(currentValue) }}
                    onError={(newError) => setSelectedDateError(newError)}
                    slotProps={{
                      actionBar: { actions: ['clear', 'today'] },
                      textField: {
                        helperText: selectedDateMessageError,
                      },
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{
            justifyContent: "flex-end",
          }}>
            <UploadProgressBar />
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              size="large"
              loading={!loadingStoreButton}
              disabled={!title || !enTitle || !selectedDate || !cover}
              startIcon={<SaveOutlined />}
              onClick={handleStore}
            >
              {/* ذخیره */}
              {save}
            </LoadingButton>
          </CardActions>
        </Card>
      </Fade>
    </Box>
  );
};

export default CreateGallery;
