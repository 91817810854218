import React from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import { separateNumbers } from "../../components/PriceFormat";

const InvoiceItems = ({ courses }) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        typography_course,
        typography_instructor,
        typography_price_with_unit,
        typography_discount_percentage,
        typography_discount,
        typography_total_with_unit
    } = t("lngInvoiceItems");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return courses?.map(function (course, index) {
        return (
            <React.Fragment key={index}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            {/* دوره */}
                            {typography_course}
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* دوره */}
                                {typography_course}
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={direction === "rtl" ? course?.course_name : course?.en_course_name}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            {/* استاد */}
                            {typography_instructor}
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* استاد */}
                                {typography_instructor}
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={direction === "rtl" ? course?.user?.full_name : course?.user?.en_full_name}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            {/* قیمت (تومان) */}
                            {typography_price_with_unit}
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* قیمت (تومان) */}
                                {typography_price_with_unit}
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={direction === "rtl"
                            ? separateNumbers(course?.pivot?.price)
                            : (course?.pivot?.en_price ?? separateNumbers(course?.pivot?.price))}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            {/* درصد تخفیف */}
                            {typography_discount_percentage}
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* تخفیف */}
                                {typography_discount}
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={course?.pivot?.discount + " %" ?? ""}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    {isMobile ? (
                        <Typography
                            component="span"
                            variant="body1"
                            color="primary"
                            sx={{ fontWeight: "700" }}
                        >
                            {/* جمع دوره (تومان) */}
                            {typography_total_with_unit}
                        </Typography>
                    ) : (
                        index === 0 && (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ fontWeight: "700" }}
                            >
                                {/* جمع دوره (تومان) */}
                                {typography_total_with_unit}
                            </Typography>
                        )
                    )}
                    <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        value={direction === "rtl"
                            ? separateNumbers(course?.pivot?.total)
                            : (course?.pivot?.en_total ?? separateNumbers(course?.pivot?.total))}
                        inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                    />
                </Grid>
            </React.Fragment>
        )
    })
}

export default InvoiceItems
