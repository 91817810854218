import { createContext } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const DirectionContext = createContext(null);
DirectionContext.displayName = "DirectionContext";

export default DirectionContext;

const languages = [
    { code: "fa", name: "فارسی", country_code: "ir", dir: "rtl" },
    { code: "en", name: "English", country_code: "gb", dir: "ltr" },
];

export const DirectionContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const currentLanguageCode = cookies.get("i18next") || "fa";
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    const contextData = {
        languages: languages,
        i18n: i18n,
        currentLanguageCode: currentLanguageCode,
        currentLanguage: currentLanguage,
        direction: currentLanguage.dir,
    };

    return (
        <DirectionContext.Provider value={contextData}>
            {children}
        </DirectionContext.Provider>
    );
};
