import { createContext, useState } from "react";

const ProgressBarContext = createContext(null);
ProgressBarContext.displayName = "ProgressBarContext";

export default ProgressBarContext;

export const ProgressBarContextProvider = ({ children }) => {
    const [pageLoading, setPageLoading] = useState(0);
    const [progress, setProgress] = useState(0);

    const contextData = {
        pageLoading, setPageLoading,
        progress, setProgress
    };

    return (
        <ProgressBarContext.Provider value={contextData}>
            {children}
        </ProgressBarContext.Provider>
    );
}
