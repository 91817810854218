import { createContext, useContext, useEffect, useState } from "react";
import AuthContext from "./AuthContext";

const CanContext = createContext(null);
CanContext.displayName = "CanContext";

export default CanContext;

export const CanContextProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const [admin, setAdmin] = useState(0);
    const [adminBlog, setAdminBlog] = useState(0);
    const [adminAnswerBlog, setAdminAnswerBlog] = useState(0);
    const [adminContact, setAdminContact] = useState(0);
    const [adminCourses, setAdminCourses] = useState(0);
    const [adminCategories, setAdminCategories] = useState(0);
    const [adminEvents, setAdminEvents] = useState(0);
    const [adminFaq, setAdminFaq] = useState(0);
    const [adminGallery, setAdminGallery] = useState(0);
    const [adminInstructors, setAdminInstructors] = useState(0);
    const [adminInstructorsInvoices, setAdminInstructorsInvoices] = useState(0);
    const [adminPrivacy, setAdminPrivacy] = useState(0);
    const [adminReceipt, setAdminReceipt] = useState(0);
    const [adminRoles, setAdminRoles] = useState(0);
    const [adminSettings, setAdminSettings] = useState(0);
    const [adminTags, setAdminTags] = useState(0);
    const [adminTransactions, setAdminTransactions] = useState(0);
    const [adminUserBankAccounts, setAdminUserBankAccounts] = useState(0);
    const [adminUsers, setAdminUsers] = useState(0);

    useEffect(() => {
        can();
        // eslint-disable-next-line
    }, [user]);

    const can = () => {
        if (user) {
            setAdmin(user?.role?.admin);
            setAdminBlog(user?.role?.admin_blog);
            setAdminAnswerBlog(user?.role?.admin_answer_blog);
            setAdminContact(user?.role?.admin_contact);
            setAdminCourses(user?.role?.admin_courses);
            setAdminCategories(user?.role?.admin_categories);
            setAdminEvents(user?.role?.admin_events);
            setAdminFaq(user?.role?.admin_faq);
            setAdminGallery(user?.role?.admin_gallery);
            setAdminInstructors(user?.role?.admin_instructors);
            setAdminPrivacy(user?.role?.admin_privacy);
            setAdminReceipt(user?.role?.admin_receipt);
            setAdminRoles(user?.role?.admin_roles);
            setAdminSettings(user?.role?.admin_settings);
            setAdminTags(user?.role?.admin_tags);
            setAdminTransactions(user?.role?.admin_transactions);
            setAdminUserBankAccounts(user?.role?.admin_user_bank_accounts);
            setAdminUsers(user?.role?.admin_users);
        }
    };

    const resetCan = () => {
        setAdmin(0);
        setAdminBlog(0);
        setAdminAnswerBlog(0);
        setAdminContact(0);
        setAdminCourses(0);
        setAdminCategories(0);
        setAdminEvents(0);
        setAdminFaq(0);
        setAdminGallery(0);
        setAdminInstructors(0);
        setAdminInstructorsInvoices(0);
        setAdminPrivacy(0);
        setAdminReceipt(0);
        setAdminRoles(0);
        setAdminSettings(0);
        setAdminTags(0);
        setAdminTransactions(0);
        setAdminUserBankAccounts(0);
        setAdminUsers(0);
    };

    const contextData = {
        can: can,
        resetCan: resetCan,
        admin: admin,
        adminBlog: adminBlog,
        adminAnswerBlog: adminAnswerBlog,
        adminContact: adminContact,
        adminCourses: adminCourses,
        adminCategories: adminCategories,
        adminEvents: adminEvents,
        adminFaq: adminFaq,
        adminGallery: adminGallery,
        adminInstructors: adminInstructors,
        adminInstructorsInvoices: adminInstructorsInvoices,
        adminPrivacy: adminPrivacy,
        adminReceipt: adminReceipt,
        adminRoles: adminRoles,
        adminSettings: adminSettings,
        adminTags: adminTags,
        adminTransactions: adminTransactions,
        adminUserBankAccounts: adminUserBankAccounts,
        adminUsers: adminUsers,
    };

    return (
        <CanContext.Provider value={contextData}>
            {children}
        </CanContext.Provider>
    );
}
