import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import CreateEvents from './CreateEvents';
import { Box } from "@mui/material";

const AdminEvents = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngAdminEvents");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/events" />
      </Box>
      <CreateEvents />
    </>
  );
};

export default AdminEvents;
