import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import SentEmailsTable from "./SentEmailsTable";

const SentEmails = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngSentEmailsIndex");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <SentEmailsTable />
      </Box>
    </>
  );
};

export default SentEmails;
