import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { Box } from "@mui/material";
import FaqTable from "./FaqTable";

const AdminFaq = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngAdminFaq");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/faq" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <FaqTable />
      </Box>
    </>
  );
};

export default AdminFaq;
