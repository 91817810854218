import { createContext, useState } from "react";
import useServices from './../utils/useServices';

const SeasonContext = createContext(null);
SeasonContext.displayName = "SeasonContext";

export default SeasonContext;

export const SeasonContextProvider = ({ children }) => {
    const { postMethod } = useServices();
    const [seasonsLoading, setSeasonsLoading] = useState(true);
    const [seasonsStatus, setSeasonsStatus] = useState(0);
    const [seasons, setSeasons] = useState([]);

    async function getSeasonsByCourseId(courseId) {
        setSeasonsLoading(true);

        try {
            const obj = {
                course_id: courseId,
            };

            const response = await postMethod("/seasons_by_course_id", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                setSeasons(data);
            }
        } catch (ex) {

        }

        setSeasonsLoading(false);
    }

    const contextData = {
        getSeasonsByCourseId,
        seasonsLoading, setSeasonsLoading,
        seasonsStatus, setSeasonsStatus,
        seasons, setSeasons
    };

    return (
        <SeasonContext.Provider value={contextData}>
            {children}
        </SeasonContext.Provider>
    );
}
