import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import logo from "./../images/Master-It.png";
import MenuContext from '../context/MenuContext';
import DirectionContext from '../context/DirectionContext';
import {
    Box,
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    IconButton,
    Badge,
} from '@mui/material';
import { Close, ExpandMore } from '@mui/icons-material';

function Sidebar({ state, toggleDrawer }) {
    const { pages } = useContext(MenuContext);
    const { direction } = useContext(DirectionContext);
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handlePath = (path) => {
        setExpanded(false);
        navigate(path);
    }

    return (
        <Box>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <Box
                            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
                            role="presentation"
                        >
                            <List>
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemText onClick={() => handlePath("/")}>
                                            <Fade triggerOnce={true}>
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        maxWidth: "150px",
                                                    }}
                                                    alt="Master it"
                                                    src={logo}
                                                />
                                            </Fade>
                                        </ListItemText>
                                        <ListItemIcon sx={{ minWidth: 0 }}>
                                            <Fade triggerOnce={true}>
                                                <IconButton
                                                    onClick={() => setExpanded(false)}
                                                    onMouseUp={toggleDrawer(anchor, false)}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </Fade>
                                        </ListItemIcon>
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                {pages?.map((page, pageIndex) => (
                                    page?.isAccess === 1 && (
                                        page?.children?.length === 0 ? (
                                            <AccordionDetails key={pageIndex}
                                                sx={{ padding: '1px 0 1px 16px' }}
                                                onClick={() => handlePath(page?.path)}
                                                onMouseUp={toggleDrawer(anchor, false)}
                                            >
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        {page?.badge === "" ? (
                                                            page?.icon
                                                        ) : (
                                                            <Badge badgeContent={page?.badge} color="error">
                                                                {/* <page label="5" /> */}
                                                                {page?.icon}
                                                            </Badge>
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText primary={page?.title} />
                                                </ListItemButton>
                                            </AccordionDetails>
                                        ) : (
                                            <Accordion key={pageIndex}
                                                expanded={expanded === "panel" + pageIndex}
                                                onChange={handleChange("panel" + pageIndex)}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1bh-content"
                                                >
                                                    <ListItemIcon>
                                                        {page?.icon}
                                                    </ListItemIcon>
                                                    <Typography>
                                                        {direction === "rtl" ? page?.fa_title : page?.en_title}
                                                    </Typography>
                                                </AccordionSummary>
                                                {page?.children.length > 0 && (
                                                    page?.children?.map((child, childIndex) => (
                                                        child?.isAccess === 1 && (
                                                            <AccordionDetails key={childIndex}
                                                                sx={{ padding: '1px 0 1px 16px' }}
                                                                onClick={() => handlePath(child?.path)}
                                                                onMouseUp={toggleDrawer(anchor, false)}
                                                            >
                                                                <ListItem disablePadding>
                                                                    <ListItemButton>
                                                                        <ListItemIcon>
                                                                            {child?.badge === "" ? (
                                                                                child?.icon
                                                                            ) : (
                                                                                <Badge badgeContent={child?.badge} color="error">
                                                                                    {/* <Chip label="5" /> */}
                                                                                    {child?.icon}
                                                                                </Badge>
                                                                            )}
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={direction === "rtl" ? child?.fa_title : child?.en_title} />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            </AccordionDetails>
                                                        )
                                                    ))
                                                )}
                                            </Accordion>
                                        )
                                    )
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </Box>
    );
}

export default Sidebar;