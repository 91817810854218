import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import SettingsContext from './../../context/SettingsContext';
import TransactionsContext from './../../context/TransactionsContext';
import AuthContext from "../../context/AuthContext";
import {
  Typography,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import logo from "./../../images/Master-It.png";
import { separateNumbers } from "../../components/PriceFormat";
import {
  Email,
  Instagram,
  LinkedIn,
  LocationOn,
  MobileFriendly,
  Phone,
} from "@mui/icons-material";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";
import InvoiceItems from "../invoices/InvoiceItems";

const useStyles = makeStyles((theme) => ({
  header: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 20px 30px",
    marginBottom: "25px"
  },
  content: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 30px 20px",
    marginBottom: "25px"
  },
  headleRightSidebar: (props) => ({
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: props.direction === "rtl" ? "flex-end" : "flex-start",
      fontWeight: "700 !important",
    },
    fontWeight: "700 !important",
  }),
  footer: {
    borderRadius: "10px",
    backgroundColor: `${tokens(theme.palette.mode).grey[900]}`,
    padding: "20px 30px 0 30px",
  }
}));

const ShowTransactions = () => {
  const { id: transactionId } = useParams();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    header_title,
    header_subtitle,
    mr_miss_value,
    invoice_number_value,
    payment_receipt_value,
    date_value,
    courses_total_with_unit,
    price_unit,
    total_with_unit,
    customer_address,
    zipcode,
    username
  } = t("lngShowTransactions");
  const {
    getTransactionById,
    transaction
  } = useContext(TransactionsContext);
  const { getSettings, settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [price, setPrice] = useState(0);
  const [enPrice, setEnPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [enTotal, setEnTotal] = useState(0);
  const classes = useStyles();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getSettings();
      getTransactionById(transactionId);
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [transactionId]);

  useEffect(() => {
    function calcTotalCourses() {
      var price = 0;
      var total = 0;
      var enPrice = 0;
      var enTotal = 0;
      transaction?.courses?.forEach((item) => {
        price = price + parseInt(item?.pivot?.price ?? 0);
        total = total + parseInt(item?.pivot?.total ?? 0);
        enPrice = enPrice + parseInt(item?.pivot?.en_price ?? 0);
        enTotal = enTotal + parseInt(item?.pivot?.en_total ?? 0);
      });
      setPrice(price);
      setTotal(total);
      setEnPrice(enPrice);
      setEnTotal(enTotal);
    }

    calcTotalCourses();
  }, [transaction]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage={user?.role?.role_key === "admin" ? "/admin-transactions" : "/invoices"} />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <Box>
            {isMobile ? (
              <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: "150px",
                      display: "block",
                      margin: "0 auto 10px auto",
                    }}
                    alt="Master it"
                    src={logo}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "16px",
                      margin: "0 0 30px 0",
                      color: colors.orangeAccent[500]
                    }}>
                    {direction === "rtl" ? (
                      settings?.slogan
                    ) : (
                      settings?.en_slogan
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {direction === "rtl" ? (
                      <>
                        {/* آقا/خانم: {transaction?.user?.full_name ?? ""} */}
                        {mr_miss_value} {transaction?.user?.full_name ?? ""}
                      </>
                    ) : (
                      <>
                        {/* آقا/خانم: {transaction?.user?.full_name ?? ""} */}
                        {mr_miss_value} {transaction?.user?.en_full_name ?? ""}
                      </>
                    )}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {/* شماره فاکتور: {transaction?.order_id ?? ""} */}
                    {invoice_number_value} {transaction?.order_id ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {/* رسید پرداخت: {transaction?.payment_receipt ?? ""} */}
                    {payment_receipt_value} {transaction?.payment_receipt ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {/* تاریخ: {transaction?.created_at ?? ""} */}
                    {date_value} {transaction?.created_at ?? ""}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container rowSpacing={1} columnSpacing={1} className={classes.header}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {direction === "rtl" ? (
                      <>
                        {/* آقا/خانم: {transaction?.user?.full_name ?? ""} */}
                        {mr_miss_value} {transaction?.user?.full_name ?? ""}
                      </>
                    ) : (
                      <>
                        {/* آقا/خانم: {transaction?.user?.full_name ?? ""} */}
                        {mr_miss_value} {transaction?.user?.en_full_name ?? ""}
                      </>
                    )}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {/* شماره فاکتور: {transaction?.order_id ?? ""} */}
                    {invoice_number_value} {transaction?.order_id ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {/* رسید پرداخت: {transaction?.payment_receipt ?? ""} */}
                    {payment_receipt_value} {transaction?.payment_receipt ?? ""}
                  </Box>
                  <Box
                    component="div"
                    className={classes.headleRightSidebar}>
                    {/* تاریخ: {transaction?.created_at ?? ""} */}
                    {date_value} {transaction?.created_at ?? ""}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: "150px",
                      display: "block",
                      margin: "0 auto 10px auto",
                    }}
                    alt="Master it"
                    src={logo}
                  />
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "700",
                      fontSize: "16px",
                      margin: "0 0 30px 0",
                      color: colors.orangeAccent[500]
                    }}>
                    {direction === "rtl" ? (
                      settings?.slogan
                    ) : (
                      settings?.en_slogan
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                </Grid>
              </Grid>
            )}
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.content}>
              <InvoiceItems courses={transaction?.courses} />
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={1} className={classes.content}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {isMobile && (
                  <Typography
                    component="span"
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: "700" }}
                  >
                    {/* جمع کل دوره‌ها (تومان) */}
                    {courses_total_with_unit}
                  </Typography>
                )}
                <TextField
                  label={!isMobile ? courses_total_with_unit : ""}
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={direction === "rtl"
                    ? separateNumbers(price) + " " + price_unit
                    : (price_unit + " " + enPrice ?? separateNumbers(price) + " " + price_unit)}
                  inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {isMobile && (
                  <Typography
                    component="span"
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: "700" }}
                  >
                    {/* جمع نهایی (تومان) */}
                    {total_with_unit}
                  </Typography>
                )}
                <TextField
                  label={!isMobile ? total_with_unit : ""}
                  fullWidth
                  variant="outlined"
                  type="text"
                  value={direction === "rtl"
                    ? separateNumbers(total) + " " + price_unit
                    : (price_unit + " " + enTotal ?? separateNumbers(total) + " " + price_unit)}
                  inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={1} className={classes.footer}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {direction === "rtl" ? (
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                    }}>
                    {/* <strong>آدرس مشتری:&nbsp;</strong> */}
                    <strong>{customer_address}&nbsp;</strong>
                    {transaction?.user?.province?.province_name ?? ""}،&nbsp;
                    {transaction?.user?.city?.city_name ?? ""}،&nbsp;
                    {transaction?.user?.address ?? ""}،&nbsp;
                    {/* کدپستی: {transaction?.user?.zip_code ?? ""}، */}
                    {zipcode} {transaction?.user?.zip_code ?? ""}،&nbsp;
                    {/* همراه: {transaction?.user?.username ?? ""} */}
                    {username}
                    <Typography
                      sx={{
                        direction: "ltr",
                        unicodeBidi: "isolate", // جلوگیری از ترکیب متن با متن اطراف
                        display: "inline-block",
                      }}
                    >
                      &nbsp;&lrm;{transaction?.user?.username ?? ""}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                    }}>
                    {/* <strong>آدرس مشتری:&nbsp;</strong> */}
                    <strong>{customer_address}&nbsp;</strong>
                    {transaction?.user?.province?.province_name ?? ""},&nbsp;
                    {transaction?.user?.city?.city_name ?? ""},&nbsp;
                    {transaction?.user?.address ?? ""},&nbsp;
                    {/* کدپستی: {transaction?.user?.zip_code ?? ""}، */}
                    {zipcode} {transaction?.user?.zip_code ?? ""},&nbsp;
                    {/* همراه: {transaction?.user?.username ?? ""} */}
                    {username} {transaction?.user?.username ?? ""}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <LocationOn sx={{ marginRight: "20px" }} />
                  {direction === "rtl" ? settings?.address : settings?.en_address}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <Email sx={{ marginRight: "20px" }} />
                  {settings?.email1 + " | " + settings?.email2 ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <Instagram sx={{ marginRight: "20px" }} />
                  {settings?.instagram ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <LinkedIn sx={{ marginRight: "20px" }} />
                  {settings?.linkedin ?? ""}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <MobileFriendly sx={{ marginRight: "20px" }} />
                  <Typography
                    sx={{
                      direction: "ltr",
                      unicodeBidi: "isolate", // جلوگیری از ترکیب متن با متن اطراف
                      display: "inline-block",
                    }}
                  >
                    &lrm;{settings?.phone1 + " | " + settings?.phone2 ?? ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginBottom: "20px" }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                  }}>
                  <Phone sx={{ marginRight: "20px" }} />
                  <Typography
                    sx={{
                      direction: "ltr",
                      unicodeBidi: "isolate", // جلوگیری از ترکیب متن با متن اطراف
                      display: "inline-block",
                    }}
                  >
                    &lrm;{settings?.tel ?? ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Box>
    </>
  );
};

export default ShowTransactions;
