import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useServices from './../utils/useServices';
import SettingsContext from "./SettingsContext";

const EventsContext = createContext();
EventsContext.displayName = "EventsContext";

export default EventsContext;

export const EventsContextProvider = (props) => {
    const { getMethod, postMethodByPageId } = useServices();
    const { settings } = useContext(SettingsContext);
    const location = useLocation();
    const baseImageUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/events/`;
    // const baseImageUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/events/`;
    const [events, setEvents] = useState([]);
    const [eventLoading, setEventLoading] = useState(true);
    const [event, setEvent] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [eventByIdloading, setEventByIdloading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(6);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        getEvents();
        // eslint-disable-next-line
    }, [page]);

    async function getEvents() {
        setLoading(true);

        if (location.pathname === "/") {
            setPage(1);
        }

        try {
            const obj = {
                page: page,
                rows: settings?.event_rows ?? rows,
            };

            const response = await postMethodByPageId("/getEvents", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setEvents(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    async function getEventById(eventId) {
        setEventByIdloading(true);

        try {
            const response = await getMethod("/events/" + eventId);
            const data = await response?.data;

            if (response?.status === 200) {
                setEvent(data);
            }
        } catch (ex) {

        }

        setEventByIdloading(false);
    }

    const contextData = {
        getEvents,
        getEventById,
        baseImageUrl,
        events, setEvents,
        eventLoading, setEventLoading,
        eventByIdloading, setEventByIdloading,
        event, setEvent,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <EventsContext.Provider
                value={contextData}
            >
                {props.children}
            </EventsContext.Provider>
        </>
    );
}
