import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useServices from "../../utils/useServices";
import { useTranslation } from "react-i18next";
import TransactionsContext from "../../context/TransactionsContext";
import { useDialogContext } from "../../context/DialogContext";
import SettingsContext from "../../context/SettingsContext";
import { Button, Grid } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Backpack, Delete, ReceiptLong, Send, CreditScore } from "@mui/icons-material";
import DeleteTransactions from "./DeleteTransactions";
import Packing from "./Packing";
import Sending from "./Sending";
import CreateReceipt from './../receipt/CreateReceipt';

const TransactionsTable = () => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        columns_row,
        columns_buyer,
        columns_payment_receipt,
        columns_order_id,
        columns_created_at,
        successful,
        unsuccessful,
        columns_active,
        complete,
        unfinished,
        columns_packing,
        columns_sending,
        customer_sale_invoice_button,
        instructor_sale_invoice_button,
        package_complete_button,
        package_send_complete_button,
        instructor_bank_receipt_title,
        bank_receipt_register_button,
        delete_button
    } = t("lngTransactionsTable");
    const { putMethod } = useServices();
    const navigate = useNavigate();
    const {
        getTransactions,
        transactions,
        selectedRow, setSelectedRow,
        loading,
        setPage,
        page,
        setRows,
        rows,
        from,
        to,
        total
    } = useContext(TransactionsContext);
    const { handleOpenDialog } = useDialogContext();
    const { getCounts } = useContext(SettingsContext);

    useEffect(() => {
        getTransactions();
        // eslint-disable-next-line
    }, [rows, total, page]);

    useEffect(() => {
        async function updateIsReadTransaction() {
            if (selectedRow?.id !== undefined && selectedRow?.is_read === 0) {
                try {
                    await putMethod(
                        "/update_transaction_count",
                        selectedRow?.id,
                    );
                    getCounts();
                } catch (ex) {

                }
            }
        }

        updateIsReadTransaction();
        // eslint-disable-next-line
    }, [selectedRow?.id]);

    const columns = [
        {
            field: "id",
            // headerName: "ردیف",
            headerName: columns_row,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "full_name",
            valueGetter: (params) => direction === "rtl"
                ? params.row?.user?.full_name
                : (params.row?.user?.en_full_name ?? params.row?.user?.full_name),
            // headerName: "خریدار",
            headerName: columns_buyer,
            width: 200,
        },
        {
            field: "payment_receipt",
            // headerName: "رسید پرداخت",
            headerName: columns_payment_receipt,
            width: 200,
        },
        {
            field: "order_id",
            // headerName: "شماره سفارش",
            headerName: columns_order_id,
            width: 200,
        },
        {
            field: "created_at",
            // headerName: "تاریخ ثبت سفارش",
            headerName: columns_created_at,
            width: 150,
        },
        {
            field: "active",
            valueGetter: (params) => params.row.active === 1 ? successful : unsuccessful,
            // headerName: "تراکنش",
            headerName: columns_active,
            width: 140,
        },
        {
            field: "packing",
            valueGetter: (params) => params.row.packing === 1 ? complete : unfinished,
            // headerName: "بسته‌بندی",
            headerName: columns_packing,
            width: 140,
        },
        {
            field: "sending",
            valueGetter: (params) => params.row.sending === 1 ? complete : unfinished,
            // headerName: "ارسال بسته",
            headerName: columns_sending,
            width: 140,
        },
    ];

    return (
        <>
            <Grid container spacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Button
                        fullWidth
                        onClick={() => navigate(`/transactions/show/${selectedRow?.id}`)}
                        variant="outlined"
                        startIcon={<ReceiptLong />}
                        disabled={!selectedRow}
                    >
                        {/* فاکتور فروش مشتری */}
                        {customer_sale_invoice_button}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Button
                        fullWidth
                        onClick={() => navigate(`/transactions/instructors-invoices/${selectedRow?.id}`)}
                        variant="outlined"
                        startIcon={<ReceiptLong />}
                        disabled={!selectedRow || selectedRow?.active === 0}
                    >
                        {/* فاکتور فروش استاد */}
                        {instructor_sale_invoice_button}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Button
                        fullWidth
                        onClick={() =>
                            handleOpenDialog(
                                "sm", // xs sm md lg xl
                                "",
                                <Packing
                                    params={selectedRow}
                                    getTransactions={getTransactions}
                                />
                            )
                        }
                        variant="outlined"
                        startIcon={<Backpack />}
                        disabled={!selectedRow || selectedRow?.active === 0 || selectedRow?.packing === 1}
                    >
                        {/* تکمیل بسته‌بندی */}
                        {package_complete_button}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Button
                        fullWidth
                        onClick={() =>
                            handleOpenDialog(
                                "sm", // xs sm md lg xl
                                "",
                                <Sending
                                    params={selectedRow}
                                    getTransactions={getTransactions}
                                />
                            )
                        }
                        variant="outlined"
                        startIcon={<Send />}
                        disabled={!selectedRow || selectedRow?.active === 0 || selectedRow?.sending === 1}
                    >
                        {/* تکمیل ارسال بسته */}
                        {package_send_complete_button}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Button
                        fullWidth
                        onClick={() =>
                            handleOpenDialog(
                                "sm", // xs sm md lg xl
                                // "رسید بانکی استاد",
                                instructor_bank_receipt_title,
                                <CreateReceipt
                                    params={selectedRow}
                                />
                            )
                        }
                        variant="outlined"
                        startIcon={<CreditScore />}
                        disabled={!selectedRow || selectedRow?.active === 0}
                    >
                        {/* ثبت رسید بانکی */}
                        {bank_receipt_register_button}
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Button
                        fullWidth
                        onClick={() =>
                            handleOpenDialog(
                                "sm", // xs sm md lg xl
                                "",
                                <DeleteTransactions
                                    params={selectedRow}
                                    getTransactions={getTransactions}
                                />
                            )
                        }
                        variant="outlined"
                        startIcon={<Delete />}
                        disabled={!selectedRow}
                    >
                        {/* حذف */}
                        {delete_button}
                    </Button>
                </Grid>
            </Grid>
            <CustomDataGrid
                loading={loading}
                myData={transactions}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default TransactionsTable;
