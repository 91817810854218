import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tokens } from "../../theme";
import { useDialogContext } from "../../context/DialogContext";
import {
    Box,
    IconButton,
    useTheme,
    Menu,
    MenuItem,
    Tooltip,
    ListItemIcon,
    Divider,
} from "@mui/material";
import { MoreVert, Delete, Edit, Add, Settings } from "@mui/icons-material";
import DeleteSeason from './DeleteSeason';
import EditSeason from './EditSeason';
import CreateEpisode from "../episodes/CreateEpisode";

const AdminCategoriesMenu = (props) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        settings,
        mnu_item_season_edit,
        mnu_item_season_edit_text,
        mnu_item_season_delete_text,
        add_season_title,
        add_season_button,
        season_management_button
    } = t("lngAdminSessionsMenu");
    const navigate = useNavigate();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const { handleOpenDialog } = useDialogContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const handleClose = () => { setAnchorEl(null); };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title={settings}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'seasons-settings-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreVert sx={{ color: mode === "light" ? colors.black : colors.white }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="seasons-settings-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    horizontal: direction === "rtl" ? "left" : "right",
                    vertical: "top",
                }}
                anchorOrigin={{
                    horizontal: direction === "rtl" ? "left" : "right",
                    vertical: 'bottom',
                }}
            >
                <MenuItem onClick={() =>
                    handleOpenDialog(
                        "sm", // xs sm md lg xl
                        // "ویرایش فصل",
                        mnu_item_season_edit,
                        <EditSeason
                            season={props?.season}
                            getSeasonsByCourseId={props?.getSeasonsByCourseId}
                        />
                    )
                }>
                    <ListItemIcon>
                        <Edit fontSize="medium"
                            sx={{
                                color: mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    {/* ویرایش */}
                    {mnu_item_season_edit_text}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() =>
                    handleOpenDialog(
                        "sm", // xs sm md lg xl
                        "",
                        <DeleteSeason
                            params={props?.season}
                        />
                    )
                }>
                    <ListItemIcon>
                        <Delete fontSize="medium"
                            sx={{
                                color: mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    {/* حذف */}
                    {mnu_item_season_delete_text}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() =>
                    handleOpenDialog(
                        "sm", // xs sm md lg xl
                        // "افزودن بخش",
                        add_season_title,
                        <CreateEpisode
                            season={props?.season}
                        />
                    )
                }>
                    <ListItemIcon>
                        <Add fontSize="medium"
                            sx={{
                                color: mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    {/* افزودن بخش‌های فصل */}
                    {add_season_button}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() =>
                    navigate(`/admin-episodes/${props?.season?.id}`)
                }>
                    <ListItemIcon>
                        <Settings fontSize="medium"
                            sx={{
                                color: theme.palette.mode === "light" ?
                                    colors.blueAccent[500]
                                    :
                                    colors.orangeAccent[500]
                            }} />
                    </ListItemIcon>
                    {/* مدیریت بخش‌ها */}
                    {season_management_button}
                </MenuItem>
            </Menu>
        </React.Fragment >
    )
}

export default AdminCategoriesMenu;
