import { createContext, useContext, useState } from "react";
import useServices from './../utils/useServices';
import SettingsContext from "./SettingsContext";

const UsersContext = createContext();
UsersContext.displayName = "UsersContext";

export default UsersContext;

export const UsersContextProvider = (props) => {
    const { postMethodByPageId, getMethod } = useServices();
    const { settings } = useContext(SettingsContext);
    const baseProfileUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/profiles/`;
    const baseInstructorUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/instructors/`;
    // const baseProfileUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/profiles/`;
    // const baseInstructorUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/instructors/`;
    const [users, setUsers] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [userCourses, setUserCourses] = useState([]);
    const [isInstructor, setIsInstructor] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(12);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getUsers() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: settings?.user_rows ?? rows,
            };

            const response = await postMethodByPageId("/users", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setUsers(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    async function getUserById(userId) {
        setUserLoading(true);

        try {
            const response = await getMethod("/user/" + userId);
            const data = await response?.data;

            if (response?.status === 200) {
                setUser(data?.user);
                setUserCourses(data?.courses);
                data?.courses === null ? setIsInstructor(false) : setIsInstructor(true);
            }
        } catch (ex) {

        }

        setUserLoading(false);
    }

    const contextData = {
        getUsers,
        getUserById,
        baseProfileUrl,
        baseInstructorUrl,
        users, setUsers,
        userLoading, setUserLoading,
        user, setUser,
        userCourses, setUserCourses,
        isInstructor, setIsInstructor,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <UsersContext.Provider
                value={contextData}
            >
                {props.children}
            </UsersContext.Provider>
        </>
    );
}
