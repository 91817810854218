import { useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import {
  TextField,
  DialogContent,
  DialogActions,
  Stack,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const CreateFaq = (props) => {
  const { t } = useTranslation();
  const {
    faq_registered,
    question_required,
    en_question_required,
    answer_required,
    en_answer_required,
    question_input,
    en_question_input,
    answer_input,
    en_answer_input,
    cancel,
    save
  } = t("lngCreateFaq");
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const { postMethod } = useServices();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [question, setQuestion] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [enQuestion, setEnQuestion] = useState("");
  const [enQuestionError, setEnQuestionError] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [enAnswer, setEnAnswer] = useState("");
  const [enAnswerError, setEnAnswerError] = useState("");

  const handleStore = async () => {
    setLoadingStoreButton(false);

    try {
      const obj = {
        question: question,
        en_question: enQuestion,
        answer: answer,
        en_answer: enAnswer,
      };
      await postMethod("/faqs", obj);
      props?.getFaqs();
      handleOpenSnackbar(
        "bottom",
        "left",
        "success",
        // "سوال و جواب با موفقیت ثبت شد"
        faq_registered
      );
      resetDialogItems();
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setQuestionError(ex.response.data.errors.question);
        setEnQuestionError(ex.response.data.errors.en_question);
        setAnswerError(ex.response.data.errors.answer);
        setEnAnswerError(ex.response.data.errors.en_answer);
      }
    }

    setLoadingStoreButton(true);
  };

  const resetDialogItems = () => {
    handleCloseDialog();
    setQuestion("");
    setQuestionError("");
    setEnQuestion("");
    setEnQuestionError("");
    setAnswer("");
    setAnswerError("");
    setEnAnswer("");
    setEnAnswerError("");
  };

  const handleQuestionChange = (e) => {
    const currentValue = e.target.value;
    setQuestion(currentValue);
    if (currentValue?.length === 0) {
      // setQuestionError("تکمیل گزینه سوال الزامی است");
      setQuestionError(question_required);
    } else {
      setQuestionError("");
    }
    // if (!validateEmail(currentValue)) {
    //   setEmailError("Please enter valid email");
    // } else {
    //   setEmailError("");
    // }
  };

  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   return emailRegex.test(email);
  // };

  const handleEnQuestionChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnQuestion(currentValue);
    if (currentValue?.length === 0) {
      // setEnQuestionError("تکمیل گزینه سوال الزامی است");
      setEnQuestionError(en_question_required);
    } else {
      setEnQuestionError("");
    }
  };

  const handleAnswerChange = (e) => {
    const currentValue = e.target.value;
    setAnswer(currentValue);
    if (currentValue?.length === 0) {
      // setAnswerError("تکمیل گزینه جواب الزامی است");
      setAnswerError(answer_required);
    } else {
      setAnswerError("");
    }
  };

  const handleEnAnswerChange = (e) => {
    const currentValue = e.target.value;
    const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
    if (!englishLettersOnly.test(currentValue)) {
      return;
    }
    setEnAnswer(currentValue);
    if (currentValue?.length === 0) {
      // setEnAnswerError("تکمیل گزینه جواب الزامی است");
      setEnAnswerError(en_answer_required);
    } else {
      setEnAnswerError("");
    }
  };

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={question ?? ""}
            onChange={handleQuestionChange}
            autoComplete="off"
            // label="سوال"
            label={question_input}
            multiline
            rows={2}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "justify" },
              maxLength: 200
            }}
            error={Boolean(questionError)}
            helperText={
              questionError ? questionError : `${question?.length ?? 0}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enQuestion ?? ""}
            onChange={handleEnQuestionChange}
            autoComplete="off"
            // label="سوال"
            label={en_question_input}
            multiline
            rows={2}
            required
            inputProps={{
              style: { direction: "ltr", textAlign: "justify" },
              maxLength: 200
            }}
            error={Boolean(enQuestionError)}
            helperText={
              enQuestionError ? enQuestionError : `${enQuestion?.length ?? 0}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={answer ?? ""}
            onChange={handleAnswerChange}
            autoComplete="off"
            // label="جواب"
            label={answer_input}
            multiline
            rows={3}
            required
            inputProps={{
              style: { direction: "rtl", textAlign: "justify" },
              maxLength: 300
            }}
            error={Boolean(answerError)}
            helperText={answerError ? answerError : `${answer?.length ?? 0}/300`}
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={enAnswer ?? ""}
            onChange={handleEnAnswerChange}
            autoComplete="off"
            // label="جواب"
            label={en_answer_input}
            multiline
            rows={3}
            required
            inputProps={{
              style: { direction: "ltr", textAlign: "justify" },
              maxLength: 300
            }}
            error={Boolean(enAnswerError)}
            helperText={enAnswerError ? enAnswerError : `${enAnswer?.length ?? 0}/300`}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={resetDialogItems}
        >
          {/* انصراف */}
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={!question || !enQuestion || !answer || !enAnswer}
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          {/* ذخیره */}
          {save}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateFaq;
