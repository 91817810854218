import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import ChangePasswordPost from "./ChangePasswordPost";

const ChangePassword = () => {
  const { t } = useTranslation();
  const {
    title,
    subtitle
  } = t("lngChangePasswordIndex");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <ChangePasswordPost />
      </Box>
    </>
  );
};

export default ChangePassword;
