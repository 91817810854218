import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import ErrorPage from "../../components/ErrorPage";

const boxStyle = (theme) => ({
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%"
});

const TransactionSuccess = () => {
  const { t } = useTranslation();
  const {
    header_title,
    go_to_library
  } = t("lngTransactionSuccess");
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const storedMessage = queryParams.get('message');
    if (storedMessage) {
      setMessage(storedMessage);
    }
    // eslint-disable-next-line
  }, [location.search]);

  return (
    <Box
      sx={{
        height: "100vh",
      }}
    >
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ErrorPage title={header_title} subtitle={message} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => navigate("/invoices")}>
            {/* برو به کتابخانه من */}
            {go_to_library}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionSuccess;
