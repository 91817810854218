import { useContext } from "react";
import useAxios from "./useAxios";
import ProgressBarContext from "../context/ProgressBarContext";

const baseURL = `${process.env.REACT_APP_DOMAIN_URL}api/v1`;
// const baseURL = `${process.env.REACT_APP_LOCALHOST_URL}api/v1`;

const useServices = () => {
    const http = useAxios();
    const { setProgress } = useContext(ProgressBarContext);

    function wait(duration) {
        return new Promise(resolve => setTimeout(resolve, duration));
    }

    async function getMethod(resourceName) {
        try {
            const response = await http.get(baseURL + resourceName);
            return response;
        } catch (error) {
            console.error("Error in getMethod:", error);
            throw error;
        }
    }

    async function postMethodByPageId(resourceName, obj) {
        try {
            const response = await http.post(baseURL + resourceName + "?page=" + obj.page, obj);
            return response;
        } catch (error) {
            console.error("Error in postMethodByPageId:", error);
            throw error;
        }
    }

    async function postMethod(resourceName, obj) {
        try {
            const response = await http.post(baseURL + resourceName, obj);
            return response;
        } catch (error) {
            console.error("Error in postMethod:", error);
            throw error;
        }
    }

    async function uploadPostMethod(resourceName, obj) {
        try {
            const response = await http.post(baseURL + resourceName, obj, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                    const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(percentCompleted);
                    if (percentCompleted === 100) {
                        setProgress(0);
                    }
                }
            });
            return response;
        } catch (error) {
            console.error("Error in uploadPostMethod:", error);
            throw error;
        }
    }

    async function putMethod(resourceName, id, obj) {
        try {
            const response = await http.put(baseURL + resourceName + "/" + id, obj);
            return response;
        } catch (error) {
            console.error("Error in putMethod:", error);
            throw error;
        }
    }

    async function deleteMethod(resourceName, id) {
        try {
            const response = await http.delete(baseURL + resourceName + "/" + id);
            return response;
        } catch (error) {
            console.error("Error in deleteMethod:", error);
            throw error;
        }
    }

    return {
        wait,
        getMethod,
        postMethodByPageId,
        postMethod,
        uploadPostMethod,
        putMethod,
        deleteMethod
    };
}

export default useServices;
