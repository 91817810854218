import { useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
    Box,
    Fade,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Tooltip,
    styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";
import { persian2english } from "../../components/ConvertDigits";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    submitButton: {
        float: "right"
    },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 1500 }} {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        maxWidth: 400,
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        marginTop: "3px !important",
        marginBottom: "3px !important",
    },
}));

const ChangePasswordPost = () => {
    const { t } = useTranslation();
    const {
        password_updated,
        user_deleted,
        current_password_required,
        new_password_required,
        confirm_password_required,
        current_password_input,
        current_password_placeholder,
        password_eight_character,
        password_capital_letter,
        password_lowercase_letter,
        password_number,
        password_spacing,
        new_password_input,
        new_password_placeholder,
        confirm_password_input,
        confirm_password_placeholder,
        registration
    } = t("lngChangePasswordPost");
    const { postMethod } = useServices();
    const { handleOpenSnackbar } = useSnackbarContext();
    const classes = useStyles();
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleShowNewPassword = () => setShowNewPassword((showNewPass) => !showNewPass);
    const handleShowConfirmPassword = () => setShowConfirmPassword((showConfirmPass) => !showConfirmPass);

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
            };

            await postMethod(
                "/password/update",
                obj
            );

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "رمز عبور با موفقیت تغییر کرد"
                password_updated
            );
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "کاربر قبلاً حذف شده است"
                    user_deleted
                );
            }
            if (ex.response && ex.response.status === 422) {
                setCurrentPasswordError(ex.response.data.errors.current_password);
                setNewPasswordError(ex.response.data.errors.new_password);
                setConfirmPasswordError(ex.response.data.errors.confirm_password);
            }
        }

        setLoadingUpdateButton(true);
    };

    const handleCurrentPasswordChange = (e) => {
        const currentValue = e.target.value;
        setCurrentPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            // setCurrentPasswordError("تکمیل گزینه رمز فعلی الزامی است");
            setCurrentPasswordError(current_password_required);
        } else {
            setCurrentPasswordError("");
        }
    };

    const handleNewPasswordChange = (e) => {
        const currentValue = e.target.value;
        setNewPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            // setNewPasswordError("تکمیل گزینه رمز جدید الزامی است");
            setNewPasswordError(new_password_required);
        } else {
            setNewPasswordError("");
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const currentValue = e.target.value;
        setConfirmPassword(persian2english(currentValue));
        if (currentValue?.length === 0) {
            // setConfirmPasswordError("تکمیل گزینه تکرار رمز جدید الزامی است");
            setConfirmPasswordError(confirm_password_required);
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleFocus = () => {
        setTooltipOpen(true);
    };

    const handleBlur = () => {
        setTooltipOpen(false);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Stack direction="column" spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                value={currentPassword ?? ""}
                                type="text"
                                onChange={handleCurrentPasswordChange}
                                required
                                autoComplete="off"
                                fullWidth
                                label={current_password_input}
                                placeholder={current_password_placeholder}
                                variant="outlined"
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: "center" },
                                }}
                                error={Boolean(currentPasswordError)}
                                helperText={currentPasswordError}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CustomWidthTooltip
                                title={
                                    <Box>
                                        <Box>
                                            {/* رمز عبور باید حداقل هشت کاراکتر باشد. */}
                                            {password_eight_character}
                                        </Box>
                                        <Box>
                                            {/* رمز عبور باید حداقل شامل یک حرف بزرگ باشد. */}
                                            {password_capital_letter}
                                        </Box>
                                        <Box>
                                            {/* رمز عبور باید حداقل شامل یک حرف کوچک باشد. */}
                                            {password_lowercase_letter}
                                        </Box>
                                        <Box>
                                            {/* رمز عبور باید حداقل شامل یک عدد باشد. */}
                                            {password_number}
                                        </Box>
                                        <Box>
                                            {/* رمز عبور نباید شامل فاصله باشد. */}
                                            {password_spacing}
                                        </Box>
                                    </Box>
                                }
                                open={tooltipOpen}
                                placement="top-end"
                            >
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    error={Boolean(newPasswordError)}
                                    required
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                >
                                    <InputLabel htmlFor="newPassword">
                                        {/* رمز جدید */}
                                        {new_password_input}
                                    </InputLabel>
                                    <OutlinedInput
                                        value={newPassword ?? ""}
                                        onChange={handleNewPasswordChange}
                                        autoComplete="off"
                                        // placeholder="رمز جدید را وارد کنید"
                                        placeholder={new_password_placeholder}
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        type={showNewPassword ? "text" : "password"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle new password visibility"
                                                    onClick={handleShowNewPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label={new_password_input}
                                    />
                                    {newPasswordError && <FormHelperText>{newPasswordError}</FormHelperText>}
                                </FormControl>
                            </CustomWidthTooltip>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl fullWidth variant="outlined" error={Boolean(confirmPasswordError)} required>
                                <InputLabel htmlFor="confirmPassword">
                                    {/* تکرار رمز جدید */}
                                    {confirm_password_input}
                                </InputLabel>
                                <OutlinedInput
                                    value={confirmPassword ?? ""}
                                    onChange={handleConfirmPasswordChange}
                                    autoComplete="off"
                                    // placeholder="تکرار رمز جدید را وارد کنید"
                                    placeholder={confirm_password_placeholder}
                                    inputProps={{
                                        min: 0,
                                        style: { textAlign: "center" },
                                    }}
                                    type={showConfirmPassword ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password confirm visibility"
                                                onClick={handleShowConfirmPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={confirm_password_input}
                                />
                                {confirmPasswordError && <FormHelperText>{confirmPasswordError}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LoadingButton
                                variant="contained"
                                size="large"
                                fullWidth
                                loading={!loadingUpdateButton}
                                disabled={!currentPassword || !newPassword || !confirmPassword}
                                onClick={handleUpdate}
                                className={classes.submitButton}
                            >
                                {/* ثبت */}
                                {registration}
                            </LoadingButton>
                        </Grid>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                </Grid>
            </Grid>
        </>
    );
};

export default ChangePasswordPost;
