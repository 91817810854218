import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useServices from './../utils/useServices';
import AuthContext from "./AuthContext";

const TransactionsContext = createContext();
TransactionsContext.displayName = "TransactionsContext";

export default TransactionsContext;

export const TransactionsContextProvider = (props) => {
    const navigate = useNavigate();
    const { getMethod, postMethodByPageId } = useServices();
    const { user } = useContext(AuthContext);
    const [transactions, setTransactions] = useState([]);
    const [transaction, setTransaction] = useState([]);
    const [transactionByIdloading, setTransactionByIdloading] = useState(true);
    const [userTransactions, setUserTransactions] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getTransactions() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/admin/transactions", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                data?.data?.forEach((item) => {
                    item.created_at = new Date(item.created_at).toLocaleDateString('fa-IR');
                });
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setTransactions(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    async function getTransactionById(transactionId) {
        setTransactionByIdloading(true);

        if (user?.id === userTransactions[0]?.user_id || user?.role?.role_key === "admin") {
            try {
                const response = await getMethod("/transactions/" + transactionId);
                const data = await response?.data;

                if (response?.status === 200) {
                    data.created_at = new Date(data.created_at).toLocaleDateString('fa-IR');
                    setTransaction(data);
                }
            } catch (ex) {

            }
        } else {
            navigate("/error-403");
        }

        setTransactionByIdloading(false);
    }

    async function getUserTransactions() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/user_transactions", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                data?.data?.forEach((item) => {
                    item.created_at = new Date(item.created_at).toLocaleDateString('fa-IR');
                    item.is_read = "0";
                });
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setUserTransactions(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getTransactions,
        getTransactionById,
        getUserTransactions,
        transactions, setTransactions,
        transaction, setTransaction,
        userTransactions, setUserTransactions,
        transactionByIdloading, setTransactionByIdloading,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <TransactionsContext.Provider
                value={contextData}
            >
                {props.children}
            </TransactionsContext.Provider>
        </>
    );
}
