import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import PrivacyPost from './PrivacyPost';

const Privacy = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngPrivacyIndex");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <PrivacyPost />
        </Fade>
      </Box>
    </>
  );
};

export default Privacy;

