import {
    AutoStories,
    EventOutlined,
    CastForEducationOutlined,
    CollectionsOutlined,
    HelpOutlineOutlined,
    PhoneEnabledOutlined,
    VerifiedUserOutlined,
    AccountCircle,
    LoginOutlined,
    AppRegistrationOutlined,
    LockResetOutlined,
    ShoppingCart,
    TheatersOutlined,
    CreditCardOutlined,
    ManageAccounts,
    PeopleAltOutlined,
    CategoryOutlined,
    SecurityOutlined,
    AccountCircleOutlined,
    Password,
    SendOutlined,
    InboxOutlined,
    Settings,
    Chat,
    SettingsOutlined,
    ReceiptLongOutlined,
    GridViewOutlined,
    HdrStrongOutlined,
    CreditScoreOutlined,
    TagOutlined,
} from '@mui/icons-material';

export const pages = [
    {
        "id": 1,
        "isAccess": 1,
        "icon": <AutoStories />,
        "badge": "",
        "fa_title": "صفحات",
        "en_title": "Pages",
        "path": "pages",
        "children": [
            {
                "id": 2,
                "isAccess": 1,
                "icon": <EventOutlined />,
                "badge": "",
                "fa_title": "رویدادها",
                "en_title": "Events",
                "path": "events",
                "children": []
            },
            {
                "id": 3,
                "isAccess": 1,
                "icon": <CastForEducationOutlined />,
                "badge": "",
                "fa_title": "اساتید",
                "en_title": "Instructors",
                "path": "instructors",
                "children": []
            },
            {
                "id": 4,
                "isAccess": 1,
                "icon": <TheatersOutlined />,
                "badge": "",
                "fa_title": "دوره‌ها",
                "en_title": "Courses",
                "path": "courses",
                "children": []
            },
            {
                "id": 5,
                "isAccess": 1,
                "icon": <CollectionsOutlined />,
                "badge": "",
                "fa_title": "گالری",
                "en_title": "Gallery",
                "path": "gallery",
                "children": []
            },
            {
                "id": 6,
                "isAccess": 1,
                "icon": <HelpOutlineOutlined />,
                "badge": "",
                "fa_title": "سؤالات متداول",
                "en_title": "FAQ",
                "path": "faq",
                "children": []
            },
            {
                "id": 7,
                "isAccess": 1,
                "icon": <GridViewOutlined />,
                "badge": "",
                "fa_title": "بلاگ",
                "en_title": "Blog",
                "path": "blog",
                "children": []
            },
            {
                "id": 8,
                "isAccess": 1,
                "icon": <PhoneEnabledOutlined />,
                "badge": "",
                "fa_title": "تماس با ما",
                "en_title": "Contact Us",
                "path": "contact",
                "children": []
            },
            {
                "id": 9,
                "isAccess": 1,
                "icon": <VerifiedUserOutlined />,
                "badge": "",
                "fa_title": "قوانین و مقررات",
                "en_title": "Terms & Conditions",
                "path": "privacy",
                "children": []
            }
        ]
    },
    {
        "id": 10,
        "isAccess": 1,
        "icon": <AccountCircle />,
        "badge": "",
        "fa_title": "حساب کاربری",
        "en_title": "User Account",
        "path": "account",
        "children": [
            {
                "id": 11,
                "isAccess": 1,
                "icon": <LoginOutlined />,
                "badge": "",
                "fa_title": "ورود",
                "en_title": "Log in",
                "path": "login"
            },
            {
                "id": 12,
                "isAccess": 1,
                "icon": <AppRegistrationOutlined />,
                "badge": "",
                "fa_title": "ایجاد حساب کاربری",
                "en_title": "Create an Account",
                "path": "register"
            },
            {
                "id": 13,
                "isAccess": 1,
                "icon": <LockResetOutlined />,
                "badge": "",
                "fa_title": "بازیابی رمز عبور",
                "en_title": "Password Recovery",
                "path": "recovery-password"
            }
        ]
    },
    {
        "id": 14,
        "isAccess": 1,
        "icon": <ShoppingCart />,
        "badge": "",
        "fa_title": "فروشگاه",
        "en_title": "Shop",
        "path": "shop",
        "children": [
            {
                "id": 15,
                "isAccess": 1,
                "icon": <TheatersOutlined />,
                "badge": "",
                "fa_title": "دوره‌ها",
                "en_title": "Courses",
                "path": "courses",
                "children": []
            },
            {
                "id": 16,
                "isAccess": 1,
                "icon": <CreditCardOutlined />,
                "badge": "",
                "fa_title": "کارت",
                "en_title": "Cart",
                "path": "cart",
                "children": []
            },
            {
                "id": 17,
                "isAccess": 0,
                "icon": <HdrStrongOutlined />,
                "badge": "",
                "fa_title": "وضعیت سفارش",
                "en_title": "Order Status",
                "path": "order-status",
                "children": []
            },
            {
                "id": 18,
                "isAccess": 0,
                "icon": <ReceiptLongOutlined />,
                "badge": "",
                "fa_title": "فاکتورهای مشتری",
                "en_title": "Customer Invoices",
                "path": "invoices",
                "children": []
            },
        ]
    },
    {
        "id": 19,
        "isAccess": 0,
        "icon": <Chat />,
        "badge": "",
        "fa_title": "نامه الکترونیک",
        "en_title": "E-mail",
        "path": "student",
        "children": [
            {
                "id": 20,
                "isAccess": 0,
                "icon": <InboxOutlined />,
                "badge": "",
                "fa_title": "صندوق ورودی",
                "en_title": "Inbox",
                "path": "inbox",
                "children": []
            },
            {
                "id": 21,
                "isAccess": 0,
                "icon": <SendOutlined />,
                "badge": "",
                "fa_title": "پیام‌های ارسال شده",
                "en_title": "Sent",
                "path": "sent-emails",
                "children": []
            },
        ]
    },
    {
        "id": 22,
        "isAccess": 0,
        "icon": <Settings />,
        "badge": "",
        "fa_title": "تنظیمات",
        "en_title": "Settings",
        "path": "settings",
        "children": [
            {
                "id": 23,
                "isAccess": 0,
                "icon": <AccountCircleOutlined />,
                "badge": "",
                "fa_title": "پروفایل",
                "en_title": "Profile",
                "path": "profile",
                "children": []
            },
            {
                "id": 24,
                "isAccess": 0,
                "icon": <Password />,
                "badge": "",
                "fa_title": "تغییر رمز عبور",
                "en_title": "Change Password",
                "path": "change-password",
                "children": []
            },
        ]
    },
    {
        "id": 25,
        "isAccess": 0,
        "icon": <ManageAccounts />,
        "badge": "",
        "fa_title": "مدیریت",
        "en_title": "Management",
        "path": "admin",
        "children": [
            {
                "id": 26,
                "isAccess": 0,
                "icon": <PeopleAltOutlined />,
                "badge": "",
                "fa_title": "کاربران",
                "en_title": "Users",
                "path": "admin-users",
                "children": []
            },
            {
                "id": 27,
                "isAccess": 0,
                "icon": <EventOutlined />,
                "badge": "",
                "fa_title": "رویدادها",
                "en_title": "Events",
                "path": "admin-events",
                "children": []
            },
            {
                "id": 28,
                "isAccess": 0,
                "icon": <CollectionsOutlined />,
                "badge": "",
                "fa_title": "گالری",
                "en_title": "Gallery",
                "path": "admin-gallery",
                "children": []
            },
            {
                "id": 29,
                "isAccess": 0,
                "icon": <HelpOutlineOutlined />,
                "badge": "",
                "fa_title": "سوالات متداول",
                "en_title": "FAQ",
                "path": "admin-faq",
                "children": []
            },
            {
                "id": 30,
                "isAccess": 0,
                "icon": <TagOutlined />,
                "badge": "",
                "fa_title": "تگ‌ها",
                "en_title": "Tags",
                "path": "admin-tags",
                "children": []
            },
            {
                "id": 31,
                "isAccess": 0,
                "icon": <PhoneEnabledOutlined />,
                "badge": "",
                "fa_title": "تماس با ما",
                "en_title": "Contact Us",
                "path": "admin-contact",
                "children": []
            },
            {
                "id": 32,
                "isAccess": 0,
                "icon": <VerifiedUserOutlined />,
                "badge": "",
                "fa_title": "قوانین و مقررات",
                "en_title": "Terms & Conditions",
                "path": "admin-privacy",
                "children": []
            },
            {
                "id": 33,
                "isAccess": 0,
                "icon": <CategoryOutlined />,
                "badge": "",
                "fa_title": "دسته‌بندی دوره‌ها",
                "en_title": "Category of Courses",
                "path": "admin-categories",
                "children": []
            },
            {
                "id": 34,
                "isAccess": 0,
                "icon": <TheatersOutlined />,
                "badge": "",
                "fa_title": "دوره‌ها",
                "en_title": "Courses",
                "path": "admin-courses",
                "children": []
            },
            {
                "id": 35,
                "isAccess": 0,
                "icon": <ReceiptLongOutlined />,
                "badge": "",
                "fa_title": "تراکنش‌ها",
                "en_title": "Transactions",
                "path": "admin-transactions",
                "children": []
            },
            {
                "id": 36,
                "isAccess": 0,
                "icon": <CreditScoreOutlined />,
                "badge": "",
                "fa_title": "رسید بانکی اساتید",
                "en_title": "Instructors' Bank Receipt",
                "path": "admin-receipts",
                "children": []
            },
            {
                "id": 37,
                "isAccess": 0,
                "icon": <ReceiptLongOutlined />,
                "badge": "",
                "fa_title": "فاکتورهای استاد",
                "en_title": "Instructors' Invoices",
                "path": "admin-instructors-invoices",
                "children": []
            },
            {
                "id": 38,
                "isAccess": 0,
                "icon": <SettingsOutlined />,
                "badge": "",
                "fa_title": "تنظیمات",
                "en_title": "Settings",
                "path": "admin-settings",
                "children": []
            },
            {
                "id": 39,
                "isAccess": 0,
                "icon": <SecurityOutlined />,
                "badge": "",
                "fa_title": "نقش‌ها و سطح دسترسی",
                "en_title": "Roles",
                "path": "admin-roles",
                "children": []
            },
        ]
    },
];
