import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DirectionContext from "../context/DirectionContext";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import UsersContext from "../context/UsersContext";
import { useDialogContext } from "../context/DialogContext";
import CartContext from "../context/CartContext";
import {
    Avatar,
    AppBar,
    Badge,
    Box,
    Grid,
    IconButton,
    Stack,
    Toolbar,
    Tooltip,
    styled,
    useTheme,
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
    Button
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
    Person,
    Logout,
    LightModeOutlined,
    DarkModeOutlined,
    Dashboard,
    ShoppingCartOutlined,
} from "@mui/icons-material";
import { ColorModeContext } from "../theme";
import { makeStyles } from "@mui/styles";
import { tokens } from "../theme";
import ShowAvatar from "./ShowAvatar";
import { LoadingButton } from "@mui/lab";
import Sidebar from "./Sidebar";
import LanguageSelector from "./LanguageSelector";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.mode === "light"
            ? `${tokens(theme.palette.mode).black} !important`
            : `${tokens(theme.palette.mode).white} !important`,
        "&:hover": {
            backgroundColor: "transparent !important",
        },
    },
    header: {
        backgroundColor:
            theme.palette.mode === "light"
                ? `${tokens(theme.palette.mode).white} !important`
                : `${tokens(theme.palette.mode).black} !important`,
        padding: "10px 0px 10px 0px",
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const Navbar = () => {
    const { t } = useTranslation();
    const {
        nav_dashboard,
        show_menu,
        shopping_cart,
        light,
        dark,
        nav_login_button,
        nav_register_button,
        nav_logout_button
    } = t("lngNavbar");
    const { direction } = useContext(DirectionContext);
    const colorMode = useContext(ColorModeContext);
    const { handleOpenDialog } = useDialogContext();
    const { user, logout } = useContext(AuthContext);
    const { baseProfileUrl } = useContext(UsersContext);
    const { cartItems } = useContext(CartContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const classes = useStyles();
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [loadingLogoutButton, setLoadingLogoutButton] = useState(true);

    const settings = [
        {
            id: 1,
            icon: <Person fontSize="small" />,
            title: direction === "rtl" ?
                user?.full_name !== null ? user?.full_name + " (" + user?.role?.role_name + ")" : user?.role?.role_name
                :
                user?.en_full_name !== null ? user?.en_full_name + " (" + user?.role?.en_role_name + ")" : user?.role?.en_role_name,
            path: "#",
        },
        {
            id: 2,
            icon: <Dashboard fontSize="small" />,
            title: nav_dashboard,
            path: "/dashboard",
        },
    ];

    const handleSettings = (setting) => {
        if (setting.id === 1) {
            handleOpenDialog(
                "xs",
                "",
                <ShowAvatar avatar={user?.profile} fullName={user?.full_name} />
            );
        } else {
            navigate(setting.path)
        }
        setAnchorElUser(null);
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async () => {
        setLoadingLogoutButton(false);
        await logout();
        setLoadingLogoutButton(true);
    }

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar component="nav" className={classes.header}>
                    <Box className="container">
                        <Toolbar sx={{ padding: "0 !important" }}>
                            <Grid container spacing={{ xs: 2, sm: 2, md: 4, lg: 6, xl: 6 }}>
                                <Grid item xs={4} sm={6} md={6} lg={6} xl={6}>
                                    <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
                                        <Tooltip
                                            title={show_menu}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [0, -14],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <IconButton
                                                className={classes.root}
                                                onClick={toggleDrawer("left", true)}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {user && (
                                            <React.Fragment>
                                                <Tooltip
                                                    title={user?.full_name}
                                                    slotProps={{
                                                        popper: {
                                                            modifiers: [
                                                                {
                                                                    name: "offset",
                                                                    options: {
                                                                        offset: [0, -14],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    }}
                                                >
                                                    <IconButton
                                                        className={classes.root}
                                                        onClick={handleOpenUserMenu}
                                                    >
                                                        <Stack direction="row" spacing={2}>
                                                            <StyledBadge
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                                variant={user?.role?.role_key === 'offline' ? "standard" : "dot"}
                                                            >
                                                                <Avatar
                                                                    alt={
                                                                        direction === "rtl" ?
                                                                            user?.last_name
                                                                            :
                                                                            user?.en_last_name
                                                                    }
                                                                    src={baseProfileUrl + user?.profile}
                                                                />
                                                            </StyledBadge>
                                                        </Stack>
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu
                                                    sx={{ mt: "50px" }}
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: direction === "rtl" ? "right" : "left",
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: direction === "rtl" ? "right" : "left",
                                                    }}
                                                    open={Boolean(anchorElUser)}
                                                    onClose={handleCloseUserMenu}
                                                >
                                                    {settings?.map((setting) => (
                                                        <MenuItem key={setting?.id} onClick={() => handleSettings(setting)}>
                                                            <ListItemIcon>{setting?.icon}</ListItemIcon>
                                                            <Typography
                                                                textAlign="center"
                                                            >
                                                                {setting?.title}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </React.Fragment>
                                        )}
                                        <Tooltip
                                            title={shopping_cart}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [0, -14],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <IconButton className={classes.root} onClick={() => navigate("/cart")}>
                                                <Badge badgeContent={cartItems?.length} color="error">
                                                    <ShoppingCartOutlined />
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title={mode === "light"
                                                ? dark
                                                : light}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [0, -14],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <IconButton
                                                className={classes.root}
                                                onClick={colorMode.toggleColorMode}
                                            >
                                                {mode === "dark" ? (
                                                    <LightModeOutlined />
                                                ) : (
                                                    <DarkModeOutlined />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                        <LanguageSelector />
                                    </Stack>
                                </Grid>
                                <Grid item xs={8} sm={6} md={6} lg={6} xl={6}>
                                    <Box sx={{ float: "right" }}>
                                        {user === null ? (
                                            <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }} marginTop={0.5}>
                                                <Button
                                                    onClick={() => navigate("/login")}
                                                    variant="outlined"
                                                >
                                                    {nav_login_button}
                                                </Button>
                                                <Button
                                                    onClick={() => navigate("/register")}
                                                    variant="outlined"
                                                >
                                                    {nav_register_button}
                                                </Button>
                                            </Stack>
                                        ) : (
                                            <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 1, lg: 1 }} marginTop={1.4}>
                                                <LoadingButton
                                                    loading={!loadingLogoutButton}
                                                    loadingPosition="start"
                                                    startIcon={<Logout />}
                                                    variant="outlined"
                                                    onClick={handleLogout}
                                                >
                                                    {nav_logout_button}
                                                </LoadingButton>
                                            </Stack>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </Box>
                </AppBar>
                <Sidebar state={state} toggleDrawer={toggleDrawer} />
            </Box>
        </React.Fragment >
    )
}

export default Navbar;
