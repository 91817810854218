import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import InboxContext from "../../context/InboxContext";
import SettingsContext from "../../context/SettingsContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import ComposeDialog from './../../components/ComposeDialog';
import { EditOutlined, DeleteOutline, VisibilityOutlined } from "@mui/icons-material";
import Compose from "./Compose";
import ShowInbox from "./ShowInbox";
import DeleteInbox from "./DeleteInbox";

const InboxTable = () => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        columns_row,
        columns_sender,
        columns_subject,
        columns_message,
        new_email_button,
        write_message,
        delete_message_button,
        show_message_title,
        show_message_button,
        null_message
    } = t("lngInboxTable");
    const { putMethod } = useServices();
    const {
        getInboxes,
        inboxes,
        selectedRow, setSelectedRow,
        loading,
        rows,
        setRows,
        page,
        setPage,
        from,
        to,
        total
    } = useContext(InboxContext);
    const { getCounts } = useContext(SettingsContext);
    const [maxWidthDialog, setMaxWidthDialog] = useState("sm");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogActions, setDialogActions] = useState("");

    useEffect(() => {
        getInboxes();
        // eslint-disable-next-line
    }, [rows, total, page]);

    useEffect(() => {
        async function updateIsReadInbox() {
            if (selectedRow?.id !== undefined && selectedRow?.pivot?.is_read === 0) {
                try {
                    await putMethod(
                        "/update_inbox_count",
                        selectedRow?.pivot?.id,
                    );
                    getCounts();
                    // Object.assign(selectedRow?.pivot);
                } catch (ex) {

                }
            }
        }

        updateIsReadInbox();
        // eslint-disable-next-line
    }, [selectedRow?.id]);

    const handleOpenDialog = (widthDialog, title, content) => {
        setMaxWidthDialog(widthDialog);
        setDialogTitle(title);
        setDialogActions(content);
        setOpenDialog(true);
    };

    const columns = [
        {
            field: "id",
            // headerName: "ردیف",
            headerName: columns_row,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + from,
        },
        {
            field: "sender",
            valueGetter: (params) => {
                const usernameWithoutPlus = params.row?.sender?.username?.replace(/\+/g, '') || '';
                const sender = params.row?.sender?.full_name || usernameWithoutPlus || null_message;
                const enSender = params.row?.sender?.en_full_name || usernameWithoutPlus || null_message;

                return direction === "rtl" ? sender : enSender;
            },
            // headerName: "از",
            headerName: columns_sender,
            width: 250,
            // Customize Cell
            // renderCell: (params) => {
            //   if (params.row.is_read === 0) {
            //     return (
            //       <strong>{params.value}</strong>
            //     )
            //   }
            // }
        },
        {
            field: "subject",
            // headerName: "موضوع",
            headerName: columns_subject,
            width: 300,
        },
        {
            field: "message",
            // headerName: "پیام",
            headerName: columns_message,
            width: 445,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            // "ایمیل جدید",
                            new_email_button,
                            <Compose
                                getInboxes={getInboxes}
                                setOpenDialog={setOpenDialog}
                            />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<EditOutlined />}
                >
                    {/* نوشتن پیام */}
                    {write_message}
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeleteInbox
                                params={selectedRow}
                                getInboxes={getInboxes}
                                setOpenDialog={setOpenDialog}
                            />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<DeleteOutline />}
                    disabled={!selectedRow}
                >
                    {/* حذف */}
                    {delete_message_button}
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            // "مشاهده پیام",
                            show_message_title,
                            <ShowInbox params={selectedRow} />
                        )
                    }
                    sx={{
                        borderRadius: "16px",
                        color: "#5f6368",
                        height: "56px",
                        padding: "0 24px 0 24px",
                        minWidth: "96px"
                    }}
                    variant="outlined"
                    startIcon={<VisibilityOutlined />}
                    disabled={!selectedRow}
                >
                    {/* مشاهده */}
                    {show_message_button}
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={inboxes}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
                toolbarFilter={false}
                toolbarExport={false}
            />
            <ComposeDialog
                maxWidthDialog={maxWidthDialog} // xs sm md lg xl
                setMaxWidthDialog={setMaxWidthDialog} // xs sm md lg xl
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                dialogTitle={dialogTitle}
                dialogActions={dialogActions}
            />
        </>
    );
};

export default InboxTable;
