import React from "react";
import ReactPlayer from "react-player";
import { Modal, Box, IconButton, useTheme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // برای آیکون بستن
import { tokens } from "../../theme";

const VideoModal = ({ open, handleCloseModal, videoUrl }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);

    const handleVideoReady = () => {
        // console.log("onReady callback");
    };

    const handleVideoStart = () => {
        // console.log("onStart callback");
    };

    const handleVideoPause = () => {
        // console.log("onPause callback");
    };

    const handleVideoEnded = () => {
        // console.log("onEnded callback");
    };

    const handleVideoError = () => {
        // console.log("onError callback");
    };

    return (
        <Modal open={open} onClose={handleCloseModal}>
            <Box
                sx={{
                    position: 'relative',
                    width: '80%', // عرض پلیر
                    maxWidth: '800px', // حداکثر عرض پلیر
                    margin: 'auto',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    outline: 'none',
                    borderRadius: 2,
                    boxShadow: 3,
                }}
            >
                {/* هدر ویدیو */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                        backgroundColor: mode === "light" ? colors.blueAccent[500] : colors.orangeAccent[500],
                        color: 'white',
                        borderTopLeftRadius: 2,
                        borderTopRightRadius: 2,
                        width: '100%', // عرض هدر برابر با عرض پلیر
                    }}
                >
                    <Typography variant="h6"></Typography>
                    <IconButton onClick={handleCloseModal} sx={{ color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* پلیر ویدیو */}
                <Box sx={{ position: 'relative', width: '100%', paddingTop: '56.25%' /* نسبت 16:9 */ }}>
                    <ReactPlayer
                        controls
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload' // جلوگیری از دانلود
                                }
                            }
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                        controlsList="nodownload"
                        url={videoUrl || null}
                        playing
                        width="100%" // عرض پلیر برابر با عرض Box
                        height="100%" // ارتفاع پلیر به طور کامل
                        style={{ position: 'absolute', top: 0, left: 0 }} // تنظیم موقعیت
                        onReady={handleVideoReady}
                        onStart={handleVideoStart}
                        onPause={handleVideoPause}
                        onEnded={handleVideoEnded}
                        onError={handleVideoError}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default VideoModal;
