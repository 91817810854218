import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import CartContext from "../../context/CartContext";
import CoursesContext from "./../../context/CoursesContext";
import CanContext from "../../context/CanContext";
import Card from "@mui/material/Card";
import {
    Box,
    ImageListItem,
    ImageListItemBar,
    useTheme,
    Typography,
    CardContent,
    CardActions,
    Chip,
    Skeleton,
} from "@mui/material";
import { tokens } from "../../theme";
import { separateNumbers } from "../../components/PriceFormat";
import AdminCourseMenu from './AdminCourseMenu';

function CoursesPost({ course }) {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        discount_label,
        price_unit,
        add_to_cart_button
    } = t("lngCoursesPost");
    const { addToCart } = useContext(CartContext);
    const {
        baseImageUrl,
        loading,
    } = useContext(CoursesContext);
    const { adminCourses } = useContext(CanContext);
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);

    return (
        <Box className="my-animation">
            <Card sx={{ margin: "5px" }}>
                <ImageListItem sx={{ height: "100% !important" }}>
                    {loading ? (
                        <ImageListItemBar
                            sx={{
                                background:
                                    "linear-gradient(to bottom, 70deg, rgba(223,228,245,1) 0%, rgba(191,201,235,1) 60%, rgba(10,102,194,1) 100%)",
                                pr: "10px",
                            }}
                            position="top"
                        >
                            <Skeleton variant="text" width="130px" />
                        </ImageListItemBar>
                    ) : (
                        <ImageListItemBar
                            sx={{
                                background:
                                    "linear-gradient(to bottom, 70deg, rgba(223,228,245,1) 0%, rgba(191,201,235,1) 60%, rgba(10,102,194,1) 100%)",
                                pr: "10px",
                                // "& .MuiImageListItemBar-title": { color: colors.orangeAccent[500] }, // styles for title
                                // "& .MuiImageListItemBar-subtitle": { color: "yellow" }, // styles for subtitle
                            }}
                            title={
                                course?.discount !== 0 && `%${course?.discount} ${discount_label}`
                            }
                            // subtitle="image subtitle"
                            actionIcon={
                                loading ? (
                                    <Skeleton
                                        width={40}
                                        height={40}
                                        animation="wave"
                                        variant="circular"
                                    />
                                ) : (
                                    adminCourses === 1 && (
                                        <Fade direction="left" triggerOnce={true}>
                                            <AdminCourseMenu course={course} />
                                        </Fade>
                                    )
                                )
                            }
                            position="top"
                        />
                    )}
                    <ImageListItemBar />
                    {loading ? (
                        <Skeleton
                            sx={{ height: 300 }}
                            animation="wave"
                            variant="rectangular"
                        />
                    ) : (
                        <NavLink to={`/course/show/${course?.id}`}>
                            <img
                                src={direction === "rtl"
                                    ? baseImageUrl + course?.cover
                                    : baseImageUrl + (course?.en_cover ?? course?.cover)}
                                title={direction === "rtl"
                                    ? course?.course_name
                                    : (course?.en_course_name ?? course?.course_name)}
                                loading="lazy"
                                style={{ cursor: "pointer" }}
                                alt={direction === "rtl"
                                    ? course?.course_name
                                    : (course?.en_course_name ?? course?.course_name)}
                            />
                        </NavLink>
                    )}
                    <ImageListItemBar
                        sx={{
                            pr: "10px",
                        }}
                        title={
                            loading ? (
                                <Skeleton variant="text" width="40%" />
                            ) : (
                                <Fade triggerOnce={true}>
                                    <span>
                                        {direction === "rtl" ? (
                                            <Typography>
                                                {separateNumbers(course?.total)}&nbsp;{price_unit}
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                {price_unit}&nbsp;{course?.en_total}
                                            </Typography>
                                        )}
                                    </span>
                                </Fade>
                            )
                        }
                        actionIcon={
                            loading ? (
                                <Skeleton variant="text" width="40%" />
                            ) : (
                                course?.discount !== 0 && (
                                    <Fade triggerOnce={true}>
                                        {direction === "rtl" ? (
                                            <del style={{ color: colors.white }}>{separateNumbers(course?.price)} {price_unit}</del>
                                        ) : (
                                            <del style={{ color: colors.white }}>{price_unit} {course?.en_price}</del>
                                        )}
                                    </Fade>
                                )
                            )
                        }
                    ></ImageListItemBar>
                </ImageListItem>
                <CardContent>
                    {loading ? (
                        <React.Fragment>
                            <Skeleton
                                animation="wave"
                                height={25}
                                style={{ marginBottom: 6 }}
                            />
                        </React.Fragment>
                    ) : (
                        <NavLink to={`/course/show/${course?.id}`}>
                            <Fade triggerOnce={true}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: "justify",
                                        fontWeight: "700",
                                        color: mode === "light"
                                            ? colors.black
                                            : colors.white,
                                    }}
                                >
                                    {direction === "rtl" ? (
                                        course?.course_name
                                    ) : (
                                        course?.en_course_name
                                    )}
                                </Typography>
                            </Fade>
                        </NavLink>
                    )}
                </CardContent>
                <Box display="flex" justifyContent="space-between">
                    <CardActions disableSpacing>
                        <Chip
                            label={
                                loading ? (
                                    <Skeleton variant="text" width="100%" />
                                ) : (
                                    <Fade triggerOnce={true}>
                                        {direction === "rtl" ? (
                                            <span>{course?.category?.category_name}</span>
                                        ) : (
                                            <span>{course?.category?.en_category_name}</span>
                                        )}
                                    </Fade>
                                )
                            }
                            variant="outlined"
                        />
                    </CardActions>
                    <CardActions disableSpacing>
                        <Chip
                            label={
                                loading ? (
                                    <Skeleton variant="text" width="100%" />
                                ) : (
                                    <Fade triggerOnce={true}>
                                        <span>
                                            {/* افزودن به سبد خرید */}
                                            {add_to_cart_button}
                                        </span>
                                    </Fade>
                                )
                            }
                            onClick={() => addToCart(course)}
                        />
                    </CardActions>
                </Box>
            </Card>
        </Box>
    );
}

export default CoursesPost;
