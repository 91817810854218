import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { separateNumbers } from "../components/PriceFormat";
import useServices from './../utils/useServices';
import SettingsContext from "./SettingsContext";

const CoursesContext = createContext();
CoursesContext.displayName = "CoursesContext";

export default CoursesContext;

export const CoursesContextProvider = (props) => {
    const { getMethod, postMethodByPageId } = useServices();
    const { settings } = useContext(SettingsContext);
    const location = useLocation();
    const baseImageUrl = `${process.env.REACT_APP_DOMAIN_URL}storage/courses/`;
    // const baseImageUrl = `${process.env.REACT_APP_LOCALHOST_URL}storage/courses/`;
    const [footerCoursesLoading, setFooterCoursesLoading] = useState(true);
    const [footerCourses, setFooterCourses] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [searchTitle, setSearchTitle] = useState("");
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState([]);
    const [courseByIdloading, setCourseByIdloading] = useState(true);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [coursesByTitleLoading, setCoursesByTitleLoading] = useState(true);
    const [coursesByCategoryIdLoading, setCoursesByCategoryIdLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(15);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            getCourses();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCourses();
        // eslint-disable-next-line
    }, [page]);

    async function getCourses() {
        setLoading(true);

        if (location.pathname === "/") {
            setPage(1);
        }

        try {
            const obj = {
                page: page,
                rows: settings?.course_rows ?? rows,
            };

            const response = await postMethodByPageId("/getCourses", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                data?.data?.forEach(function (course) {
                    course.price = separateNumbers(course.price);
                    course.total = separateNumbers(course.total);
                });
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setCourses(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    async function getCourseById(courseId) {
        setCourseByIdloading(true);

        try {
            const response = await getMethod("/courses/" + courseId);
            const data = await response?.data;

            if (response?.status === 200) {
                data.price = separateNumbers(data.price);
                data.total = separateNumbers(data.total);
                setCourse(data);
            }
        } catch (ex) {

        }

        setCourseByIdloading(false);
    }

    async function getCoursesByCategoryId(categoryId) {
        setCoursesByCategoryIdLoading(true);

        try {
            setSelectedCategory(categoryId);

            const obj = {
                page: 1,
                rows: rows,
                category_id: categoryId,
            };

            const response = await postMethodByPageId("/courses/category", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setCourses(data.data);
            }
        } catch (ex) {

        }

        setCoursesByCategoryIdLoading(false);
    }

    async function getCoursesByTitle() {
        setCoursesByTitleLoading(true);

        try {
            const obj = {
                page: 1,
                rows: rows,
                title: searchTitle,
            };

            const response = await postMethodByPageId("/courses/title", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setCourses(data.data);
            }
        } catch (ex) {

        }

        setCoursesByTitleLoading(false);
    }

    async function getFooterCourses() {
        setFooterCoursesLoading(true);

        try {
            const response = await getMethod("/courses");
            const data = await response?.data;

            if (response?.status === 200) {
                setFooterCourses(data);
            }
        } catch (ex) {

        }

        setFooterCoursesLoading(false);
    }

    const contextData = {
        getCourses,
        getCourseById,
        getCoursesByCategoryId,
        getCoursesByTitle,
        getFooterCourses,
        baseImageUrl,
        footerCoursesLoading, setFooterCoursesLoading,
        footerCourses, setFooterCourses,
        selectedCategory, setSelectedCategory,
        searchTitle, setSearchTitle,
        courses, setCourses,
        course, setCourse,
        courseByIdloading, setCourseByIdloading,
        selectedRow, setSelectedRow,
        loading, setLoading,
        coursesByTitleLoading, setCoursesByTitleLoading,
        coursesByCategoryIdLoading, setCoursesByCategoryIdLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <CoursesContext.Provider
                value={contextData}
            >
                {props.children}
            </CoursesContext.Provider>
        </>
    );
}
