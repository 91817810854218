import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { useDialogContext } from "../../context/DialogContext";
import RoleContext from './../../context/RoleContext';
import UsersContext from "../../context/UsersContext";
import {
    TextField,
    DialogContent,
    DialogActions,
    Grid,
    FormControlLabel,
    Switch,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    Button,
    Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const UserSettings = (props) => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir();
    const {
        settings_done,
        record_deleted,
        role_required,
        suspended_done,
        suspended_not_done,
        blocked_done,
        blocked_not_done,
        role_warning,
        role_input,
        user_suspend_input,
        user_block_input,
        cancel,
        save
    } = t("lngUserSettings");
    const { handleOpenSnackbar } = useSnackbarContext();
    const { handleCloseDialog } = useDialogContext();
    const { getAllRoles, allRoles } = useContext(RoleContext);
    const { getUsers } = useContext(UsersContext);
    const { putMethod } = useServices();
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rolesError, setRolesError] = useState("");
    const [loadingSuspend, setLoadingSuspend] = useState(false);
    const [suspend, setSuspend] = useState(false);
    const [loadingBlock, setLoadingBlock] = useState(false);
    const [block, setBlock] = useState(false);

    useEffect(() => {
        getAllRoles();
        fillRoles();
        // eslint-disable-next-line
    }, [props?.user?.id]);

    useEffect(() => {
        fillRoles();
        // eslint-disable-next-line
    }, [direction]);

    useEffect(() => {
        fillItems();
        // eslint-disable-next-line
    }, [props?.user?.is_suspended, props?.user?.is_blocked]);

    function fillRoles() {
        const list = props?.user?.roles.map(item =>
            direction === "rtl" ? item.role_name : item.en_role_name
        );
        setRoles(list);
        setRolesError("");
    }

    function fillItems() {
        setSuspend(props?.user?.is_suspended ?? false);
        setBlock(props?.user?.is_blocked ?? false);
    }

    const handleResetDialogItems = () => {
        fillRoles();
        handleCloseDialog();
    };

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                dir: direction,
                roles: roles
            };

            await putMethod(
                "/user/roles/update",
                props?.user?.id,
                obj
            );

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "تنظیمات با موفقیت انجام شد"
                settings_done
            );

            handleCloseDialog();
            getUsers();
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "رکورد مورد نظر قبلاً حذف شده است"
                    record_deleted
                );
            }
        }

        setLoadingUpdateButton(true);
    };

    const handleRolesChange = (event) => {
        const { target: { value } } = event;
        setRoles(
            typeof value === 'string' ? value.split(',') : value,
        );
        if (value.length === 0) {
            // setRolesError("تکمیل گزینه نقش الزامی است");
            setRolesError(role_required);
        } else {
            setRolesError("");
        }
    };

    const handleSuspend = async (e) => {
        setLoadingSuspend(true);

        const isChecked = e.target.checked;
        setSuspend(isChecked);

        try {
            const obj = {
                is_suspended: isChecked ? 1 : 0,
            };

            await putMethod(
                "/user/suspended/update",
                props?.user?.id,
                obj
            );

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "کاربر تعلیق شد"
                isChecked ? suspended_done : suspended_not_done
            );
        } catch (ex) {
            handleOpenSnackbar(
                "bottom",
                "left",
                "error",
                "An error occurred while updating suspension status"
            );
        }

        setLoadingSuspend(false);
    };

    const handleBlock = async (e) => {
        setLoadingBlock(true);

        const isChecked = e.target.checked;
        setBlock(isChecked);

        try {
            const obj = {
                is_blocked: isChecked ? 1 : 0,
            };

            await putMethod(
                "/user/blocked/update",
                props?.user?.id,
                obj
            );

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "کاربر بلاک شد"
                isChecked ? blocked_done : blocked_not_done
            );
        } catch (ex) {
            handleOpenSnackbar(
                "bottom",
                "left",
                "error",
                "An error occurred while updating block status"
            );
        }

        setLoadingBlock(false);
    };

    return (
        <>
            <DialogContent>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Alert severity="warning" sx={{ textAlign: "justify" }}>
                            {/* در صورتی که کاربر فرم پروفایل خود را تکمیل نکرده باشد امکان تغییر وضعیت نقش وجود ندارد. */}
                            {role_warning}
                        </Alert>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            value={direction === "rtl" ? props?.user?.full_name : props?.user?.en_full_name?.length > 1 ? props?.user?.en_full_name : props?.user?.full_name}
                            autoComplete="off"
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControl required fullWidth error={Boolean(rolesError)}>
                            <InputLabel id="roles-label">{role_input}</InputLabel>
                            <Select
                                labelId="roles-label"
                                id="roles-multiple-checkbox"
                                multiple
                                value={roles ?? []}
                                onChange={handleRolesChange}
                                input={<OutlinedInput
                                    // label="نقش"
                                    label={role_input}
                                />}
                                renderValue={(selected) => direction === "rtl" ? selected.join('، ') : selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {allRoles?.map((item) => (
                                    <MenuItem key={item?.id} value={direction === "rtl" ? item?.role_name : item?.en_role_name}>
                                        <Checkbox checked={roles.indexOf(direction === "rtl" ? item?.role_name : item?.en_role_name) > -1} />
                                        <ListItemText primary={direction === "rtl" ? item?.role_name : item?.en_role_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{rolesError}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleSuspend}
                                    checked={suspend}
                                    disabled={loadingSuspend}
                                />
                            }
                            // label="تعلیق کاربر"
                            label={user_suspend_input}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleBlock}
                                    checked={block}
                                    disabled={loadingBlock}
                                />
                            }
                            // label="قرار دادن کاربر در لیست سیاه"
                            label={user_block_input}
                        />
                    </Grid>
                </Grid>
            </DialogContent >
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={<CancelOutlined />}
                    onClick={handleResetDialogItems}
                >
                    {/* انصراف */}
                    {cancel}
                </Button>
                <LoadingButton
                    variant="outlined"
                    loadingPosition="start"
                    loading={!loadingUpdateButton}
                    disabled={
                        roles.length === 0 ||
                        !props?.user?.first_name ||
                        !props?.user?.last_name ||
                        !props?.user?.en_first_name ||
                        !props?.user?.en_last_name
                    }
                    startIcon={<SaveOutlined />}
                    onClick={handleUpdate}
                >
                    {/* ذخیره */}
                    {save}
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default UserSettings;
