import { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import EventsContext from "../../context/EventsContext";
import { tokens } from "../../theme";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Card,
  CardActions,
  Grid,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import { CalendarMonth, LightModeOutlined, LocationOn, Schedule } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).black} !important`
      : `${tokens(theme.palette.mode).white} !important`,
    fontWeight: "700 !important",
  },
  description: {
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).black} !important`
      : `${tokens(theme.palette.mode).white} !important`,
    fontWeight: "700 !important",
    marginTop: "50px !important",
    marginBottom: "20px !important"
  },
  text: {
    textAlign: "justify"
  },
  card: {
    padding: "30px"
  }
}));

const ShowEvents = () => {
  const { id: eventId } = useParams();
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    title,
    subtitle,
    typography_event_description
  } = t("lngShowEvents");
  const {
    baseImageUrl,
    getEventById,
    eventByIdloading,
    event
  } = useContext(EventsContext);
  const classes = useStyles();
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getEventById(eventId);
      isMounted.current = true;
    }
    // eslint-disable-next-line
  }, [eventId]);

  const convertToDay = (day, currentLang) => {
    if (currentLang === "en") {
      switch (day) {
        case "شنبه":
          return "Saturday";
        case "یکشنبه":
          return "Sunday";
        case "دوشنبه":
          return "Monday";
        case "سه‌شنبه":
          return "Tuesday";
        case "چهارشنبه":
          return "Wednesday";
        case "پنج‌شنبه":
          return "Thursday";
        case "جمعه":
          return "Friday";
        default:
          return day;
      }
    } else {
      return day;
    }
  };


  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={title} subtitle={subtitle} backToPage="/events" />
      </Box>
      <Box mb="50px" mt="50px" className="container">
        <Fade triggerOnce={true}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              {eventByIdloading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="100%"
                    sx={{ height: 50 }}
                  />
                  <Skeleton
                    sx={{
                      height: {
                        xs: 300,
                        sm: 450,
                        md: 500,
                        lg: 500,
                        xl: 500
                      }
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    variant="text"
                    width="100%"
                    sx={{ height: 50 }}
                  />
                  <Skeleton
                    sx={{ height: 300 }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ) : (
                <Fade direction="up" triggerOnce={true}>
                  <Grid container spacing={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography variant="h2" className={classes.title}>
                        {direction === "rtl" ? (
                          event?.title
                        ) : (
                          event?.en_title
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ textAlign: "center" }}>
                        <img
                          src={direction === "rtl"
                            ? baseImageUrl + event?.cover
                            : baseImageUrl + (event?.en_cover ?? event?.cover)}
                          title={direction === "rtl"
                            ? event?.title
                            : (event?.en_title ?? event?.title)}
                          loading="lazy"
                          style={{
                            cursor: "pointer",
                            borderRadius: "10px",
                          }}
                          alt={direction === "rtl"
                            ? event?.title
                            : (event?.en_title ?? event?.title)}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h2" className={classes.description}>
                      {/* شرح رویداد */}
                      {typography_event_description}
                    </Typography>
                    <Box className={classes.text}>
                      {direction === "rtl" ? (
                        event?.description
                      ) : (
                        event?.en_description
                      )}
                    </Box>
                  </Grid>
                </Fade>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Card className={classes.card}>
                <CardActions>
                  {eventByIdloading ? (
                    <Stack direction="column" spacing={2}>
                      <Skeleton variant="text" width="160px" />
                      <Skeleton variant="text" width="130px" />
                      <Skeleton variant="text" width="110px" />
                      <Skeleton variant="text" width="300px" />
                    </Stack>
                  ) : (
                    <Fade triggerOnce={true}>
                      <Grid container spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Fade direction="down" triggerOnce={true}>
                            <CalendarMonth />
                          </Fade>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {direction === "rtl" ? (
                                event?.date
                              ) : (
                                event?.date
                              )}
                            </Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Fade direction="down" triggerOnce={true}>
                            <LightModeOutlined />
                          </Fade>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {convertToDay(event?.day, i18n.language)}
                            </Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Fade direction="down" triggerOnce={true}>
                            <Schedule />
                          </Fade>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {direction === "rtl" ? (
                                event?.time
                              ) : (
                                event?.time
                              )}
                            </Typography>
                          </Fade>
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                          <Fade direction="down" triggerOnce={true}>
                            <LocationOn />
                          </Fade>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                          <Fade direction="up" triggerOnce={true}>
                            <Typography component="span">
                              {direction === "rtl" ? (
                                event?.address
                              ) : (
                                event?.en_address
                              )}
                            </Typography>
                          </Fade>
                        </Grid>
                      </Grid>
                    </Fade>
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Fade>
      </Box>
    </>
  );
};

export default ShowEvents;
