import React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";

const ContactInfo = (props) => {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                {props.title ? (
                    <Typography sx={{ fontWeight: 700 }}>{props.title}</Typography>
                ) : (
                    <Skeleton variant="text" height={20} width="50%" />
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                {props.value ? (
                    <Typography sx={{ textAlign: "justify" }}>{props.value}</Typography>
                ) : (
                    <Skeleton variant="text" height={20} width="50%" />
                )}
            </Grid>
        </Grid>
    )
}

export default ContactInfo;
