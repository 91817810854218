import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const ProvinceContext = createContext(null);
ProvinceContext.displayName = "ProvinceContext";

export default ProvinceContext;

export const ProvinceContextProvider = ({ children }) => {
    const { getMethod } = useServices();
    const [allProvinces, setAllProvinces] = useState([]);
    const [allProvincesLoading, setAllProvincesLoading] = useState(true);
    const [allProvincesStatus, setAllProvincesStatus] = useState(0);

    async function getAllProvinces() {
        setAllProvincesLoading(true);

        try {
            const response = await getMethod("/provinces");
            const data = await response?.data;

            if (response?.status === 200) {
                setAllProvinces(data);
                setAllProvincesStatus(response.status);
            }
        } catch (ex) {

        }

        setAllProvincesLoading(false);
    }

    const contextData = {
        getAllProvinces,
        allProvinces, setAllProvinces,
        allProvincesLoading, setAllProvincesLoading,
        allProvincesStatus, setAllProvincesStatus
    };

    return (
        <ProvinceContext.Provider value={contextData}>
            {children}
        </ProvinceContext.Provider>
    );
}
