import { useTranslation } from "react-i18next";
import { DialogContent, Typography, TextField, Stack, Grid } from "@mui/material";
import { DoneAll } from "@mui/icons-material";

const ShowSentEmails = ({ params }) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    typography_to,
    typography_subject,
    typography_message,
    null_message
  } = t("lngShowSentEmails");

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* به: */}
                {typography_to}
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={params?.users.map((item, index) => {
                const usernameWithoutPlus = item?.username?.replace(/\+/g, '') || '';

                const receiver = item?.full_name || usernameWithoutPlus || null_message;
                const enReceiver = item?.en_full_name || usernameWithoutPlus || null_message;

                return index === 0
                  ? (direction === "rtl" ? receiver : enReceiver)
                  : (direction === "rtl" ? receiver : enReceiver);
              }).join(direction === "rtl" ? "، " : ", ")}
              inputProps={{
                style: { textAlign: "justify" },
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* موضوع */}
                {typography_subject}
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={params.subject ?? ""}
              multiline
              rows={3}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* پیام */}
                {typography_message}
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={params.message ?? ""}
              multiline
              rows={5}
              sx={{ mb: "20px" }}
              inputProps={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ShowSentEmails;
