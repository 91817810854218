import { useState, useEffect, useContext } from "react";
import DirectionContext from "../context/DirectionContext";
import { ExpandLess } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { tokens } from "../theme";

const useStyles = makeStyles((theme) => ({
  "@keyframes myEffect": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  toTop: {
    // zIndex: 1050,
    position: "fixed !important",
    bottom: "2vh",
    backgroundColor: "transparent",
    color: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).blueAccent[500]} !important`
      : `${tokens(theme.palette.mode).orangeAccent[500]} !important`,
    "&:hover, &.Mui-focusVisible": {
      animation: `$myEffect 1.6s ${theme.transitions.easing.easeInOut}`,
    },
    borderRadius: '50%',
  },
  circleBackground: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    strokeWidth: 2,
    fill: 'none',
  },
  circleGrey: {
    stroke: 'rgba(95, 58, 252, 0.2)', // Grey circle for the background
  },
  circleProgress: {
    stroke: theme.palette.mode === "light"
      ? `${tokens(theme.palette.mode).blueAccent[500]} !important`
      : `${tokens(theme.palette.mode).orangeAccent[500]} !important`,
    transition: 'stroke-dashoffset 0.6s ease-out',
  }
}));

const BackToTop = ({ showBelow }) => {
  const direction = useContext(DirectionContext);
  const classes = useStyles();
  const [show, setShow] = useState(showBelow ? false : true);
  const [strokeOffset, setStrokeOffset] = useState(100);
  const radius = 16; // Radius of the circle
  const circumference = 2 * Math.PI * radius;

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrolledPercentage = Math.min(100, Math.max(0, (scrollPosition / windowHeight) * 100));
    setStrokeOffset(100 - scrolledPercentage);

    if (window.scrollY > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <IconButton
          size="large"
          onClick={handleClick}
          className={classes.toTop}
          style={{
            right: direction === "rtl" ? "0" : "2%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="100%"
            height="100%"
            className={classes.circleBackground}
          >
            {/* Grey background circle */}
            <circle
              cx="18"
              cy="18"
              r="16"
              className={classes.circleGrey}
              strokeDasharray={circumference}
              strokeDashoffset={0} // Static background circle
            />
            {/* Blue progress circle */}
            <circle
              cx="18"
              cy="18"
              r="16"
              className={classes.circleProgress}
              strokeDasharray={circumference}
              strokeDashoffset={strokeOffset}
            />
          </svg>
          <ExpandLess />
        </IconButton>
      )}
    </div>
  );
};

export default BackToTop;
