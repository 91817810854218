import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import CanContext from "../../context/CanContext";
import InstructorsContext from "../../context/InstructorsContext";
import UsersContext from "../../context/UsersContext";
import { tokens } from "../../theme";
import {
    Avatar,
    Box,
    Card,
    useTheme,
    Skeleton,
    CardHeader,
    CardMedia,
    CardContent,
} from "@mui/material";
import AdminInstructorsMenu from "./AdminInstructorsMenu";

function stringAvatar(name) {
    return {
        children: `${name.split(' ')[0][0]}`,
    };
}

const InstructorPost = ({ instructor }) => {
    const { i18n } = useTranslation();
    const direction = i18n.dir();
    const { baseImageUrl, loading } = useContext(InstructorsContext);
    const { baseProfileUrl } = useContext(UsersContext);
    const { adminInstructors } = useContext(CanContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box className="my-animation">
            <Card sx={{ margin: "10px" }}>
                <CardHeader
                    avatar={
                        loading ? (
                            <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                            />
                        ) : (
                            instructor?.profile ? (
                                <Fade triggerOnce={true}>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.mode === "light" ?
                                                colors.blueAccent[500]
                                                :
                                                colors.orangeAccent[500]
                                        }}
                                        src={baseProfileUrl + instructor?.profile}
                                    />
                                </Fade>
                            ) : (
                                <Fade triggerOnce={true}>
                                    <Avatar
                                        sx={{
                                            bgcolor: theme.palette.mode === "light" ?
                                                colors.blueAccent[500]
                                                :
                                                colors.orangeAccent[500]
                                        }}
                                        aria-label="recipe"
                                        {...stringAvatar(
                                            direction === "rtl" ? (
                                                instructor?.last_name
                                            ) : (
                                                instructor?.en_last_name ?? instructor?.last_name
                                            )
                                        )}
                                    />
                                </Fade>
                            )
                        )
                    }
                    action={
                        loading ? null : (
                            adminInstructors === 1 && (
                                <Fade triggerOnce={true}>
                                    <AdminInstructorsMenu instructor={instructor} />
                                </Fade>
                            )
                        )
                    }
                    title={
                        loading ? (
                            <Skeleton variant="text" height={15} width="80%" />
                        ) : (
                            <Fade triggerOnce={true}>
                                {direction === "rtl" ? (
                                    <span>{instructor?.full_name}</span>
                                ) : (
                                    <span>{instructor?.en_full_name ?? instructor?.full_name}</span>
                                )}
                            </Fade>
                        )
                    }
                    subheader={
                        loading ? (
                            <Skeleton variant="text" height={10} width="40%" />
                        ) : (
                            <Fade triggerOnce={true}>
                                {direction === "rtl" ? (
                                    <span>{instructor?.categories[0]?.category_name}</span>
                                ) : (
                                    <span>{instructor?.categories[0]?.en_category_name}</span>
                                )}
                            </Fade>
                        )
                    }
                />
                <CardContent>
                    {loading ? (
                        <Skeleton
                            sx={{ height: 350 }}
                            animation="wave"
                            variant="rectangular"
                        />
                    ) : (
                        <Link to={`/instructor/show/${instructor?.id}`}>
                            <Fade triggerOnce={true}>
                                <CardMedia
                                    component="img"
                                    className="zoom-in-animation"
                                    height="194"
                                    image={baseImageUrl + instructor?.cover}
                                    alt={direction === "rtl" ? instructor?.full_name : instructor?.en_full_name ?? instructor?.full_name}
                                />
                            </Fade>
                        </Link>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default InstructorPost
