import { createContext, useState } from "react";
import useServices from "../utils/useServices";

const InstructorsInvoicesContext = createContext();
InstructorsInvoicesContext.displayName = "InstructorsInvoicesContext";

export default InstructorsInvoicesContext;

export const InstructorsInvoicesContextProvider = (props) => {
    const { postMethod, postMethodByPageId } = useServices();
    const [instructorsInvoicesCount, setInstructorsInvoicesCount] = useState(0);
    const [instructorsInvoices, setInstructorsInvoices] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(5);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [receipts, setReceipts] = useState([]);
    const [receiptsByTransactionIdLoading, setReceiptsByTransactionIdLoading] = useState(true);

    async function getInstructorsInvoices() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/admin/instructors_invoices", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                data.data.forEach((item) => {
                    item.created_at = new Date(item.created_at).toLocaleDateString('fa-IR');
                });
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setInstructorsInvoices(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    async function getReceiptByTransactionId(transactionId) {
        setReceiptsByTransactionIdLoading(true);

        try {
            const obj = {
                transaction_id: transactionId,
            };

            const response = await postMethod("/receipts/list", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                setReceipts(data);
            }
        } catch (ex) {

        }

        setReceiptsByTransactionIdLoading(false);
    }

    const contextData = {
        getInstructorsInvoices,
        getReceiptByTransactionId,
        receipts, setReceipts,
        receiptsByTransactionIdLoading, setReceiptsByTransactionIdLoading,
        instructorsInvoicesCount, setInstructorsInvoicesCount,
        instructorsInvoices, setInstructorsInvoices,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <InstructorsInvoicesContext.Provider
                value={contextData}
            >
                {props.children}
            </InstructorsInvoicesContext.Provider>
        </>
    );
}
