import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const SectionTitle = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        sx={{
          textAlign: "center",
          width: "100vw"
        }}
      >
        <Typography
          variant="h3"
          color={colors.blueAccent[500]}
          fontWeight="bold"
          sx={{ mb: "10px" }}
          className="scale_animation"
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          color={colors.orangeAccent[500]}
          className="scale_animation"
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default SectionTitle;
