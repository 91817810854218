import { useState } from "react";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import { useDialogContext } from "../../context/DialogContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import AutocompleteSearch from "../../components/AutocompleteSearch";

const CreatePost = (props) => {
  const { t } = useTranslation();
  const {
    five_tags,
    your_question_registered,
    title_required,
    details_required,
    title_input,
    details_input,
    no_items_found,
    cancel,
    post
  } = t("lngCreatePost");
  const { postMethod } = useServices();
  const { handleOpenSnackbar } = useSnackbarContext();
  const { handleCloseDialog } = useDialogContext();
  const [loadingStoreButton, setLoadingStoreButton] = useState(true);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [details, setDetails] = useState("");
  const [detailsError, setDetailsError] = useState("");
  const [tags, setTags] = useState([]);
  const [tagsError, setTagsError] = useState("");
  const [searchTagsArray, setSearchTagsArray] = useState([]);

  const handleStore = async () => {
    setLoadingStoreButton(false);
    try {
      if (tags.length > 5) {
        handleOpenSnackbar(
          "bottom",
          "left",
          "error",
          // "شما مجاز به انتخاب بیش از 5 تگ نمی باشید"
          { five_tags }
        );
      } else {
        const tagIds = [];
        tags.forEach(function (tag) {
          tagIds.push(tag.id);
        });
        const obj = {
          title: title,
          details: details,
          tags: tagIds,
        };
        await postMethod("/blogs", obj);
        handleResetDialogItems();
        handleOpenSnackbar(
          "bottom",
          "left",
          "success",
          // "سوال شما با موفقیت ثبت شد"
          your_question_registered
        );
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 422) {
        setTitleError(ex.response.data.errors.title);
        setDetailsError(ex.response.data.errors.details);
      }
    }

    setLoadingStoreButton(true);
  };

  const handleResetDialogItems = () => {
    setTitle("");
    setTitleError("");
    setDetails("");
    setDetailsError("");
    setTags([]);
    setTagsError("");
    setSearchTagsArray([]);
    handleCloseDialog();
  };

  const handleTitleChange = (e) => {
    const currentValue = e.target.value;
    setTitle(currentValue);
    if (currentValue?.length === 0) {
      // setTitleError("تکمیل گزینه عنوان الزامی است");
      setTitleError(title_required);
    } else {
      setTitleError("");
    }
  };

  const handleDetailsChange = (e) => {
    const currentValue = e.target.value;
    setDetails(currentValue);
    if (currentValue?.length === 0) {
      // setDetailsError("تکمیل گزینه جزئیات الزامی است");
      setDetailsError(details_required);
    } else {
      setDetailsError("");
    }
  };

  const handleTagsChange = async (e) => {
    const currentValue = e.target.value;
    setTagsError("");
    const obj = {
      tag_name: currentValue,
    };
    const { data: findTags } = await postMethod("/tags/search", obj);
    if (Object.keys(findTags).length > 0) {
      const array = [];
      findTags.forEach(function (tag) {
        array.push({ id: tag.id, tag_name: tag.tag_name });
        setSearchTagsArray(array);
      });
    }
    else {
      setSearchTagsArray([]);
    }
  }

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={title ?? ""}
            onChange={handleTitleChange}
            autoComplete="off"
            // label="عنوان سوال"
            label={title_input}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 200 }}
            error={Boolean(titleError)}
            helperText={
              titleError ? titleError : `${title?.length ?? 0}/200`
            }
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            value={details ?? ""}
            onChange={handleDetailsChange}
            autoComplete="off"
            // label="جزئیات سوال"
            label={details_input}
            multiline
            rows={10}
            required
            inputProps={{ style: { textAlign: "justify" }, maxLength: 1500 }}
            error={Boolean(detailsError)}
            helperText={detailsError ? detailsError : `${details?.length ?? 0}/1500`}
          />
          <AutocompleteSearch
            options={searchTagsArray}
            freeSolo={true} // باعث می شود "موردی یافت نشد" را نمایش ندهد
            // noOptionsText="موردی یافت نشد"
            noOptionsText={no_items_found}
            forcePopupIcon={false}
            variant="outlined"
            placeholder="Tags"
            value={tags ?? []}
            setValue={setTags}
            onChange={handleTagsChange}
            error={Boolean(tagsError)}
            helperText={
              tagsError
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<CancelOutlined />}
          onClick={handleResetDialogItems}
        >
          {/* انصراف */}
          {cancel}
        </Button>
        <LoadingButton
          variant="outlined"
          loadingPosition="start"
          loading={!loadingStoreButton}
          disabled={
            !title ||
            !details
          }
          startIcon={<SaveOutlined />}
          onClick={handleStore}
        >
          {/* پست */}
          {post}
        </LoadingButton>
      </DialogActions>
    </>
  );

};

export default CreatePost;
