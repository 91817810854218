import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import ProvinceContext from "../../context/ProvinceContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import { jwtDecode } from "jwt-decode";
import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "react-avatar-edit";
import useServices from "../../utils/useServices";
import { SaveOutlined } from "@mui/icons-material";
import { persian2english } from "../../components/ConvertDigits";

const ProfilePost = () => {
    const { t } = useTranslation();
    const {
        profile_cover_changed,
        user_deleted,
        user_info_registered,
        first_name_required,
        en_first_name_required,
        last_name_required,
        en_last_name_required,
        province_required,
        city_required,
        address_required,
        zip_code_required,
        biography_required,
        profile_cover_avatar,
        avatar_cover_edit,
        address_warning,
        first_name_input,
        en_first_name_input,
        first_name_placeholder,
        en_first_name_placeholder,
        last_name_input,
        en_last_name_input,
        last_name_placeholder,
        en_last_name_placeholder,
        province_input_label,
        city_input_label,
        no_city_exist,
        address_input,
        address_input_placeholder,
        zipcode_input,
        zipcode_placeholder,
        biography_input,
        en_biography_input,
        registration_details
    } = t("lngProfilePost");
    const {
        tokenKey,
        dateKey,
        setToken,
        user,
        setUser,
        setRefreshingTokenDT,
    } = useContext(AuthContext);
    const {
        getAllProvinces,
        allProvincesloading,
        allProvinces,
        allProvincesStatus
    } = useContext(ProvinceContext);
    const { postMethod, uploadPostMethod } = useServices();
    const { handleOpenSnackbar } = useSnackbarContext();
    const isMountedProvinces = useRef(false);
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [loadingProfileButton, setLoadingProfileButton] = useState(true);
    const [preview, setPreview] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [enFirstName, setEnFirstName] = useState("");
    const [enFirstNameError, setEnFirstNameError] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [enLastName, setEnLastName] = useState("");
    const [enLastNameError, setEnLastNameError] = useState("");
    const [province, setProvince] = useState("");
    const [provinceError, setProvinceError] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");
    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [biography, setBiography] = useState("");
    const [biographyError, setBiographyError] = useState("");
    const [enBiography, setEnBiography] = useState("");
    const [enBiographyError, setEnBiographyError] = useState("");

    useEffect(() => {
        if (user?.country_id === 100) {
            if (!isMountedProvinces.current) {
                getAllProvinces();
                isMountedProvinces.current = true;
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getCitiesByProvinceId();
        // eslint-disable-next-line
    }, [province]);

    async function getCitiesByProvinceId() {
        setCitiesLoading(true);

        const obj = {
            provinceId: province,
        };
        const { data: citiesByProvinceId } = await postMethod("/province/cities", obj);
        setCities(citiesByProvinceId.cities);

        setCitiesLoading(false);
    }

    useEffect(() => {
        fillProfileItems();
        // eslint-disable-next-line
    }, []);

    function fillProfileItems() {
        setFirstName(user?.first_name);
        setFirstNameError("");
        setEnFirstName(user?.en_first_name);
        setEnFirstNameError("");
        setLastName(user?.last_name);
        setLastNameError("");
        setEnLastName(user?.en_last_name);
        setEnLastNameError("");
        setProvince(user?.province_id);
        setProvinceError("");
        setCity(user?.city_id);
        setCityError("");
        setAddress(user?.address);
        setAddressError("");
        setZipCode(user?.zip_code);
        setZipCodeError("");
        setBiography(user?.biography);
        setBiographyError("");
        setEnBiography(user?.en_biography);
        setEnBiographyError("");
    }

    const onClose = () => {
        setPreview(null);
    }

    const onCrop = view => {
        setPreview(view);
    }

    const handleProfile = async () => {
        setLoadingProfileButton(false);

        try {
            const obj = {
                profile: preview
            };

            const { data: response } = await uploadPostMethod(
                "/profile/update",
                obj
            );

            setToken(response.token);
            setUser(jwtDecode(response.token));
            setRefreshingTokenDT(response.refreshingTokenDT);
            localStorage.setItem(tokenKey, response.token);
            localStorage.setItem(dateKey, response.refreshingTokenDT);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "تصویر پروفایل تغییر کرد"
                profile_cover_changed
            );

            window.location.href = "/profile";

        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "کاربر قبلاً حذف شده است"
                    user_deleted
                );
            }
            if (ex.response && ex.response.status === 422) {
                alert("ex.response.data.errors.profile");
            }
        }

        setLoadingProfileButton(true);
    };

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                first_name: firstName,
                en_first_name: enFirstName,
                last_name: lastName,
                en_last_name: enLastName,
                province_id: province,
                city_id: city,
                address: address,
                zip_code: zipCode,
                biography: biography?.length > 0 ? biography : null,
                en_biography: enBiography?.length > 0 ? enBiography : null,
            };

            const { data: response } = await postMethod(
                "/user/update",
                obj
            );

            setToken(response.token);
            setUser(jwtDecode(response.token));
            setRefreshingTokenDT(response.refreshingTokenDT);
            localStorage.setItem(tokenKey, response.token);
            localStorage.setItem(dateKey, response.refreshingTokenDT);

            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "مشخصات کاربر با موفقیت ثبت شد"
                user_info_registered
            );
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "کاربر قبلاً حذف شده است"
                    user_deleted
                );
            }
            if (ex.response && ex.response.status === 422) {
                if (ex.response.data.errors.first_name &&
                    ex.response.data.errors.first_name.length > 1) {
                    setFirstNameError(ex.response.data.errors.first_name[0]);
                } else {
                    setFirstNameError(ex.response.data.errors.first_name);
                }
                if (ex.response.data.errors.en_first_name &&
                    ex.response.data.errors.en_first_name.length > 1) {
                    setEnFirstNameError(ex.response.data.errors.en_first_name[0]);
                } else {
                    setEnFirstNameError(ex.response.data.errors.en_first_name);
                }
                if (ex.response.data.errors.last_name &&
                    ex.response.data.errors.last_name.length > 1) {
                    setLastNameError(ex.response.data.errors.last_name[0]);
                } else {
                    setLastNameError(ex.response.data.errors.last_name);
                }
                if (ex.response.data.errors.en_last_name &&
                    ex.response.data.errors.en_last_name.length > 1) {
                    setEnLastNameError(ex.response.data.errors.en_last_name[0]);
                } else {
                    setEnLastNameError(ex.response.data.errors.en_last_name);
                }
                setAddressError(ex.response.data.errors.address);
                if (ex.response.data.errors.zip_code &&
                    ex.response.data.errors.zip_code.length > 1) {
                    setZipCodeError(ex.response.data.errors.zip_code[0]);
                } else {
                    setZipCodeError(ex.response.data.errors.zip_code);
                }
                setBiographyError(ex.response.data.errors.biography);
                setEnBiographyError(ex.response.data.errors.en_biography);
            }
        }

        setLoadingUpdateButton(true);
    };

    const handleFirstNameChange = (e) => {
        const currentValue = e.target.value;

        // جلوگیری از ورود حروف انگلیسی
        const persianLettersOnly = /^[\u0600-\u06FF\s]*$/;
        if (!persianLettersOnly.test(currentValue)) {
            return;
        }

        setFirstName(currentValue);
        if (currentValue?.length === 0) {
            // setFirstNameError("تکمیل گزینه نام الزامی است");
            setFirstNameError(first_name_required);
        } else {
            setFirstNameError("");
        }
    };

    const handleEnFirstNameChange = (e) => {
        let currentValue = e.target.value;

        // جلوگیری از ورود حروف فارسی
        const englishLettersOnly = /^[A-Za-z\s]*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }

        // تبدیل حرف اول هر کلمه به حروف بزرگ، بدون حذف فاصله بین کلمات
        currentValue = currentValue
            .split(' ') // رشته را به کلمات جدا می‌کند
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // حرف اول هر کلمه را به بزرگ تبدیل می‌کند
            .join(' '); // کلمات را با یک فاصله به هم متصل می‌کند

        setEnFirstName(currentValue);
        if (currentValue?.length === 0) {
            // setEnFirstNameError("تکمیل گزینه نام الزامی است");
            setEnFirstNameError(en_first_name_required);
        } else {
            setEnFirstNameError("");
        }
    };

    const handleLastNameChange = (e) => {
        const currentValue = e.target.value;

        // جلوگیری از ورود حروف انگلیسی
        const persianLettersOnly = /^[\u0600-\u06FF\s]*$/;
        if (!persianLettersOnly.test(currentValue)) {
            return;
        }

        setLastName(currentValue);
        if (currentValue?.length === 0) {
            // setLastNameError("تکمیل گزینه نام خانوادگی الزامی است");
            setLastNameError(last_name_required);
        } else {
            setLastNameError("");
        }
    };

    const handleEnLastNameChange = (e) => {
        let currentValue = e.target.value;

        // جلوگیری از ورود حروف فارسی
        const englishLettersOnly = /^[A-Za-z\s]*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }

        // تبدیل حرف اول هر کلمه به حروف بزرگ، بدون حذف فاصله بین کلمات
        currentValue = currentValue
            .split(' ') // رشته را به کلمات جدا می‌کند
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // حرف اول هر کلمه را به بزرگ تبدیل می‌کند
            .join(' '); // کلمات را با یک فاصله به هم متصل می‌کند

        setEnLastName(currentValue);
        if (currentValue?.length === 0) {
            // setEnLastNameError("تکمیل گزینه نام خانوادگی الزامی است");
            setEnLastNameError(en_last_name_required);
        } else {
            setEnLastNameError("");
        }
    };

    const handleProvinceChange = (e) => {
        const currentValue = e.target.value;
        setProvince(currentValue);
        if (currentValue?.length === 0) {
            // setProvinceError("تکمیل گزینه استان الزامی است");
            setProvinceError(province_required);
        } else {
            setProvinceError("");
        }
    };

    const handleCityChange = (e) => {
        const currentValue = e.target.value;
        setCity(currentValue);
        if (currentValue?.length === 0) {
            // setCityError("تکمیل گزینه شهر الزامی است");
            setCityError(city_required);
        } else {
            setCityError("");
        }
    };

    const handleAddressChange = (e) => {
        const currentValue = e.target.value;
        setAddress(currentValue);
        if (currentValue?.length === 0) {
            // setAddressError("تکمیل گزینه آدرس الزامی است");
            setAddressError(address_required);
        } else {
            setAddressError("");
        }
    };

    const handleZipCodeChange = (e) => {
        const currentValue = e.target.value;
        setZipCode(persian2english(currentValue));
        if (currentValue?.length === 0) {
            // setZipCodeError("تکمیل گزینه کد پستی الزامی است");
            setZipCodeError(zip_code_required);
        } else {
            setZipCodeError("");
        }
    };

    const handleBiographyChange = (e) => {
        const currentValue = e.target.value;
        setBiography(currentValue);
        if (currentValue?.length === 0) {
            // setBiographyError("تکمیل گزینه زندگی نامه الزامی است");
            setBiographyError(biography_required);
        } else {
            setBiographyError("");
        }
    };

    const handleEnBiographyChange = (e) => {
        const currentValue = e.target.value;
        setEnBiography(currentValue);
    };

    return (
        <>
            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container rowSpacing={1} columnSpacing={1} sx={{ marginBottom: "20px" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                            <Avatar
                                width={300}
                                height={300}
                                onCrop={onCrop}
                                onClose={onClose}
                                // label="انتخاب تصویر"
                                label={profile_cover_avatar}
                                labelStyle={{ fontFamily: "shabnam" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                            <LoadingButton
                                variant="contained"
                                size="large"
                                loading={!loadingProfileButton}
                                disabled={!preview}
                                onClick={handleProfile}
                            >
                                {/* ویرایش تصویر آواتار */}
                                {avatar_cover_edit}
                            </LoadingButton>
                            <UploadProgressBar />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Card>
                        <CardContent>
                            <Grid container rowSpacing={3} columnSpacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Alert severity="warning" sx={{ textAlign: "justify" }}>
                                        {/* دوره‌ های شما به نشانی وارد شده ارسال خواهد شد لذا در پر کردن فیلدهای زیر دقت فرمایید. مسئولیت هرگونه مغایرت در ورود اطلاعات بر عهده شما می‌ باشد. */}
                                        {address_warning}
                                    </Alert>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={firstName ?? ""}
                                        type="text"
                                        onChange={handleFirstNameChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        // label="نام"
                                        label={first_name_input}
                                        // placeholder="نام را وارد کنید"
                                        placeholder={first_name_placeholder}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        error={Boolean(firstNameError)}
                                        helperText={firstNameError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={lastName ?? ""}
                                        type="text"
                                        onChange={handleLastNameChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        // label="نام خانوادگی"
                                        label={last_name_input}
                                        // placeholder="نام خانوادگی را وارد کنید"
                                        placeholder={last_name_placeholder}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        error={Boolean(lastNameError)}
                                        helperText={lastNameError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={enFirstName ?? ""}
                                        type="text"
                                        onChange={handleEnFirstNameChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        // label="نام لاتین"
                                        label={en_first_name_input}
                                        // placeholder="نام لاتین را وارد کنید"
                                        placeholder={en_first_name_placeholder}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center", direction: "ltr" },
                                        }}
                                        error={Boolean(enFirstNameError)}
                                        helperText={enFirstNameError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={enLastName ?? ""}
                                        type="text"
                                        onChange={handleEnLastNameChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        // label="نام خانوادگی لاتین"
                                        label={en_last_name_input}
                                        // placeholder="نام خانوادگی لاتین را وارد کنید"
                                        placeholder={en_last_name_placeholder}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center", direction: "ltr" },
                                        }}
                                        error={Boolean(enLastNameError)}
                                        helperText={enLastNameError}
                                    />
                                </Grid>

                                {user?.country_id === 100 &&
                                    <>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormControl required fullWidth error={Boolean(provinceError)}>
                                                <InputLabel id="province-label">
                                                    {/* استان */}
                                                    {province_input_label}
                                                </InputLabel>
                                                {allProvincesloading ? (
                                                    <Select value="">
                                                        <MenuItem>
                                                            <CircularProgress />
                                                        </MenuItem>
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        labelId="province-label"
                                                        value={allProvincesStatus === 200 && province ? province : ""}
                                                        label={province_input_label}
                                                        onChange={handleProvinceChange}
                                                    >
                                                        {allProvinces?.map((item) => (
                                                            <MenuItem key={item?.id} value={item?.id}>
                                                                {item?.province_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                                <FormHelperText>{provinceError}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormControl required fullWidth error={Boolean(cityError)} disabled={!province}>
                                                <InputLabel id="city-label">
                                                    {/* شهر */}
                                                    {city_input_label}
                                                </InputLabel>
                                                {citiesLoading ? (
                                                    <Select value="">
                                                        <MenuItem>
                                                            <CircularProgress />
                                                        </MenuItem>
                                                    </Select>
                                                ) : (
                                                    <Select
                                                        labelId="city-label"
                                                        value={cities && city ? city : ""}
                                                        label={city_input_label}
                                                        onChange={handleCityChange}
                                                    >
                                                        {cities?.map((item, index) => (
                                                            <MenuItem key={index} value={item?.id}>
                                                                {item?.city_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                                {/* <FormHelperText sx={{ textAlign: "justify" }}>{cityError ? cityError : "چنانچه شهر مورد نظر در لیست نباشد آن را در بخش آدرس بنویسید"}</FormHelperText> */}
                                                <FormHelperText sx={{ textAlign: "justify" }}>{cityError ? cityError : no_city_exist}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={address ?? ""}
                                        type="text"
                                        onChange={handleAddressChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        // label="آدرس"
                                        label={address_input}
                                        // placeholder="آدرس را وارد کنید"
                                        placeholder={address_input_placeholder}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                        }}
                                        error={Boolean(addressError)}
                                        helperText={addressError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextField
                                        value={zipCode ?? ""}
                                        type="text"
                                        onChange={handleZipCodeChange}
                                        required
                                        autoComplete="off"
                                        fullWidth
                                        // label="کد پستی"
                                        label={zipcode_input}
                                        // placeholder="کد پستی را وارد کنید"
                                        placeholder={zipcode_placeholder}
                                        variant="outlined"
                                        inputProps={{
                                            min: 0,
                                            style: { textAlign: "center" },
                                            maxLength: 10,
                                        }}
                                        error={Boolean(zipCodeError)}
                                        helperText={zipCodeError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={biography ?? ""}
                                        onChange={handleBiographyChange}
                                        autoComplete="off"
                                        // label="زندگی‌نامه"
                                        label={biography_input}
                                        multiline
                                        rows={9}
                                        inputProps={{ style: { textAlign: "justify" }, maxLength: 1000 }}
                                        error={Boolean(biographyError)}
                                        helperText={
                                            biographyError ? biographyError : `${biography?.length ?? 0}/1000`
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={enBiography ?? ""}
                                        onChange={handleEnBiographyChange}
                                        autoComplete="off"
                                        // label="زندگی‌نامه به لاتین"
                                        label={en_biography_input}
                                        multiline
                                        rows={9}
                                        inputProps={{ style: { textAlign: "justify", direction: "ltr" }, maxLength: 1000 }}
                                        error={Boolean(enBiographyError)}
                                        helperText={
                                            enBiographyError ? enBiographyError : `${enBiography?.length ?? 0}/1000`
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{
                            justifyContent: "flex-end",
                        }}>
                            <LoadingButton
                                variant="outlined"
                                loadingPosition="start"
                                size="large"
                                loading={!loadingUpdateButton}
                                disabled={
                                    !firstName ||
                                    !enFirstName ||
                                    !lastName ||
                                    !enLastName ||
                                    (user.country_id === 100 && (!province || !city)) ||
                                    !address ||
                                    !zipCode
                                }
                                startIcon={<SaveOutlined />}
                                onClick={handleUpdate}
                            >
                                {/* ثبت مشخصات */}
                                {registration_details}
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default ProfilePost;
