import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "../../context/DialogContext";
import RoleContext from "../../context/RoleContext";
import { Button, Stack } from "@mui/material";
import CustomDataGrid from "../../components/CustomDataGrid";
import { Add, Edit, Delete } from "@mui/icons-material";
import CreateRoles from "./CreateRoles";
import EditRoles from "./EditRoles";
import DeleteRoles from "./DeleteRoles";

const RolesTable = () => {
    const { t } = useTranslation();
    const {
        columns_row,
        columns_role_name,
        columns_en_role_name,
        columns_role_key,
        columns_admin,
        add_role_title,
        add_role_button,
        edit_role_title,
        edit_role_button,
        delete_role_button
    } = t("lngRolesTable");
    const { handleOpenDialog } = useDialogContext();
    const {
        getRoles,
        roles, setRoles,
        selectedRow, setSelectedRow,
        loading,
        page,
        setPage,
        rows,
        setRows,
        from,
        to,
        total
    } = useContext(RoleContext);

    useEffect(() => {
        getRoles();
        // eslint-disable-next-line
    }, [rows, total, page]);

    const columns = [
        {
            field: "id",
            // headerName: "ردیف",
            headerName: columns_row,
            filterable: false,
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            field: "role_name",
            // headerName: "نام نقش",
            headerName: columns_role_name,
            flex: 1,
        },
        {
            field: "en_role_name",
            // headerName: "نام نقش",
            headerName: columns_en_role_name,
            flex: 1,
        },
        {
            field: "role_key",
            // headerName: "شناسه نقش",
            headerName: columns_role_key,
            flex: 1,
        },
        {
            field: "admin",
            // headerName: "فعال‌سازی",
            headerName: columns_admin,
            flex: 1,
        },
    ];

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "lg", // xs sm md lg xl
                            // "افزودن نقش و سطح دسترسی",
                            add_role_title,
                            <CreateRoles
                                roles={roles}
                                setRoles={setRoles}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Add />}
                >
                    {/* افزودن */}
                    {add_role_button}
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "lg", // xs sm md lg xl
                            // "ویرایش نقش و سطح دسترسی",
                            edit_role_title,
                            <EditRoles
                                params={selectedRow}
                                getRoles={getRoles}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Edit />}
                    disabled={!selectedRow}
                >
                    {/* ویرایش */}
                    {edit_role_button}
                </Button>
                <Button
                    onClick={() =>
                        handleOpenDialog(
                            "sm", // xs sm md lg xl
                            "",
                            <DeleteRoles
                                params={selectedRow}
                                roles={roles}
                                setRoles={setRoles}
                            />
                        )
                    }
                    variant="outlined"
                    startIcon={<Delete />}
                    disabled={!selectedRow}
                >
                    {/* حذف */}
                    {delete_role_button}
                </Button>
            </Stack>
            <CustomDataGrid
                loading={loading}
                myData={roles}
                myColumns={columns}
                setSelectedRow={setSelectedRow}
                setRows={setRows}
                setPage={setPage}
                from={from}
                to={to}
                total={total}
            />
        </>
    );
};

export default RolesTable;
