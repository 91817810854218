import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, useTheme, LinearProgress } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill:
      theme.palette.mode === "light"
        ? tokens(theme.palette.mode).blueAccent[500]
        : "#434343",
  },
  "& .ant-empty-img-4": {
    fill:
      theme.palette.mode === "light"
        ? tokens(theme.palette.mode).white
        : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill:
      theme.palette.mode === "light"
        ? "#f5f5f5"
        : tokens(theme.palette.mode).grey[800],
  },
}));

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  const {
    no_rows
  } = t("lngCustomDataGrid");

  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>
        {/* داده‌ای وجود ندارد */}
        {no_rows}
      </Box>
    </StyledGridOverlay>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiDataGrid-row.Mui-my-css-bold": { // & .MuiDataGrid-row.Mui-my-css-bold:not(:hover)
    //   fontWeight: "700",
    // },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: tokens(theme.palette.mode).grey[800],
      borderBottom: "none",
      borderradius: "unset",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: tokens(theme.palette.mode).grey[900],
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: tokens(theme.palette.mode).grey[800],
      borderTop: "none",
    },
    "& .MuiDataGrid-toolbarContainer": {
      backgroundColor: tokens(theme.palette.mode).grey[900],
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-cell:hover": {
      color:
        theme.palette.mode === "light"
          ? `${tokens(theme.palette.mode).black} !important`
          : `${tokens(theme.palette.mode).white} !important`,
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor:
        theme.palette.mode === "light"
          ? `${tokens(theme.palette.mode).blueAccent[800]} !important`
          : `${tokens(theme.palette.mode).blueAccent[500]} !important`,
      color:
        theme.palette.mode === "light"
          ? tokens(theme.palette.mode).black
          : tokens(theme.palette.mode).white,
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor:
        theme.palette.mode === "light"
          ? `${tokens(theme.palette.mode).blueAccent[800]} !important`
          : `${tokens(theme.palette.mode).blueAccent[500]} !important`,
      color:
        theme.palette.mode === "light"
          ? tokens(theme.palette.mode).black
          : tokens(theme.palette.mode).white,
    },
    "& .MuiDataGrid-row.Mui-selected:hover": {
      color:
        theme.palette.mode === "light"
          ? tokens(theme.palette.mode).black
          : tokens(theme.palette.mode).white,
    },
  },
  paginationLabel: {
    marginBottom: "0px", // استایل برای MuiTablePagination-selectLabel
  },
  displayedRows: {
    marginBottom: "0px", // استایل برای MuiTablePagination-selectLabel
  },
}));

function myToolbar(
  toolbarColumns,
  toolbarFilter,
  toolbarDensity,
  toolbarExport) {
  return (
    <GridToolbarContainer>
      {toolbarColumns && <GridToolbarColumnsButton />}
      {toolbarFilter && <GridToolbarFilterButton />}
      {toolbarDensity && <GridToolbarDensitySelector />}
      {toolbarExport && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const CustomDataGrid = (props) => {
  const { t } = useTranslation();
  const {
    no_rows,
    no_results_overlay,
    toolbar_density,
    toolbar_density_compact,
    toolbar_density_standard,
    toolbar_density_comfortable,
    toolbar_columns,
    toolbar_filters,
    toolbar_filters_tooltip_hide,
    toolbar_filters_tooltip_show,
    toolbar_export,
    toolbar_export_CSV,
    toolbar_export_print,
    columns_panel_text_field,
    columns_panel_text_field_placeholder,
    columns_panel_show_all_button,
    columns_panel_hide_all_button,
    filter_panel_delete_icon,
    filter_panel_columns,
    filter_panel_input,
    filter_panel_input_placeholder,
    filter_panel_operator,
    filter_operator_contains,
    filter_operator_equals,
    filter_operator_starts_with,
    filter_operator_ends_with,
    filter_operator_is,
    filter_operator_is_empty,
    filter_operator_is_not_empty,
    filter_operator_is_any_of,
    filter_value_any,
    filter_value_true,
    filter_value_false,
    column_menu,
    column_menu_sort_asc,
    column_menu_sort_desc,
    column_menu_filter,
    column_menu_hide_column,
    column_menu_unsort,
    column_menu_manage_columns,
    column_header_sort_icon,
    selection_header_name,
    from_text,
    rows_per_page
  } = t("lngCustomDataGrid");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  useEffect(() => {
    props?.setPage(1);
    props?.setRows(5);
    // eslint-disable-next-line
  }, []);

  return (
    <Box m="10px 0 0 0" height="58vh">
      <DataGrid
        localeText={{
          noRowsLabel: no_rows, // داده‌ای وجود ندارد
          noResultsOverlayLabel: no_results_overlay, // موردی یافت نشد
          errorOverlayDefaultLabel: "string", // خطای پیش‌فرض
          toolbarDensity: toolbar_density, // تراکم
          toolbarDensityLabel: "string", // برچسب تراکم
          toolbarDensityCompact: toolbar_density_compact, // فشرده
          toolbarDensityStandard: toolbar_density_standard, // استاندارد
          toolbarDensityComfortable: toolbar_density_comfortable, // راحت
          toolbarColumns: toolbar_columns, // ستون‌ها
          toolbarColumnsLabel: "string", // برچسب ستون‌ها
          toolbarFilters: toolbar_filters, // فیلتر
          toolbarFiltersLabel: "string", // برچسب فیلترها
          toolbarFiltersTooltipHide: toolbar_filters_tooltip_hide, // فیلترها را پنهان کنید
          toolbarFiltersTooltipShow: toolbar_filters_tooltip_show, // نمایش فیلترها
          // toolbarFiltersTooltipActive: (count: number) => "React.ReactNode",
          toolbarQuickFilterPlaceholder: "string", // فیلتر سریع
          toolbarQuickFilterLabel: "string", // برچسب فیلتر سریع
          toolbarQuickFilterDeleteIconLabel: "string", // آیکون حذف فیلتر سریع
          toolbarExport: toolbar_export, // استخراج
          toolbarExportLabel: "string", // برچسب استخراج
          toolbarExportCSV: toolbar_export_CSV, // فایل CSV
          toolbarExportPrint: toolbar_export_print, // پرینت
          toolbarExportExcel: "string", // فایل Excel
          columnsPanelTextFieldLabel: columns_panel_text_field, // جستجوی ستون
          columnsPanelTextFieldPlaceholder: columns_panel_text_field_placeholder, // عنوان ستون
          columnsPanelDragIconLabel: "string", // برچسب آیکون کشیدن ستون
          columnsPanelShowAllButton: columns_panel_show_all_button, // نمایش همه
          columnsPanelHideAllButton: columns_panel_hide_all_button, // همه مخفی شوند
          filterPanelAddFilter: "React.ReactNode", // افزودن فیلتر
          filterPanelDeleteIconLabel: filter_panel_delete_icon, // بستن
          filterPanelLinkOperator: "string", // عمل پیوند
          filterPanelOperators: "React.ReactNode", // عملیات فیلتر
          filterPanelOperatorAnd: "React.ReactNode", // عمل AND
          filterPanelOperatorOr: "React.ReactNode", // عمل OR
          filterPanelColumns: filter_panel_columns, // ستون‌ها
          filterPanelInputLabel: filter_panel_input, // مقدار
          filterPanelInputPlaceholder: filter_panel_input_placeholder, // مقدار فیلتر
          filterPanelOperator: filter_panel_operator, // عملیات
          filterOperatorContains: filter_operator_contains, // شامل
          filterOperatorEquals: filter_operator_equals, // برابر
          filterOperatorStartsWith: filter_operator_starts_with, // شروع شود با
          filterOperatorEndsWith: filter_operator_ends_with, // ختم شود به
          filterOperatorIs: filter_operator_is, // وجود دارد
          filterOperatorNot: "string", // وجود ندارد
          filterOperatorAfter: "string", // بعد از
          filterOperatorOnOrAfter: "string", // برابر یا بعد از
          filterOperatorBefore: "string", // قبل از
          filterOperatorOnOrBefore: "string", // برابر یا قبل از
          filterOperatorIsEmpty: filter_operator_is_empty, // خالی است
          filterOperatorIsNotEmpty: filter_operator_is_not_empty, // خالی نیست
          filterOperatorIsAnyOf: filter_operator_is_any_of, // هر یک از
          filterValueAny: filter_value_any, // همه مقادیر
          filterValueTrue: filter_value_true, // مقادیر true
          filterValueFalse: filter_value_false, // مقادیر false
          columnMenuLabel: column_menu, // منو
          columnMenuShowColumns: "React.ReactNode", // نمایش ستون‌ها
          columnMenuSortAsc: column_menu_sort_asc, // مرتب کردن ASC
          columnMenuSortDesc: column_menu_sort_desc, // مرتب کردن DESC
          columnMenuFilter: column_menu_filter, // فیلتر
          columnMenuHideColumn: column_menu_hide_column, // مخفی کردن ستون
          columnMenuUnsort: column_menu_unsort, // مرتب نشده
          columnMenuManageColumns: column_menu_manage_columns, // مدیریت ستون‌ها
          // columnHeaderFiltersTooltipActive: (count: number) =>
          //   "React.ReactNode",
          columnHeaderFiltersLabel: "string", // برچسب فیلترهای ستون
          columnHeaderSortIconLabel: column_header_sort_icon, // مرتب‌سازی
          // footerRowSelected: (count: number) => footer_row_selected, // "ردیف انتخاب شده: ",
          footerTotalRows: "React.ReactNode", // کل سطرها
          // footerTotalVisibleRows: (
          //   visibleCount: number,
          //   totalCount: number
          // ) => "React.ReactNode",
          checkboxSelectionHeaderName: selection_header_name, // انتخاب کادر
          checkboxSelectionSelectAllRows: "string", // انتخاب همه سطرها
          checkboxSelectionUnselectAllRows: "string", // لغو انتخاب همه سطرها
          checkboxSelectionSelectRow: "string", // انتخاب سطر
          checkboxSelectionUnselectRow: "string", // لغو انتخاب سطر
          booleanCellTrueLabel: "string", // مقدار True
          booleanCellFalseLabel: "string", // مقدار False
          actionsCellMore: "string", // بیشتر
          pinToLeft: "string", // سنجاق به چپ
          pinToRight: "string", // سنجاق به راست
          unpin: "string", // لغو سنجاق
          treeDataGroupingHeaderName: "string", // عنوان گروه‌بندی داده درختی
          treeDataExpand: "string", // گسترش
          treeDataCollapse: "string", // جمع کردن
          groupingColumnHeaderName: "string", // عنوان ستون گروه‌بندی
          // groupColumn: (name: string) => "string",
          // unGroupColumn: (name: string) => "string",
          detailPanelToggle: "string", // تغییر وضعیت پنل جزئیات
          expandDetailPanel: "string", // گسترش پنل جزئیات
          collapseDetailPanel: "string", // جمع کردن پنل جزئیات
          rowReorderingHeaderName: "string", // عنوان مرتب‌سازی سطر
          aggregationMenuItemHeader: "string", // عنوان منوی جمع‌بندی
          aggregationFunctionLabelSum: "string", // مجموع
          aggregationFunctionLabelAvg: "string", // میانگین
          aggregationFunctionLabelMin: "string", // حداقل
          aggregationFunctionLabelMax: "string", // حداکثر
          aggregationFunctionLabelSize: "string", // اندازه

          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, total }) =>
              `${props?.from} - ${props?.to} ${from_text} ${props?.total}`,
          },
        }}
        hideFooterPagination={props?.hideFooterPagination}
        hideFooterSelectedRowCount={props?.hideFooterSelectedRowCount}
        className={classes.root}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: colors.blueAccent[500],
          },
        }}
        // getRowClassName={(params) =>
        //   params.row.pivot ? (
        //     params.row.pivot.is_read === 0 ? 'Mui-my-css-bold' : ''
        //   ) : (
        //     params.row.is_read === 0 ? 'Mui-my-css-bold' : ''
        //   )
        // }
        rowCount={props?.total}
        loading={props?.loading}
        paginationMode="server"
        onPaginationModelChange={(newPaginationModel) => {
          // fetch data from server
          props?.setPage(newPaginationModel.page + 1);
          props?.setRows(newPaginationModel.pageSize);
        }}
        rows={props?.myData}
        columns={props?.myColumns}
        onRowClick={(params, event) => {
          if (!event.ignore) {
            // console.log(params.row);
            props?.setSelectedRow(params.row);
          }
        }}
        slots={{
          toolbar: () => myToolbar(
            props?.toolbarColumns ?? true,
            props?.toolbarFilter ?? true,
            props?.toolbarDensity ?? true,
            props?.toolbarExport ?? true
          ),
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: LinearProgress,
        }}
        density={props?.density ?? "standard"}
        {...props?.myData}
        initialState={{
          ...props?.myData?.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        componentsProps={{
          pagination: {
            labelRowsPerPage: rows_per_page, // "ردیف در هر صفحه:",
            classes: {
              selectLabel: classes.paginationLabel, // استایل به selectLabel
              displayedRows: classes.displayedRows, // استایل به displayedRows
            },
          },
        }}
        checkboxSelection={props?.checkboxSelection ? true : false}
        keepNonExistentRowsSelected // ردیف‌های غیرموجود را انتخاب شده نگه دارید
        onRowSelectionModelChange={(newSelectionModel) => {
          props?.setSelectedItems && props.setSelectedItems(newSelectionModel);
        }}
        rowSelectionModel={props?.selectedItems}
      />
    </Box>
  );
};

export default CustomDataGrid;
