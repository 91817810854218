import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Stack, Chip } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            fontWeight: "700",
        },

        "& .MuiChip-deleteIcon": {
            margin: "0 0 0 5px !important",
        },
    },
}));

const AutocompleteSearch = (props) => {
    const classes = useStyles();

    return (
        <Stack spacing={3}>
            <Autocomplete
                style={{ direction: "ltr" }} // سرچ این خط به سختی به دست آمد
                ListboxProps={{ dir: "ltr" }} // سرچ این خط به سختی به دست آمد
                multiple={true}
                freeSolo={props?.freeSolo}
                id="tags-standard"
                options={props?.options ?? []}
                getOptionLabel={(option) => option.tag_name}
                isOptionEqualToValue={(option, value) => option.tag_name === value.tag_name}
                noOptionsText={props?.noOptionsText}
                forcePopupIcon={props?.forcePopupIcon}
                onChange={(event, newValue) => {
                    if (props?.setValue) {
                        props.setValue([...newValue.filter((option) => option.tag_name.indexOf(option) === -1)]);
                    }
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                            <Chip variant="outlined" label={option?.tag_name} key={key} {...tagProps} />
                        );
                    })
                }
                className={classes.root}
                value={props?.value ?? []}
                renderInput={(params) => (
                    <TextField
                        autoComplete="off"
                        fullWidth
                        {...params}
                        variant={props?.variant}
                        placeholder={props?.placeholder}
                        id={props?.id}
                        name={props?.name}
                        value={props?.value ?? ""}
                        onChange={props?.onChange}
                        onFocus={props?.onFocus ? props.onFocus : () => { }}
                        required={props?.required}
                        inputProps={{
                            ...params.inputProps,
                            maxLength: 14
                        }}
                        error={Boolean(props?.error)}
                        helperText={
                            props?.helperText
                        }
                    />
                )}
            />
        </Stack>
    );
}

export default AutocompleteSearch;
