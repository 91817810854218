import { createContext, useState } from "react";
import useServices from './../utils/useServices';

const InboxContext = createContext();
InboxContext.displayName = "InboxContext";

export default InboxContext;

export const InboxContextProvider = (props) => {
    const { postMethodByPageId } = useServices();
    const [inboxes, setInboxes] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState(5);
    const [page, setPage] = useState(1);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getInboxes() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: rows,
            };

            const response = await postMethodByPageId("/getInboxes", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setInboxes(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getInboxes,
        inboxes, setInboxes,
        selectedRow, setSelectedRow,
        loading, setLoading,
        rows, setRows,
        page, setPage,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <InboxContext.Provider value={contextData}>
                {props.children}
            </InboxContext.Provider>
        </>
    );
}
