import { createContext, useContext, useState } from "react";
import useServices from './../utils/useServices';
import SettingsContext from "./SettingsContext";

const ContactContext = createContext();
ContactContext.displayName = "ContactContext";

export default ContactContext;

export const ContactContextProvider = (props) => {
    const { postMethodByPageId } = useServices();
    const { settings } = useContext(SettingsContext);
    const [contacts, setContacts] = useState([]);
    const [selectedRow, setSelectedRow] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(10);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);

    async function getContacts() {
        setLoading(true);

        try {
            const obj = {
                page: page,
                rows: settings?.contact_rows ?? rows,
            };

            const response = await postMethodByPageId("/getContacts", obj);
            const data = await response?.data;

            if (response?.status === 200) {
                if (data.from === null) {
                    setFrom(0);
                } else {
                    setFrom(data.from);
                }
                if (data.to === null) {
                    setTo(0);
                } else {
                    setTo(data.to);
                }
                setTotal(data.total);
                setLastPage(data.last_page);
                setContacts(data.data);
            }
        } catch (ex) {

        }

        setLoading(false);
    }

    const contextData = {
        getContacts,
        contacts, setContacts,
        selectedRow, setSelectedRow,
        loading, setLoading,
        page, setPage,
        rows, setRows,
        from, setFrom,
        to, setTo,
        total, setTotal,
        lastPage, setLastPage
    };

    return (
        <>
            <ContactContext.Provider
                value={contextData}
            >
                {props.children}
            </ContactContext.Provider>
        </>
    );
}
