import { useTranslation } from 'react-i18next';
import Header from './../../components/Header';
import { Box } from "@mui/material";
import SettingsPost from "./SettingsPost";

const AdminSettings = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngAdminSettings");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/" />
      </Box>
      <SettingsPost />
    </>
  );
};

export default AdminSettings;
