import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import InvoicesTable from "./InvoicesTable";

const Invoices = () => {
  const { t } = useTranslation();
  const {
    header_title,
    header_subtitle
  } = t("lngInvoices");

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={header_title} subtitle={header_subtitle} backToPage="/" />
      </Box>
      <Box mt="50px" mb="50px" className="container">
        <InvoicesTable />
      </Box>
    </>
  );
};

export default Invoices;
