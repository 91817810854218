import { useTranslation } from "react-i18next";
import { DialogContent, Typography, TextField, Stack, Grid } from "@mui/material";
import { DoneAll } from "@mui/icons-material";

const ShowInbox = (props) => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const {
    typography_sender,
    typography_subject,
    typography_message,
    null_message
  } = t("lngShowInbox");

  return (
    <>
      <DialogContent>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* از: */}
                {typography_sender}
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={
                (() => {
                  const usernameWithoutPlus = props?.params?.sender?.username?.replace(/\+/g, '') || '';
                  const sender = props?.params?.sender?.full_name || usernameWithoutPlus || null_message;
                  const enSender = props?.params?.sender?.en_full_name || usernameWithoutPlus || null_message;

                  return direction === "rtl" ? sender : enSender;
                })()
              }
              inputprops={{
                style: { textAlign: "justify" },
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* موضوع */}
                {typography_subject}
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={props?.params?.subject ?? ""}
              multiline
              rows={3}
              inputprops={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Stack direction="row" spacing={1}>
              <DoneAll color="primary" />
              <Typography
                component="span"
                variant="body1"
                color="primary"
                sx={{ fontWeight: "700" }}
              >
                {/* پیام */}
                {typography_message}
              </Typography>
            </Stack>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              value={props?.params?.message ?? ""}
              multiline
              rows={5}
              sx={{ mb: "20px" }}
              inputprops={{ style: { textAlign: "justify" }, readOnly: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ShowInbox;
