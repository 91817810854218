import { useState, useEffect, useContext, useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import useServices from "../../utils/useServices";
import SettingsContext from "../../context/SettingsContext";
import { useSnackbarContext } from "../../context/SnackbarContext";
import {
    TextField,
    Grid,
    InputAdornment,
    Box,
    Card,
    CardHeader,
    CardContent,
    CardActions
} from "@mui/material";
import UploadProgressBar from "../../components/UploadProgressBar";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloudUpload, SaveOutlined } from "@mui/icons-material";

const SettingsPost = () => {
    const { t } = useTranslation();
    const {
        bank_terminal_required,
        bank_username_required,
        bank_password_required,
        bank_additional_data_required,
        bank_callback_url_required,
        temporary_code_id_required,
        active_code_id_required,
        api_key_required,
        category_rows_required,
        contact_rows_required,
        course_rows_required,
        event_rows_required,
        gallery_rows_required,
        instructor_rows_required,
        user_rows_required,
        settings_updated,
        record_deleted,
        header_settings,
        logo_input,
        favicon_input,
        video_cover_input,
        en_video_cover_input,
        video_url_input,
        en_video_url_input,
        main_background_input,
        slogan_input,
        en_slogan_input,
        instagram_input,
        linkedin_input,
        address_input,
        en_address_input,
        google_map_area_input,
        phone1_input,
        phone2_input,
        tel_input,
        email1_input,
        email2_input,
        bank_terminal_id_input,
        bank_username_input,
        bank_password_input,
        bank_additional_data_input,
        bank_callback_url_input,
        temporary_code_id_input,
        active_code_id_input,
        api_key_input,
        category_rows_input,
        contact_rows_input,
        course_rows_input,
        event_rows_input,
        gallery_rows_input,
        instructor_rows_input,
        user_rows_input,
        save
    } = t("lngSettingsPost");
    const { handleOpenSnackbar } = useSnackbarContext();
    const { settings, getSettings } = useContext(SettingsContext);
    const { uploadPostMethod } = useServices();
    const isMounted = useRef(false);
    const [loadingUpdateButton, setLoadingUpdateButton] = useState(true);
    const [logo, setLogo] = useState([]);
    const [logoError, setLogoError] = useState("");
    const [favicon, setFavicon] = useState([]);
    const [faviconError, setFaviconError] = useState("");
    const [videoCover, setVideoCover] = useState([]);
    const [videoCoverError, setVideoCoverError] = useState("");
    const [enVideoCover, setEnVideoCover] = useState([]);
    const [enVideoCoverError, setEnVideoCoverError] = useState("");
    const [videoUrl, setVideoUrl] = useState([]);
    const [videoUrlError, setVideoUrlError] = useState("");
    const [enVideoUrl, setEnVideoUrl] = useState([]);
    const [enVideoUrlError, setEnVideoUrlError] = useState("");
    const [mainBackground, setMainBackground] = useState([]);
    const [mainBackgroundError, setMainBackgroundError] = useState("");
    const [slogan, setSlogan] = useState("");
    const [sloganError, setSloganError] = useState("");
    const [enSlogan, setEnSlogan] = useState("");
    const [enSloganError, setEnSloganError] = useState("");
    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [enAddress, setEnAddress] = useState("");
    const [enAddressError, setEnAddressError] = useState("");
    const [instagram, setInstagram] = useState("");
    const [instagramError, setInstagramError] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [linkedinError, setLinkedinError] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone1Error, setPhone1Error] = useState("");
    const [phone2, setPhone2] = useState("");
    const [phone2Error, setPhone2Error] = useState("");
    const [tel, setTel] = useState("");
    const [telError, setTelError] = useState("");
    const [email1, setEmail1] = useState("");
    const [email1Error, setEmail1Error] = useState("");
    const [email2, setEmail2] = useState("");
    const [email2Error, setEmail2Error] = useState("");
    const [googleMapArea, setGoogleMapArea] = useState("");
    const [googleMapAreaError, setGoogleMapAreaError] = useState("");
    const [bankTerminalId, setBankTerminalId] = useState("");
    const [bankTerminalIdError, setBankTerminalIdError] = useState("");
    const [bankUsername, setBankUsername] = useState("");
    const [bankUsernameError, setBankUsernameError] = useState("");
    const [bankPassword, setBankPassword] = useState("");
    const [bankPasswordError, setBankPasswordError] = useState("");
    const [bankAdditionalData, setBankAdditionalData] = useState("");
    const [bankAdditionalDataError, setBankAdditionalDataError] = useState("");
    const [bankCallbackUrl, setBankCallbackUrl] = useState("");
    const [bankCallbackUrlError, setBankCallbackUrlError] = useState("");
    const [temporaryCodeId, setTemporaryCodeId] = useState("");
    const [temporaryCodeIdError, setTemporaryCodeIdError] = useState("");
    const [activeCodeId, setActiveCodeId] = useState("");
    const [activeCodeIdError, setActiveCodeIdError] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiKeyError, setApiKeyError] = useState("");
    const [categoryRows, setCategoryRows] = useState(15);
    const [categoryRowsError, setCategoryRowsError] = useState("");
    const [contactRows, setContactRows] = useState(10);
    const [contactRowsError, setContactRowsError] = useState("");
    const [courseRows, setCourseRows] = useState(15);
    const [courseRowsError, setCourseRowsError] = useState("");
    const [eventRows, setEventRows] = useState(6);
    const [eventRowsError, setEventRowsError] = useState("");
    const [galleryRows, setGalleryRows] = useState(9);
    const [galleryRowsError, setGalleryRowsError] = useState("");
    const [instructorRows, setInstructorRows] = useState(6);
    const [instructorRowsError, setInstructorRowsError] = useState("");
    const [userRows, setUserRows] = useState(12);
    const [userRowsError, setUserRowsError] = useState("");

    useEffect(() => {
        if (!isMounted.current) {
            getSettings();
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fillItems();
        // eslint-disable-next-line
    }, [settings]);

    function fillItems() {
        setSlogan(settings.slogan || "");
        setSloganError("");
        setEnSlogan(settings.en_slogan || "");
        setEnSloganError("");
        setAddress(settings.address || "");
        setAddressError("");
        setEnAddress(settings.en_address || "");
        setEnAddressError("");
        setInstagram(settings.instagram || "");
        setInstagramError("");
        setLinkedin(settings.linkedin || "");
        setLinkedinError("");
        setPhone1(settings.phone1 || "");
        setPhone1Error("");
        setPhone2(settings.phone2 || "");
        setPhone2Error("");
        setTel(settings.tel || "");
        setTelError("");
        setEmail1(settings.email1 || "");
        setEmail1Error("");
        setEmail2(settings.email2 || "");
        setEmail2Error("");
        setGoogleMapArea(settings.google_map_area || "");
        setGoogleMapAreaError("");
        setBankTerminalId(settings.bank_terminal_id || "");
        setBankTerminalIdError("");
        setBankUsername(settings.bank_username || "");
        setBankUsernameError("");
        setBankPassword(settings.bank_password || "");
        setBankPasswordError("");
        setBankAdditionalData(settings.bank_additional_data || "");
        setBankAdditionalDataError("");
        setBankCallbackUrl(settings.bank_callback_url || "");
        setBankCallbackUrlError("");
        setTemporaryCodeId(settings.temporary_code_id || "");
        setTemporaryCodeIdError("");
        setActiveCodeId(settings.active_code_id || "");
        setActiveCodeIdError("");
        setApiKey(settings.api_key || "");
        setApiKeyError("");
        setCategoryRows(settings.category_rows || 15);
        setCategoryRowsError("");
        setContactRows(settings.contact_rows || 10);
        setContactRowsError("");
        setCourseRows(settings.course_rows || 15);
        setCourseRowsError("");
        setEventRows(settings.event_rows || 6);
        setEventRowsError("");
        setGalleryRows(settings.gallery_rows || 9);
        setGalleryRowsError("");
        setInstructorRows(settings.instructor_rows || 6);
        setInstructorRowsError("");
        setUserRows(settings.user_rows || 12);
        setUserRowsError("");
        setLogoError("");
        setFaviconError("");
        setVideoCoverError("");
        setVideoUrlError("");
        setMainBackgroundError("");
    }

    const handleLogoChange = (e) => {
        const currentValue = e.target.files[0];
        setLogo(currentValue);
        // console.log(logo);
    };

    const handleFaviconChange = (e) => {
        const currentValue = e.target.files[0];
        setFavicon(currentValue);
        // console.log(favicon);
    };

    const handleVideoCoverChange = (e) => {
        const currentValue = e.target.files[0];
        setVideoCover(currentValue);
        // console.log(videoCover);
    };

    const handleEnVideoCoverChange = (e) => {
        const currentValue = e.target.files[0];
        setEnVideoCover(currentValue);
        // console.log(videoCover);
    };

    const handleVideoUrlChange = (e) => {
        const currentValue = e.target.files[0];
        setVideoUrl(currentValue);
        // console.log(videoUrl);
    };

    const handleEnVideoUrlChange = (e) => {
        const currentValue = e.target.files[0];
        setEnVideoUrl(currentValue);
        // console.log(videoUrl);
    };

    const handleMainBackgroundChange = (e) => {
        const currentValue = e.target.files[0];
        setMainBackground(currentValue);
        // console.log(mainBackground);
    };

    const handleSloganChange = (e) => {
        const currentValue = e.target.value;
        setSlogan(currentValue);
    };

    const handleEnSloganChange = (e) => {
        const currentValue = e.target.value;
        const englishLettersOnly = /^[A-Za-z\s&()-_/.|"']*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }
        setEnSlogan(currentValue);
    };

    const handleAddressChange = (e) => {
        const currentValue = e.target.value;
        setAddress(currentValue);
    };

    const handleEnAddressChange = (e) => {
        const currentValue = e.target.value;
        const englishLettersOnly = /^[A-Za-z0-9\s!@#$%^&*()\-_=+/.?|\\"'<>[\]]*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }
        setEnAddress(currentValue);
    };

    const handleInstagramChange = (e) => {
        const currentValue = e.target.value;
        setInstagram(currentValue);
    };

    const handleLinkedinChange = (e) => {
        const currentValue = e.target.value;
        setLinkedin(currentValue);
    };

    const handlePhone1Change = (e) => {
        const currentValue = e.target.value;
        const englishLettersOnly = /^[0-9+]*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }
        setPhone1(currentValue);
    };

    const handlePhone2Change = (e) => {
        const currentValue = e.target.value;
        const englishLettersOnly = /^[0-9+]*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }
        setPhone2(currentValue);
    };

    const handleTelChange = (e) => {
        const currentValue = e.target.value;
        const englishLettersOnly = /^[0-9+]*$/;
        if (!englishLettersOnly.test(currentValue)) {
            return;
        }
        setTel(currentValue);
    };

    const handleEmail1Change = (e) => {
        const currentValue = e.target.value;
        setEmail1(currentValue);
    };

    const handleEmail2Change = (e) => {
        const currentValue = e.target.value;
        setEmail2(currentValue);
    };

    const handleGoogleMapAreaChange = (e) => {
        const currentValue = e.target.value;
        setGoogleMapArea(currentValue);
    };

    const handleBankTerminalIdChange = (e) => {
        const currentValue = e.target.value;
        setBankTerminalId(currentValue);
        if (currentValue?.length === 0) {
            // setBankTerminalIdError("تکمیل گزینه شماره ترمینال الزامی است");
            setBankTerminalIdError(bank_terminal_required);
        } else {
            setBankTerminalIdError("");
        }
    };

    const handleBankUsernameChange = (e) => {
        const currentValue = e.target.value;
        setBankUsername(currentValue);
        if (currentValue?.length === 0) {
            // setBankUsernameError("تکمیل گزینه نام کاربری درگاه الزامی است");
            setBankUsernameError(bank_username_required);
        } else {
            setBankUsernameError("");
        }
    };

    const handleBankPasswordChange = (e) => {
        const currentValue = e.target.value;
        setBankPassword(currentValue);
        if (currentValue?.length === 0) {
            // setBankPasswordError("تکمیل گزینه رمز عبور درگاه الزامی است");
            setBankPasswordError(bank_password_required);
        } else {
            setBankPasswordError("");
        }
    };

    const handleBankAdditionalDataChange = (e) => {
        const currentValue = e.target.value;
        setBankAdditionalData(currentValue);
        if (currentValue?.length === 0) {
            // setBankAdditionalDataError("تکمیل گزینه متن درگاه الزامی است");
            setBankAdditionalDataError(bank_additional_data_required);
        } else {
            setBankAdditionalDataError("");
        }
    };

    const handleBankCallbackUrlChange = (e) => {
        const currentValue = e.target.value;
        setBankCallbackUrl(currentValue);
        if (currentValue?.length === 0) {
            // setBankCallbackUrlError("تکمیل گزینه آدرس برگشت از درگاه الزامی است");
            setBankCallbackUrlError(bank_callback_url_required);
        } else {
            setBankCallbackUrlError("");
        }
    };

    const handleTemporaryCodeIdChange = (e) => {
        const currentValue = e.target.value;
        setTemporaryCodeId(currentValue);
        if (currentValue?.length === 0) {
            // setTemporaryCodeIdError("تکمیل گزینه شناسه رمز موقت الزامی است");
            setTemporaryCodeIdError(temporary_code_id_required);
        } else {
            setTemporaryCodeIdError("");
        }
    };

    const handleActiveCodeIdChange = (e) => {
        const currentValue = e.target.value;
        setActiveCodeId(currentValue);
        if (currentValue?.length === 0) {
            // setActiveCodeIdError("تکمیل گزینه شناسه کد فعال‌سازی الزامی است");
            setActiveCodeIdError(active_code_id_required);
        } else {
            setActiveCodeIdError("");
        }
    };

    const handleApiKeyChange = (e) => {
        const currentValue = e.target.value;
        setApiKey(currentValue);
        if (currentValue?.length === 0) {
            // setApiKeyError("تکمیل گزینه کلید سرویس پیامک الزامی است");
            setApiKeyError(api_key_required);
        } else {
            setApiKeyError("");
        }
    };

    const handleCategoryRowsChange = (e) => {
        const currentValue = e.target.value;
        setCategoryRows(currentValue);
        if (currentValue?.length === 0) {
            // setCategoryRowsError("تکمیل گزینه تعداد سطرهای دسته‌بندی الزامی است");
            setCategoryRowsError(category_rows_required);
        } else {
            setCategoryRowsError("");
        }
    };

    const handleContactRowsChange = (e) => {
        const currentValue = e.target.value;
        setContactRows(currentValue);
        if (currentValue?.length === 0) {
            // setContactRowsError("تکمیل گزینه تعداد سطرهای تماس با ما الزامی است");
            setContactRowsError(contact_rows_required);
        } else {
            setContactRowsError("");
        }
    };

    const handleCourseRowsChange = (e) => {
        const currentValue = e.target.value;
        setCourseRows(currentValue);
        if (currentValue?.length === 0) {
            // setCourseRowsError("تکمیل گزینه تعداد سطرهای دوره الزامی است");
            setCourseRowsError(course_rows_required);
        } else {
            setCourseRowsError("");
        }
    };

    const handleEventRowsChange = (e) => {
        const currentValue = e.target.value;
        setEventRows(currentValue);
        if (currentValue?.length === 0) {
            // setEventRowsError("تکمیل گزینه تعداد سطرهای رویداد الزامی است");
            setEventRowsError(event_rows_required);
        } else {
            setEventRowsError("");
        }
    };

    const handleGalleryRowsChange = (e) => {
        const currentValue = e.target.value;
        setGalleryRows(currentValue);
        if (currentValue?.length === 0) {
            // setGalleryRowsError("تکمیل گزینه تعداد سطرهای گالری الزامی است");
            setGalleryRowsError(gallery_rows_required);
        } else {
            setGalleryRowsError("");
        }
    };

    const handleInstructorRowsChange = (e) => {
        const currentValue = e.target.value;
        setInstructorRows(currentValue);
        if (currentValue?.length === 0) {
            // setInstructorRowsError("تکمیل گزینه تعداد سطر اساتید الزامی است");
            setInstructorRowsError(instructor_rows_required);
        } else {
            setInstructorRowsError("");
        }
    };

    const handleUserRowsChange = (e) => {
        const currentValue = e.target.value;
        setUserRows(currentValue);
        if (currentValue?.length === 0) {
            // setUserRowsError("تکمیل گزینه تعداد سطر کاربران الزامی است");
            setUserRowsError(user_rows_required);
        } else {
            setUserRowsError("");
        }
    };

    const handleUpdate = async () => {
        setLoadingUpdateButton(false);

        try {
            const obj = {
                logo: logo,
                favicon: favicon,
                video_cover: videoCover,
                en_video_cover: enVideoCover,
                video_url: videoUrl,
                en_video_url: enVideoUrl,
                main_background: mainBackground,
                slogan: slogan,
                en_slogan: enSlogan,
                address: address,
                en_address: enAddress,
                instagram: instagram,
                linkedin: linkedin,
                phone1: phone1,
                phone2: phone2,
                tel: tel,
                email1: email1,
                email2: email2,
                google_map_area: googleMapArea,
                bank_terminal_id: bankTerminalId,
                bank_username: bankUsername,
                bank_password: bankPassword,
                bank_additional_data: bankAdditionalData,
                bank_callback_url: bankCallbackUrl,
                temporary_code_id: temporaryCodeId,
                active_code_id: activeCodeId,
                api_key: apiKey,
                category_rows: categoryRows,
                contact_rows: contactRows,
                course_rows: courseRows,
                event_rows: eventRows,
                gallery_rows: galleryRows,
                instructor_rows: instructorRows,
                user_rows: userRows,
            };

            await uploadPostMethod("/settings", obj);
            getSettings();
            handleOpenSnackbar(
                "bottom",
                "left",
                "success",
                // "تنظیمات با موفقیت ویرایش شد"
                settings_updated
            );
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                handleOpenSnackbar(
                    "bottom",
                    "left",
                    "error",
                    // "رکورد مورد نظر قبلاً حذف شده است"
                    record_deleted
                );
            }
            if (ex.response && ex.response.status === 422) {
                setLogoError(ex.response.data.errors.logo);
                setFaviconError(ex.response.data.errors.favicon);
                setVideoCoverError(ex.response.data.errors.video_cover);
                setEnVideoCoverError(ex.response.data.errors.en_video_cover);
                setVideoUrlError(ex.response.data.errors.video_url);
                setEnVideoUrlError(ex.response.data.errors.en_video_url);
                setMainBackgroundError(ex.response.data.errors.main_background);
                setSloganError(ex.response.data.errors.slogan);
                setEnSloganError(ex.response.data.errors.en_slogan);
                setAddressError(ex.response.data.errors.address);
                setEnAddressError(ex.response.data.errors.en_address);
                if (ex.response.data.errors.instagram &&
                    ex.response.data.errors.instagram.length > 1) {
                    setInstagramError(ex.response.data.errors.instagram[0]);
                } else {
                    setInstagramError(ex.response.data.errors.instagram);
                }
                if (ex.response.data.errors.linkedin &&
                    ex.response.data.errors.linkedin.length > 1) {
                    setLinkedinError(ex.response.data.errors.linkedin[0]);
                } else {
                    setLinkedinError(ex.response.data.errors.linkedin);
                }
                if (ex.response.data.errors.phone1 &&
                    ex.response.data.errors.phone1.length > 1) {
                    setPhone1Error(ex.response.data.errors.phone1[0]);
                } else {
                    setPhone1Error(ex.response.data.errors.phone1);
                }
                if (ex.response.data.errors.phone2 &&
                    ex.response.data.errors.phone2.length > 1) {
                    setPhone2Error(ex.response.data.errors.phone2[0]);
                } else {
                    setPhone2Error(ex.response.data.errors.phone2);
                }
                if (ex.response.data.errors.tel &&
                    ex.response.data.errors.tel.length > 1) {
                    setTelError(ex.response.data.errors.tel[0]);
                } else {
                    setTelError(ex.response.data.errors.tel);
                }
                if (ex.response.data.errors.email1 &&
                    ex.response.data.errors.email1.length > 1) {
                    setEmail1Error(ex.response.data.errors.email1[0]);
                } else {
                    setEmail1Error(ex.response.data.errors.email1);
                }
                if (ex.response.data.errors.email2 &&
                    ex.response.data.errors.email2.length > 1) {
                    setEmail2Error(ex.response.data.errors.email2[0]);
                } else {
                    setEmail2Error(ex.response.data.errors.email2);
                }
                if (ex.response.data.errors.google_map_area &&
                    ex.response.data.errors.google_map_area.length > 1) {
                    setGoogleMapAreaError(ex.response.data.errors.google_map_area[0]);
                } else {
                    setGoogleMapAreaError(ex.response.data.errors.google_map_area);
                }
                if (ex.response.data.errors.bank_terminal_id &&
                    ex.response.data.errors.bank_terminal_id.length > 1) {
                    setBankTerminalIdError(ex.response.data.errors.bank_terminal_id[0]);
                } else {
                    setBankTerminalIdError(ex.response.data.errors.bank_terminal_id);
                }
                if (ex.response.data.errors.bank_username &&
                    ex.response.data.errors.bank_username.length > 1) {
                    setBankUsernameError(ex.response.data.errors.bank_username[0]);
                } else {
                    setBankUsernameError(ex.response.data.errors.bank_username);
                }
                if (ex.response.data.errors.bank_password &&
                    ex.response.data.errors.bank_password.length > 1) {
                    setBankPasswordError(ex.response.data.errors.bank_password[0]);
                } else {
                    setBankPasswordError(ex.response.data.errors.bank_password);
                }
                setBankAdditionalDataError(ex.response.data.errors.bank_additional_data);
                if (ex.response.data.errors.bank_callback_url &&
                    ex.response.data.errors.bank_callback_url.length > 1) {
                    setBankCallbackUrlError(ex.response.data.errors.bank_callback_url[0]);
                } else {
                    setBankCallbackUrlError(ex.response.data.errors.bank_callback_url);
                }
                setTemporaryCodeIdError(ex.response.data.errors.temporary_code_id);
                setActiveCodeIdError(ex.response.data.errors.active_code_id);
                setApiKeyError(ex.response.data.errors.api_key);
                setCategoryRowsError(ex.response.data.errors.category_rows);
                setContactRowsError(ex.response.data.errors.contact_rows);
                setCourseRowsError(ex.response.data.errors.course_rows);
                setEventRowsError(ex.response.data.errors.event_rows);
                setGalleryRowsError(ex.response.data.errors.gallery_rows);
                setInstructorRowsError(ex.response.data.errors.instructor_rows);
                setUserRowsError(ex.response.data.errors.user_rows);
            }
        }

        setLoadingUpdateButton(true);
    };

    return (
        <>
            <Box mb="50px" mt="50px" className="container">
                <Fade triggerOnce={true}>
                    <Card>
                        <CardHeader title={header_settings} />
                        <CardContent>
                            <Grid container rowSpacing={2} columnSpacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="لوگو"
                                        label={logo_input}
                                        onChange={handleLogoChange}
                                        accept="image/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(logoError)}
                                        helperText={logoError ? logoError : "1280px × 550px"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="آیکن محبوب"
                                        label={favicon_input}
                                        onChange={handleFaviconChange}
                                        accept="image/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(faviconError)}
                                        helperText={faviconError ? faviconError : "51px × 51px"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="تصویر ویدیو"
                                        label={video_cover_input}
                                        onChange={handleVideoCoverChange}
                                        accept="image/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(videoCoverError)}
                                        helperText={videoCoverError ? videoCoverError : "810px × 450px"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="تصویر ویدیو (انگلیسی)"
                                        label={en_video_cover_input}
                                        onChange={handleEnVideoCoverChange}
                                        accept="image/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(enVideoCoverError)}
                                        helperText={enVideoCoverError ? enVideoCoverError : "810px × 450px"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="آدرس ویدیو"
                                        label={video_url_input}
                                        onChange={handleVideoUrlChange}
                                        accept="video/mp4,video/x-m4v,video/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(videoUrlError)}
                                        helperText={videoUrlError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="آدرس ویدیو (انگلیسی)"
                                        label={en_video_url_input}
                                        onChange={handleEnVideoUrlChange}
                                        accept="video/mp4,video/x-m4v,video/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(enVideoUrlError)}
                                        helperText={enVideoUrlError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        // label="تصویر پشت زمینه"
                                        label={main_background_input}
                                        onChange={handleMainBackgroundChange}
                                        accept="image/*"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CloudUpload />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={Boolean(mainBackgroundError)}
                                        helperText={mainBackgroundError ? mainBackgroundError : "2234px × 1259px"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={slogan ?? ""}
                                        onChange={handleSloganChange}
                                        autoComplete="off"
                                        // label="شعار"
                                        label={slogan_input}
                                        inputProps={{ maxLength: 150 }}
                                        error={Boolean(sloganError)}
                                        helperText={
                                            sloganError ? sloganError : `${slogan?.length ?? 0}/150`
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={enSlogan ?? ""}
                                        onChange={handleEnSloganChange}
                                        autoComplete="off"
                                        // label="شعار (انگلیسی)"
                                        label={en_slogan_input}
                                        inputProps={{
                                            maxLength: 150,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(enSloganError)}
                                        helperText={
                                            enSloganError ? enSloganError : `${enSlogan?.length ?? 0}/150`
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={instagram ?? ""}
                                        onChange={handleInstagramChange}
                                        autoComplete="off"
                                        // label="اینستاگرام"
                                        label={instagram_input}
                                        inputProps={{
                                            maxLength: 50,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(instagramError)}
                                        helperText={instagramError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={linkedin ?? ""}
                                        onChange={handleLinkedinChange}
                                        autoComplete="off"
                                        // label="لینکدین"
                                        label={linkedin_input}
                                        inputProps={{
                                            maxLength: 50,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(linkedinError)}
                                        helperText={linkedinError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={address ?? ""}
                                        onChange={handleAddressChange}
                                        autoComplete="off"
                                        // label="آدرس"
                                        label={address_input}
                                        inputProps={{ maxLength: 400 }}
                                        error={Boolean(addressError)}
                                        helperText={
                                            addressError ? addressError : `${address?.length ?? 0}/400`
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={enAddress ?? ""}
                                        onChange={handleEnAddressChange}
                                        autoComplete="off"
                                        // label="آدرس (انگلیسی)"
                                        label={en_address_input}
                                        inputProps={{
                                            maxLength: 400,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(enAddressError)}
                                        helperText={
                                            enAddressError ? enAddressError : `${enAddress?.length ?? 0}/400`
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={googleMapArea ?? ""}
                                        onChange={handleGoogleMapAreaChange}
                                        autoComplete="off"
                                        // label="گوگل مپ"
                                        label={google_map_area_input}
                                        inputProps={{
                                            maxLength: 1000,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(googleMapAreaError)}
                                        helperText={googleMapAreaError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={phone1 ?? ""}
                                        onChange={handlePhone1Change}
                                        autoComplete="off"
                                        // label="همراه (فوتر)"
                                        label={phone1_input}
                                        inputProps={{
                                            maxLength: 20,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(phone1Error)}
                                        helperText={phone1Error}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={phone2 ?? ""}
                                        onChange={handlePhone2Change}
                                        autoComplete="off"
                                        // label="همراه (تماس با ما)"
                                        label={phone2_input}
                                        inputProps={{
                                            maxLength: 20,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(phone2Error)}
                                        helperText={phone2Error}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={tel ?? ""}
                                        onChange={handleTelChange}
                                        autoComplete="off"
                                        // label="تلفن"
                                        label={tel_input}
                                        inputProps={{
                                            maxLength: 20,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(telError)}
                                        helperText={telError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={email1 ?? ""}
                                        onChange={handleEmail1Change}
                                        autoComplete="off"
                                        // label="ایمیل 1"
                                        label={email1_input}
                                        inputProps={{
                                            maxLength: 50,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(email1Error)}
                                        helperText={email1Error}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={email2 ?? ""}
                                        onChange={handleEmail2Change}
                                        autoComplete="off"
                                        // label="ایمیل 2"
                                        label={email2_input}
                                        inputProps={{
                                            maxLength: 50,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(email2Error)}
                                        helperText={email2Error}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={bankTerminalId ?? ""}
                                        onChange={handleBankTerminalIdChange}
                                        autoComplete="off"
                                        // label="شماره ترمینال"
                                        label={bank_terminal_id_input}
                                        inputProps={{
                                            maxLength: 10,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(bankTerminalIdError)}
                                        helperText={bankTerminalIdError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={bankUsername ?? ""}
                                        onChange={handleBankUsernameChange}
                                        autoComplete="off"
                                        // label="نام کاربری درگاه"
                                        label={bank_username_input}
                                        inputProps={{
                                            maxLength: 10,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(bankUsernameError)}
                                        helperText={bankUsernameError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={bankPassword ?? ""}
                                        onChange={handleBankPasswordChange}
                                        autoComplete="off"
                                        // label="رمز عبور درگاه"
                                        label={bank_password_input}
                                        inputProps={{
                                            maxLength: 10,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(bankPasswordError)}
                                        helperText={bankPasswordError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={bankAdditionalData ?? ""}
                                        onChange={handleBankAdditionalDataChange}
                                        autoComplete="off"
                                        // label="متن درگاه"
                                        label={bank_additional_data_input}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        error={Boolean(bankAdditionalDataError)}
                                        helperText={bankAdditionalDataError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={bankCallbackUrl ?? ""}
                                        onChange={handleBankCallbackUrlChange}
                                        autoComplete="off"
                                        // label="آدرس برگشت از درگاه"
                                        label={bank_callback_url_input}
                                        inputProps={{
                                            maxLength: 255,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(bankCallbackUrlError)}
                                        helperText={bankCallbackUrlError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={temporaryCodeId ?? ""}
                                        onChange={handleTemporaryCodeIdChange}
                                        inputProps={{
                                            maxLength: 15,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        autoComplete="off"
                                        // label="شناسه رمز موقت"
                                        label={temporary_code_id_input}
                                        error={Boolean(temporaryCodeIdError)}
                                        helperText={temporaryCodeIdError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={activeCodeId ?? ""}
                                        onChange={handleActiveCodeIdChange}
                                        inputProps={{
                                            maxLength: 15,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        autoComplete="off"
                                        // label="شناسه کد فعال‌سازی"
                                        label={active_code_id_input}
                                        error={Boolean(activeCodeIdError)}
                                        helperText={activeCodeIdError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={apiKey ?? ""}
                                        onChange={handleApiKeyChange}
                                        inputProps={{
                                            maxLength: 44,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        autoComplete="off"
                                        // label="کلید سرویس پیامک"
                                        label={api_key_input}
                                        error={Boolean(apiKeyError)}
                                        helperText={apiKeyError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={categoryRows ?? ""}
                                        onChange={handleCategoryRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطرهای دسته‌بندی"
                                        label={category_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(categoryRowsError)}
                                        helperText={categoryRowsError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={contactRows ?? ""}
                                        onChange={handleContactRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطرهای تماس با ما"
                                        label={contact_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(contactRowsError)}
                                        helperText={contactRowsError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={courseRows ?? ""}
                                        onChange={handleCourseRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطرهای دوره"
                                        label={course_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(courseRowsError)}
                                        helperText={courseRowsError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={eventRows ?? ""}
                                        onChange={handleEventRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطرهای رویداد"
                                        label={event_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(eventRowsError)}
                                        helperText={eventRowsError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={galleryRows ?? ""}
                                        onChange={handleGalleryRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطرهای گالری"
                                        label={gallery_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(galleryRowsError)}
                                        helperText={galleryRowsError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={instructorRows ?? ""}
                                        onChange={handleInstructorRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطر اساتید"
                                        label={instructor_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(instructorRowsError)}
                                        helperText={instructorRowsError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        type="text"
                                        value={userRows ?? ""}
                                        onChange={handleUserRowsChange}
                                        autoComplete="off"
                                        // label="نمایش تعداد سطر کاربران"
                                        label={user_rows_input}
                                        inputProps={{
                                            maxLength: 5,
                                            style: { direction: "ltr", textAlign: "left" }
                                        }}
                                        error={Boolean(userRowsError)}
                                        helperText={userRowsError}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "flex-end" }}>
                            <UploadProgressBar />
                            <LoadingButton
                                variant="outlined"
                                loadingPosition="start"
                                size="large"
                                loading={!loadingUpdateButton}
                                disabled={
                                    !bankTerminalId ||
                                    !bankUsername ||
                                    !bankPassword ||
                                    !bankAdditionalData ||
                                    !bankCallbackUrl ||
                                    !temporaryCodeId ||
                                    !activeCodeId ||
                                    !apiKey ||
                                    !categoryRows ||
                                    !contactRows ||
                                    !courseRows ||
                                    !eventRows ||
                                    !galleryRows ||
                                    !instructorRows ||
                                    !userRows
                                }
                                startIcon={<SaveOutlined />}
                                onClick={handleUpdate}
                            >
                                {/* ذخیره */}
                                {save}
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </Fade>
            </Box>
        </>
    );
};

export default SettingsPost;
